import React, { useContext, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { SrRefTextIntRe15 } from "../../../ref/text";
import MainContext from "../../../components/helpers/context/mainContext";
import { BackFAB } from "../../../components/lvl0Components/buttons/FABs";
import { TextBtn } from "../../../components/lvl0Components/buttons/textBtns";
import { SetupTemplate } from "../../../components/lvl0Components/setupTemplate";

import { style } from "./style";

const UserType = () => {
  // Main context
  const {
    scrollBoxWidth,
    // API related
    setupNew,
    setNew
  } = useContext(MainContext);
  const navigate = useNavigate();

  // To check if Invite Code is present in the params
  const [searchParams] = useSearchParams();

  // If user doesn't exist, navigate to login
  useEffect(() => {
    !setupNew.newProfile === true && navigateToLogin();
  }, []);

  // Navigate to main startup listing
  const navigateToLogin = () => {
    navigate("/login");
  };

  // Navigate to Choice on clicking create
  const navigateToChoice = () => {
    setNew({ ...setupNew, userType: "employee" });

    // Add Invite Code in the link if it is present in the link
    var link = "";
    const code = searchParams.get("inviteCode");
    link = code ? `/userProfile?inviteCode=${code}` : "/userProfile";

    const community_id = searchParams.get("community_id");

    if (code) {
      // If user is invited by someone
      setNew({ ...setupNew, orgJoin: true });
      navigate(link);
    } else if (community_id) {
      navigate(`/userProfile?community_id=${community_id}`);
    } else {
      //If user is not invited by anyone
      setNew({ ...setupNew, orgJoin: false });
      navigate(link);
    }
  };

  // Navigate to Choice on clicking create
  const navigateToUserProfile = () => {
    setNew({ ...setupNew, userType: "viewer" });
    navigate("/userProfile");
  };

  useEffect(() => {
    const referral_code = searchParams.get("referral_code");
    const code = searchParams.get("inviteCode");

    var link = "";
    link = code ? `/userProfile?inviteCode=${code}` : "/userProfile";

    if (code) {
      // If user is invited by someone
      setNew({ ...setupNew, orgJoin: true });
      navigate(link);
    } else if (referral_code) {
      setNew({ ...setupNew, orgJoin: false });
      navigate(`/userProfile?referral_code=${referral_code}`);
    } else {
      //If user is not invited by anyone
      setNew({ ...setupNew, orgJoin: false });
      navigate(link);
    }
  }, []);

  // Snackbar
  const snackbar = (message) => {
    toast(message, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
  };

  return (
    <div style={style.outerMost}>
      <SetupTemplate>
        {/* Choice screen */}
        <div style={{ ...style.flexColumn, ...{ width: `${scrollBoxWidth}px` } }}>
          <div style={style.paddingHor24}>
            {/* Text */}
            <SrRefTextIntRe15
              content={"Are you a founder/co-founder at a startup or a member of its team?"}
              state={"enabled"}
              style={style.colorBlack}
            />

            <div style={{ ...style.flexRow, ...style.marginTop24 }}>
              {/* Yes */}
              <TextBtn onClick={() => navigateToChoice()} content={"Yes"} />

              <div style={style.marginLeft12}>
                {/* No */}
                <TextBtn onClick={() => navigateToUserProfile()} content={"No"} />
              </div>
            </div>
          </div>

          {/* Back button */}
          <BackFAB onClick={() => navigateToLogin()} />
        </div>
      </SetupTemplate>

      {/* Snackbar */}
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
};

export default UserType;
