import React, { useState } from "react";
import SrRefColour from "../../../../ref/colours";
import {
  SrCompIconEdit,
  SrCompIconAdd,
  SrCompIconDelete
} from "../../../../components/lvl0Components/icons";
import style from "./style";

// Avatar for big image
const EditBtn0 = (props) => {
  const [btnHover, setBtnHover] = useState(false);

  // Dynamic styling for this component
  const style2 = {
    divStyle: {
      ...style.divStyle,
      borderColor: props.selected || btnHover ? SrRefColour.Neutral2A : SrRefColour.Neutral2B,
      backgroundColor: props.selected || btnHover ? SrRefColour.Neutral1C : SrRefColour.Neutral0
    }
  };

  return (
    <div
      onMouseEnter={() => setBtnHover(true)}
      onMouseLeave={() => setBtnHover(false)}
      onClick={props.onClick}
      style={style.clickable}
    >
      {/* Edit button */}
      <div style={style2.divStyle}>
        {/* Edit icon */}
        <SrCompIconEdit size={20} color={SrRefColour.Neutral2A} />
      </div>
    </div>
  );
};

// Add button
const AddBtn0 = (props) => {
  const [btnHover, setBtnHover] = useState(false);

  return (
    <div
      onMouseEnter={() => setBtnHover(true)}
      onMouseLeave={() => setBtnHover(false)}
      onClick={props.onClick}
      style={style.clickable}
    >
      {/* Edit button */}
      <div
        style={{
          ...style.button,
          ...style.transition,
          borderColor: props.selected || btnHover ? SrRefColour.Neutral2A : SrRefColour.Neutral2B,
          backgroundColor: props.selected || btnHover ? SrRefColour.Neutral1C : SrRefColour.Neutral0
        }}
      >
        {/* Add icon */}
        <SrCompIconAdd size={20} color={SrRefColour.Neutral2A} />
      </div>
    </div>
  );
};

// Delete button
const DeleteBtn0 = (props) => {
  const [btnHover, setBtnHover] = useState(false);

  return (
    <div
      onMouseEnter={() => setBtnHover(true)}
      onMouseLeave={() => setBtnHover(false)}
      onClick={props.onClick}
      style={style.clickable}
    >
      {/* Edit button */}
      <div
        style={{
          ...style.button,
          ...{
            borderColor: props.selected || btnHover ? SrRefColour.Neutral2A : SrRefColour.Neutral2B,
            backgroundColor:
              props.selected || btnHover ? SrRefColour.Neutral1C : SrRefColour.Neutral0
          },
          ...style.transition
        }}
      >
        {/* Add icon */}
        <SrCompIconDelete size={20} color={SrRefColour.Neutral2A} />
      </div>
    </div>
  );
};

export { EditBtn0, AddBtn0, DeleteBtn0 };
