import React, { useState, useRef, useLayoutEffect } from "react";
import SrRefColour from "../../../ref/colours";
import { style } from "./style";
import { SizeLimit } from "../characterLimit";

// Text input for Inter regular font size 12
const TextAreaIntRe12 = (props) => {
  // Text area height resizing
  const textAreaRef = useRef(null);

  useLayoutEffect(() => {
    // Reset height - important to shrink on delete
    textAreaRef.current.style.height = "inherit";
    // Set height
    textAreaRef.current.style.height = `${Math.max(textAreaRef.current.scrollHeight, 16)}px`;
  }, [props.value]);

  // Hover and click states
  const [inputHover, setInputHover] = useState(false);
  const [inputClick, setInputClick] = useState(false);

  const setInputHoverFunc = () => {
    setInputClick(true);
  };

  // Dynamic styling for this component
  const style2 = {
    textStyle: {
      ...style.textStyle,
      ...props.gradientText,
      border:
        inputHover || inputClick
          ? `1px solid ${SrRefColour.Neutral2A}`
          : `1px solid ${SrRefColour.Neutral2B}`
    }
  };

  return (
    <div>
      <textarea
        name={props.name}
        placeholder={props.placeholder}
        limit={props.limit}
        isShown={props.isShown}
        value={props.value}
        readOnly={props.readOnly ? props.readOnly : false}
        rows={props.rows ? props.rows : 1}
        onChange={props.onChange}
        onMouseEnter={() => setInputHover(true)}
        onMouseLeave={() => setInputHover(false)}
        onClick={props.onClick}
        style={style2.textStyle}
        ref={textAreaRef}
        required
      />
      {!props.noLimit && (
        <SizeLimit
          isShown={props.isShown}
          lengthOfDesc={props.value ? props.value.length : 0}
          lengthLimit={props.limit}
        />
      )}
    </div>
  );
};

export { TextAreaIntRe12 };
