import React, { useState, useContext, useEffect, useRef } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import SrRefColour from "../../../ref/colours";
import {
  SrRefTextRubSb25,
  SrRefTextIntSb20,
  SrRefTextRubSb18,
  SrRefTextIntRe12,
  SrRefTextIntLi12,
  SrRefTextIntMe10
} from "../../../ref/text";

import { apiendpoint } from "../../../components/helpers/apiendpoint";
import { Industries, StageArrShort } from "../../../components/helpers/localData";
import MainContext from "../../../components/helpers/context/mainContext";
import { returnRGBA } from "../../../components/helpers/themeColors";

import {
  SrCompIconCalendarToday,
  SrCompIconLocation,
  SrCompIconGroup,
  SrCompIconContentCopy
} from "../../../components/lvl0Components/icons";
import Loading from "../../../components/lvl0Components/Loading";
import { Popup } from "../../../components/lvl0Components/popup";
import { AddBtn0, EditBtn0 } from "../../../components/lvl0Components/buttons/iconBtns";
import { ShramStamp } from "../../../components/lvl0Components/shramStamp";
import { AddNewBtn, EventChoiceBtn } from "../../../components/lvl1Components/buttons/iconBtns";
import { LargeCard } from "../../../components/lvl1Components/cards/largeCard";
import AccountPopup from "../../../components/lvl1Components/setupPopups/AccountPopup";
import EventPopup from "../../../components/lvl1Components/setupPopups/EventPopup";
import SpeakerPopup from "../../../components/lvl1Components/setupPopups/SpeakerPopup";
import { CommunityColorPanel } from "../../../components/lvl2Components/communityColorPanel";
// import SpeakerPopup from "../../../components/lvl1Components/setupPopups/SpeakerPopup";
// import SponsorPopup from "../../../components/lvl1Components/setupPopups/SponsorPopup";
// import ReferralPopup from "../../../components/lvl1Components/setupPopups/ReferralPopup";
import { UserPanel } from "../../../components/lvl2Components/userPanel";

import {
  DateAndLocation,
  SpeakersList,
  SponsorsList,
  AttendeeCard
} from "../../../components/quickComponents";

import style from "./style";
import { Avatar } from "../../../components/lvl0Components/avatar";

const Community = () => {
  // Context
  const {
    screenWidth,
    breakpoint1,
    breakpoint2,
    breakpoint3,
    defaultColor,
    colorSplit,
    // API related
    user,
    userStartup,
    scrollToTop,

    // Community related
    eventData,
    setEventData
  } = useContext(MainContext);
  const navigate = useNavigate();

  // Get basic page and user details
  let { id } = useParams();
  //   const [id, setId] = useState(16);
  let jwt = localStorage.getItem("@jwtauth");
  if (!jwt) jwt = "";

  const [searchParams] = useSearchParams();
  const ve = searchParams.get("ve");

  // If user doesn't exist, navigate to login
  window.onload = function () {
    if (!user) {
      localStorage.setItem("@initial", window.location.pathname);
      // navigateToLogin();
    }
  };

  window.onbeforeunload = function () {
    setLoading(true);
    localStorage.setItem("loading", "true");
  };

  const [showNotLivePopup, setShowNotLivePopup] = useState(false);
  const [showNotLivePopupHover, setShowNotLivePopupHover] = useState(false);
  const [showReferral, setShowReferral] = useState(false);
  const [showReferralHover, setShowReferralHover] = useState(false);
  const [showEventEndedPopup, setShowEventEndedPopup] = useState(false);
  const [showEventEndedPopupHover, setShowEventEndedPopupHover] = useState(false);
  const [showWaitlist, setShowWaitlist] = useState(false);

  const userOrgId = localStorage.getItem("@orgid");
  const [orgId, setOrgId] = useState();
  const [showLogin, setShowLogin] = useState(false);
  const [orgs, setOrgs] = useState([]);
  const [pendingOrg, setPendingOrgs] = useState([]);
  const [pendingStartups, setPendingStartups] = useState([]);
  const [loading, setLoading] = useState(localStorage.getItem("loading") === "true" || true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [communityDefaultValue, setCommunityDefaultValue] = useState({
    name: "Community's Name",
    logo: "https://shram-mvp-logo.s3.ap-south-1.amazonaws.com/org_logos/org_02.png",
    description: "A brief description of your community",
    cover: "https://shram-mvp-logo.s3.ap-south-1.amazonaws.com/platform/event/5RVEYLx7N.png"
  });

  // Navigate to main startup listing
  const navigateHome = () => {
    navigate("/");
    scrollToTop();
  };

  const navigateToCommunity = () => {
    let communityId = localStorage.getItem("@communityId");
    communityId = communityId ? communityId : "20";
    navigate(`/community/${communityId}`);
  };

  // Navigate to main startup listing
  const navigateToLogin = () => {
    navigate("/login");
  };

  // Navigate to a particular startup listing
  const navigateToStartup = (e) => {
    navigate(`/startup/${e.id}`, { state: { id: e.id } });
    setOrgId(e.id);
    scrollToTop();
  };

  // Navigate to a particular startup listing
  const navigateToOwnStartup = (e) => {
    navigate(`/startup/${e}`, { state: { id: e } });
    setOrgId(e);
    scrollToTop();
  };

  // Constants
  const screenPadding = breakpoint3 ? 24 : 48;
  const largeCardWidth = 348 - 6;
  const nameLimit = 15;
  const descriptionLimit = 31;

  // scrollPosition
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset; // Find the top offset of the window
    setScrollPosition(position); // Set scrollPosition = top offset of the window
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true }); // listen to scroll event
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // scrollRef
  const scrollRef = useRef();
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const handleScrollPosition = () => {
      if (scrollRef.current) {
        setDarkMode(scrollPosition >= scrollRef.current.offsetTop - 48);
      }
    };

    handleScrollPosition();

    if (scrollRef.current) {
      scrollRef.current.addEventListener("scroll", handleScrollPosition, { passive: true });
    }

    return () => {
      if (scrollRef.current) {
        scrollRef.current.removeEventListener("scroll", handleScrollPosition);
      }
    };
  }, [scrollPosition]);

  // Snackbar
  const snackbar = (message) => {
    toast(message, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
  };

  const [startup, setStartup] = useState({
    name: "Startup's name",
    description: "Startup description",
    logo: require("../../../media/no_org.png"),
    listingStatus: "to",
    theme: {
      primary: { color: defaultColor },
      secondary: { color: defaultColor },
      tertiary: { color: defaultColor },
      accent: { color: defaultColor },
      invertBackground: false
    },
    industry: "Industry",
    sectors: ["Sector 1", "Sector 2"],
    team: [
      {
        fname: "Name",
        lname: "",
        designation: "Founder",
        pic: require("../../../media/no_user.png"),
        representer: true
      }
    ],
    cofounderCount: 1,
    teamSize: 3,
    other: {
      year: "year",
      location: "location",
      stage: null,
      socialMedia: {
        discord: "",
        facebook: "",
        instagram: "",
        linkedIn: "",
        medium: "",
        pinterest: "",
        reddit: "",
        substack: "",
        twitter: "",
        whatsapp: "",
        youtube: ""
      },
      status: ""
    },
    pitch: {
      problem:
        "What is the specific pain point your target customer faces? What are the shortcomings to current solutions?",
      solution:
        "Describe your value proposition briefly. Is it a product or a service? How does it deliver value to your customer?",
      image: require("../../../media/image_default.png"),
      video: {
        link: "",
        thumbnail: require("../../../media/video_default.png")
      },
      website: "",
      features:
        "Articulate the features of your product/service. These need not be unique but should paint a picture of what a user can do with it.",
      unique:
        "Talk about how your product solves the pain point you've just described in a unique way. How does this set you apart from your competitors?",
      market:
        "Who is your user? Can you describe them more accurately? Are there more than one customer segment you are targeting?"
    }
  });

  // Get basic data
  useEffect(() => {
    try {
      let jwt = localStorage.getItem("@jwtauth");
      if (!jwt) jwt = "";
      setLoading(true);
      fetch(`${apiendpoint}/platform/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`
        }
      })
        .then((res) => {
          if (res.ok) return res.json();
          else throw new Error("Unauthorized");
        })

        .then((data) => {
          let temp = [];
          data.forEach((item) => {
            temp = [
              ...temp,
              {
                id: item.id,
                name: item.name,
                description: item.description,
                logo: item.logo,
                theme: {
                  primary: {
                    color: item.primary_colour ? item.primary_colour : defaultColor
                  },
                  accent: { color: item.accent_colour ? item.accent_colour : "" },
                  invertBackground: item.invert_background === "Y" ? true : false
                },
                industry: Industries[item.industry],
                sectors: [item.sector1, item.sector2],
                event_id: item.event_id
              }
            ];
          });
          // setOrgs(temp);
          setLoading(false);
          localStorage.removeItem("loading");
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  // Set user's startup
  const fetchUserStartupData = async (jwt, orgId) => {
    if (!jwt || !orgId) return;
    fetch(`${apiendpoint}/platform/org/${orgId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw new Error("Unauthorized");
      })
      .then((data) => {
        if (data.org) {
          const startup = {
            id: data.org.id,
            name: data.org.name,
            description: data.org.description,
            logo: data.org.logo,
            inviteCode: data.org.invite_code,
            theme: {
              primary: {
                color: data.org.primary_colour ? data.org.primary_colour : defaultColor
              },
              secondary: {
                color: data.org.secondary_colour
              },
              tertiary: {
                color: data.org.tertiary_colour
              },
              accent: {
                color: data.org.accent_colour
              },
              invertBackground: data.org.invert_background
            },
            industry: Industries[data.org.industry],
            sectors: [data.org.sector1, data.org.sector2],
            team: data.orgMembers.map((lead) => {
              return {
                id: lead.id,
                fname: lead.fname,
                lname: lead.lname,
                designation: lead.designation,
                pic: lead.picture,
                linkedIn: lead.linkedin,
                orgId: lead.org_id,
                orgLead: lead.is_org_lead,
                representer: lead.representer
              };
            }),
            cofounderCount: data.org.cofounder_count,
            teamSize: data.org.team_size,
            other: {
              year: data.org.establishment_year,
              location: data.org.location,
              zaubacorp: data.org.zaubacorp,
              stage: StageArrShort.indexOf(data.org.stage),
              socialMedia: {
                discord: data.org.discord,
                facebook: data.org.facebook,
                instagram: data.org.instagram,
                linkedIn: data.org.linkedin,
                medium: data.org.medium,
                pinterest: data.org.pinterest,
                reddit: data.org.reddit,
                substack: data.org.substack,
                twitter: data.org.twitter,
                whatsapp: data.org.whatsapp,
                youtube: data.org.youtube
              },
              status: data.org.org_status
            },
            pitch: {
              problem: data.platform.problem,
              solution: data.platform.solution,
              image: data.platform.image,
              video: {
                link: data.platform.video,
                thumbnail: data.platform.thumbnail
              },
              website: data.org.website,
              features: data.platform.features,
              unique: data.platform.unique_features,
              market: data.platform.market
            }
          };
          setPrimaryColor(startup.theme?.primary.color);
          setReturnPrimary(
            returnRGBA(
              startup.theme?.primary.color.r,
              startup.theme?.primary.color.g,
              startup.theme?.primary.color.b,
              1
            )
          );
          setStartup(startup);
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    try {
      setLoading(true);
      let jwt = localStorage.getItem("@jwtauth");
      let orgId = localStorage.getItem("@orgid");
      if (!jwt) jwt = "";
      fetchUserStartupData(jwt, orgId);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }, []);

  const [primaryColor, setPrimaryColor] = useState(startup.theme.primary.color);
  const [primaryColorEdit, setPrimaryColorEdit] = useState(false);
  const [secondaryColor, setSecondaryColor] = useState(startup.theme.secondary.color);
  const [secondaryColorEdit, setSecondaryColorEdit] = useState(false);
  const [accentColor, setAccentColor] = useState(startup.theme.accent.color);
  const [invertBackground, setInvertBackground] = useState(startup.theme.invertBackground);

  const [returnPrimary, setReturnPrimary] = useState();
  useEffect(() => {
    setReturnPrimary(
      returnRGBA(
        colorSplit(startup.theme.primary.color).r,
        colorSplit(startup.theme.primary.color).g,
        colorSplit(startup.theme.primary.color).b,
        1
      )
    );
  });

  const [returnPrimaryCard, setReturnPrimaryCard] = useState();
  useEffect(() => {
    setReturnPrimaryCard(
      returnRGBA(
        startup.theme.primary.color.r,
        startup.theme.primary.color.g,
        startup.theme.primary.color.b,
        1
      )
    );
  });

  const [accountEdit, setAccountEdit] = useState(false);
  const [eventEdit, setEventEdit] = useState(false);
  const [editHover, setEditHover] = useState(false);
  const [editHover1, setEditHover1] = useState(false);

  // Popup effect
  const body = document.body;

  // Disallow scroll when login popup is visible
  useEffect(() => {
    body.style.overflowY = showLogin ? "hidden" : "auto";
  }, [showLogin]); // Whenever scroll position chamges

  // Allow scrolling on body element in DOM
  const allowScroll = () => {
    body.style.overflowY = "auto";
  };

  // Conditions to allow expanding of other startups
  // array of orgs with status complete
  const [allowNavigation, setAllowNavigation] = useState(
    true
    // orgs.includes(userStartup)
  );

  // useStates for Events
  const [guest, setGuest] = useState([]);
  const [guestAdd, setGuestAdd] = useState(false);
  const [specialGuest, setSpecialGuest] = useState([]);
  const [specialGuestAdd, setSpecialGuestAdd] = useState(false);
  const [sponsors, setSponsors] = useState([]);
  const [sponsorEdit, setSponsorEdit] = useState(-1);
  const [sponsorAdd, setSponsorAdd] = useState(false);
  const [speakers, setSpeakers] = useState([]);
  const [speakerEdit, setSpeakerEdit] = useState(-1);
  const [speakerAdd, setSpeakerAdd] = useState(false);
  const [startups, setStartups] = useState([]);
  const [startupAdd, setStartupAdd] = useState(false);
  const [fetchMore, setFetchMore] = useState(false);

  const [eventInfoEdit, setEventInfoEdit] = useState(false);

  const [showInvites, setShowInvites] = useState(!user ? false : isAdmin ? true : false);
  const [showPreview, setShowPreview] = useState(!user ? true : isAdmin ? false : true);

  // Fetch event data
  const fetchEventData = () => {
    fetch(`${apiendpoint}/community?community_id=${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw new Error("Unauthorized");
      })
      .then((data) => {
        // Find if user is admin or not
        for (let num in data.event.speaker) {
          if (data.event.speaker[num].id === user?.id) {
            setIsAdmin(true);
            break;
          }
        }

        setGuest(data?.eventGuest?.guest);
        setSpecialGuest(data?.eventGuest?.specialGuests);
        setSponsors(data.event.sponsors);
        setSpeakers(data.event.speaker);
        setStartups(data.startups);
        setPendingStartups(data.pendingStartups);

        // Process data to be able show in largeCard component
        let temp = [];
        data.startups.forEach((item) => {
          item = item.org;
          temp = [
            ...temp,
            {
              id: item.id,
              name: item.name,
              description: item.description,
              logo: item.logo,
              theme: {
                primary: {
                  color: item.primary_colour ? item.primary_colour : defaultColor
                },
                accent: { color: item.accent_colour ? item.accent_colour : "" },
                invertBackground: item.invert_background === "Y" ? true : false
              },
              industry: Industries[item.industry],
              sectors: [item.sector1, item.sector2],
              event_id: item.event_id
            }
          ];
        });
        setOrgs(temp);

        //Process the pending startups to be able to show in largeCard component
        temp = [];
        data.pendingStartups.forEach((item) => {
          item = item.org;
          temp = [
            ...temp,
            {
              id: item.id,
              name: item.name,
              description: item.description,
              logo: item.logo,
              theme: {
                primary: {
                  color: item.primary_colour ? item.primary_colour : defaultColor
                },
                accent: { color: item.accent_colour ? item.accent_colour : "" },
                invertBackground: item.invert_background === "Y" ? true : false
              },
              industry: Industries[item.industry],
              sectors: [item.sector1, item.sector2],
              event_id: item.event_id
            }
          ];
        });

        setPendingOrgs(temp);
        setEventData(data.event.eventData);
      });
  };

  // func to change event status
  const handleEventStatus = (s) => {
    fetch(`${apiendpoint}/community/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`
      },
      body: JSON.stringify({ event_details: { status: s } })
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw new Error("Unauthorized");
      })
      .then((data) => {
        setFetchMore(true);
        if (s === "live")
          snackbar("Your event is live! Now, attendees can interact with one-another.");
        if (s === "pending")
          snackbar("Event published! Now you can invite attendees to the event.");
      });
  };

  // Fetch event data after every change
  useEffect(() => {
    fetchEventData();
    setFetchMore(false);
  }, [fetchMore]);

  const logoImgWidth = 120;
  const logoImgHeight = 120;
  const startDate = eventData?.event_start_date;
  const endDate = eventData?.event_end_date;
  const location = eventData?.location;

  const [returnPrimaryInverse, setReturnPrimaryInverse] = useState(
    returnRGBA(primaryColor.r, primaryColor.g, primaryColor.b, 0.1 / primaryColor.a)
  );
  // Picker for primary colour
  const PrimaryColourSet = (color) => {
    setPrimaryColor(color.rgb);
    setReturnPrimary(returnRGBA(color.rgb.r, color.rgb.g, color.rgb.b, 1));
    setReturnPrimaryInverse(returnRGBA(color.rgb.r, color.rgb.g, color.rgb.b, 0.1 / color.rgb.a));
    setStartup({
      ...startup,
      theme: {
        ...startup.theme,
        primary: { color: color.rgb }
      }
    });
  };

  // Picker for secondary colour
  const SecondaryColourSet = (color) => {
    setSecondaryColor(color.rgb);
    setStartup({
      ...startup,
      theme: {
        ...startup.theme,
        secondary: { color: color.rgb }
      }
    });
  };

  const communityInviteLink =
    "https://platform.shram.io/login?referral_code=" + eventData.referral_code;

  const Event = (w) => {
    return (
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="description" content={"The Growth Platform your startup deserves"} />
          <meta property="og:title" content={"The Rise Summit | Shram"} />
          <meta property="og:image" content={`require("../../../media/logo512_green.png)`} />
          <meta property="og:url" content={"https://platform.shram.io/"} />
          <title>BITS | Shram</title>
          <link rel="canonical" href="https://platform.shram.io/" />
        </Helmet>

        <div style={style.screen}>
          {/* cover image */}
          <div
            style={{
              position: "absolute",
              height: "20rem",
              aspectRatio: "6/1",
              backgroundColor: "grey"
            }}
          >
            <img
              src={eventData?.cover ? eventData?.cover : communityDefaultValue.cover}
              style={{ width: "100%", height: "100%", objectFit: "fill" }}
            />
          </div>

          {/* User panel at the top when login popup is not being shown */}
          {!showLogin && (
            <UserPanel
              user={user}
              userStartup={userStartup}
              screenPadding={screenPadding}
              screenWidth={screenWidth}
              onClickHome={() => navigateToCommunity()}
              onClickApprove={() => {}}
              onClickAccount={() => setAccountEdit(true)}
              onClickPreview={() => navigateToOwnStartup(user.orgId)}
              eventEdit={eventEdit}
              onClickEdit2={() => setEventEdit(!eventEdit)}
              fromHome={true}
              fromEvent={true}
              darkMode={!darkMode}
              setShowReferral={setShowReferral}
              setShowInvites={setShowInvites}
              eventData={eventData}
              isAdmin={isAdmin}
              allowEdit={true}
            />
          )}

          {/* event info edit  */}
          {eventInfoEdit && (
            <Popup
              onClick={() => {
                setAccountEdit(false);
                setEditHover1(false);
                setEventInfoEdit(false);
                allowScroll();
              }}
              scrollPosition={scrollPosition}
              noPadding={true}
              modal={breakpoint3 ? true : false}
            >
              <EventPopup
                eventId={id}
                setFetchMore={setFetchMore}
                setEventInfoEdit={setEventInfoEdit}
                setEdit={setEventInfoEdit}
                setEditHover={setEditHover1}
                allowScroll={allowScroll}
                setEventData={setEventData}
                eventData={eventData}
              />
            </Popup>
          )}

          {/* (AVIRAL) speaker modal for editing  */}
          {(speakerEdit >= 0 || speakerAdd) && (
            <Popup
              onClick={() => {
                setSpeakerEdit(-1);
                setSpeakerAdd(false);
                // setEditHover1(false);
                // setEventInfoEdit(false);
                // allowScroll();
              }}
              scrollPosition={scrollPosition}
              noPadding={true}
              modal={breakpoint3 ? true : false}
            >
              <SpeakerPopup
                data={speakers[speakerEdit]}
                setSpeakerAdd={setSpeakerAdd}
                setSpeakerEdit={setSpeakerEdit}
                setFetchMore={setFetchMore}
                // if we want to add speaker
                speakerAdd={speakerAdd}
                eventId={eventData.id}
              />
            </Popup>
          )}

          {(sponsorEdit >= 0 || sponsorAdd) && (
            <Popup
              onClick={() => {
                setSponsorAdd(false);
                setSponsorEdit(-1);
                // setEditHover1(false);
                // setEventInfoEdit(false);
                allowScroll();
              }}
              scrollPosition={scrollPosition}
              noPadding={true}
              modal={breakpoint3 ? true : false}
            >
              {/* <SponsorPopup
                data={sponsors[sponsorEdit]}
                setSponsorEdit={setSponsorEdit}
                setFetchMore={setFetchMore}
                setSponsorAdd={setSponsorAdd}
                // if we want to add speaker
                sponsorAdd={sponsorAdd}
                eventId={eventData.id}
              /> */}
            </Popup>
          )}

          {/* Event cover */}
          <div
            style={{
              ...style.cover,
              ...{ paddingTop: `${(breakpoint2 ? 12 * 2 : 36 * 2) + 74 + 24}px` } // marginVertical wrt screen size + height of the UserPanel
            }}
          >
            <div
              style={{
                ...{
                  flexDirection: breakpoint3 ? "column" : "row",
                  alignItems: breakpoint3 ? "flex-start" : "flex-end",
                  justifyContent: "center",
                  margin: "0 auto"
                }
              }}
            >
              <div style={style.flexColumn}>
                {/* Event Details  */}
                <div
                  style={{
                    ...style.flexColumn,
                    ...style.gap36
                  }}
                >
                  <div
                    style={{
                      ...style.flexColumn,
                      ...style.gap18,
                      width: "calc(100% - 24px)",
                      margin: "0 auto",
                      maxWidth: `${w}px`
                    }}
                  >
                    <div
                      onClick={() => eventEdit && setEventInfoEdit(true)}
                      onMouseEnter={() => eventEdit && setEditHover(true)}
                      onMouseLeave={() => eventEdit && setEditHover(false)}
                      style={{
                        ...style.borderRadius18,
                        ...{
                          width: "fit-content",
                          marginTop: "5rem",
                          marginLeft: "-6px",
                          marginRight: "-6px",
                          padding: "3px",
                          backgroundImage:
                            editHover &&
                            "linear-gradient(to bottom right, rgba(255, 255, 255, 0.06), rgba(255, 255, 255, 0.03))",
                          cursor: eventEdit && "pointer"
                        },
                        ...style.transition
                      }}
                    >
                      <div
                        style={{
                          ...style.flexColumn,
                          ...style.gap18,
                          ...style.borderRadius16,
                          ...{
                            position: "relative",
                            width: "fit-content",
                            padding: eventEdit ? "14px 8px" : "15px 8px",
                            border: eventEdit && `1px dotted ${SrRefColour.Neutral2B}`
                          },
                          ...style.transition
                        }}
                      >
                        {/* Event logo */}
                        <Avatar
                          width={95}
                          pic={
                            eventData?.logo ? eventData?.logo : require("../../../media/no_org.png")
                          }
                          borderColor={SrRefColour.Neutral2A}
                        />

                        {/* Event title */}
                        <div
                          style={{
                            ...style.flexColumn,
                            ...style.gap6,
                            ...style.fitContent,
                            marginLeft: eventEdit ? "6px" : "7px",
                            marginRight: eventEdit ? "6px" : "7px"
                          }}
                        >
                          {/* Event title */}
                          <SrRefTextRubSb25
                            content={eventData?.name ? eventData?.name : communityDefaultValue.name}
                            style={style.colorBlack}
                          />
                        </div>

                        {/* Event brief */}
                        <SrRefTextIntRe12
                          content={
                            eventData?.description
                              ? eventData?.description
                              : communityDefaultValue.description
                          }
                          style={{
                            ...style.colorBlack,
                            maxWidth: screenWidth > 768 ? "768px" : `${w}px`,
                            marginLeft: eventEdit ? "6px" : "7px",
                            marginRight: eventEdit ? "6px" : "7px"
                          }}
                        />

                        {/* <CommunityColorPanel
                          // Primary color
                          primaryColor={primaryColor}
                          setPrimaryColor={setPrimaryColor}
                          primaryColorEdit={primaryColorEdit}
                          setPrimaryColorEdit={setPrimaryColorEdit}
                          onPrimaryChange={PrimaryColourSet}
                          // Secondary color
                          secondaryColor={secondaryColor}
                          setSecondaryColor={setSecondaryColor}
                          secondaryColorEdit={secondaryColorEdit}
                          setSecondaryColorEdit={setSecondaryColorEdit}
                          onSecondaryChange={SecondaryColourSet}
                          // Other
                          setStartup={setStartup}
                          startup={startup}
                          // changePosition={changePosition}
                          scrollPosition={scrollPosition}
                          windowHeight={window.innerHeight}
                          bodyScrollHeight={document.body.scrollHeight}
                        /> */}

                        {eventEdit && (
                          <div
                            style={{ position: "absolute", top: "8px", right: "8px" }}
                            onClick={() => setEditHover(true)}
                          >
                            <EditBtn0 selected={editHover} />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Admins */}
                  {isAdmin && (
                    <div
                      style={{
                        ...style.flexColumn,
                        ...style.gap18,
                        marginTop: "-18px"
                      }}
                    >
                      <div
                        style={{
                          ...style.flexRow,
                          ...style.gap12,
                          maxWidth: `${w}px`,
                          width: "calc(100% - 24px)",
                          margin: "0 auto"
                        }}
                      >
                        {/* Admins title*/}
                        {speakers.length > 0 && (
                          <SrRefTextRubSb18
                            content={"Admins"}
                            style={{
                              ...style.minHeight32,
                              ...style.marginLeft12,
                              ...style.colorBlack
                            }}
                          />
                        )}

                        {/* Add Title modal */}
                        {eventEdit && <AddBtn0 onClick={() => setSpeakerAdd(true)} />}
                      </div>

                      {/* Speakers */}
                      <div
                        style={{
                          ...style.flexRow,
                          ...style.gap18,
                          ...style.overflowScroll,
                          paddingLeft: `calc(50% - ${w / 2 - 12}px)`,
                          paddingRight: `calc(50% - ${w / 2 - 12}px)`
                        }}
                      >
                        {/* List of Admin */}
                        <SpeakersList
                          speakers={speakers}
                          setSpeakerEdit={setSpeakerEdit}
                          setFetchMore={setFetchMore}
                          eventEdit={eventEdit}
                        />
                      </div>
                    </div>
                  )}

                  {isAdmin && (
                    <div
                      style={{
                        ...style.flexRow,
                        ...style.gap18,
                        ...style.overflowScroll,
                        ...style.clickable,
                        paddingLeft: `calc(50% - ${w / 2 - 12}px)`,
                        paddingRight: `calc(50% - ${w / 2 - 12}px)`
                      }}
                      onClick={() => {
                        // Copy text to clipboard
                        navigator.clipboard.writeText(
                          `Join ${eventData?.name} on Shram's Growth Platform by using this link here: ${communityInviteLink}`
                        );
                        snackbar("Invite link copied to clipboard");
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          minHeight: "32px",
                          color: SrRefColour.Neutral1A,
                          padding: "6px 12px",
                          borderRadius: "6px",
                          backgroundColor: SrRefColour.Neutral1B
                        }}
                      >
                        <SrRefTextIntRe12
                          content={`Invite link: https://platform.shram.io/login?referral_code=970676`}
                        />

                        {/* Copy icon */}
                        <div style={{ ...style.marginLeft12, width: "20px", height: "20px" }}>
                          <SrCompIconContentCopy size={20} color={SrRefColour.Neutral1A} />
                        </div>
                      </div>
                    </div>
                  )}

                  {isAdmin && eventData.status !== "live" && (
                    <div
                      style={{
                        ...style.flexColumn,
                        ...style.gap18
                      }}
                    >
                      <div
                        style={{
                          ...style.flexRow,
                          ...style.gap6,
                          maxWidth: `${w}px`,
                          width: "calc(100% - 24px)",
                          margin: "0 auto"
                        }}
                      >
                        {eventData.status === "pending" && (
                          <div
                            // Add two functions for the following:
                            // 1. Publish event if not already published
                            // 2. Make event live if event is published
                            onClick={() => handleEventStatus("live")}
                            style={{
                              ...style.flexRow,
                              ...style.gap12,
                              ...style.marginTop6,
                              ...style.marginHor12,
                              padding: "12px 12px 12px 18px",
                              ...style.borderRadius6,
                              backgroundColor: "#FF4F4F",
                              ...style.clickable
                            }}
                          >
                            <SrRefTextIntRe12 content={"GO LIVE"} />
                            <SrCompIconGroup />
                          </div>
                        )}

                        {eventData.event_status === "ad" && (
                          <div
                            onClick={() => handleEventStatus("pe")}
                            style={{
                              ...style.flexRow,
                              ...style.gap12,
                              ...style.marginTop6,
                              ...style.marginHor12,
                              padding: "12px 12px 12px 18px",
                              ...style.borderRadius6,
                              backgroundColor: "#FF4F4F",
                              ...style.clickable
                            }}
                          >
                            <SrRefTextIntRe12 content={"PUBLISH EVENT"} />
                            <SrCompIconGroup />
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {eventData.event_status !== "ad" && (
            <>
              {/* Lower section */}
              <div
                style={{
                  margin: eventData.status == "live" ? "48px auto 36px" : ""
                }}
                ref={scrollRef}
              >
                {/* Choice buttons */}
                {isAdmin && (
                  <div style={{ ...style.flexRow, ...style.gap12, margin: "0 18px" }}>
                    {/* <EventChoiceBtn
                      onClick={() => {
                        setShowInvites(true);
                        setShowPreview(false);
                      }}
                      text={"INVITE ATTENDEES"}
                      type={"invite"}
                      pressed={showInvites}
                    /> */}

                    {/* <EventChoiceBtn
                      onClick={() => {
                        setShowInvites(false);
                        setShowPreview(true);
                      }}
                      text={"PREVIEW LISTINGS"}
                      type={"preview"}
                      pressed={showPreview}
                    /> */}
                  </div>
                )}

                {/* Show pendind startups */}
                {!showInvites && isAdmin && eventData.status == "live" && pendingOrg.length > 0 && (
                  <>
                    {/* Preview of completed listings */}
                    <div>
                      <div style={{ margin: `${isAdmin ? 36 : 0}px 24px 0` }}>
                        <SrRefTextIntSb20
                          content={isAdmin ? "Pending startups" : "Explore lovely startups"}
                        />
                      </div>

                      <div
                        style={{
                          ...style.flexWrap,
                          width: !breakpoint1 && `${w}px`,
                          maxWidth: `${w}px`,
                          margin: "24px auto 0"
                        }}
                      >
                        {/* Large cards for startup */}
                        {pendingOrg
                          .filter((s) => s.id !== startup.id)
                          .sort((a, b) => {
                            if (a.name < b.name) {
                              return -1;
                            } else {
                              return +1;
                            }
                          })
                          // .filter((e) => e.event_id === 16)
                          ?.map((startup) => (
                            <div style={style.margin12} key={startup.id}>
                              <LargeCard
                                startup={startup}
                                // onClick={() => navigateToStartup(startup)}
                                onClick={() => {
                                  navigateToStartup(startup);
                                  // eventData?.status === "live" && isAdmin
                                  //   ? navigateToStartup(startup)
                                  //   : eventData.status !== "live" &&
                                  //     (user?.userType === "gst" || user?.userType === "exp")
                                  //   ? setShowNotLivePopup(true)
                                  //   : user?.userType === "emp"
                                  //   ? setShowNotLivePopup(true)
                                  //   : eventData.status === "iu" && isAdmin
                                  //   ? setShowEventEndedPopup(true)
                                  //   : user && allowNavigation
                                  //   ? navigateToStartup(startup)
                                  //   : setShowReferral(true);
                                }}
                                largeCardWidth={largeCardWidth}
                                nameLimit={nameLimit}
                                descriptionLimit={descriptionLimit}
                                glowOpacity={0.1}
                                blur={1}
                              />
                            </div>
                          ))}
                      </div>
                    </div>
                  </>
                )}

                {/* Event planning mode */}
                {showInvites ? (
                  <div
                    style={{
                      width: !breakpoint1 && `${w}px`,
                      maxWidth: `${w}px`,
                      margin: "0 auto"
                    }}
                  >
                    {/* guests  */}
                    <div
                      style={{
                        ...style.flexColumn,
                        ...style.gap12,
                        ...style.marginBot12
                      }}
                    >
                      <div
                        style={{
                          ...style.flexRow,
                          ...style.spaceBetween,
                          ...style.alignFlexEnd,
                          width: "calc(100% - 48px)",
                          margin: "36px 12px 0",
                          ...style.overflowScroll
                        }}
                      >
                        <div
                          style={{
                            width: "300px"
                          }}
                        >
                          <SrRefTextIntSb20 content={"Delegates"} />
                        </div>

                        <div style={style.flexRow}>
                          <div style={style.status}>
                            <SrRefTextIntMe10 content={"Invite"} />
                            <SrRefTextIntMe10 content={"sent"} />
                          </div>

                          <div style={style.status}>
                            <SrRefTextIntMe10 content={"Setup"} />
                            <SrRefTextIntMe10 content={"account"} />
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          ...style.flexColumn,
                          ...style.gap12
                        }}
                      >
                        {/* Fetched data of startups  */}
                        {guest?.map((e) => (
                          <AttendeeCard
                            type={"guest"}
                            name={e.fname}
                            mobile={e.phone_no}
                            email={e.email_id}
                            listingStatus={e.is_setup}
                            setFetchMore={setFetchMore}
                            // orgId={e.org.id}
                            guestId={e.id}
                            userType={e.user_type}
                            startupAdd={startupAdd}
                            setStartupAdd={setStartupAdd}
                            eventId={id}
                            adding={false}
                          />
                        ))}
                      </div>
                    </div>

                    {/* guest add btn  */}
                    {guestAdd ? (
                      <div style={style.marginTop12}>
                        <AttendeeCard
                          type={"guest"}
                          org={""}
                          name={""}
                          mobile={""}
                          email={""}
                          listingStatus={""}
                          setFetchMore={setFetchMore}
                          orgId={""}
                          guestId={""}
                          startupAdd={startupAdd}
                          setStartupAdd={setStartupAdd}
                          setGuestAdd={setGuestAdd}
                          setSpecialGuestAdd={setSpecialGuestAdd}
                          eventId={id}
                          adding={true}
                        />
                      </div>
                    ) : (
                      <div style={{ margin: "12px 12px 0" }}>
                        <AddNewBtn onClick={() => setGuestAdd(true)} text={"Delegate"} />
                      </div>
                    )}

                    {/* special guestss  */}
                    <div
                      style={{
                        ...style.flexColumn,
                        ...style.gap12,
                        ...style.marginBot12
                      }}
                    >
                      <div
                        style={{
                          ...style.flexRow,
                          ...style.spaceBetween,
                          ...style.alignFlexEnd,
                          width: "calc(100% - 48px)",
                          margin: "36px 12px 0",
                          ...style.overflowScroll
                        }}
                      >
                        <div
                          style={{
                            width: "300px"
                          }}
                        >
                          <SrRefTextIntSb20 content={"Jurors"} />
                        </div>

                        <div style={style.flexRow}>
                          <div style={style.status}>
                            <SrRefTextIntMe10 content={"Invite"} />
                            <SrRefTextIntMe10 content={"sent"} />
                          </div>

                          <div style={style.status}>
                            <SrRefTextIntMe10 content={"Setup"} />
                            <SrRefTextIntMe10 content={"account"} />
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          ...style.flexColumn,
                          ...style.gap12
                        }}
                      >
                        {/* Fetched data of startups  */}
                        {specialGuest?.map((e) => (
                          <>
                            <AttendeeCard
                              type={"specialGuest"}
                              name={e.fname}
                              mobile={e.phone_no}
                              email={e.email_id}
                              listingStatus={e.is_setup}
                              setFetchMore={setFetchMore}
                              // orgId={e.org.id}
                              userType={e.user_type}
                              guestId={e.id}
                              startupAdd={startupAdd}
                              setStartupAdd={setStartupAdd}
                              setSpecialGuestAdd={setSpecialGuestAdd}
                              eventId={id}
                              adding={false}
                            />
                          </>
                        ))}
                      </div>
                    </div>

                    {/*special guest add btn  */}
                    {specialGuestAdd ? (
                      <div style={style.marginTop12}>
                        <AttendeeCard
                          type={"specialGuest"}
                          org={""}
                          name={""}
                          mobile={""}
                          email={""}
                          listingStatus={""}
                          setFetchMore={setFetchMore}
                          orgId={""}
                          guestId={""}
                          startupAdd={startupAdd}
                          setStartupAdd={setStartupAdd}
                          setSpecialGuestAdd={setSpecialGuestAdd}
                          setGuestAdd={setGuestAdd}
                          eventId={id}
                          adding={true}
                        />
                      </div>
                    ) : (
                      <div style={{ margin: "12px 12px 0" }}>
                        <AddNewBtn onClick={() => setSpecialGuestAdd(true)} text={"Juror"} />
                      </div>
                    )}

                    {/* startups  */}
                    <div
                      style={{
                        ...style.flexColumn,
                        ...style.gap12,
                        ...style.marginBot12
                      }}
                    >
                      <div
                        style={{
                          ...style.flexRow,
                          ...style.spaceBetween,
                          ...style.alignFlexEnd,
                          width: "calc(100% - 48px)",
                          margin: "36px 12px 0",
                          ...style.overflowScroll
                        }}
                      >
                        <div
                          style={{
                            width: "300px"
                          }}
                        >
                          <SrRefTextIntSb20 content={"Startups"} />
                        </div>

                        <div style={style.flexRow}>
                          <div style={style.status}>
                            <SrRefTextIntMe10 content={"Invite"} />
                            <SrRefTextIntMe10 content={"sent"} />
                          </div>

                          <div style={style.status}>
                            <SrRefTextIntMe10 content={"Setup"} />
                            <SrRefTextIntMe10 content={"account"} />
                          </div>

                          <div style={style.status}>
                            <SrRefTextIntMe10 content={"Listing"} />
                            <SrRefTextIntMe10 content={"underway"} />
                          </div>

                          <div style={style.status}>
                            <SrRefTextIntMe10 content={"Listing"} />
                            <SrRefTextIntMe10 content={"complete"} />
                          </div>

                          <div style={style.status}>
                            <SrRefTextIntMe10 content={"Listing"} />
                            <SrRefTextIntMe10 content={"published"} />
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          ...style.flexColumn,
                          ...style.gap12
                        }}
                      >
                        {/* Fetched data of startups  */}
                        {pendingStartups?.map((e) => (
                          <AttendeeCard
                            type={"participant"}
                            org={e.org?.name}
                            name={e.userData ? e.userData[0]?.fname : ""}
                            mobile={e.userData ? e.userData[0]?.phone_no : ""}
                            email={e.userData ? e.userData[0]?.email_id : ""}
                            listingStatus={e.org?.listing_status}
                            // listingStatus={"re"}
                            representer={e.userData?.filter((i) => i.representer === "Y")[0]}
                            setFetchMore={setFetchMore}
                            orgId={e.org?.id}
                            userType={e.user_type}
                            guestId={e.userData ? e.userData[0]?.id : ""}
                            startupAdd={startupAdd}
                            setStartupAdd={setStartupAdd}
                            setSpecialGuestAdd={setSpecialGuestAdd}
                            setGuestAdd={setGuestAdd}
                            eventId={id}
                            adding={false}
                          />
                        ))}
                      </div>
                    </div>

                    {/* add new startups  */}
                    {startupAdd ? (
                      <div style={{ ...style.marginTop12, ...style.marginBot12 }}>
                        <AttendeeCard
                          type={"participant"}
                          org={""}
                          name={""}
                          mobile={""}
                          email={""}
                          listingStatus={""}
                          setFetchMore={setFetchMore}
                          orgId={""}
                          guestId={""}
                          startupAdd={startupAdd}
                          setStartupAdd={setStartupAdd}
                          eventId={id}
                          adding={true}
                        />
                      </div>
                    ) : (
                      <div style={{ margin: "12px" }}>
                        <AddNewBtn onClick={() => setStartupAdd(true)} text={"Startup"} />
                      </div>
                    )}
                  </div>
                ) : // Show only if there is at least one startup
                orgs.length > 0 && eventData.status == "live" ? (
                  <>
                    {/* Preview of completed listings */}
                    <div>
                      <div style={{ margin: `${isAdmin ? 36 : 0}px 24px 0` }}>
                        <SrRefTextIntSb20
                          content={isAdmin ? "Published startups" : "Explore lovely startups"}
                        />
                      </div>

                      <div
                        style={{
                          ...style.flexWrap,
                          width: !breakpoint1 && `${w}px`,
                          maxWidth: `${w}px`,
                          margin: "24px auto 0"
                        }}
                      >
                        {/* Large cards for startup */}
                        {orgs
                          .filter((s) => s.id !== startup.id)
                          .sort((a, b) => {
                            if (a.name < b.name) {
                              return -1;
                            } else {
                              return +1;
                            }
                          })
                          // .filter((e) => e.event_id === 16)
                          ?.map((startup) => (
                            <div style={style.margin12} key={startup.id}>
                              <LargeCard
                                startup={startup}
                                // onClick={() => navigateToStartup(startup)}
                                onClick={() => {
                                  // eventData?.status === "live" && isAdmin
                                  //   ? navigateToStartup(startup)
                                  //   : eventData.status !== "live" &&
                                  //     (user?.userType === "gst" || user?.userType === "exp")
                                  //   ? setShowNotLivePopup(true)
                                  //   : user?.userType === "emp"
                                  //   ? setShowNotLivePopup(true)
                                  //   : eventData.status === "iu" && isAdmin
                                  //   ? setShowEventEndedPopup(true)
                                  //   : user && allowNavigation
                                  //   ? navigateToStartup(startup)
                                  //   : setShowReferral(true);
                                  navigateToStartup(startup);
                                }}
                                largeCardWidth={largeCardWidth}
                                nameLimit={nameLimit}
                                descriptionLimit={descriptionLimit}
                                glowOpacity={0.1}
                                blur={1}
                              />
                            </div>
                          ))}
                      </div>
                    </div>
                  </>
                ) : (
                  eventData.status == "live" && (
                    <div
                      style={{
                        width: "calc(100% - 24px)",
                        margin: "0 auto",
                        minWidth: `${w}px`
                      }}
                    >
                      <div
                        style={{
                          margin: "36px 18px 48px"
                        }}
                      >
                        <SrRefTextIntLi12
                          content={
                            "There aren't any startups that have completed their listings as of now."
                          }
                        />
                      </div>
                    </div>
                  )
                )}
              </div>
            </>
          )}
        </div>
      </HelmetProvider>
    );
  };

  return loading ? (
    <Loading />
  ) : (
    <div
      style={{
        ...style.outerMost,
        ...{ overflowY: showLogin ? "hidden" : "auto" }
      }}
    >
      {breakpoint3
        ? // For phones with width less than 480px
          Event(largeCardWidth + 6 + 12 * 2)
        : breakpoint1
        ? // For tablets with width more than 480px and less than 1280px
          Event((largeCardWidth + 6) * 2 + 24 + 12 * 2)
        : // For screens with width more than 1280px
          Event((largeCardWidth + 6) * 3 + 24 * 2 + 12 * 2)}

      {/* Shram stamp */}
      {<ShramStamp />}

      {/* Account popup */}
      {accountEdit && (
        <Popup
          onClick={() => {
            setAccountEdit(false);
            setEditHover1(false);
            allowScroll();
          }}
          scrollPosition={scrollPosition}
          noPadding={true}
          modal={breakpoint3 ? true : false}
        >
          {/* Edit1 popup content */}
          <AccountPopup
            setEdit={setAccountEdit}
            setEditHover={setEditHover1}
            allowScroll={allowScroll}
          />
        </Popup>
      )}

      {/* Login popup */}
      {showLogin && (
        <Popup
          onClick={() => [setShowLogin(false), allowScroll()]}
          scrollPosition={scrollPosition}
          narrow={true}
        >
          {/* Popup content */}
          <SrRefTextIntLi12
            content={
              "Please submit your listing for review. Once verified, you can view other startups"
            }
          />
        </Popup>
      )}

      {/* Not live popup */}
      {showNotLivePopup && (
        <Popup
          onClick={() => [setShowNotLivePopup(false), allowScroll()]}
          scrollPosition={scrollPosition}
          narrow={true}
        >
          {/* Popup content */}
          <SrRefTextIntLi12
            content={
              "The event isn't live yet. We apologize but you might have to wait a little longer before you can check out these awesome startups!"
            }
          />
        </Popup>
      )}

      {/* Event ended popup */}
      {showEventEndedPopup && (
        <Popup
          onClick={() => [setShowEventEndedPopup(false), allowScroll()]}
          scrollPosition={scrollPosition}
          narrow={true}
        >
          {/* Popup content */}
          <SrRefTextIntLi12
            content={
              "The event has now ended. If you were part of the event, you will soon receive an email about the highlights of the event!"
            }
          />
        </Popup>
      )}

      {/* Referral Code Popup */}
      {showReferral && (
        <Popup
          onClick={() => [setShowReferral(false), setShowReferralHover(false), allowScroll()]}
          scrollPosition={scrollPosition}
          noPadding={true}
          modal={breakpoint3 ? true : false}
        >
          {/* Referral popup popup content */}
          {/* <ReferralPopup
            setEdit={setShowReferral}
            setEditHover={setShowReferralHover}
            allowScroll={allowScroll}
            setShowWaitlist={setShowWaitlist}
          /> */}
        </Popup>
      )}

      {/* Snackbar */}
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
};

export default Community;
