import React, { useState } from "react";
import { apiendpoint } from "../../../../components/helpers/apiendpoint";
import { toast } from "react-toastify";
import { CropperPopup } from "../../../../components/lvl1Components/setupPopups/cropperPopup";
import { useNavigate } from "react-router-dom";

const EventCreate = () => {
  const [eventDetails, setEventDetails] = useState({
    title: "",
    location: "",
    brief: "",
    logo: "",
    starting_date: "",
    ending_date: "",
    number_of_startups: "",
    sponsors: [""]
  });
  const [showCropper, setShowCropper] = useState(false);
  const [showCropperLogo, setShowCropperLogo] = useState(false);
  const [image, setImage] = useState(null);

  const navigate = useNavigate();

  const handleChange = (e) => {
    setEventDetails({ ...eventDetails, [e.target.name]: e.target.value });
  };

  const snackbar = (message) => {
    toast(message, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
  };

  const uploadFile = (videoImage) => {
    var jwt = localStorage.getItem("@jwtauth");
    if (!jwt) jwt = "";

    if (videoImage.size / 1024 / 1024 <= 10) {
      const fd = new FormData();
      fd.append("file", videoImage);

      fetch(`${apiendpoint}/platform/eventgraphics`, {
        method: "POST",
        body: fd,
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      })
        .then((response) => {
          if (response.ok) return response.json();
          else {
            throw new Error("unauthorised");
          }
        })
        .then((json) => {
          if (showCropperLogo) setEventDetails({ ...eventDetails, logo: json.url });
          else
            setEventDetails({
              ...eventDetails,
              sponsors: [...eventDetails.sponsors, json.url]
            });

          setShowCropper(false);
          setShowCropperLogo(false);
          snackbar("Image uploaded successfully");
        })
        .catch((error) => {
          setShowCropper(false);
          setShowCropperLogo(false);
          snackbar("An error occurred, please try again.");
        });
    } else {
      setShowCropper(false);
      setShowCropperLogo(false);
      snackbar("Image size should be less than 10mb");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let jwt = localStorage.getItem("@jwtauth");
    if (!jwt) jwt = "";

    fetch(`${apiendpoint}/event`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`
      },
      body: JSON.stringify({ event_details: eventDetails })
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw new Error("Unauthorized");
      })
      .then((data) => {
        setEventDetails({
          title: "",
          location: "",
          brief: "",
          logo: "",
          event_date: "",
          number_of_startups: "",
          sponsors: [""]
        });
        alert("Event Created!!");
        navigate(`/eventInvites/${data.event.id}`);
      });
  };

  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        backgroundColor: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <form
        onSubmit={handleSubmit}
        style={{ display: "flex", flexDirection: "column", width: "20%" }}
      >
        <h1>Event Create</h1>

        <div style={{ display: "flex" }}>
          <p>Logo : </p>
          <input
            type="file"
            onChange={(e) => {
              let files;
              if (e.dataTransfer) {
                files = e.dataTransfer.files;
              } else if (e.target) {
                files = e.target.files;
              }
              const reader = new FileReader();
              reader.onload = () => {
                setImage(reader.result);
              };
              reader.readAsDataURL(files[0]);
              setShowCropperLogo(true);
            }}
            required
            style={{ marginBottom: "5px", padding: "5px" }}
          />
        </div>
        {(showCropperLogo || showCropper) && (
          <CropperPopup image={image} onClick={uploadFile} setImage={setImage} />
        )}

        <input
          type="text"
          value={eventDetails.title}
          placeholder="Event Title"
          name="title"
          onChange={handleChange}
          required
          style={{ marginBottom: "5px", padding: "5px" }}
        />

        <input
          type="text"
          placeholder="Brief"
          value={eventDetails.brief}
          name="brief"
          onChange={handleChange}
          required
          style={{ marginBottom: "5px", padding: "5px" }}
        />
        <input
          type="text"
          placeholder="location"
          value={eventDetails.location}
          name="location"
          onChange={handleChange}
          required
          style={{ marginBottom: "5px", padding: "5px" }}
        />
        <input
          type="number"
          placeholder="Number of Startups"
          value={eventDetails.number_of_startups}
          name="number_of_startups"
          onChange={handleChange}
          required
          style={{ marginBottom: "5px", padding: "5px" }}
        />
        <span>Starting Date</span>
        <input
          type="date"
          placeholder="Event Date"
          value={eventDetails.event_date}
          name="starting_date"
          onChange={handleChange}
          required
          style={{ marginBottom: "5px", padding: "5px" }}
        />
        <span>Ending Date</span>
        <input
          type="date"
          placeholder="Event Date"
          value={eventDetails.event_date}
          name="ending_date"
          onChange={handleChange}
          required
          style={{ marginBottom: "5px", padding: "5px" }}
        />

        <button type="submit" style={{ padding: "5px", cursor: "pointer" }}>
          Submit
        </button>
      </form>
    </div>
  );
};

export default EventCreate;
