import { useContext } from "react";

import Founding from "../Founding";
import Stage from "../Stage";
import SocialMedia from "../SocialMedia";
import { StatusChip } from "../../lvl0Components/chips";
import { EditBtn0 } from "../../lvl0Components/buttons/iconBtns";

import MainContext from "../../helpers/context/mainContext";

import SrRefColour from "../../../ref/colours";

import style from "./style";

// MainComponent which renders on startup page and startupEdit page with some extra styling
const OtherDetails = () => {
  const {
    ifEdit,
    breakpoint1,
    breakpoint3,
    startup,
    accentColor,
    primaryColor,
    setEditHover3,
    editHover3,
    setEdit3,
    setAllHoverFalse,

    reddit,
    facebook,
    twitter,
    linkedIn,
    medium,
    whatsapp,
    substack,
    discord,
    instagram,
    pinterest
  } = useContext(MainContext);

  // Dynamic styling for this component
  const style2 = {
    container: ifEdit
      ? {
          ...style.width100,
          ...style.marginTop12,
          ...style.borderRadius18,
          padding: "3px",
          backgroundColor: editHover3 && SrRefColour.Neutral1C,
          ...style.clickable,
          ...style.transition
        }
      : { width: "100%" },

    innerContainer: ifEdit
      ? {
          ...style.flexColumn,
          ...style.spaceBetween,
          ...style.borderRadius16,
          ...{
            position: "relative",
            padding: "8px 0 8px 0",
            border: `1px dotted ${SrRefColour.Neutral2B}`
          },
          ...style.clickable,
          ...style.transition
        }
      : {
          ...style.flexColumn,
          ...style.spaceBetween,
          ...style.width100,
          ...style.marginTop36
        },

    largeContainer: ifEdit
      ? {
          ...style.borderRadius18,
          ...{
            width: breakpoint1 ? "calc(50% - 12px)" : "calc(50% + 6px)",
            padding: "3px",
            backgroundColor: editHover3 && SrRefColour.Neutral1C
          },
          ...style.clickable,
          ...style.transition
        }
      : style.width50,

    largeInnerContainer: ifEdit
      ? {
          ...style.flexColumn,
          ...style.spaceBetween,
          ...style.borderRadius16,
          ...{
            position: "relative",
            height: "145px",
            padding: "8px 0 8px 0",
            border: `1px dotted ${SrRefColour.Neutral2B}`
          },
          ...style.clickable,
          ...style.transition
        }
      : { ...style.flexColumn, ...style.spaceBetween, ...style.width100, height: "100%" },

    editButton: {
      position: "absolute",
      top: "8px",
      right: "8px"
    }
  };

  return breakpoint3 ? (
    <div
      onMouseEnter={() => ifEdit && [setAllHoverFalse(), setEditHover3(true)]}
      onMouseLeave={() => ifEdit && setEditHover3(false)}
      style={style2.container}
    >
      <div style={style2.innerContainer}>
        {/* Founding details */}
        <Founding onClick={() => ifEdit && setEdit3(-1)} startup={startup} />

        {/* Stage */}
        <div style={style.marginTop12}>
          <Stage onClick={() => ifEdit && setEdit3(1)} startup={startup} />
        </div>

        {/* Social media */}
        <div
          style={{ ...style.marginTop12, ...style.overflowScroll }}
          onClick={() => ifEdit && setEdit3(2)}
        >
          <SocialMedia />
        </div>

        {/* Status */}
        <div style={{ ...style.marginTop12, marginLeft: ifEdit ? "8px" : "12px" }}>
          <StatusChip
            primaryColor={primaryColor}
            accentColor={accentColor}
            status={startup?.other?.status}
          />
        </div>

        {ifEdit && (
          <div style={style2.editButton} onClick={() => setEdit3(-1)}>
            <EditBtn0 selected={editHover3} />
          </div>
        )}
      </div>
    </div>
  ) : (
    <div
      onMouseEnter={() => ifEdit && [setAllHoverFalse(), setEditHover3(true)]}
      onMouseLeave={() => ifEdit && setEditHover3(false)}
      style={style2.largeContainer}
    >
      <div style={style2.largeInnerContainer}>
        {/* Founding details */}
        <Founding onClick={() => ifEdit && setEdit3(-1)} startup={startup} />

        {/* Stage */}
        <Stage onClick={() => ifEdit && setEdit3(1)} startup={startup} />

        {/* Social media */}
        <div
          style={ifEdit ? style.overflowAuto : style.overflowScroll}
          onClick={() => ifEdit && setEdit3(2)}
        >
          <SocialMedia />
        </div>

        {/* Status */}
        <div
          style={ifEdit ? { marginLeft: "8px" } : style.marginLeft12}
          onClick={() => ifEdit && setEdit3(3)}
        >
          <StatusChip
            primaryColor={primaryColor}
            accentColor={accentColor}
            status={startup?.other?.status}
          />
        </div>

        {ifEdit && (
          <div style={style2.editButton} onClick={() => setEdit3(-1)}>
            <EditBtn0 selected={editHover3} />
          </div>
        )}
      </div>
    </div>
  );
};

export default OtherDetails;
