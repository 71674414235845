import React from "react";
import { ChromePicker } from "react-color";
import { SrRefTextIntLi10 } from "../../../ref/text";
import {
  white,
  gradientText,
  gradientBgTextBox,
  concatColorMixer,
  gradientBorderTextBox
} from "../../helpers/themeColors";
import style from "./style";

// AlphaPicker
// BlockPicker
// ChromePicker
// CirclePicker
// CompactPicker
// GithubPicker
// HuePicker
// MaterialPicker
// PhotoshopPicker
// SketchPicker
// SliderPicker
// SwatchesPicker
// TwitterPicker

const ColorPicker = (props) => {
  // Dynamic styling for this component
  const style2 = {
    positionPicker: {
      ...style.positionPicker,
      bottom: props.fromCommunity ? "300px" : "112px"
    },
    textWrap: {
      ...style.smallBtn,
      ...gradientBorderTextBox(props.color, "", "N"),
      border: props.color
        ? `1px solid ${concatColorMixer(
            [props.color.r, props.color.g, props.color.b],
            white,
            props.color.a
          )}`
        : "1px solid rgba(0,0,0,0"
    },
    textWrap2: {
      border: "1px solid rgba(0,0,0,0",
      ...style.smallBtn,
      ...gradientBgTextBox(props.color, "", 0.6, "N"),
      ...gradientBorderTextBox(props.color, "", "N")
    },
    inputStyle2: {
      ...style.clickable,
      ...gradientText(props.color, "", "N")
    }
  };

  return (
    <div style={style2.positionPicker}>
      <div style={style.outerDiv}>
        <ChromePicker color={props.color} onChange={props.onChange} />

        {/* Done btn */}
        <div style={style.innerDiv}>
          <div
            onClick={() => (
              props.setColor(""),
              props.primary &&
                props.setStartup({
                  ...props.startup,
                  theme: {
                    ...props.startup.theme,
                    primary: { color: "" }
                  }
                }),
              props.secondary &&
                props.setStartup({
                  ...props.startup,
                  theme: {
                    ...props.startup.theme,
                    secondary: { color: "" }
                  }
                }),
              props.tertiary &&
                props.setStartup({
                  ...props.startup,
                  theme: {
                    ...props.startup.theme,
                    tertiary: { color: "" }
                  }
                }),
              props.accent &&
                props.setStartup({
                  ...props.startup,
                  theme: {
                    ...props.startup.theme,
                    accent: { color: "" }
                  }
                }),
              props.setColorEdit(false)
            )}
            style={style2.textWrap}
          >
            <SrRefTextIntLi10 content={"Remove colour"} style={style.inputStyle} />
          </div>

          <div onClick={() => props.setColorEdit(false)} style={style2.textWrap2}>
            <SrRefTextIntLi10 content={"Save"} style={style2.inputStyle2} />
          </div>
        </div>
      </div>
    </div>
  );
};

export { ColorPicker };
