import React, { useState } from "react";
import SrRefColour from "../../../ref/colours";
import { SrRefTextIntLi075 } from "../../../ref/text";
import { SizeLimit } from "../characterLimit";
import { style } from "./style";

// Text input for Inter regular font size 12
const TextInputIntRe12 = (props) => {
  const [inputHover, setInputHover] = useState(false);
  const [inputClick, setInputClick] = useState(false);

  const setInputHoverFunc = () => {
    setInputClick(true);
  };

  // Dynamic styling for this component
  const style2 = {
    inputStyle: {
      ...style.inputStyle,
      border:
        inputHover || inputClick
          ? `1px solid ${SrRefColour.Neutral2A}`
          : `1px solid ${SrRefColour.Neutral2B}`
    }
  };

  return (
    <div style={style.positionRelative}>
      {/* Placeholder text at the top */}
      {!props.topTextAlwaysOn && props.topText && !props.value ? (
        <div></div>
      ) : (
        <div style={style.textInputTop}>
          <SrRefTextIntLi075
            content={props.topText}
            state={
              !props.content && props.condition
                ? "enabled"
                : !props.content
                ? "disabled"
                : props.condition
                ? "pressed"
                : "enabled"
            }
          />
        </div>
      )}

      <input
        type={props.type}
        min={props.min}
        max={props.max}
        limit={props.limit}
        name={props.name}
        placeholder={props.placeholder}
        id={"textInput"}
        isShown={props.isShown}
        value={props.value}
        onChange={props.onChange}
        onMouseEnter={() => setInputHover(true)}
        onMouseLeave={() => setInputHover(false)}
        onClick={props.onClick}
        style={style2.inputStyle}
        required
      />
      {/* Size limit for text inputs */}
      {!props.noLimit && (
        <SizeLimit
          isShown={props.isShown}
          lengthOfDesc={props.value ? props.value.length : 0}
          lengthLimit={props.limit}
        />
      )}
    </div>
  );
};

// Text input for Inter regular font size 12
const TextInputIntRe12Small = (props) => {
  const [inputHover, setInputHover] = useState(false);
  const [inputClick, setInputClick] = useState(false);

  const setInputHoverFunc = () => {
    setInputClick(true);
  };

  return (
    <input
      type={props.type}
      min={props.min}
      max={props.max}
      name={props.name}
      placeholder={props.placeholder}
      id={"textInput"}
      value={props.value}
      onChange={props.onChange}
      onMouseEnter={() => setInputHover(true)}
      onMouseLeave={() => setInputHover(false)}
      onClick={props.onClick}
      style={{
        ...style.textInputSmall,
        ...style.intRe12,
        ...{
          border:
            inputHover || inputClick
              ? `1px solid ${SrRefColour.Neutral2A}`
              : `1px solid ${SrRefColour.Neutral2B}`
        },
        ...style.transition
      }}
      required
    />
  );
};

export { TextInputIntRe12, TextInputIntRe12Small };
