import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation, redirect } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

import MainContext from "../../../components/helpers/context/mainContext";
import { Industries, StageArrShort } from "../../../components/helpers/localData";
import { apiendpoint } from "../../../components/helpers/apiendpoint";
import { returnRGBA } from "../../../components/helpers/themeColors";

// Screens
import PhoneScreen from "../../../components/startupComponents/ViewPorts/PhoneScreen";
import SmallTablet from "../../../components/startupComponents/ViewPorts/SmallTablet";
import BigTablet from "../../../components/startupComponents/ViewPorts/BigTablet";
import Laptop from "../../../components/startupComponents/ViewPorts/Laptop";

import Loading from "../../../components/lvl0Components/Loading";
import { Popup } from "../../../components/lvl0Components/popup";
import { ShramStamp } from "../../../components/lvl0Components/shramStamp";
import AccountPopup from "../../../components/lvl1Components/setupPopups/AccountPopup";
import CommentPopup from "../../../components/lvl1Components/setupPopups/CommentPopup";
import { Navigator } from "../../../components/lvl2Components/navigator";
import { UserPanel } from "../../../components/lvl2Components/userPanel";
import { ReviewPanel } from "../../../components/lvl2Components/reviewPanel";
import { style } from "./style";

const Startup = () => {
  // Context
  const {
    screenWidth,
    screenPadding,
    breakpoint1,
    breakpoint2,
    breakpoint3,
    defaultColor,
    defaultStartup,
    colorSplit,
    // API related
    user,
    scrollToTop,
    startup,
    setStartup,

    // Colors related
    primaryColor,
    setPrimaryColor,
    setSecondaryColor,
    setAccentColor,
    setInvertBackground,
    setTertiaryColor,
    setReturnPrimary,
    setReturnPrimaryInverse,

    //Social media link
    setDiscord,
    setReddit,
    setFacebook,
    setInstagram,
    setMedium,
    setLinkedIn,
    setSubstack,
    setWhatsapp,
    setTwitter,
    setPinterest,
    setYoutube
  } = useContext(MainContext);
  const navigate = useNavigate();

  // If user doesn't exist, navigate to the login page
  // If the user exsits but he/she is not allowed to navigate, navigate to home page
  // window.onload = function () {
  //   localStorage.removeItem("loading");
  //   if (!user) {
  //     navigateToLogin();
  //   }
  //   // else if (user && !allowNavigation) {
  //   //   navigateHome();
  //   // }
  // };

  window.onbeforeunload = function () {
    localStorage.setItem("loading", "true");
  };

  // Get id of the startup currently being viewed
  const { pathname } = useLocation();
  const id = Number(window.location.pathname.split("/")[2]);

  const [loading, setLoading] = useState(localStorage.getItem("loading") === "true" || false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [orgsLoaded, setOrgsLoaded] = useState(false);
  const [accountEdit, setAccountEdit] = useState(false);
  const [editHover1, setEditHover1] = useState(false);
  const [comment, setComment] = useState("");
  const [commentClicked, setCommentClicked] = useState(false);
  const [shouldApproveVisible, setShouldApproveVisible] = useState(false);

  // Get the startup body by filtering through the right id
  const [orgId, setOrgId] = useState(id);

  // Navigate to main startup listing
  const navigateHome = () => {
    navigate("/home");
    scrollToTop();
  };

  // Navigate to community page
  const navigateToCommunity = () => {
    let communityId = localStorage.getItem("@communityId");
    communityId = communityId ? communityId : "20";
    navigate(`/community/${communityId}`);
  };

  // Navigate to main startup listing
  const navigateToLogin = () => {
    localStorage.setItem("@startupId", window.location.pathname.split("/").splice(-1));
    setTimeout(() => {
      navigate("/login");
    }, 200);
  };

  // Navigate to a particular startup listing
  const navigateToStartup = (e) => {
    navigate(`/startup/${e.id}`, { state: { id: e.id } });
    setOrgId(e.id);
    scrollToTop();
  };

  // Find how much progress has been made toward the listing
  const [progress, setProgress] = useState();
  const computeProgress = (startup) => {
    const totalScore = 24 - 2; // Subtracted 2 because maybe the video should be optional for now
    const obtainedScore =
      // Startup details
      Boolean(startup.name && startup.name !== defaultStartup.name) +
      Boolean(startup.description && startup.description !== defaultStartup.description) +
      Boolean(startup.logo && startup.logo !== defaultStartup.logo) +
      Boolean(startup.industry && startup.industry !== defaultStartup.industry) +
      Boolean(startup.sectors && startup.sectors[0] !== defaultStartup.sectors[0]) +
      Boolean(startup.sectors && startup.sectors[1] !== defaultStartup.sectors[1]) +
      // Startup colours
      Boolean(startup.theme && startup.theme !== defaultStartup.theme) +
      // Startup teamData
      Boolean(startup.team && startup.team !== defaultStartup.team) +
      Boolean(startup.cofounderCount && startup.cofounderCount !== defaultStartup.cofounderCount) +
      Boolean(startup.teamSize && startup.teamSize !== defaultStartup.teamSize) +
      // Startup Founding data
      Boolean(startup.other.year && startup.other.year !== defaultStartup.other.year) +
      Boolean(startup.other.location && startup.other.location !== defaultStartup.other.location) +
      Boolean(startup.other.stage !== defaultStartup.other.stage) +
      Boolean(
        startup.other.socialMedia && startup.other.socialMedia !== defaultStartup.other.socialMedia
      ) +
      Boolean(startup.other.status && startup.other.status !== defaultStartup.other.status) +
      // Startup pitch data
      Boolean(startup.pitch.problem && startup.pitch.problem !== defaultStartup.pitch.problem) +
      Boolean(startup.pitch.solution && startup.pitch.solution !== defaultStartup.pitch.solution) +
      Boolean(startup.pitch.image && startup.pitch.image !== defaultStartup.pitch.image) +
      // Boolean(
      //   startup.pitch.video.link && startup.pitch.video.link !== defaultStartup.pitch.video.link
      // ) +
      // Boolean(
      //   startup.pitch.video.thumbnail &&
      //     startup.pitch.video.thumbnail !== defaultStartup.pitch.video.thumbnail
      // ) +
      Boolean(startup.pitch.features && startup.pitch.features !== defaultStartup.pitch.features) +
      Boolean(startup.pitch.traction && startup.pitch.traction !== defaultStartup.pitch.traction) +
      Boolean(startup.pitch.market && startup.pitch.market !== defaultStartup.pitch.market) +
      Boolean(startup.pitch.website && startup.pitch.website !== defaultStartup.pitch.website);
    setProgress((100 * obtainedScore) / totalScore);
  };

  useEffect(() => {
    if (localStorage.getItem("@startupId")) localStorage.removeItem("@startupId");
  }, []);

  // If the id of the startup matches the user's orgId, allow isEdit
  const allowEdit = () => {
    if (user) {
      if (user?.orgId === startup?.id && user?.orgLead === "Y") {
        return true;
      }
      return false;
    } else {
      return false;
    }
  };

  // Navigate to a particular startup listing
  const navigateToOwnStartup = (e) => {
    navigate(`/startup/${e}`, { state: { id: e } });
    setOrgId(e);
    scrollToTop();
  };

  // Navigate to the edit page of the startup listing
  const navigateEdit = () => {
    navigate(`/startup/edit/${id}`, { state: { id: id } });
    scrollToTop();
  };

  // Set orgs for the navigator
  const [orgs, setOrgs] = useState([]);

  useEffect(() => {
    try {
      let jwt = localStorage.getItem("@jwtauth");
      if (!jwt) jwt = "";
      fetch(`${apiendpoint}/platform/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`
        }
      })
        .then((res) => {
          if (res.ok) return res.json();
          else throw new Error("Unauthorized");
        })

        .then((data) => {
          let temp = [];
          data.forEach((item) => {
            temp = [
              ...temp,
              {
                id: item.id,
                name: item.name,
                brief: item.brief,
                description: item.description,
                logo: item.logo,
                listingStatus: item.listing_status,
                theme: {
                  primary: {
                    color: item.primary_colour ? colorSplit(item.primary_colour) : defaultColor
                  },
                  secondary: {
                    color: item.secondary_colour ? colorSplit(item.secondary_colour) : ""
                  },
                  tertiary: {
                    color: item.tertiary_colour ? colorSplit(item.tertiary_colour) : ""
                  },
                  accent: {
                    color: item.accent_colour ? colorSplit(item.accent_colour) : ""
                  },
                  invertBackground: item.invert_background
                },
                industry: Industries[item.industry],
                sectors: [item.sector1, item.sector2],
                team: [],
                cofounderCount: item.cofounder_count,
                teamSize: item.team_size,
                other: {
                  year: item.establishment_year,
                  localtion: item.location,
                  zaubacorp: item.zaubacorp,
                  stage: item.stage,
                  socialMedia: {
                    discord: item.discord,
                    facebook: item.facebook,
                    instagram: item.instagram,
                    medium: item.medium,
                    pinterest: item.pinterest,
                    reddit: item.reddit,
                    substack: item.substack,
                    twitter: item.twitter,
                    whatsapp: item.whatsapp,
                    youtube: item.youtube
                  },
                  status: item.org_status
                }
              }
            ];
          });
          setOrgs(temp);
          setOrgsLoaded(true);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  //upvote
  const [upVote, setUpVote] = useState(false);
  const [upVoteCount, setUpVoteCount] = useState(0);
  const [views, setViews] = useState(0);

  const handleUpVote = () => {
    let jwt = localStorage.getItem("@jwtauth");
    setUpVote(!upVote);
    fetch(`${apiendpoint}/platform/upvote`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`
      },
      body: JSON.stringify({
        org_id: id
      })
    }).then((res) => {
      if (res.ok) return res.json();
      else throw new Error("Unauthorized");
    });
  };

  const [interest, setInterest] = useState(false);
  //interested in meeting
  const handleInterestInMeeting = (int) => {
    let jwt = localStorage.getItem("@jwtauth");
    setInterest(int);
    fetch(`${apiendpoint}/platform/feedback`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`
      },
      body: JSON.stringify({
        org_id: id,
        interest_in_meeting: int ? "Y" : "N"
      })
    }).then((res) => {
      if (res.ok) return res.json();
      else throw new Error("Unauthorized");
    });
  };

  const handleComment = () => {
    let jwt = localStorage.getItem("@jwtauth");
    fetch(`${apiendpoint}/platform/feedback`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`
      },
      body: JSON.stringify({
        org_id: id,
        comment: comment
      })
    }).then((res) => {
      if (res.ok) return res.json();
      else throw new Error("Unauthorized");
    });
  };

  const [thoughtClarity, setThoughtClarity] = useState(null);
  //thought clarity
  const handleThoughtClarity = (num) => {
    let jwt = localStorage.getItem("@jwtauth");
    setThoughtClarity(num);
    fetch(`${apiendpoint}/platform/feedback`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`
      },
      body: JSON.stringify({
        org_id: id,
        thought_clarity: num
      })
    }).then((res) => {
      if (res.ok) return res.json();
      else throw new Error("Unauthorized");
    });
  };

  const [valueProp, setValueProp] = useState(null);
  //setting value proposition
  const handleValueProposition = (num) => {
    let jwt = localStorage.getItem("@jwtauth");
    setValueProp(num);
    fetch(`${apiendpoint}/platform/feedback`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`
      },
      body: JSON.stringify({
        org_id: id,
        value_proposition: num
      })
    }).then((res) => {
      if (res.ok) return res.json();
      else throw new Error("Unauthorized");
    });
  };

  const fetchData = async (jwt) => {
    fetch(`${apiendpoint}/platform/org/${orgId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw new Error("Unauthorized");
      })
      .then((data) => {
        //setting upvote that whether a user has already voted or not
        setUpVote(data?.features?.upvoted);

        setShouldApproveVisible(data.shouldApprove);

        //setting upvote count
        setUpVoteCount(data?.features?.upvoteCount);

        //setting view count
        setViews(data.viewCount || 0);

        //interested in meeting
        setInterest(data?.features?.interest_in_meeting);

        //thought clarity
        setThoughtClarity(data?.features?.thought_clarity || "");

        //value proposition
        setValueProp(data?.features?.value_proposition || "");

        const temp = {
          id: data?.org?.id,
          name: data?.org?.name,
          brief: data?.org?.brief,
          description: data?.org?.description,
          logo: data?.org?.logo,
          listingStatus: data?.org?.listing_status,
          theme: {
            primary: {
              color: data?.org?.primary_colour
                ? colorSplit(data?.org?.primary_colour)
                : defaultColor
            },
            secondary: {
              color: data?.org?.secondary_colour ? colorSplit(data?.org?.secondary_colour) : ""
            },
            tertiary: {
              color: data?.org?.tertiary_colour ? colorSplit(data?.org?.tertiary_colour) : ""
            },
            accent: {
              color: data?.org?.accent_colour ? colorSplit(data?.org?.accent_colour) : ""
            },
            invertBackground: data?.org?.invert_background
          },
          industry: data?.org?.industry,
          sectors: [data?.org?.sector1, data?.org?.sector2],
          team: data.orgMembers.map((lead) => {
            return {
              id: lead.user.id,
              fname: lead.user.fname,
              lname: lead.user.lname,
              designation: lead.user.designation,
              pic: lead.user.picture,
              linkedIn: lead.user.linkedin,
              orgId: lead.user.org_id,
              orgLead: lead.user.is_org_lead,
              representer: lead.user.representer
            };
          }),
          cofounderCount: data?.org?.cofounder_count,
          teamSize: data?.org?.team_size,
          other: {
            year: data?.org?.establishment_year,
            location: data?.org?.location,
            zaubacorp: data?.org?.zaubacorp,
            stage: StageArrShort.indexOf(data?.org?.stage),
            socialMedia: {
              discord: data?.org?.discord,
              facebook: data?.org?.facebook,
              instagram: data?.org?.instagram,
              linkedIn: data?.org?.linkedin,
              medium: data?.org?.medium,
              pinterest: data?.org?.pinterest,
              reddit: data?.org?.reddit,
              substack: data?.org?.substack,
              twitter: data?.org?.twitter,
              whatsapp: data?.org?.whatsapp,
              youtube: data?.org?.youtube
            },
            status: data?.org?.org_status
          },
          pitch: {
            problem: data?.platform?.problem,
            solution: data?.platform?.solution,
            image: data?.platform?.image,
            video: {
              link: data?.platform?.video,
              thumbnail: data?.platform?.thumbnail
            },
            website: data?.org?.website,
            features: data?.platform?.features,
            traction: data?.platform?.traction,
            market: data?.platform?.market,
            challenge1: data?.platform?.challenge1,
            challenge2: data?.platform?.challenge2,
            challenge3: data?.platform?.challenge3,
            mentorSector1: data?.platform?.mentor_sector1,
            mentorSector2: data?.platform?.mentor_sector2,
            mentorSector3: data?.platform?.mentor_sector3,
            mentorSector4: data?.platform?.mentor_sector4,
            mentorSector5: data?.platform?.mentor_sector5,
            pitchDeck: data?.platform?.pitch_deck
          }
        };

        // temp theme colours
        setPrimaryColor(temp.theme.primary.color);
        setSecondaryColor(temp.theme.secondary.color);
        setTertiaryColor(temp.theme.tertiary.color);
        setAccentColor(temp.theme.accent.color);
        setInvertBackground(temp.theme.invertBackground);

        // Social media links
        setDiscord(temp.other.socialMedia.discord);
        setFacebook(temp.other.socialMedia.facebook);
        setInstagram(temp.other.socialMedia.instagram);
        setLinkedIn(temp.other.socialMedia.linkedIn);
        setMedium(temp.other.socialMedia.medium);
        setPinterest(temp.other.socialMedia.pinterest);
        setReddit(temp.other.socialMedia.reddit);
        setSubstack(temp.other.socialMedia.substack);
        setTwitter(temp.other.socialMedia.twitter);
        setWhatsapp(temp.other.socialMedia.whatsapp);
        setYoutube(temp.other.socialMedia.youtube);
        setStartup(temp);

        computeProgress(temp);
        setDataLoaded(true);
        localStorage.removeItem("loading");
      });
  };

  // Change the status of the startup
  const changeStartupStatus = () => {
    let jwt = localStorage.getItem("@jwtauth");
    if (!jwt) return;

    fetch(`${apiendpoint}/community/changeStatus/${orgId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`
      },
      body: JSON.stringify({
        status: "approved"
      })
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw new Error("Unauthorized");
      })
      .then((data) => {
        alert("Startup has been approved");
      });
  };

  useEffect(() => {
    try {
      let jwt = localStorage.getItem("@jwtauth");
      if (!jwt) jwt = "";
      setLoading(false);
      fetchData(jwt);
      // if (!user) {
      //   navigateToLogin();
      // }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }, [pathname, upVote]);

  useEffect(() => {
    if (dataLoaded && orgsLoaded) setLoading(false);
  }, [dataLoaded, orgsLoaded]);

  useEffect(() => {
    body.style.overflowY = accountEdit ? "hidden" : "auto";
  }, [accountEdit]);
  const allowScroll = () => {
    body.style.overflowY = "auto";
  };

  // scrollPosition
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset; // Find the top offset of the window
    setScrollPosition(position); // Set scrollPosition = top offset of the window
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true }); // listen to scroll event
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // changePosition
  const [changePosition, setChangePosition] = useState(
    breakpoint3
      ? scrollPosition + window.innerHeight >= document.body.scrollHeight - (60 + 36 + 75 + 24)
      : scrollPosition + window.innerHeight >= document.body.scrollHeight - (60 + 48 + 75 + 30)
  );

  useEffect(() => {
    return () => {
      setChangePosition(
        breakpoint3
          ? scrollPosition + window.innerHeight >= document.body.scrollHeight - (60 + 36 + 75 + 24)
          : scrollPosition + window.innerHeight >= document.body.scrollHeight - (60 + 48 + 75 + 30)
      );
    };
  }, [scrollPosition]);

  // Popup effect
  const body = document.body;

  useEffect(() => {
    setReturnPrimary(returnRGBA(primaryColor.r, primaryColor.g, primaryColor.b, 1));
    setReturnPrimaryInverse(
      returnRGBA(primaryColor.r, primaryColor.g, primaryColor.b, 0.1 / primaryColor.a)
    );
  }, [primaryColor]);

  // Conditions to show the navigator
  const [allowNavigation, setAllowNavigation] = useState(
    true
    // orgs.includes(startup) && orgs.filter((s) => s.id !== user.orgId).length > 0
  );

  // For phones with width less than 480px
  const phone = () => {
    return <PhoneScreen />;
  };

  // For tablets with width more than 480px and less than 840px
  const smallTablet = () => {
    return <SmallTablet />;
  };

  // For tablets with width more than 840px and less than 1280px
  const bigTablet = () => {
    return <BigTablet />;
  };

  // For screens with width more than 1280px
  const laptop = () => {
    return <Laptop />;
  };

  return loading || !dataLoaded || !orgsLoaded ? (
    <Loading />
  ) : (
    <HelmetProvider>
      <div style={style.outerMost}>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="description" content={startup.description} />
          <meta property="og:title" content={startup.name} />
          <meta property="og:image" content={startup.logo} />
          <meta
            property="og:url"
            content={`${window.location.hostname}${window.location.pathname}`}
          />
          <title>{startup.name} | Shram's Startup Platform</title>
          <link rel="canonical" href="http://mysite.com/example" />
        </Helmet>

        <div
          style={{
            ...style.flexColumn,
            ...style.screen
          }}
        >
          {/* User panel at the top */}
          <UserPanel
            screenPadding={screenPadding}
            screenWidth={screenWidth}
            onClickHome={() => navigateToCommunity()}
            onClickEdit={() => navigateEdit()}
            onClickPreview={() => navigateToOwnStartup(user.orgId)}
            onClickAccount={() => setAccountEdit(true)}
            allowEdit={allowEdit()}
            sameStartup={user ? user.orgId === startup.id : null}
            fromStartup={true}
            progress={progress}
            shouldApproveVisible={shouldApproveVisible}
            listingStatus={startup.listingStatus}
            onClickApprove={changeStartupStatus}
          />

          {breakpoint3
            ? // For phones with width less than 480px
              phone()
            : breakpoint2
            ? // For tablets with width more than 480px and less than 840px
              smallTablet()
            : breakpoint1
            ? // For tablets with width more than 840px and less than 1280px
              bigTablet()
            : // For screens with width more than 1280px
              laptop()}
        </div>

        {/* Show review panel when the listing status is complete and it isnt your startup */}
        {startup.listingStatus === "co" && user && user?.orgId !== startup.id && (
          <div style={style.marginTop36}>
            <ReviewPanel
              specialGuest={user.userType && user.specialGuest}
              upVote={upVote}
              onClickUpVote={handleUpVote}
              upVoteCount={upVoteCount}
              valueProp={valueProp}
              onClickValueProp={handleValueProposition}
              thoughtClarity={thoughtClarity}
              onClickClarity={handleThoughtClarity}
              comment={comment}
              onClickComment={() => setCommentClicked(!commentClicked)}
              interest={interest}
              onClickInterest={() => handleInterestInMeeting(!interest)}
              // Parameters for positioning
              changePosition={changePosition}
              scrollPosition={scrollPosition}
              windowHeight={window.innerHeight}
              bodyScrollHeight={document.body.scrollHeight}
            />
          </div>
        )}

        {/* Space for the reviewPanel to rest at */}
        {startup.listingStatus === "co" && user?.orgId !== startup.id && (
          <div style={{ width: "100%", height: "87px" }}></div>
        )}

        <div
          style={{ margin: `${user ? 36 : 0}px ${screenPadding}px ${breakpoint3 ? 36 : 48}px` }}
        ></div>

        {/* Navigator */}
        {/* {allowNavigation && (
          <Navigator
            navigateToStartup={navigateToStartup}
            user={user}
            startup={startup}
            orgs={orgs
              .filter((s) => s?.id !== userStartup?.id)
              .sort((a, b) => {
                if (a.name < b.name) {
                  return -1;
                } else {
                  return +1;
                }
              })}
            breakpoint3={breakpoint3}
            screenWidth={screenWidth}
            screenPadding={screenPadding}
          />
        )} */}

        {/* Account popup */}
        {accountEdit && (
          <Popup
            onClick={() => {
              setAccountEdit(false);
              setEditHover1(false);
              allowScroll();
            }}
            scrollPosition={scrollPosition}
            noPadding={true}
            modal={breakpoint3 ? true : false}
          >
            {/* Edit1 popup content */}
            <AccountPopup
              setEdit={setAccountEdit}
              setEditHover={setEditHover1}
              allowScroll={allowScroll}
            />
          </Popup>
        )}

        {/* Comment popup */}
        {commentClicked && (
          <Popup
            onClick={() => {
              setAccountEdit(false);
              setEditHover1(false);
              setCommentClicked(false);
              allowScroll();
            }}
            scrollPosition={scrollPosition}
            noPadding={true}
            modal={breakpoint3 ? true : false}
          >
            {/* Comment popup content */}
            <CommentPopup
              comment={comment}
              setComment={setComment}
              setCommentClicked={setCommentClicked}
              setEdit={setAccountEdit}
              setEditHover={setEditHover1}
              allowScroll={allowScroll}
              handleSubmit={() => {
                handleComment(), setCommentClicked(false);
              }}
            />
          </Popup>
        )}

        {/* Shram stamp */}
        <div
          style={{
            marginTop: allowNavigation ? 0 : breakpoint3 ? "36px" : "48px"
          }}
        ></div>
        <ShramStamp />
      </div>
    </HelmetProvider>
  );
};

export default Startup;
