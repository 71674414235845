import React from "react";
import style from "./style";

const Popup = (props) => {
  //for dynamic styling
  const style2 = {
    outerDiv: {
      ...style.outerDiv,
      top: `${props.scrollPosition}px`,
      alignItems: props.modal ? "flex-end" : "center"
    },
    containerDiv: {
      ...style.containerDiv,
      padding: props.noPadding ? 0 : "24px",
      borderRadius: props.modal ? "12px 12px 0 0" : "12px"
    }
  };

  return (
    <div style={style2.outerDiv}>
      {/* Popup content */}
      <div style={style2.containerDiv}>{props.children}</div>

      {/* Blur effect */}
      <div onClick={props.onClick} style={style.blurContainer}></div>
    </div>
  );
};

export { Popup };
