import SrRefColour from "../../../ref/colours";

const style = {
  outerMost: {
    position: "relative",
    width: "100vw",
    backgroundColor: SrRefColour.Neutral0
  },
  flexWrap: { display: "flex", flexWrap: "wrap" },
  flexRow: {
    display: "flex",
    flexDirection: "row"
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column"
  },
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between"
  },
  spaceEvenly: {
    display: "flex",
    justifyContent: "space-evenly"
  },
  justifyFlexEnd: {
    display: "flex",
    justifyContent: "flex-end"
  },
  alignItemsCenter: {
    display: "flex",
    alignItems: "center"
  },
  alignFlexEnd: {
    display: "flex",
    alignItems: "flex-end"
  },
  overflowScroll: {
    overflow: "scroll"
  },
  screen: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100vw",
    minHeight: "100vh",
    maxWidth: "1600px",
    margin: "0 auto",
    backgroundColor: SrRefColour.Neutral0,
    overflowX: "hidden"
  },
  teamSize: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "47px",
    minWidth: "47px",
    height: "47px",
    minHeight: "47px",
    borderWidth: "1px",
    borderStyle: "solid",
    borderRadius: "47px"
  },
  scrollWrap: {
    maxWidth: "100%",
    overflow: "auto"
  },
  scroll: {
    display: "inline-flex"
  },
  positionFixed: {
    position: "fixed",
    zIndex: 20
  },
  oneThirdWidth: {
    width: "33.34%",
    borderRadius: "12px",
    backgroundColor: "#eee"
  },
  halfWidth: {
    width: "50%",
    borderRadius: "12px",
    backgroundColor: "#eee"
  },
  oneFourthWidth: {
    width: "25%",
    borderRadius: "12px",
    backgroundColor: "#eee"
  },
  fitContent: {
    width: "fit-content",
    height: "fit-content"
  },
  cover: {
    width: "100%",
    paddingBottom: "72px",
    borderWidth: "0 0 1px 0",
    borderStyle: "solid",
    borderColor: SrRefColour.Neutral2B,
    // backgroundColor: SrRefColour.Neutral1D,
    backgroundColor: "white"
  },
  editBtn: {
    position: "absolute",
    top: "3px",
    right: "3px",
    zIndex: 1
  },
  clickable: {
    cursor: "pointer",
    border: "none",
    textDecoration: "none"
  },
  clickable2: {
    cursor: "pointer"
  },
  borderRadius6: { borderRadius: "6px" },
  borderRadius12: { borderRadius: "12px" },
  borderRadius16: { borderRadius: "16px" },
  borderRadius18: { borderRadius: "18px" },
  popup: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  positionAbsolute: {
    position: "absolute"
  },
  surfaceBorder: {
    border: `0.5px solid ${SrRefColour.Neutral2A}`,
    borderRadius: "12px",
    boxShadow: "0px 2px 4px rgba(0,0,0,0.15)",
    zIndex: 10
  },
  surfaceBlur: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    backgroundImage:
      "linear-gradient(to bottom right, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0))",
    backdropFilter: "blur(9px)"
  },
  surfaceBlurNote: {
    backgroundImage: "linear-gradient(to bottom right, rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.03))",
    backdropFilter: "blur(9px)"
  },
  backgroundWhite: { backgroundColor: SrRefColour.Neutral0 },
  padding24: { padding: "24px" },
  padding12: { padding: "12px" },
  paddingVert1: { padding: "1px 0" },
  colorWhite: { color: SrRefColour.Neutral0 },
  colorBlack: { color: "black" },
  icon20: {
    width: "20px",
    height: "20px",
    cursor: "pointer"
  },
  gap36: {
    gap: "36px"
  },
  gap24: {
    gap: "24px"
  },
  gap18: {
    gap: "18px"
  },
  gap12: {
    gap: "12px"
  },
  gap6: {
    gap: "6px"
  },
  gap3: {
    gap: "3px"
  },
  status: { width: "100px", textAlign: "center" },

  // Widths, heights and margins
  width100: { width: "100%" },
  width50: { width: "50%" },
  height80: { height: "80%" },
  height20: { height: "20%" },
  minHeight32: { minHeight: "32px" },
  customMargin: { margin: "12px auto 36px" },
  customMargin2: {
    margin: " 24px auto 0"
  },
  margin12: {
    margin: "12px"
  },
  marginTop6: {
    marginTop: "6px"
  },
  marginTop12: {
    marginTop: "12px"
  },
  marginTop18: {
    marginTop: "18px"
  },
  marginTop24: {
    marginTop: "24px"
  },
  marginTop36: {
    marginTop: "36px"
  },
  marginBot12: {
    marginBottom: "12px"
  },
  marginLeft6: {
    marginLeft: "6px"
  },
  marginLeft12: {
    marginLeft: "12px"
  },
  marginRight12: {
    marginRight: "12px"
  },
  marginHor6: {
    marginLeft: "6px",
    marginRight: "6px"
  },
  marginHor12: {
    marginLeft: "12px",
    marginRight: "12px"
  },
  marginHor15: {
    marginLeft: "15px",
    marginRight: "15px"
  },
  marginHor18: {
    marginLeft: "18px",
    marginRight: "18px"
  },
  marginHor24: {
    marginLeft: "24px",
    marginRight: "24px"
  },
  marginHor36: {
    marginLeft: "36px",
    marginRight: "36px"
  }
};

export default style;
