import React, { useState, useEffect, useRef, useContext } from "react";

import SrRefColour from "../../../../ref/colours";
import { SrRefTextRubSb25, SrRefTextIntMe12 } from "../../../../ref/text";

import MainContext from "../../../helpers/context/mainContext";
import { returnRGBA } from "../../../helpers/themeColors";

import { Avatar } from "../../../lvl0Components/avatar";
import { IndustryChip, SectorChip } from "../../../lvl0Components/chips";

import style from "./style";

const LargeCard = (props) => {
  // Context
  const { colorSplit } = useContext(MainContext);

  // Startup theme colours
  const primaryColor = colorSplit(props.startup.theme.primary.color);
  const accentColor =
    props.startup.theme.accent.color && colorSplit(props.startup.theme.accent.color);
  const invertBackground = props.startup.theme.invertBackground;

  const [cardHover, setCardHover] = useState(false);

  // Get dimensions of the card
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const cardRef = useRef(null);

  useEffect(() => {
    if (cardRef.current) {
      setDimensions({
        width: cardRef.current.offsetWidth,
        height: cardRef.current.offsetHeight
      });
    }
  }, []);

  // Dynamic styling
  const style2 = {
    outerDiv: {
      ...style.outerDiv,
      cursor: props.noHover ? "default" : "pointer",
      padding: "2px",
      border: cardHover || props.select ? "1px solid rgba(0,0,0,0.15)" : "1px solid rgba(0,0,0,0)",
      borderRadius: "21px"
    },
    innerDiv: {
      ...style.innerDiv,
      width: `${props.largeCardWidth}px`,
      backgroundColor:
        cardHover || props.select ? "rgba(255, 255, 255, 1)" : "rgba(255, 255, 255, 0.8)",
      borderColor: SrRefColour.Neutral2B
    },
    blurEffect: {
      ...style.blurEffect,
      width: `${dimensions.width + 24}px`,
      height: `${dimensions.height + 24}px`,
      backgroundColor: accentColor
        ? returnRGBA(accentColor.r, accentColor.g, accentColor.b, props.glowOpacity)
        : returnRGBA(primaryColor.r, primaryColor.g, primaryColor.b, props.glowOpacity),
      filter: `blur(${props.blur}rem)`
    }
  };

  return (
    <div style={style.positionRelative}>
      <div
        onMouseEnter={() => setCardHover(true)}
        onMouseLeave={() => setCardHover(false)}
        onClick={props.onClick}
        style={style2.outerDiv}
        key={props.startup.id}
      >
        {/* Card body */}
        <div style={style2.innerDiv} ref={cardRef}>
          {/* Org logo */}
          <div style={style.marginLeft18}>
            <Avatar
              width={75}
              pic={props.startup.logo}
              borderColor={returnRGBA(primaryColor.r, primaryColor.g, primaryColor.b, 1)}
            />
          </div>

          {/* Org name */}
          <SrRefTextRubSb25
            content={
              props.startup.name?.length > props.nameLimit + 3
                ? props.startup.name.slice(0, props.nameLimit) + "..."
                : props.startup.name
            }
            state={"enabled"}
            style={style.nameStyle}
          />

          {/* Org description */}
          <SrRefTextIntMe12
            content={
              props.startup.description?.length > props.descriptionLimit + 3
                ? props.startup.description.slice(0, props.descriptionLimit) + "..."
                : props.startup.description
            }
            state={"enabled"}
            style={style.descriptionStyle}
          />

          {/* Chips for industry and sectors */}
          <div style={style.scrollWrap}>
            <div style={style.chipInnerDiv}>
              {/* Chip for industry */}
              <IndustryChip
                industry={props.startup.industry}
                primaryColor={primaryColor}
                accentColor={accentColor}
                invertBackground={invertBackground}
              />

              {/* Chip for sectors */}
              {props.startup.sectors.map((sector) => (
                <SectorChip
                  sector={sector}
                  primaryColor={primaryColor}
                  accentColor={accentColor}
                  invertBackground={invertBackground}
                />
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Blur effect in the background */}
      <div style={style2.blurEffect}></div>
    </div>
  );
};

export { LargeCard };
