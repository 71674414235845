import React, { useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import SrRefColour from "../../../../ref/colours";
import { SrRefTextRubSb25, SrRefTextIntRe15 } from "../../../../ref/text";
import MainContext from "../../../helpers/context/mainContext";
import { returnRGBA } from "../../../helpers/themeColors";

import { TextAreaIntRe12 } from "../../../lvl0Components/textAreas";
import { Avatar } from "../../../lvl0Components/avatar";
import { BackFAB, NextFAB } from "../../../lvl0Components/buttons/FABs";

import { style } from "./style";

const CommentPopup = (props) => {
  // Main context
  const {
    breakpoint3,
    scrollBoxWidth,
    defaultColor,
    // API related,
    user,
    userStartup,
    setUserStartup,
    loadUser,
    setUser,
    setupNew,
    setNew
  } = useContext(MainContext);

  // Snackbar
  const snackbar = (message) => {
    toast(message, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
  };

  // Startup theme colours
  const primaryColor =
    user.userType === "emp" ? userStartup.theme?.primary.color : "rgba(200,200,200,1)";
  const returnPrimary = returnRGBA(primaryColor.r, primaryColor.g, primaryColor.b, 1);

  return (
    <div>
      {/* Background */}
      <div
        style={{
          ...style.alignItemsCenter,
          ...{ justifyContent: breakpoint3 ? "none" : "center" }
        }}
      >
        {/* Bordered card */}
        <div
          style={{
            ...style.surfaceBorder,
            ...style.scrollBox,
            ...{
              width: `${scrollBoxWidth}px`,
              border: breakpoint3 ? "none" : `0.5px solid ${SrRefColour.Neutral2B}`,
              marginTop: breakpoint3 ? "24px" : 0
            }
          }}
        >
          {/* Shram logo at the top */}
          <div
            style={{
              ...style.height50,
              ...style.marginTop48,
              ...style.marginBot72,
              ...style.marginHor24
            }}
          >
            {user.userType === "emp" ? (
              <div
                style={{
                  ...style.flexRow,
                  ...style.alignItemsCenter
                }}
              >
                {/* User org logo */}
                <Avatar
                  pic={userStartup.logo}
                  width={50}
                  borderColor={returnPrimary}
                  type={"logo"}
                />

                {/* User org name */}
                <SrRefTextRubSb25 content={userStartup.name} style={style.marginLeft12} />
              </div>
            ) : (
              // Shram logo at the top
              <div
                style={{
                  ...style.height50,
                  ...style.marginTop48,
                  ...style.marginBot72,
                  ...style.marginHor24
                }}
              >
                <img src={require("../../../../media/logo.png")} height={50} alt={"Shram logo"} />
              </div>
            )}
          </div>

          {/* Scroll view */}
          <div
            style={{
              ...style.scrollWrap,
              ...{
                width: `${scrollBoxWidth}px`,
                height: "316px"
              },
              ...style.marginTop48
            }}
          >
            <div style={{ ...{ width: `${scrollBoxWidth}px` }, ...style.paddingHor24 }}>
              <SrRefTextIntRe15
                content={"Share feedback"}
                state={"enabled"}
                style={style.colorBlack}
              />
              {/* Input for problem */}
              <div style={style.marginTop18}>
                <TextAreaIntRe12
                  isShown={true}
                  limit="500"
                  name="problem"
                  placeholder={"Ex: Loved your idea! Let's discuss it further over GMeet?"}
                  value={props.comment}
                  onChange={(e) => {
                    if (e.target.value.length < 501) {
                      props.setComment(e.target.value);
                    }
                  }}
                  onClick={() => props.setCommentClicked(true)}
                  rows={4}
                />
              </div>
            </div>
          </div>

          {/* Back button */}
          <BackFAB onClick={() => props.setCommentClicked(false)} />

          {/* Next button */}
          <NextFAB content={"Submit"} onClick={() => props.handleSubmit()} />
        </div>
      </div>

      {/* Snackbar */}
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
};

export default CommentPopup;
