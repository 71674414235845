const style = {
  textBox: {
    display: "flex",
    flexDirection: "column",
    padding: "12px",
    borderRadius: "12px"
  },
  width100: {
    width: "100%"
  },
  marginTop24: {
    marginTop: "24px"
  },

  // Custom styling for this component
  outerDiv: {
    display: "flex",
    flexDirection: "column",
    padding: "12px",
    borderRadius: "12px",
    width: "100%"
    // marginTop: "24px"
  },

  // Custom styling for this Component on Edit state
  editOuterDiv: {
    borderRadius: "18px",
    padding: "3px",
    cursor: "pointer",
    textDecoration: "none",
    transition: "0.2s ease-in"
  },
  editInnerDiv: {
    borderRadius: "16px",
    position: "relative",
    padding: "8px",
    cursor: "pointer",
    textDecoration: "none",
    transition: "0.2s ease-in"
  }
};

export default style;
