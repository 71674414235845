import React, { useState, useContext, useRef, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import SrRefColour from "../../../../ref/colours";
import {
  SrRefTextRubSb25,
  SrRefTextIntRe15,
  SrRefTextIntSb12,
  SrRefTextIntRe12,
  SrRefTextIntRe07
} from "../../../../ref/text";

import { apiendpoint } from "../../../helpers/apiendpoint";
import MainContext from "../../../helpers/context/mainContext";
import { returnRGBA } from "../../../helpers/themeColors";

import { SrCompIconCheckBox, SrCompIconCheckBoxOutlineBlank } from "../../../lvl0Components/icons";
import { Avatar } from "../../../lvl0Components/avatar";
import { BackFAB, NextFAB } from "../../../lvl0Components/buttons/FABs";
import { Dot } from "../../../lvl0Components/dot";
import { TextInputIntRe12 } from "../../../lvl0Components/textInputs";
import { ShareInviteBtn } from "../../../lvl1Components/buttons/iconBtns";

import { style } from "./style";

const EditPopup2 = (props) => {
  // Main context
  const {
    breakpoint3,
    scrollBoxWidth,
    // API related,
    startup,
    setStartup
  } = useContext(MainContext);

  // Scroll view
  const [screen, setScreen] = useState(0);
  const scrollRef = useRef();

  useEffect(() => {
    scrollRef.current.scrollLeft = screen * scrollBoxWidth;
  }, [screen]);

  // Snackbar
  const snackbar = (message) => {
    toast(message, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
  };

  // Startup theme colours
  const primaryColor = props.primaryColor;
  const returnPrimary = returnRGBA(primaryColor?.r, primaryColor?.g, primaryColor?.b, 1);

  // useStates
  const [teamSize, setTeamSize] = useState(startup.teamSize);
  const [teamSizeEdit, setTeamSizeEdit] = useState(false);
  const [cofounderCount, setCofounderCount] = useState(startup.cofounderCount);
  const [cofounderCountEdit, setCofounderCountEdit] = useState(false);
  const [team, setTeam] = useState(startup.team);
  const [loading, setLoading] = useState(false);

  const saveData = () => {
    const body = {
      cofounder_count: Number(cofounderCount),
      team_size: Number(teamSize)
    };
    var jwt = localStorage.getItem("@jwtauth");
    if (!jwt) jwt = "";

    // Org ID
    const id = Number(window.location.pathname.split("/")[3]);
    var link = `${apiendpoint}/platform/phase2/${id}`;

    fetch(link, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`
      },
      body: JSON.stringify(body)
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw new Error("Unauthorized");
      })
      .then((json) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  const saveMembers = () => {
    var temp = team;
    var teamData = [];
    temp.forEach((emp) => {
      teamData.push({
        id: emp.id,
        fname: emp.fname,
        lname: emp.lname,
        designation: emp.designation,
        picture: emp.pic,
        linkedIn: emp.linkedin,
        org_id: emp.orgId,
        is_org_lead: emp.orgLead,
        representer: emp.representer
      });
    });
    var body = { changeEmpDetails: teamData };
    let jwt = localStorage.getItem("@jwtauth");
    if (!jwt) jwt = "";
    fetch(`${apiendpoint}/platform/orgUser`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`
      },
      body: JSON.stringify(body)
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw new Error("Unauthorised");
      })
      .then((json) => {})
      .catch((err) => {
        console.log(err);
        console.log("updateTeam failed");
      });
  };

  useEffect(() => {
    return () => {
      saveData();
      saveMembers();
    };
  }, [cofounderCount, teamSize, team]);

  const changeRepresenter = (id) => {
    var temp = team;
    temp.forEach((t) => {
      if (t.id === id) t.representer = "Y";
      else t.representer = "N";
    });
    setStartup({
      ...startup,
      team: temp
    });
    setTeam(temp);
  };

  const setOrgLead = (id) => {
    var temp = team;
    temp.forEach((t) => {
      if (t.id === id) t.orgLead === "Y" ? (t.orgLead = "N") : (t.orgLead = "Y");
    });
    setTeam(temp);
    setStartup({
      ...startup,
      team: temp
    });
  };

  const CheckBox = (props) => {
    const [btnHover, setBtnHover] = useState(false);
    const [checkBoxFill, setCheckBoxFill] = useState(props.status);

    return (
      <div
        onMouseEnter={() => setBtnHover(true)}
        onMouseLeave={() => setBtnHover(false)}
        onClick={
          // setCheckBoxFill(!checkBoxFill);
          props.onClick
        }
      >
        {/* Checkbox for orglead */}
        {checkBoxFill ? (
          <SrCompIconCheckBox size={24} />
        ) : (
          <SrCompIconCheckBoxOutlineBlank size={24} />
        )}
      </div>
    );
  };

  //dynamic styling for this component
  const style2 = {
    backgroundWrap: {
      ...style.alignItemsCenter,
      justifyContent: breakpoint3 ? "none" : "center"
    },
    cardContainer: {
      ...style.cardContainer,
      width: `${scrollBoxWidth}px`,
      border: breakpoint3 ? "none" : `0.5px solid ${SrRefColour.Neutral2B}`,
      marginTop: breakpoint3 ? "24px" : 0
    },
    scrollViewContainer: {
      ...style.scrollViewContainer,
      width: `${scrollBoxWidth}px`,
      height: "316px"
    },
    nameContainer: {
      ...style.paddingHor24,
      width: `${scrollBoxWidth}px`
    }
  };

  return (
    <div>
      {/* Background */}
      <div style={style2.backgroundWrap}>
        {/* Bordered card */}
        <div style={style2.cardContainer}>
          {/* Shram logo at the top */}
          <div style={style.logoOuterWrap}>
            <div style={style.logoInnerWrap}>
              {/* User org logo */}
              <Avatar pic={props.startupLogo} width={50} borderColor={returnPrimary} />

              {/* User org name */}
              <SrRefTextRubSb25 content={props.startupName} style={style.marginLeft12} />
            </div>
          </div>

          {/* Scroll view */}
          <div style={style2.scrollViewContainer} ref={scrollRef}>
            {/* Enter team size screen */}
            <div style={style.scroll}>
              <div style={style2.nameContainer}>
                {/* Text */}
                <SrRefTextIntRe15
                  content={"What is your team size?"}
                  state={"enabled"}
                  style={style.colorBlack}
                />

                {/* Input for cofounder count */}
                <div style={style.marginTop18}>
                  <TextInputIntRe12
                    type="number"
                    name="cofounderCount"
                    placeholder={"Total no of co-founders"}
                    topText={"Total no of co-founders"}
                    value={cofounderCount}
                    onChange={(e) => [
                      setCofounderCount(e.target.value),
                      setStartup({
                        ...startup,
                        cofounderCount: e.target.value.trim()
                      })
                    ]}
                    onClick={() => setCofounderCountEdit(true)}
                    noLimit={true}
                  />
                </div>

                {/* Input for team size */}
                <div style={style.marginTop18}>
                  <TextInputIntRe12
                    type="number"
                    name="teamSize"
                    placeholder={"Total no of team members"}
                    topText={"Total no of team members"}
                    value={teamSize}
                    onChange={(e) => [
                      setTeamSize(e.target.value),
                      setStartup({
                        ...startup,
                        teamSize: e.target.value.trim()
                      })
                    ]}
                    onClick={() => setTeamSizeEdit(true)}
                    noLimit={true}
                  />
                </div>
              </div>
            </div>

            {/* Invite team members */}
            <div style={style.scroll}>
              <div style={style2.nameContainer}>
                {/* Text */}
                <SrRefTextIntRe15
                  content={"Invite team members"}
                  state={"enabled"}
                  style={style.colorBlack}
                />

                {/* Input for team size */}
                <div style={style.marginTop18}>
                  <SrRefTextIntRe12
                    content={
                      "Share this code with your team members to invite them on this platform"
                    }
                  />
                </div>

                {/* Share invite code button */}

                <div style={style.marginTop24}>
                  <ShareInviteBtn
                    startup={startup}
                    color={startup.theme.primary.color}
                    snackbar={snackbar}
                  />
                </div>
              </div>
            </div>

            {/* Edit team representation screen */}
            <div style={style.scroll}>
              <div style={style2.nameContainer}>
                {/* Text */}
                <SrRefTextIntRe15
                  content={"Your team representation"}
                  state={"enabled"}
                  style={style.colorBlack}
                />

                <div style={style.vpScrollable}>
                  {/* Table headers */}
                  <div style={style.flexRowReverse}>
                    <div style={style.textWrap}>
                      <SrRefTextIntRe07 content={"Co-founder"} />
                    </div>
                    <div style={style.textWrap2}>
                      <SrRefTextIntRe07 content={"Representer"} />
                    </div>
                  </div>

                  {/* All team members */}
                  {team.map((m) => (
                    <div
                      style={{
                        marginTop: team.indexOf(m) ? "12px" : 0
                      }}
                      key={m.id}
                    >
                      <div style={style.avatarOuterDiv}>
                        {/* Team lead details */}
                        <div style={style.avatarInnerDiv}>
                          {/* Team lead pic */}
                          <Avatar
                            width={42}
                            pic={m.pic ? m.pic : require("../../../../media/no_user.png")}
                            borderColor={returnPrimary}
                            key={m}
                          />

                          {/* Team lead details */}
                          <div style={style.marginLeft12}>
                            {/* Name */}
                            <SrRefTextIntSb12 content={`${m.fname} ${m.lname}`} />

                            {/* Designation */}
                            <SrRefTextIntRe12 content={m.designation} />
                          </div>
                        </div>

                        {/* Team lead options */}
                        <div style={style.flexRow}>
                          {/* CheckBox for representer */}
                          <div style={style.checkboxWrap}>
                            <CheckBox
                              onClick={() => changeRepresenter(m.id)}
                              showRepresenter={false}
                              status={m.representer === "Y"}
                            />
                          </div>

                          {/* CheckBox for co-founder */}
                          <div style={style.checkboxWrap2}>
                            <CheckBox onClick={() => setOrgLead(m.id)} status={m.orgLead === "Y"} />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* Back button */}
          <BackFAB
            onClick={() =>
              screen === 0
                ? [props.setEdit(false), props.setEditHover(false), props.allowScroll()]
                : setScreen((p) => p - 1)
            }
          />

          {/* Dot carousal */}
          <div style={style.dotArray}>
            <Dot screen={screen} actualScreen={0} />
            <Dot screen={screen} actualScreen={1} />
            <Dot screen={screen} actualScreen={2} />
          </div>

          {/* Next button */}
          <NextFAB
            content={screen === 2 ? "Done" : "Next"}
            onClick={() =>
              screen === 2
                ? [props.setEdit(false), props.setEditHover(false), props.allowScroll()]
                : setScreen((p) => p + 1)
            }
          />
        </div>
      </div>

      {/* Snackbar */}
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
};

export default EditPopup2;
