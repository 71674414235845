import React from "react";

import ShramLogo from "../../../media/logo512.png";
import { style } from "./style";

const Loading = () => {
  return (
    // Loading screen background
    <div style={style.background}>
      {/* Image placed within the loading screen */}
      <img src={ShramLogo} style={style.logo} />
    </div>
  );
};

export default Loading;
