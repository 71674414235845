import { useContext } from "react";
import MainContext from "../../helpers/context/mainContext";

import { EditBtn0 } from "../../lvl0Components/buttons/iconBtns";
import { BITSChip } from "../../lvl0Components/chips";

import SrRefColour from "../../../ref/colours";
import { SrRefTextIntRe12, SrRefTextIntSb15 } from "../../../ref/text";
import { gradientBorderTextBox, gradientText, newLineText } from "../../helpers/themeColors";

import style from "./style";

// "What are the primary areas where you need guidance and support crucial for achieving success?"

const newLineText2 = (props, color1, color2, invertBackground) => {
  const newText = props
    ? props
        .split("\n")
        .map((str) =>
          str ? <SrRefTextIntRe12 content={str} style={{ color: "black" }} /> : <br />
        )
    : [];

  return newText;
};

const MainComponent = () => {
  const { startup, primaryColor, tertiaryColor, invertBackground } = useContext(MainContext);

  return (
    <div
      style={{
        ...style.textBox,
        ...gradientBorderTextBox(tertiaryColor, primaryColor, invertBackground),
        ...style.width100,
        backgroundColor: SrRefColour.Neutral0
      }}
    >
      <div style={style.marginHor3}>
        <SrRefTextIntSb15 content={"Key mentorship areas"} style={{ color: "black" }} />
      </div>

      {/* mentorSectors default text */}
      <div style={style.marginTop6}>
        {!(
          startup.pitch.mentorSector1 ||
          startup.pitch.mentorSector2 ||
          startup.pitch.mentorSector3 ||
          startup.pitch.mentorSector4 ||
          startup.pitch.mentorSector5
        ) &&
          newLineText2(
            "Outline the three main obstacles and limitations hindering the development of your product or service.",
            tertiaryColor,
            primaryColor,
            invertBackground
          )}
      </div>

      {/* Challenge text */}
      <div style={style.chips}>
        {/* Chip for mentorSectors */}
        {startup.pitch.mentorSector1 && (
          <BITSChip
            content={startup.pitch.mentorSector1 || "Key mentorship area 1"}
            color1={primaryColor}
            color2={tertiaryColor}
            invertBackground={invertBackground}
          />
        )}

        {startup.pitch.mentorSector2 && (
          <BITSChip
            content={startup.pitch.mentorSector2 || "Key mentorship area 2"}
            color1={primaryColor}
            color2={tertiaryColor}
            invertBackground={invertBackground}
          />
        )}

        {startup.pitch.mentorSector3 && (
          <BITSChip
            content={startup.pitch.mentorSector3 || "Key mentorship area 3"}
            color1={primaryColor}
            color2={tertiaryColor}
            invertBackground={invertBackground}
          />
        )}

        {startup.pitch.mentorSector4 && (
          <BITSChip
            content={startup.pitch.mentorSector4 || "Key mentorship area 4"}
            color1={primaryColor}
            color2={tertiaryColor}
            invertBackground={invertBackground}
          />
        )}

        {startup.pitch.mentorSector5 && (
          <BITSChip
            content={startup.pitch.mentorSector5 || "Key mentorship area 5"}
            color1={primaryColor}
            color2={tertiaryColor}
            invertBackground={invertBackground}
          />
        )}
      </div>
    </div>
  );
};

const MentorshipAreas = () => {
  // Edit state to check if user is on startupEdit page
  const { ifEdit, setEdit13, editHover13, setEditHover13, setAllHoverFalse } =
    useContext(MainContext);

  return ifEdit ? (
    <div
      onClick={() => setEdit13(true)}
      onMouseEnter={() => [setAllHoverFalse(), setEditHover13(true)]}
      onMouseLeave={() => setEditHover13(false)}
      style={{
        ...style.editOuterDiv,
        backgroundColor: editHover13 && SrRefColour.Neutral1C
      }}
    >
      <div
        style={{
          ...style.editInnerDiv,
          border: `1px dotted ${SrRefColour.Neutral2B}`
        }}
      >
        <MainComponent />

        <div style={{ position: "absolute", top: "11px", right: "11px" }}>
          <EditBtn0 selected={editHover13} />
        </div>
      </div>
    </div>
  ) : (
    <MainComponent />
  );
};

export default MentorshipAreas;
