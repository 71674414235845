import React, { useState } from "react";
import SrRefColour from "../../../../ref/colours";
import { SrRefTextIntRe12, SrRefTextIntRe09 } from "../../../../ref/text";
import {
  SrCompIconHome,
  SrCompIconThumbUpEmpty,
  SrCompIconThumbUpFull,
  SrCompIconLogout,
  SrCompIconManageAccounts,
  SrCompIconPersonAddFilled,
  SrCompIconStarRateFilled,
  SrCompIconThumbUpFilled,
  SrCompIconThumbUpUnfilled,
  SrCompIconStarRateUnfilled,
  SrCompIconChatUnfilled,
  SrCompIconPersonAddUnfilled,
  SrCompIconChatFilled,
  SrCompIconSave,
  SrCompIconUpload,
  SrCompIconEdit,
  SrCompIconBrowseActivity
} from "../../../lvl0Components/icons";
import style from "./style";

// Home button
const HomeBtn = (props) => {
  const [btnHover, setBtnHover] = useState(false);

  // Dynamic styling for this component
  const style2 = {
    innerDiv: {
      ...style.homeInnerDiv,
      borderColor: props.pressed
        ? SrRefColour.Neutral1A
        : btnHover
        ? SrRefColour.Neutral2A
        : SrRefColour.Neutral2B
    }
  };

  return (
    <div style={style.marginHor6}>
      <div
        onMouseEnter={() => setBtnHover(true)}
        onMouseLeave={() => setBtnHover(false)}
        onClick={props.onClick}
        style={style.clickable}
      >
        <div style={style2.innerDiv}>
          <div style={style.icon24}>
            <SrCompIconHome size={24} />
          </div>
        </div>
      </div>
    </div>
  );
};

// ThumbUp button
const ThumbUpBtn = (props) => {
  const [btnHover, setBtnHover] = useState(false);

  // Dynamic styling for this component
  const style2 = {
    phoneOuterDiv: {
      ...style.shell,
      backgroundColor: props.darkMode ? "rgb(0,0,0,0.15)" : "rgb(255,255,255,0.8)",
      borderColor: props.pressed
        ? props.darkMode
          ? SrRefColour.Neutral0
          : SrRefColour.Neutral1A
        : btnHover
        ? props.darkMode
          ? SrRefColour.Neutral2B
          : SrRefColour.Neutral2A
        : props.darkMode
        ? SrRefColour.Neutral2A
        : SrRefColour.Neutral2B,
      ...style.transition
    },
    laptopOuterDiv: {
      ...style.flexRow,
      ...style.alignItemsCenter,
      ...style.shell2,
      backgroundColor: props.darkMode ? "rgb(0,0,0,0.15)" : "rgb(255,255,255,0.8)",
      borderColor: props.pressed
        ? props.darkMode
          ? SrRefColour.Neutral0
          : SrRefColour.Neutral1A
        : btnHover
        ? props.darkMode
          ? SrRefColour.Neutral2B
          : SrRefColour.Neutral2A
        : props.darkMode
        ? SrRefColour.Neutral2A
        : SrRefColour.Neutral2B,
      ...style.transition
    }
  };

  return (
    <div style={style.marginHor6}>
      <div
        onMouseEnter={() => setBtnHover(true)}
        onMouseLeave={() => setBtnHover(false)}
        onClick={props.onClick}
        style={{ ...style.positionRelative, ...style.clickable }}
      >
        {props.breakpoint3 ? (
          <div style={style2.phoneOuterDiv}>
            <div style={style.icon24}>
              {props.pressed ? (
                <SrCompIconThumbUpFilled
                  size={24}
                  color={props.darkMode ? SrRefColour.Neutral0 : SrRefColour.Neutral1A}
                />
              ) : (
                <SrCompIconThumbUpUnfilled
                  size={24}
                  color={props.darkMode ? SrRefColour.Neutral0 : SrRefColour.Neutral1A}
                />
              )}
            </div>
          </div>
        ) : (
          // For larger screens
          <div style={style2.laptopOuterDiv}>
            <SrRefTextIntRe12
              content={"Upvote"}
              style={{
                ...style.transition,
                ...{ color: props.darkMode ? SrRefColour.Neutral0 : SrRefColour.Neutral1A }
              }}
            />

            <div style={{ ...style.icon20, ...style.marginLeft6 }}>
              {props.pressed ? (
                <SrCompIconThumbUpFilled
                  size={20}
                  color={props.darkMode ? SrRefColour.Neutral0 : SrRefColour.Neutral1A}
                />
              ) : (
                <SrCompIconThumbUpUnfilled
                  size={20}
                  color={props.darkMode ? SrRefColour.Neutral0 : SrRefColour.Neutral1A}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

// Comment button
const CommentBtn = (props) => {
  const [btnHover, setBtnHover] = useState(false);

  // Dynmic styling for this component
  const style2 = {
    phoneOuterDiv: {
      ...style.shell,
      backgroundColor: props.darkMode ? "rgb(0,0,0,0.15)" : "rgb(255,255,255,0.8)",
      borderColor: props.pressed
        ? props.darkMode
          ? SrRefColour.Neutral0
          : SrRefColour.Neutral1A
        : btnHover
        ? props.darkMode
          ? SrRefColour.Neutral2B
          : SrRefColour.Neutral2A
        : props.darkMode
        ? SrRefColour.Neutral2A
        : SrRefColour.Neutral2B,
      ...style.transition
    },
    laptopOuterDiv: {
      ...style.flexRow,
      ...style.alignItemsCenter,
      ...style.shell2,
      backgroundColor: props.darkMode ? "rgb(0,0,0,0.15)" : "rgb(255,255,255,0.8)",
      borderColor: props.pressed
        ? props.darkMode
          ? SrRefColour.Neutral0
          : SrRefColour.Neutral1A
        : btnHover
        ? props.darkMode
          ? SrRefColour.Neutral2B
          : SrRefColour.Neutral2A
        : props.darkMode
        ? SrRefColour.Neutral2A
        : SrRefColour.Neutral2B,
      ...style.transition
    }
  };

  return (
    <div style={style.marginHor6}>
      <div
        onMouseEnter={() => setBtnHover(true)}
        onMouseLeave={() => setBtnHover(false)}
        onClick={props.onClick}
        style={{ ...style.positionRelative, ...style.clickable }}
      >
        {props.breakpoint3 ? (
          <div style={style2.phoneOuterDiv}>
            <div style={style.icon24}>
              {props.pressed ? (
                <SrCompIconChatFilled
                  size={24}
                  color={props.darkMode ? SrRefColour.Neutral0 : SrRefColour.Neutral1A}
                />
              ) : (
                <SrCompIconChatUnfilled
                  size={24}
                  color={props.darkMode ? SrRefColour.Neutral0 : SrRefColour.Neutral1A}
                />
              )}
            </div>
          </div>
        ) : (
          // For larger screens
          <div style={style2.laptopOuterDiv}>
            <SrRefTextIntRe12
              content={"Comment"}
              style={{
                ...style.transition,
                ...{ color: props.darkMode ? SrRefColour.Neutral0 : SrRefColour.Neutral1A }
              }}
            />

            <div style={{ ...style.icon20, ...style.marginLeft6 }}>
              {props.pressed ? (
                <SrCompIconChatFilled
                  size={20}
                  color={props.darkMode ? SrRefColour.Neutral0 : SrRefColour.Neutral1A}
                />
              ) : (
                <SrCompIconChatUnfilled
                  size={20}
                  color={props.darkMode ? SrRefColour.Neutral0 : SrRefColour.Neutral1A}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

// Connect button
const ConnectBtn = (props) => {
  const [btnHover, setBtnHover] = useState(false);

  // Dynamic styling for this component
  const style2 = {
    phoneOuterDiv: {
      ...style.shell,
      backgroundColor: props.darkMode ? "rgb(0,0,0,0.15)" : "rgb(255,255,255,0.8)",
      borderColor: props.pressed
        ? props.darkMode
          ? SrRefColour.Neutral0
          : SrRefColour.Neutral1A
        : btnHover
        ? props.darkMode
          ? SrRefColour.Neutral2B
          : SrRefColour.Neutral2A
        : props.darkMode
        ? SrRefColour.Neutral2A
        : SrRefColour.Neutral2B,
      ...style.transition
    },
    laptopOuterDiv: {
      ...style.flexRow,
      ...style.alignItemsCenter,
      ...style.shell2,

      backgroundColor: props.darkMode ? "rgb(0,0,0,0.15)" : "rgb(255,255,255,0.8)",
      borderColor: props.pressed
        ? props.darkMode
          ? SrRefColour.Neutral0
          : SrRefColour.Neutral1A
        : btnHover
        ? props.darkMode
          ? SrRefColour.Neutral2B
          : SrRefColour.Neutral2A
        : props.darkMode
        ? SrRefColour.Neutral2A
        : SrRefColour.Neutral2B,
      ...style.transition
    }
  };

  return (
    <div style={style.marginHor6}>
      <div
        onMouseEnter={() => setBtnHover(true)}
        onMouseLeave={() => setBtnHover(false)}
        onClick={props.onClick}
        style={{ ...style.positionRelative, ...style.clickable }}
      >
        {props.breakpoint3 ? (
          <div style={style2.phoneOuterDiv}>
            <div style={style.icon24}>
              {props.pressed ? (
                <SrCompIconPersonAddFilled
                  size={24}
                  color={props.darkMode ? SrRefColour.Neutral0 : SrRefColour.Neutral1A}
                />
              ) : (
                <SrCompIconPersonAddUnfilled
                  size={24}
                  color={props.darkMode ? SrRefColour.Neutral0 : SrRefColour.Neutral1A}
                />
              )}
            </div>
          </div>
        ) : (
          // For larger screens
          <div style={style2.laptopOuterDiv}>
            <SrRefTextIntRe12
              content={"Connect"}
              style={{
                ...style.transition,
                ...{ color: props.darkMode ? SrRefColour.Neutral0 : SrRefColour.Neutral1A }
              }}
            />

            <div style={{ ...style.icon20, ...style.marginLeft6 }}>
              {props.pressed ? (
                <SrCompIconPersonAddFilled
                  size={20}
                  color={props.darkMode ? SrRefColour.Neutral0 : SrRefColour.Neutral1A}
                />
              ) : (
                <SrCompIconPersonAddUnfilled
                  size={20}
                  color={props.darkMode ? SrRefColour.Neutral0 : SrRefColour.Neutral1A}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

// Rate button
const RateBtn = (props) => {
  const [btnHover, setBtnHover] = useState(false);

  const RateIcon20 = (params) => {
    return (
      <div
        style={style.icon20Clickable}
        onClick={() => params.onChange({ target: { value: params.value } })}
      >
        {params.pressed && params.pressed > params.value - 1 ? (
          <SrCompIconStarRateFilled
            size={20}
            color={params.darkMode ? SrRefColour.Neutral0 : SrRefColour.Neutral1A}
          />
        ) : (
          <SrCompIconStarRateUnfilled
            size={20}
            color={params.darkMode ? SrRefColour.Neutral0 : SrRefColour.Neutral1A}
          />
        )}
      </div>
    );
  };

  const RateIcon24 = (params) => {
    return (
      <div
        style={style.icon24Clickable}
        onClick={() => params.onChange({ target: { value: params.value } })}
      >
        {params.pressed && params.pressed > params.value - 1 ? (
          <SrCompIconStarRateFilled
            size={24}
            color={params.darkMode ? SrRefColour.Neutral0 : SrRefColour.Neutral1A}
          />
        ) : (
          <SrCompIconStarRateUnfilled
            size={24}
            color={params.darkMode ? SrRefColour.Neutral0 : SrRefColour.Neutral1A}
          />
        )}
      </div>
    );
  };

  return (
    <div style={style.marginHor6}>
      <div
        onMouseEnter={() => setBtnHover(true)}
        onMouseLeave={() => setBtnHover(false)}
        onClick={props.onClick}
        style={{ ...style.positionRelative, cursor: "default" }}
      >
        {props.breakpoint3 ? (
          <div
            style={{
              ...style.flexRow,
              ...style.gap6,
              ...style.shell,
              ...{
                backgroundColor: props.darkMode ? "rgb(0,0,0,0.15)" : "rgb(255,255,255,0.8)",
                borderColor:
                  props.pressed && props.pressed > 1
                    ? props.darkMode
                      ? SrRefColour.Neutral0
                      : SrRefColour.Neutral1A
                    : btnHover
                    ? props.darkMode
                      ? SrRefColour.Neutral2B
                      : SrRefColour.Neutral2A
                    : props.darkMode
                    ? SrRefColour.Neutral2A
                    : SrRefColour.Neutral2B
              },
              ...style.transition
            }}
          >
            {/* Star icon inputs for rating */}
            <RateIcon24
              value={1}
              onChange={props.onChange}
              pressed={props.pressed}
              darkMode={props.darkMode}
            />
            <RateIcon24
              value={2}
              onChange={props.onChange}
              pressed={props.pressed}
              darkMode={props.darkMode}
            />
            <RateIcon24
              value={3}
              onChange={props.onChange}
              pressed={props.pressed}
              darkMode={props.darkMode}
            />
            <RateIcon24
              value={4}
              onChange={props.onChange}
              pressed={props.pressed}
              darkMode={props.darkMode}
            />
            <RateIcon24
              value={5}
              onChange={props.onChange}
              pressed={props.pressed}
              darkMode={props.darkMode}
            />
          </div>
        ) : (
          // For larger screens
          <div
            style={{
              ...style.flexRow,
              ...style.alignItemsCenter,
              ...style.gap6,
              ...style.shell2,
              ...{
                backgroundColor: props.darkMode ? "rgb(0,0,0,0.15)" : "rgb(255,255,255,0.8)",
                borderColor:
                  props.pressed && props.pressed > 1
                    ? props.darkMode
                      ? SrRefColour.Neutral0
                      : SrRefColour.Neutral1A
                    : btnHover
                    ? props.darkMode
                      ? SrRefColour.Neutral2B
                      : SrRefColour.Neutral2A
                    : props.darkMode
                    ? SrRefColour.Neutral2A
                    : SrRefColour.Neutral2B
              },
              ...style.transition
            }}
          >
            <SrRefTextIntRe12
              content={"Rate"}
              style={{
                ...style.marginRight6,
                ...style.transition,
                ...{ color: props.darkMode ? SrRefColour.Neutral0 : SrRefColour.Neutral1A }
              }}
            />

            {/* Star icon inputs for rating */}
            <RateIcon20
              value={1}
              onChange={props.onChange}
              pressed={props.pressed}
              darkMode={props.darkMode}
            />
            <RateIcon20
              value={2}
              onChange={props.onChange}
              pressed={props.pressed}
              darkMode={props.darkMode}
            />
            <RateIcon20
              value={3}
              onChange={props.onChange}
              pressed={props.pressed}
              darkMode={props.darkMode}
            />
            <RateIcon20
              value={4}
              onChange={props.onChange}
              pressed={props.pressed}
              darkMode={props.darkMode}
            />
            <RateIcon20
              value={5}
              onChange={props.onChange}
              pressed={props.pressed}
              darkMode={props.darkMode}
            />
          </div>
        )}
      </div>
    </div>
  );
};

// Upload button
const UploadBtn = (props) => {
  const [btnHover, setBtnHover] = useState(false);

  // Dynamic styling for this component
  const style2 = {
    inputContainer: {
      ...style.uploadInputContainer,
      borderColor: props.pressed
        ? SrRefColour.Neutral1A
        : btnHover
        ? SrRefColour.Neutral2A
        : SrRefColour.Neutral2B
    }
  };

  return (
    <div style={style.marginHor6}>
      <div
        onMouseEnter={() => setBtnHover(true)}
        onMouseLeave={() => setBtnHover(false)}
        onClick={props.onClick}
        style={style.clickable}
      >
        <div style={style2.inputContainer}>
          <SrRefTextIntRe12 content={"Submit"} style={style.transition} />

          <div style={style.iconStyle}>
            <SrCompIconUpload size={20} />
          </div>
        </div>
      </div>
    </div>
  );
};

// Logout button
const LogoutBtn = (props) => {
  const [btnHover, setBtnHover] = useState(false);

  // Dynamic styling for this component
  const style2 = {
    phoneContainer: {
      ...style.logoutPhoneContainer,
      borderColor: props.pressed
        ? SrRefColour.Neutral1A
        : btnHover
        ? SrRefColour.Neutral2A
        : SrRefColour.Neutral2B
    },
    screenContainer: {
      ...style.logoutScreenContainer,
      borderColor: props.pressed
        ? SrRefColour.Neutral1A
        : btnHover
        ? SrRefColour.Neutral2A
        : SrRefColour.Neutral2B
    }
  };

  return (
    <div style={style.marginHor6}>
      <div
        onMouseEnter={() => setBtnHover(true)}
        onMouseLeave={() => setBtnHover(false)}
        onClick={props.onClick}
        style={style.clickable}
      >
        {props.breakpoint3 ? (
          // For phone
          <div style={style2.phoneContainer}>
            <div style={style.icon24}>
              <SrCompIconLogout size={24} />
            </div>
          </div>
        ) : (
          // For larger screens
          <div style={style2.screenContainer}>
            <SrRefTextIntRe12 content={"Logout"} style={style.transition} />

            <div style={style.iconStyle}>
              <SrCompIconLogout size={20} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const LoginBtn = (props) => {
  const [btnHover, setBtnHover] = useState(false);

  // Dynamic styling for this component
  const style2 = {
    phoneContainer: {
      ...style.logoutPhoneContainer,
      borderColor: props.pressed
        ? SrRefColour.Neutral1A
        : btnHover
        ? SrRefColour.Neutral2A
        : SrRefColour.Neutral2B
    },
    screenContainer: {
      ...style.logoutScreenContainer,
      borderColor: props.pressed
        ? SrRefColour.Neutral1A
        : btnHover
        ? SrRefColour.Neutral2A
        : SrRefColour.Neutral2B
    }
  };

  return (
    <div style={style.marginHor6}>
      <div
        onMouseEnter={() => setBtnHover(true)}
        onMouseLeave={() => setBtnHover(false)}
        onClick={props.onClick}
        style={style.clickable}
      >
        {props.breakpoint3 ? (
          // For phone
          <div style={style2.phoneContainer}>
            <div style={style.icon24}>
              <SrCompIconLogout size={24} />
            </div>
          </div>
        ) : (
          // For larger screens
          <div style={style2.screenContainer}>
            <SrRefTextIntRe12 content={"Login"} style={style.transition} />

            <div style={style.iconStyle}>
              <SrCompIconLogout size={20} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

// Account button
const AccountBtn = (props) => {
  const [btnHover, setBtnHover] = useState(false);

  // Dynamic styling for this component
  const style2 = {
    phoneIconContainer: {
      ...style.accountPhoneContainer,
      borderColor: props.pressed
        ? SrRefColour.Neutral1A
        : btnHover
        ? SrRefColour.Neutral2A
        : SrRefColour.Neutral2B
    },
    screenIconContainer: {
      ...style.accountScreenContainer,
      borderColor: props.pressed
        ? SrRefColour.Neutral1A
        : btnHover
        ? SrRefColour.Neutral2A
        : SrRefColour.Neutral2B
    }
  };

  return (
    <div style={style.marginHor6}>
      <div
        onMouseEnter={() => setBtnHover(true)}
        onMouseLeave={() => setBtnHover(false)}
        onClick={props.onClick}
        style={style.clickable}
      >
        {props.breakpoint3 ? (
          // For phone
          <div style={style2.phoneIconContainer}>
            <div style={style.icon24}>
              <SrCompIconManageAccounts size={24} />
            </div>
          </div>
        ) : (
          // For larger screens
          <div style={style2.screenIconContainer}>
            <SrRefTextIntRe12 content={"Account"} style={style.transition} />
            <div style={style.iconStyle}>
              <SrCompIconManageAccounts size={20} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

// Edit button
const EditBtn = (props) => {
  const [btnHover, setBtnHover] = useState(false);

  const style2 = {
    phoneIconContainer: {
      ...style.editPhoneContainer,
      borderColor: props.pressed
        ? SrRefColour.Neutral1A
        : btnHover
        ? SrRefColour.Neutral2A
        : SrRefColour.Neutral2B
    },
    screenIconContainer: {
      ...style.editScreenContainer,
      borderColor: props.pressed
        ? SrRefColour.Neutral1A
        : btnHover
        ? SrRefColour.Neutral2A
        : SrRefColour.Neutral2B
    }
  };

  return (
    <div style={style.marginHor6}>
      <div
        onMouseEnter={() => setBtnHover(true)}
        onMouseLeave={() => setBtnHover(false)}
        onClick={props.onClick}
        style={style.clickable}
      >
        {props.breakpoint3 ? (
          // For phone
          <div style={style2.phoneIconContainer}>
            <div style={style.icon24}>
              <SrCompIconEdit size={24} />
            </div>
          </div>
        ) : (
          // For larger screens
          <div style={style2.screenIconContainer}>
            <SrRefTextIntRe12 content={"Edit"} style={style.transition} />

            <div style={style.iconStyle}>
              <SrCompIconEdit size={20} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

// Save button
const SaveBtn = (props) => {
  const [btnHover, setBtnHover] = useState(false);

  // Dynamic styling for this component
  const style2 = {
    phoneContainer: {
      ...style.savePhoneContainer,
      borderColor: props.pressed
        ? SrRefColour.Neutral1A
        : btnHover
        ? SrRefColour.Neutral2A
        : SrRefColour.Neutral2B
    },
    screenContainer: {
      ...style.saveScreenContainer,
      borderColor: props.pressed
        ? SrRefColour.Neutral1A
        : btnHover
        ? SrRefColour.Neutral2A
        : SrRefColour.Neutral2B
    }
  };

  return (
    <div style={style.marginHor6}>
      <div
        onMouseEnter={() => setBtnHover(true)}
        onMouseLeave={() => setBtnHover(false)}
        onClick={props.onClick}
        style={style.clickable}
      >
        {props.breakpoint3 ? (
          // For phone
          <div style={style2.phoneContainer}>
            <div style={style.icon24}>
              <SrCompIconSave size={24} />
            </div>
          </div>
        ) : (
          // For larger screens
          <div style={style2.screenContainer}>
            <SrRefTextIntRe12 content={props.content} style={style.transition} />

            <div style={style.iconStyle}>
              <SrCompIconSave size={20} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

// Approve startup button
const ApproveBtn = (props) => {
  const [btnHover, setBtnHover] = useState(false);

  // Dynamic styling for this component
  const style2 = {
    phoneContainer: {
      ...style.savePhoneContainer,
      borderColor: props.pressed
        ? SrRefColour.Neutral1A
        : btnHover
        ? SrRefColour.Neutral2A
        : SrRefColour.Neutral2B
    },
    screenContainer: {
      ...style.saveScreenContainer,
      borderColor: props.pressed
        ? SrRefColour.Neutral1A
        : btnHover
        ? SrRefColour.Neutral2A
        : SrRefColour.Neutral2B
    }
  };

  return (
    <div style={style.marginHor6}>
      <div
        onMouseEnter={() => setBtnHover(true)}
        onMouseLeave={() => setBtnHover(false)}
        onClick={props.onClick}
        style={style.clickable}
      >
        {props.breakpoint3 ? (
          // For phone
          <div style={style2.phoneContainer}>
            <div style={style.icon24}>
              <SrCompIconSave size={24} />
            </div>
          </div>
        ) : (
          // For larger screens
          <div style={style2.screenContainer}>
            <SrRefTextIntRe12 content={props.content} style={style.transition} />

            <div style={style.iconStyle}>
              <SrCompIconSave size={20} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

// Preview button
const PreviewBtn = (props) => {
  const [btnHover, setBtnHover] = useState(false);

  // Dynamic styling for this component
  const style2 = {
    phoneContainer: {
      ...style.previewPhoneContainer,
      borderColor: props.pressed
        ? SrRefColour.Neutral1A
        : btnHover
        ? SrRefColour.Neutral2A
        : SrRefColour.Neutral2B
    },
    screenContainer: {
      ...style.previewScreenContainer,
      borderColor: props.pressed
        ? SrRefColour.Neutral1A
        : btnHover
        ? SrRefColour.Neutral2A
        : SrRefColour.Neutral2B
    }
  };

  return (
    <div style={style.marginHor6}>
      <div
        onMouseEnter={() => setBtnHover(true)}
        onMouseLeave={() => setBtnHover(false)}
        onClick={props.onClick}
        style={style.clickable}
      >
        {props.breakpoint3 ? (
          // For phone
          <div style={style2.phoneContainer}>
            <div style={style.icon24}>
              <SrCompIconBrowseActivity size={24} />
            </div>
          </div>
        ) : (
          // For larger screens
          <div style={style2.screenContainer}>
            <SrRefTextIntRe12 content={"Your listing"} style={style.transition} />

            <div style={style.iconStyle}>
              <SrCompIconBrowseActivity size={20} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const UserPanelBtn = (props) => {
  const [btnHover, setBtnHover] = useState(false);

  // Dynamic styling for this component
  const style2 = {
    outerDiv: {
      ...style.userOuterDiv,
      borderColor: props.pressed
        ? SrRefColour.Neutral1A
        : btnHover
        ? SrRefColour.Neutral2A
        : SrRefColour.Neutral2B
    },
    picWrap: {
      ...style.maxHeight39,
      marginLeft: props.userStartup ? "12px" : 0
    }
  };

  return (
    <div style={{ ...style.justifyContentCenter, ...style.marginHor6 }}>
      <div
        onMouseEnter={() => setBtnHover(true)}
        onMouseLeave={() => setBtnHover(false)}
        onClick={props.onClick}
        style={style.clickable}
      >
        <div style={style2.outerDiv}>
          {/* Dont delete this condition. It might seem unnecessary but protects us from a weird .logo error */}
          {props.user && (
            // If the user is logged in
            <div style={style.logo}>
              {props.userStartup && (
                <>
                  {/* User org logo */}
                  <div style={style.maxHeight39}>
                    <img
                      src={props.userStartup.logo}
                      width={39}
                      alt={"User org logo"}
                      style={style.borderRadius39}
                    />
                  </div>

                  {/* Org name on screens larger than a phone */}
                  {!props.breakpoint3 && (
                    <SrRefTextIntRe12 content={props.userStartup.name} style={style.inputStyle} />
                  )}
                </>
              )}

              {/* User pic */}
              <div style={style2.picWrap}>
                <img
                  src={props.user.pic}
                  width={39}
                  alt={"User pic"}
                  style={style.borderRadius39}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export {
  HomeBtn,
  ThumbUpBtn,
  CommentBtn,
  ConnectBtn,
  RateBtn,
  SaveBtn,
  UploadBtn,
  LogoutBtn,
  AccountBtn,
  EditBtn,
  UserPanelBtn,
  PreviewBtn,
  ApproveBtn,
  LoginBtn
};
