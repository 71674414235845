import { useContext } from "react";

import { Avatar } from "../../lvl0Components/avatar";
import { SrRefTextIntSb12 } from "../../../ref/text";
import { SrRefTextIntRe12 } from "../../../ref/text";

import MainContext from "../../helpers/context/mainContext";

import style from "./style";

const TeamLead = ({ startup, returnPrimary, edit }) => {
  const { breakpoint3 } = useContext(MainContext);

  return (
    <div style={{ ...style.outerWrap, marginLeft: edit ? "6px" : "12px" }}>
      {/* Team lead pic */}
      <Avatar
        width={breakpoint3 ? 52 : 57}
        pic={
          startup.team.filter((m) => m.representer === "Y").length
            ? startup.team.filter((m) => m.representer === "Y")[0].pic
            : require("../../../media/no_user.png")
        }
        borderColor={returnPrimary}
      />

      {/* Team lead details */}
      <div style={style.marginLeft12}>
        {/* Name */}
        <SrRefTextIntSb12
          content={
            startup.team.filter((m) => m.representer === "Y").length
              ? `${startup.team.filter((m) => m.representer === "Y")[0].fname} ${
                  startup.team.filter((m) => m.representer === "Y")[0].lname
                }`
              : "Name"
          }
        />

        {/* Designation */}
        <SrRefTextIntRe12
          content={
            startup.team.filter((m) => m.representer === "Y").length
              ? startup.team.filter((m) => m.representer === "Y")[0].designation
              : "Founder"
          }
        />
      </div>
    </div>
  );
};

export default TeamLead;
