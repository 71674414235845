import React, { useState, useContext, useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import SrRefColour from "../../../ref/colours";
import {
  SrRefTextRubSb30,
  SrRefTextIntRe15,
  SrRefTextIntRe12,
  SrRefTextIntLi10
} from "../../../ref/text";

import { apiendpoint } from "../../../components/helpers/apiendpoint";
import MainContext from "../../../components/helpers/context/mainContext";

import { Avatar } from "../../../components/lvl0Components/avatar";
import { BackFAB, NextFAB } from "../../../components/lvl0Components/buttons/FABs";
import { TextInputIntRe12 } from "../../../components/lvl0Components/textInputs";
import { Popup } from "../../../components/lvl0Components/popup";
import { SetupTemplate } from "../../../components/lvl0Components/setupTemplate";
import { LoginBtn } from "../../../components/lvl1Components/buttons/iconBtns";

import { style } from "./style";

const OrgJoin = () => {
  // Main context
  const {
    scrollBoxWidth,
    popupWidth,
    // API related
    userId,
    setUserId,
    setupNew,
    setNew,
    loadUser
  } = useContext(MainContext);
  const navigate = useNavigate();

  // To read search params
  const [searchParams] = useSearchParams();

  // If user doesn't exist, navigate to login
  useEffect(() => {
    !setupNew.newProfile === true && navigateToLogin();
  }, []);

  // console.log(setupNew);

  // Navigate to main startup listing
  const navigateHome = () => {
    navigate("/home");
  };

  // Navigate to main startup listing
  const navigateToLogin = () => {
    navigate("/login");
  };

  // Navigate to main startup listing
  const navigateToUserProfile = () => {
    navigate("/userProfile");
  };

  const navigateToCommunity = () => {
    let communityId = localStorage.getItem("@communityId");
    communityId = communityId ? communityId : "20";
    navigate(`/community/${communityId}`);
  };

  // scrollPosition
  const [scrollPosition, setScrollposition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset; // Find the top offset of the window
    setScrollposition(position); // Set scrollPosition = top offset of the window
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true }); // listen to scroll event
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Scroll view
  const [screen, setScreen] = useState(0);
  const scrollRef = useRef();

  useEffect(() => {
    scrollRef.current.scrollLeft = screen * scrollBoxWidth;
  }, [screen]);

  // Snackbar
  const snackbar = (message) => {
    toast(message, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
  };

  // useStates
  const [inviteCode, setInviteCode] = useState("");
  const [inviteCodeEdit, setInviteCodeEdit] = useState("");
  const [showOrg, setShowOrg] = useState(false);

  const [loading, setLoading] = useState(false);
  const [orgLoaded, setOrgLoaded] = useState(false);
  const [psuedoEmployees, setPsuedoEmployees] = useState([]);

  // Updates when user selects a psuedo employee from the list
  const [selectedPsuedoEmployee, setSelectedPsuedoEmployee] = useState("");

  // Dummy org data
  const [orgInfo, setOrgInfo] = useState({
    orgName: "Organization Name",
    purpose: "Organization Purpose",
    employees: [],
    logo: null
  });

  // fetchOrg
  const fetchOrg = (invite_code) => {
    setLoading(true);
    fetch(`${apiendpoint}/org?invite_code=${invite_code}`, {
      method: "GET",
      headers: {
        "Content-type": "application/json"
      }
    })
      .then((response) => {
        if (response.ok) return response.json();
        else {
          throw new Error("Unauthorised");
        }
      })
      .then((json) => {
        setOrgInfo({
          logo: json.data.logo,
          orgName: json.data.name,
          purpose: json.data.purpose,
          employees: json.data.employees
        });

        const psEmpl = json?.data?.employees?.filter((emp) => emp?.is_setup === "N");

        setPsuedoEmployees(psEmpl);
        json?.data?.employees?.forEach((employee) => {
          if (employee?.email_id === setupNew.addEmp.emailId) {
            setShowOrg(true);
          }
        });

        // Show org join screen if psuedo employee length is 0
        setShowOrg(psEmpl.length == 0);

        setLoading(false);
        setOrgLoaded(true);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        snackbar("Invalid invite code");
        setOrgLoaded(false);
      });
  };

  const addEmp = async () => {
    setLoading(true);
    // do all the api calls
    var jwt = localStorage.getItem("@jwtauth");
    if (!jwt) jwt = "";

    // URL to send request
    // Only difference in empExistingOrgAndDeletePsued is it first deletes psuedo employee
    const url = selectedPsuedoEmployee
      ? `${apiendpoint}/empExistingOrgAndDeletePsuedo`
      : `${apiendpoint}/empExistingOrg`;

    fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${jwt}`
      },
      body: JSON.stringify({
        addEmp: {
          designation: setupNew.addEmp.designation.trim(),
          DOB: null,
          fname: setupNew.addEmp.fName.trim(),
          whatsapp_no: setupNew.addEmp.whatsappNo,
          email_id: setupNew.addEmp.emailId,
          gender: null,
          linkedin: null,
          lname: setupNew.addEmp.lName.trim(),
          picture: setupNew.addEmp.pp,
          is_setup: "Y"
        },
        psuedo_email: selectedPsuedoEmployee,
        invite_code: inviteCode
      })
    })
      .then((res) => {
        // (res.status);
        if (res.ok) return res.json();
        else throw Error("Unauthorized");
      })
      .then((json) => {
        const fetchStorage = async () => {
          localStorage.setItem("@empId", String(json.data.emp_id));
          localStorage.setItem("@orgid", String(json.data.org_id));
          localStorage.setItem("@jwtauth", json?.data?.jwt);
          setUserId(parseInt(json.data.emp_id));
        };
        fetchStorage();
        loadUser();

        // Load community of its a part of a community
        let communityId = json.data.communityId;
        communityId && localStorage.setItem("@communityId", String(communityId));
        communityId && localStorage.setItem("@initial", `/community/${json.data.community}`);
        navigateToCommunity();
      })
      .catch((error) => {
        snackbar("An error occurred, please try again!");
        console.log(error);
      });
  };

  // Auto fill invite code if it is present in the search params
  useEffect(() => {
    const code = searchParams.get("inviteCode");
    console.log(code);
    if (code) {
      setInviteCode(code);
      fetchOrg(code);
    }
  }, []);

  return (
    <div>
      <div style={{ ...style.outerMost, ...{ overflowY: showOrg ? "hidden" : "auto" } }}>
        <SetupTemplate>
          {/* Scroll view */}
          <div
            style={{ ...style.scrollWrap, ...{ width: `${scrollBoxWidth}px`, height: "316px" } }}
            ref={scrollRef}
          >
            {/* Enter invite code screen
            <div style={style.scroll}> */}

            {/* Claiming profile before forwarding */}
            <div style={style.scroll}>
              <div
                style={{
                  ...style.scrollWrap,
                  ...style.flexColumn,
                  ...{ width: `${scrollBoxWidth}px`, height: "316px" }
                }}
                ref={scrollRef}
              >
                <div style={{ ...{ width: `${scrollBoxWidth}px` }, ...style.paddingHor24 }}>
                  <SrRefTextIntRe15
                    content={"Claim your profile before you proceed"}
                    state={"enabled"}
                    style={style.colorBlack}
                  />
                </div>
                <div
                  style={{ ...style.flexColumn, ...style.alignItemsCenter, ...style.marginTop18 }}
                >
                  {psuedoEmployees.map((employee) => {
                    return (
                      <div
                        style={{
                          ...style.marginLeft12,
                          ...style.paddingHor24,
                          ...style.clickable,
                          backgroundColor: selectedPsuedoEmployee === employee.email_id && "black"
                        }}
                        onClick={() => {
                          // Unselct if this option is already selected
                          if (selectedPsuedoEmployee === employee.email_id) {
                            setSelectedPsuedoEmployee("");
                            return;
                          }

                          setSelectedPsuedoEmployee(employee.email_id);
                        }}
                      >
                        <SrRefTextIntRe15
                          content={employee.fname}
                          state={"enabled"}
                          style={style.colorBlack}
                        />
                      </div>
                    );
                  })}
                </div>

                <div>
                  <button
                    onClick={() => {
                      // setScreen(screen + 1);
                      setShowOrg(true);
                      // setTimeout(() => {
                      //   addEmp();
                      // }, 2000);
                    }}
                  >
                    {selectedPsuedoEmployee ? "Confirm profile and continue" : "Skip this step"}
                  </button>
                </div>
              </div>
            </div>
            {/* AHA moment screen */}
            <div style={style.scroll}>
              <div
                style={{
                  ...{ width: `${scrollBoxWidth}px` },
                  ...style.paddingHor24
                }}
              >
                <div
                  style={{
                    ...style.flexColumn,
                    ...style.alignItemsCenter
                  }}
                >
                  {/* Cover image */}
                  <img
                    src={require("../../../media/setup_guide_aha_4.png")}
                    width={180}
                    alt={"Welcome aboard!"}
                  />

                  {/* Aha moment text */}
                  <SrRefTextIntRe15
                    content={"Welcome aboard!"}
                    state={"enabled"}
                    style={{ ...style.marginTop24, ...style.textCenter, ...style.colorBlack }}
                  />
                </div>
              </div>
            </div>
            {/* Back button */}
            {screen !== 1 && (
              <BackFAB
                onClick={() => (screen === 0 ? navigateToUserProfile() : setScreen((p) => p - 1))}
              />
            )}
            {/* Next button */}
            {screen !== 1 && (
              <NextFAB
                content={screen === 1 ? "Done" : "Next"}
                onClick={() => setShowOrg(true)}
                // onClick={() => (screen === 2 ? setScreen(1) : setScreen((p) => p + 1))}
              />
            )}
          </div>
        </SetupTemplate>

        {/* Popup for user's org */}
        {showOrg && (
          <Popup onClick={() => setShowOrg(false)} scrollPosition={scrollPosition} noPadding={true}>
            <div style={{ width: `${popupWidth}px` }}>
              <div style={{ ...style.marginHor24, ...style.marginTop24 }}>
                {/* Org logo */}
                <div style={style.profilePicShell}>
                  {/* Show org logo when available or else show default icon */}
                  <Avatar width={75} pic={orgInfo.logo} borderColor={SrRefColour.Neutral2A} />
                </div>

                {/* Org name */}
                <SrRefTextRubSb30
                  content={orgInfo.orgName}
                  state={"enabled"}
                  style={{ ...style.marginTop6, ...style.colorBlack }}
                />

                {/* Purpose text */}
                <SrRefTextIntRe12
                  content={orgInfo.purpose}
                  state={"enabled"}
                  style={style.marginTop6}
                />
              </div>

              {/* Avatar list */}
              <div style={{ ...style.scrollWrap, ...style.customPadding }}>
                <div style={style.scroll}>
                  {orgInfo.employees
                    .sort((a, b) => {
                      if (a?.id < b?.id) {
                        return -1;
                      } else {
                        return +1;
                      }
                    })
                    .map((emp, id) => (
                      // Avatar for each co-worker
                      <Avatar
                        width={39}
                        pic={emp?.picture}
                        borderColor={SrRefColour.Neutral2A}
                        key={id}
                        style={style.marginLeft12}
                      />
                    ))}
                </div>
              </div>

              <div
                style={{
                  ...style.borderTop,
                  ...style.paddingTop24,
                  ...style.paddingHor24,
                  ...style.marginBot24
                }}
              >
                {/* Intro text */}
                <SrRefTextIntLi10
                  content={`Hello ${setupNew.addEmp.fName}! You have been invited to join the above organization.`}
                  state={"enabled"}
                  style={style.colorBlack}
                />

                {/* Log into account link */}
                <div style={style.marginTop24}>
                  <LoginBtn
                    onClick={() => {
                      setShowOrg(false);
                      setScreen(1);
                      setTimeout(() => {
                        addEmp();
                      }, 2000);
                    }}
                    text={"Join"}
                  />
                </div>
              </div>
            </div>
          </Popup>
        )}
      </div>

      {/* Snackbar */}
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
};

export default OrgJoin;
