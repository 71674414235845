import React from "react";
import {
  textStates,
  RubikFontWeights,
  RubikFontSizes,
  InterFontWeights,
  InterFontSizes
} from "./style.js";

// Rubik 4.8em texts
const SrRefTextRubSb48 = (props) => {
  return (
    <p
      onClick={props.onClick}
      placeholder={props.placeholder}
      style={{
        ...textStates[props.state],
        ...RubikFontWeights.Sb,
        ...RubikFontSizes.fs48,
        ...props.style
      }}
    >
      {props.content}
    </p>
  );
};

// Rubik 4.2em texts
const SrRefTextRubSb42 = (props) => {
  return (
    <p
      onClick={props.onClick}
      placeholder={props.placeholder}
      style={{
        ...textStates[props.state],
        ...RubikFontWeights.Sb,
        ...RubikFontSizes.fs42,
        ...props.style
      }}
    >
      {props.content}
    </p>
  );
};

// Rubik 3.6em texts
const SrRefTextRubSb36 = (props) => {
  return (
    <p
      onClick={props.onClick}
      placeholder={props.placeholder}
      style={{
        ...textStates[props.state],
        ...RubikFontWeights.Sb,
        ...RubikFontSizes.fs36,
        ...props.style
      }}
    >
      {props.content}
    </p>
  );
};

// Rubik 3em texts
const SrRefTextRubSb30 = (props) => {
  return (
    <p
      onClick={props.onClick}
      placeholder={props.placeholder}
      style={{
        ...textStates[props.state],
        ...RubikFontWeights.Sb,
        ...RubikFontSizes.fs30,
        ...props.style
      }}
    >
      {props.content}
    </p>
  );
};

// Rubik 2.5em texts
const SrRefTextRubSb25 = (props) => {
  return (
    <p
      onClick={props.onClick}
      placeholder={props.placeholder}
      style={{
        ...textStates[props.state],
        ...RubikFontWeights.Sb,
        ...RubikFontSizes.fs25,
        ...props.style
      }}
    >
      {props.content}
    </p>
  );
};

// Rubik 1.8em texts
const SrRefTextRubSb18 = (props) => {
  return (
    <p
      onClick={props.onClick}
      placeholder={props.placeholder}
      style={{
        ...textStates[props.state],
        ...RubikFontWeights.Sb,
        ...RubikFontSizes.fs18,
        ...props.style
      }}
    >
      {props.content}
    </p>
  );
};

// Inter 3.0em texts
const SrRefTextIntSb30 = (props) => {
  return (
    <p
      onClick={props.onClick}
      placeholder={props.placeholder}
      style={{
        ...textStates[props.state],
        ...InterFontWeights.Sb,
        ...InterFontSizes.fs30,
        ...props.style
      }}
    >
      {props.content}
    </p>
  );
};

// Inter 2.0em texts
const SrRefTextIntSb20 = (props) => {
  return (
    <p
      onClick={props.onClick}
      placeholder={props.placeholder}
      style={{
        ...textStates[props.state],
        ...InterFontWeights.Sb,
        ...InterFontSizes.fs20,
        ...props.style
      }}
    >
      {props.content}
    </p>
  );
};

// Inter 1.5em texts
const SrRefTextIntSb15 = (props) => {
  return (
    <p
      onClick={props.onClick}
      placeholder={props.placeholder}
      style={{
        ...textStates[props.state],
        ...InterFontWeights.Sb,
        ...InterFontSizes.fs15,
        ...props.style
      }}
    >
      {props.content}
    </p>
  );
};

const SrRefTextIntRe15 = (props) => {
  return (
    <p
      onClick={props.onClick}
      placeholder={props.placeholder}
      style={{
        ...textStates[props.state],
        ...InterFontWeights.Re,
        ...InterFontSizes.fs15,
        ...props.style
      }}
    >
      {props.content}
    </p>
  );
};

const SrRefTextIntLi15 = (props) => {
  return (
    <p
      onClick={props.onClick}
      placeholder={props.placeholder}
      style={{
        ...textStates[props.state],
        ...InterFontWeights.Li,
        ...InterFontSizes.fs15,
        ...props.style
      }}
    >
      {props.content}
    </p>
  );
};

// Inter 1.2em texts
const SrRefTextIntSb12 = (props) => {
  return (
    <p
      onClick={props.onClick}
      placeholder={props.placeholder}
      style={{
        ...textStates[props.state],
        ...InterFontWeights.Sb,
        ...InterFontSizes.fs12,
        ...props.style
      }}
    >
      {props.content}
    </p>
  );
};

const SrRefTextIntMe12 = (props) => {
  return (
    <p
      onClick={props.onClick}
      placeholder={props.placeholder}
      style={{
        ...textStates[props.state],
        ...InterFontWeights.Me,
        ...InterFontSizes.fs12,
        ...props.style
      }}
    >
      {props.content}
    </p>
  );
};

const SrRefTextIntRe12 = (props) => {
  return (
    <p
      onClick={props.onClick}
      placeholder={props.placeholder}
      style={{
        ...textStates[props.state],
        ...InterFontWeights.Re,
        ...InterFontSizes.fs12,
        ...props.style
      }}
    >
      {props.content}
    </p>
  );
};

const SrRefTextIntLi12 = (props) => {
  return (
    <p
      onClick={props.onClick}
      placeholder={props.placeholder}
      style={{
        ...textStates[props.state],
        ...InterFontWeights.Li,
        ...InterFontSizes.fs12,
        ...props.style
      }}
    >
      {props.content}
    </p>
  );
};

// Inter 1em texts
const SrRefTextIntSb10 = (props) => {
  return (
    <p
      onClick={props.onClick}
      placeholder={props.placeholder}
      style={{
        ...textStates[props.state],
        ...InterFontWeights.Sb,
        ...InterFontSizes.fs10,
        ...props.style
      }}
    >
      {props.content}
    </p>
  );
};

const SrRefTextIntMe10 = (props) => {
  return (
    <p
      onClick={props.onClick}
      placeholder={props.placeholder}
      style={{
        ...textStates[props.state],
        ...InterFontWeights.Me,
        ...InterFontSizes.fs10,
        ...props.style
      }}
    >
      {props.content}
    </p>
  );
};

const SrRefTextIntRe10 = (props) => {
  return (
    <p
      onClick={props.onClick}
      placeholder={props.placeholder}
      style={{
        ...textStates[props.state],
        ...InterFontWeights.Re,
        ...InterFontSizes.fs10,
        ...props.style
      }}
    >
      {props.content}
    </p>
  );
};

const SrRefTextIntLi10 = (props) => {
  return (
    <p
      onClick={props.onClick}
      placeholder={props.placeholder}
      style={{
        ...textStates[props.state],
        ...InterFontWeights.Li,
        ...InterFontSizes.fs10,
        ...props.style
      }}
    >
      {props.content}
    </p>
  );
};

// Inter 0.9em texts
const SrRefTextIntSb09 = (props) => {
  return (
    <p
      onClick={props.onClick}
      placeholder={props.placeholder}
      style={{
        ...textStates[props.state],
        ...InterFontWeights.Sb,
        ...InterFontSizes.fs09,
        ...props.style
      }}
    >
      {props.content}
    </p>
  );
};

const SrRefTextIntRe09 = (props) => {
  return (
    <p
      onClick={props.onClick}
      placeholder={props.placeholder}
      style={{
        ...textStates[props.state],
        ...InterFontWeights.Re,
        ...InterFontSizes.fs09,
        ...props.style
      }}
    >
      {props.content}
    </p>
  );
};

const SrRefTextIntRe08 = (props) => {
  return (
    <p
      onClick={props.onClick}
      placeholder={props.placeholder}
      style={{
        ...textStates[props.state],
        ...InterFontWeights.Re,
        ...InterFontSizes.fs08,
        ...props.style
      }}
    >
      {props.content}
    </p>
  );
};

const SrRefTextIntLi075 = (props) => {
  return (
    <p
      onClick={props.onClick}
      placeholder={props.placeholder}
      style={{
        ...textStates[props.state],
        ...InterFontWeights.Li,
        ...InterFontSizes.fs075,
        ...props.style
      }}
    >
      {props.content}
    </p>
  );
};

const SrRefTextIntRe07 = (props) => {
  return (
    <p
      onClick={props.onClick}
      placeholder={props.placeholder}
      style={{
        ...textStates[props.state],
        ...InterFontWeights.Re,
        ...InterFontSizes.fs07,
        ...props.style
      }}
    >
      {props.content}
    </p>
  );
};

export {
  SrRefTextRubSb48,
  SrRefTextRubSb42,
  SrRefTextRubSb36,
  SrRefTextRubSb30,
  SrRefTextRubSb25,
  SrRefTextRubSb18,
  SrRefTextIntSb30,
  SrRefTextIntSb20,
  SrRefTextIntSb15,
  SrRefTextIntRe15,
  SrRefTextIntLi15,
  SrRefTextIntSb12,
  SrRefTextIntMe12,
  SrRefTextIntRe12,
  SrRefTextIntLi12,
  SrRefTextIntSb10,
  SrRefTextIntMe10,
  SrRefTextIntRe10,
  SrRefTextIntLi10,
  SrRefTextIntSb09,
  SrRefTextIntRe09,
  SrRefTextIntRe08,
  SrRefTextIntLi075,
  SrRefTextIntRe07
};
