import { useContext } from "react";
import { SrRefTextIntSb15 } from "../../../ref/text";
import MainContext from "../../helpers/context/mainContext";

const OrgDescription = () => {
  const { startup } = useContext(MainContext);

  return <SrRefTextIntSb15 content={startup.brief ? startup.brief : "Startup description"} />;
};

export default OrgDescription;
