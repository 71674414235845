import React, { useState } from "react";
import SrRefColour from "../../../../ref/colours";
import { SrRefTextIntLi12 } from "../../../../ref/text";
import style from "./style";

// Next FAB button
const TextBtn = (props) => {
  const [btnHover, setBtnHover] = useState(false);

  // Dynamic styling for this component
  const style2 = {
    divStyle: {
      ...style.divStyle,
      borderColor: props.pressed
        ? SrRefColour.Neutral1A
        : btnHover
        ? SrRefColour.Neutral2A
        : SrRefColour.Neutral2B
    }
  };

  return (
    <div
      onMouseEnter={() => setBtnHover(true)}
      onMouseLeave={() => setBtnHover(false)}
      onClick={props.onClick}
      style={style.buttonWrap}
    >
      <div style={style2.divStyle}>
        <SrRefTextIntLi12 content={props.content} state={"enabled"} style={style.colorBlack} />
      </div>
    </div>
  );
};

export { TextBtn };
