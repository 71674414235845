import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

import SrRefColour from "../../../ref/colours";
import {
  SrRefTextRubSb48,
  SrRefTextRubSb36,
  SrRefTextRubSb30,
  SrRefTextRubSb25,
  SrRefTextIntRe15,
  SrRefTextIntRe12,
  SrRefTextIntLi12
} from "../../../ref/text";

import MainContext from "../../../components/helpers/context/mainContext";
import Startups from "../../../components/helpers/startups";
import { returnRGBA, gradientTextColour } from "../../../components/helpers/themeColors";

import {
  SrCompIconInstagram,
  SrCompIconLinkedin,
  SrCompIconMedium,
  SrCompIconWhatsapp,
  SrCompIconEMail
} from "../../../components/lvl0Components/icons";
import { Popup } from "../../../components/lvl0Components/popup";
import Timer from "../../../components/lvl0Components/timer";
import { CTABtn } from "../../../components/lvl1Components/buttons/iconBtns";
import { LargeCard } from "../../../components/lvl1Components/cards/largeCard";
import { NoteDropdown } from "../../../components/lvl1Components/note";
import WaitlistPopup from "../../../components/lvl1Components/setupPopups/waitlistPopup";
import ReferralPopup from "../../../components/lvl1Components/setupPopups/ReferralPopup";

import { style } from "./style";

const Landing = () => {
  // Context
  const {
    screenWidth,
    breakpoint2,
    breakpoint3,
    scrollToTop,
    // API related
    user
  } = useContext(MainContext);
  const navigate = useNavigate();

  // If user exists, navigate to home
  useEffect(() => {
    const jwt = localStorage.getItem("@jwtauth");
    jwt && navigateToCommunity();
  }, []);

  //Navigate to community
  const navigateToCommunity = () => {
    let communityId = localStorage.getItem("@communityId");
    communityId = communityId ? communityId : "20";
    navigate(`/community/${communityId}`);
  };

  // Navigate to main startup listing
  const navigateHome = () => {
    navigate("/home");
    scrollToTop();
  };

  // Navigate to main startup listing
  const navigateToLogin = () => {
    navigate("/login");
  };

  // useStates
  const [showSolocl, setShowSolocl] = useState(false);
  const [showWaitlist, setShowWaitlist] = useState(false);
  const [showWaitlistHover, setShowWaitlistHover] = useState(false);
  const [showReferral, setShowReferral] = useState(false);
  const [showReferralHover, setShowReferralHover] = useState(false);

  // scrollPosition
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset; // Find the top offset of the window
    setScrollPosition(position); // Set scrollPosition = top offset of the window
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true }); // listen to scroll event
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Popup effect
  const body = document.body;

  // Disallow scroll when login popup is visible
  useEffect(() => {
    body.style.overflowY = showWaitlist ? "hidden" : "auto";
  }, [showWaitlist]); // Whenever scroll position chamges

  // Allow scrolling on body element in DOM
  const allowScroll = () => {
    body.style.overflowY = "auto";
  };

  // Get dimensions of the how much margin to set on either side
  const [carouselWidth, setCarouselWidth] = useState(0);
  const carousel = useRef(null);

  useEffect(() => {
    if (carousel.current) {
      setCarouselWidth(carousel.current.scrollWidth - (screenWidth >= 1600 ? 1600 : screenWidth));
    }
  }, []);

  // Get cover band height
  const [coverBandHeight, setCoverBandHeight] = useState(0);
  const coverBand = useRef(null);

  useEffect(() => {
    if (coverBand.current) {
      setCoverBandHeight(coverBand.current.clientHeight);
    }
  }, []);

  // Get cover image height
  const [coverImgHeight, setCoverImgHeight] = useState(0);
  const coverImg = useRef(null);

  useEffect(() => {
    if (coverImg.current) {
      setCoverImgHeight(coverImg.current.clientHeight);
    }
  }, []);

  // Get dimensions of the card
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const listingRef = useRef(null);

  useEffect(() => {
    if (listingRef.current) {
      setDimensions({
        width: listingRef.current.offsetWidth,
        height: listingRef.current.offsetHeight
      });
    }
  }, []);

  // Constants
  const screenPadding = breakpoint2 ? 24 : 48;
  const largeCardWidth = 348 - 6;
  const nameLimit = 15;
  const descriptionLimit = 32;
  const array = [0, 1, 2, 3, 4, 5];
  const sectionWidth = breakpoint3 ? 840 : 1280;
  const carouselSpeed = 1;
  const carouselMargin = scrollPosition * carouselSpeed;
  const text1 = "The Growth Platform";
  // const text2 = "your startup deserves.";
  const text2 = "startups deserve.";
  const text3 = "Vibrant.";
  const text4 = "Lively.";
  const text5 = "Be part of a platform that isn't ";
  const text6 = "a graveyard of startups.";
  const text7 = "Crisp. ";
  const text8 = "Beautiful.";
  const text9 = "Share a snapshot of your startup ";
  const text10 = "with anyone. Securely.";
  const text11 = "Seek those who are seeking you!";
  const text12 = "Build friendships that will help you grow.";

  // const CTATxt1 = "Join the waitlist";
  // const CTATxt2 = "Join the waitlist";
  // const CTATxt3 = "Join the waitlist";
  const CTATxt1 = "Enter the Platform";
  const CTATxt2 = "Check it out";
  const CTATxt3 = "Enlist your startup";

  const footerTxt1 = "Shram is primarily a work management tool for small teams, not a network.";
  const footerTxt2 =
    "We made a simple listing for 10 of our startup friends who loved it and here ";
  const footerTxt3 = "we are - building a side project into a lovely platform that doesn't suck.";

  const instagram = "https://www.instagram.com/shram.io/";
  const linkedIn = "https://www.linkedin.com/company/77073721/";
  const medium = "https://medium.com/@shram.io";
  const whatsapp = "https://api.whatsapp.com/send/?phone=9769739018";
  const mail = "mailto:info@shram.io";

  const Preview = (props) => {
    const startupListing = Startups[props.showSolocl ? 3 : 0].theme.primary.color;

    return (
      <div
        style={{
          position: "relative",
          height: `${breakpoint2 ? (screenWidth - 48) * 0.635 : (960 - 48) * 0.635}px`
        }}
      >
        <img
          src={require(`../../../media/${
            props.showSolocl ? "solocl" : "minimalistic"
          }_listing.png`)}
          width={breakpoint2 ? screenWidth - 48 : 960 - 48}
          alt={"Image 1"}
          style={{
            ...style.surfaceBlur,
            ...{
              position: "relative",
              borderRadius: breakpoint3 ? "9px" : breakpoint2 ? "18px" : "24px",
              border: `1px solid ${SrRefColour.Neutral2B}`,
              zIndex: 1
            }
          }}
          ref={listingRef}
        />

        {/* Blur effect in the background */}
        <div
          style={{
            position: "absolute",
            top: "-12px",
            left: "-12px",
            width: `${breakpoint2 ? screenWidth - 48 + 24 : 960 - 48 + 24}px`,
            height: `${
              breakpoint2 ? (screenWidth - 48 + 24) * 0.635 + 12 : (960 - 48 + 24) * 0.635 + 12
            }px`,
            backgroundColor: returnRGBA(startupListing.r, startupListing.g, startupListing.b, 0.15),
            filter: `blur(${1.2}rem)`,
            zIndex: 0
          }}
        ></div>
      </div>
    );
  };

  // Social media link
  const Link = (props) => {
    const [linkHover, setLinkHover] = useState(false);

    return (
      props.link && (
        <div style={style.marginHor6}>
          <a
            onMouseEnter={() => setLinkHover(true)}
            onMouseLeave={() => setLinkHover(false)}
            href={`${props.link}`}
            target="_blank"
            style={{
              ...style.flexColumn,
              ...style.icon,
              ...style.transition,
              ...style.borderRadius6,
              ...{
                backgroundColor: linkHover && SrRefColour.Neutral1B
              }
            }}
            rel="noopener noreferrer"
          >
            {props.link === instagram ? (
              <SrCompIconInstagram size={24} color={"#757575"} />
            ) : props.link === linkedIn ? (
              <SrCompIconLinkedin size={24} color={"#757575"} />
            ) : props.link === medium ? (
              <SrCompIconMedium size={24} color={"#757575"} />
            ) : props.link === whatsapp ? (
              <SrCompIconWhatsapp size={24} color={"#757575"} />
            ) : (
              <SrCompIconEMail size={24} c color={"#757575"} />
            )}
          </a>
        </div>
      )
    );
  };

  // Social media links
  const SocialMedia = () => {
    return (
      <div style={{ display: "flex", flexDirection: breakpoint2 ? "row" : "row-reverse" }}>
        {mail && <Link link={mail} />}

        {instagram && <Link link={instagram} />}

        {linkedIn && <Link link={linkedIn} />}

        {medium && <Link link={medium} />}

        {whatsapp && <Link link={whatsapp} />}
      </div>
    );
  };

  return (
    <HelmetProvider>
      <div style={style.outerMost}>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="description" content={"The Growth Platform your startup deserves"} />
          <meta property="og:title" content={"Shram's Startup Platform"} />
          <meta property="og:image" content={`require("../../../media/logo512_green.png)`} />
          <meta property="og:url" content={"https://platform.shram.io/"} />
          <title>Shram's Startup Platform</title>
          <link rel="canonical" href="https://platform.shram.io/" />
        </Helmet>

        {/* <div>
        <Timer />
      </div> */}

        <div
          style={{
            ...style.screen,
            ...style.flexColumn
          }}
        >
          {/* Section 1 */}
          {breakpoint3 ? (
            <div style={{ marginTop: "180px", textAlign: "center", zIndex: 1 }}>
              <SrRefTextRubSb36
                content={text1}
                style={gradientTextColour("#000", "rgba(0, 0, 0, 0.7)")}
              />
              <SrRefTextRubSb30
                content={text2}
                style={gradientTextColour("#000", "rgba(0, 0, 0, 0.7)")}
              />
            </div>
          ) : (
            <div style={{ marginTop: "210px", textAlign: "center", zIndex: 1 }}>
              <SrRefTextRubSb48
                content={text1}
                style={gradientTextColour("#000", "rgba(0, 0, 0, 0.7)")}
              />
              <SrRefTextRubSb36
                content={text2}
                style={gradientTextColour("#000", "rgba(0, 0, 0, 0.7)")}
              />
            </div>
          )}

          <div
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              height: `${breakpoint3 ? 246 : 317}px`
            }}
          >
            <div
              style={{
                zIndex: 1
              }}
              ref={coverBand}
            >
              {/* CTA button */}
              <div
                style={{ ...style.flexRow, ...style.justifyContentCenter, ...style.marginTop60 }}
              >
                <CTABtn
                  text={CTATxt1}
                  // onClick={() => setShowReferral(true)}
                  onClick={() => navigateToLogin()}
                />
              </div>

              {/* Created by Shram stamp */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "96px"
                }}
              >
                <SrRefTextIntLi12 content={"Created by"} style={style.colorGrey} />

                <div
                  style={{
                    height: "30px",
                    marginLeft: "9px"
                  }}
                >
                  <img src={require("../../../media/logo_grey.png")} height={30} alt={"Logo"} />
                </div>
              </div>
            </div>

            {/* Cover image */}
            <div
              style={{
                position: "absolute",
                display: "flex",
                justifyContent: "center",
                width: "100%",
                opacity: 0.8,
                zIndex: 0
              }}
            >
              <img
                src={require("../../../media/landing_cover-min.png")}
                // src={require("../../../media/landing_cover.svg")}
                width={sectionWidth}
                alt={"Cover"}
                ref={coverImg}
              />
            </div>
          </div>
        </div>

        {/* Section 2 */}
        <div
          style={{
            position: "relative",
            height: `${232.5 * 2 + 60 * 2 + 24}px`,
            maxWidth: "1600px",
            margin: "0 auto",
            overflow: "hidden",
            marginTop: screenWidth > 1600 ? "72px" : 0,
            border: screenWidth > 1600 ? "1px solid #eee" : "none",
            borderRadius: screenWidth > 1600 ? "24px" : 0
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "0px",
              width: "100%"
            }}
          >
            <div
              style={{
                position: "relative",
                display: "flex",
                flexDirection: "row",
                marginLeft: `-${
                  carouselMargin <= carouselWidth ? carouselMargin : carouselWidth
                }px`,
                paddingTop: "60px",
                paddingBottom: "60px",
                overflowX: "auto"
              }}
            >
              {array.map((i) => (
                <div
                  style={{
                    height: `${232.5 + 12}px`,
                    marginLeft: i === 0 ? "24px" : "12px",
                    marginRight: i === 5 ? "24px" : "12px"
                  }}
                >
                  <LargeCard
                    startup={Startups[i]}
                    onClick={() => {}}
                    largeCardWidth={largeCardWidth}
                    nameLimit={nameLimit}
                    descriptionLimit={descriptionLimit}
                    glowOpacity={0.1}
                    blur={1.2}
                    noHover={true}
                  />
                </div>
              ))}
            </div>
          </div>

          <div
            style={{
              ...{
                position: "absolute",
                top: `${232.5 + 24}px`,
                width: "100%"
              }
            }}
          >
            <div
              style={{
                position: "relative",
                display: "flex",
                flexDirection: "row",
                marginLeft: `${
                  carouselMargin - carouselWidth <= 0 ? carouselMargin - carouselWidth : 0
                }px`,
                paddingTop: "60px",
                paddingBottom: "60px",
                overflowX: "auto"
              }}
              ref={carousel}
            >
              {array.map((i) => (
                <div
                  style={{
                    height: `${232.5 + 12}px`,
                    marginLeft: i === 0 ? "24px" : "12px",
                    marginRight: i === 5 ? "24px" : "12px"
                  }}
                  key={i}
                >
                  <LargeCard
                    startup={Startups[i + 5]}
                    onClick={() => {}}
                    largeCardWidth={largeCardWidth}
                    nameLimit={nameLimit}
                    descriptionLimit={descriptionLimit}
                    glowOpacity={0.1}
                    blur={1.2}
                    noHover={true}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Grid */}
        <div style={{ ...style.positionRelative, ...style.gridBg }}>
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "60px",
              top: 0,
              backgroundImage:
                "linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))"
            }}
          ></div>

          {/* Section 3 */}
          <div
            style={{
              ...style.screen,
              ...{
                display: "flex",
                flexDirection: breakpoint2 ? "column" : "row",
                alignItems: breakpoint2 ? "flex-start" : "center",
                justifyContent: "space-between",
                maxWidth: "960px",
                marginTop: "60px"
              }
            }}
          >
            <div
              style={{
                ...style.flexColumn,
                ...{ width: breakpoint3 ? "calc(100% - 48px)" : breakpoint2 ? "60%" : "60%" },
                ...style.marginHor24
              }}
            >
              <SrRefTextRubSb30 content={text3} />
              <SrRefTextRubSb30 content={text4} />

              {breakpoint3 ? (
                <div style={style.marginTop18}>
                  <SrRefTextIntRe15 content={text5 + text6} />
                </div>
              ) : (
                <div>
                  <div style={style.marginTop18}>
                    <SrRefTextIntRe15 content={text5} />
                  </div>

                  <div style={style.marginTop6}>
                    <SrRefTextIntRe15 content={text6} />
                  </div>
                </div>
              )}

              {/* CTA button */}
              <div style={style.marginTop24}>
                <CTABtn
                  text={CTATxt2}
                  // onClick={() => setShowReferral(true)}
                  onClick={() => navigateToLogin()}
                  color={true}
                />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                marginTop: breakpoint2 ? "48px" : 0,
                opacity: 0.8
              }}
            >
              <div
                style={{
                  position: "relative"
                }}
              >
                <img
                  src={require("../../../media/landing_img-min.png")}
                  // src={require("../../../media/landing_img.svg")}
                  height={screenWidth < 424 ? screenWidth - 48 : 400}
                  alt={"Image 1"}
                  style={{
                    position: "relative",
                    zIndex: 1
                  }}
                />

                <div
                  style={{
                    position: "absolute",
                    width: `${screenWidth < 424 ? screenWidth - 48 + 24 : 400 + 24}px`,
                    height: `${screenWidth < 424 ? screenWidth - 48 + 24 : 400 + 24}px`,
                    top: "-12px",
                    left: "-12px",
                    backgroundImage:
                      "radial-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.2))",
                    zIndex: 0
                  }}
                ></div>
              </div>
            </div>
          </div>

          {/* Section 4 */}
          <div
            style={{
              ...style.screen,
              ...{
                display: "flex",
                flexDirection: "column",
                maxWidth: `${960 + 48}px`,
                marginTop: "120px",
                paddingBottom: "60px"
              }
            }}
          >
            <div
              style={{
                marginLeft: breakpoint2 ? "24px" : "48px",
                marginRight: breakpoint2 ? "24px" : "48px"
              }}
            >
              <div>
                <SrRefTextRubSb30 content={text7} />
                <SrRefTextRubSb30 content={text8} />
              </div>

              {breakpoint3 ? (
                <div style={style.marginTop18}>
                  <SrRefTextIntRe15 content={text9 + text10} />
                </div>
              ) : (
                <div>
                  <div style={style.marginTop18}>
                    <SrRefTextIntRe15 content={text9} />
                  </div>

                  <div style={style.marginTop6}>
                    <SrRefTextIntRe15 content={text10} />
                  </div>
                </div>
              )}
            </div>

            <div
              style={{
                display: "flex",
                width: "100%",
                marginTop: "48px"
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "100%",
                  marginLeft: breakpoint2 ? "24px" : "48px",
                  marginRight: breakpoint2 ? "24px" : "48px"
                }}
              >
                <Preview showSolocl={showSolocl} />
                {breakpoint3 && (
                  <div style={style.marginTop36}>
                    <Preview showSolocl={!showSolocl} />
                  </div>
                )}

                {!breakpoint3 && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      width: "100%"
                    }}
                  >
                    {/* Minimalistic */}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "100%"
                      }}
                    >
                      <div
                        style={{
                          ...style.verticalLine,
                          ...{ backgroundColor: showSolocl ? "transparent" : SrRefColour.Neutral2B }
                        }}
                      ></div>
                      <LargeCard
                        startup={Startups[0]}
                        onClick={() => setShowSolocl(false)}
                        largeCardWidth={largeCardWidth}
                        nameLimit={nameLimit}
                        descriptionLimit={descriptionLimit}
                        glowOpacity={0.1}
                        blur={1.2}
                        select={!showSolocl}
                      />
                    </div>

                    {/* Solocl */}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "100%"
                      }}
                    >
                      <div
                        style={{
                          ...style.verticalLine,
                          ...{
                            backgroundColor: !showSolocl ? "transparent" : SrRefColour.Neutral2B
                          }
                        }}
                      ></div>
                      <LargeCard
                        startup={Startups[3]}
                        onClick={() => setShowSolocl(true)}
                        largeCardWidth={largeCardWidth}
                        nameLimit={nameLimit}
                        descriptionLimit={descriptionLimit}
                        glowOpacity={0.1}
                        blur={1.2}
                        select={showSolocl}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Section 5 */}
          <div
            style={{
              ...style.screen,
              ...{
                display: "flex",
                flexDirection: "column",
                maxWidth: "960px",
                marginTop: "60px"
              }
            }}
          >
            <div
              style={{
                marginLeft: "24px",
                marginRight: "24px"
              }}
            >
              <SrRefTextRubSb30 content={"FAQs"} />

              <div
                style={{
                  ...{ display: "flex", flexDirection: breakpoint3 ? "column" : "row" },
                  ...style.marginTop24
                }}
              >
                <NoteDropdown
                  text1="Who is this platform for?"
                  text2="If you are a budding startup and wish to enjoy the company of others like yourself or if you are an enthusiast willing to explore vibrant startups, you will love this platform."
                  breakpoint3={breakpoint3}
                  breakpoint2={breakpoint2}
                  width={912}
                />
                <div
                  style={{
                    marginTop: breakpoint3 ? "24px" : 0,
                    marginLeft: breakpoint3 ? 0 : "24px"
                  }}
                >
                  <NoteDropdown
                    text1="How can I join?"
                    text2="You can join for free but you need a referral code. You can either get a referral from startups enlisted here or you can fill up this form to request access."
                    // link="mailto:info@shram.io?subject=Requesting referral to the platform&body=Hi! My name is (your name). I wish to join Shram's Startup Platform. Please contact me at email:(your email) or phone:(your phone number)"
                    onClick={() => setShowWaitlist(true)}
                    linkText="Contact us here"
                    breakpoint3={breakpoint3}
                    breakpoint2={breakpoint2}
                    width={912}
                  />
                </div>
              </div>

              <div
                style={{
                  ...{ display: "flex", flexDirection: breakpoint3 ? "column" : "row" },
                  ...style.marginTop24
                }}
              >
                <NoteDropdown
                  text1="How does this work?"
                  text2="First, you need to setup your account. If you are a startup, we verify the information and help you curate your page. Once verified, you can explore other startups, enthusiasts or investors."
                  breakpoint3={breakpoint3}
                  breakpoint2={breakpoint2}
                  width={912}
                />
                <div
                  style={{
                    marginTop: breakpoint3 ? "24px" : 0,
                    marginLeft: breakpoint3 ? 0 : "24px"
                  }}
                >
                  <NoteDropdown
                    text1="What info do you need?"
                    text2="For startups, we need information that you can find on the preview above. We have a built a setup guide to make it easy for you. For other users, we need just basic personal information."
                    breakpoint3={breakpoint3}
                    breakpoint2={breakpoint2}
                    width={912}
                  />
                </div>
              </div>

              <div
                style={{
                  ...{ display: "flex", flexDirection: breakpoint3 ? "column" : "row" },
                  ...style.marginTop24
                }}
              >
                <NoteDropdown
                  text1="Who can view my page?"
                  text2="Only those who are part of the platform can view anything here. But you can generate a shareable link of your startup's page to forward amongst your social circle."
                  breakpoint3={breakpoint3}
                  breakpoint2={breakpoint2}
                  width={912}
                />
                <div
                  style={{
                    marginTop: breakpoint3 ? "24px" : 0,
                    marginLeft: breakpoint3 ? 0 : "24px"
                  }}
                >
                  <NoteDropdown
                    text1="What's special about this?"
                    text2="Most startup platforms focus on getting more listings. For us, active startups are the priority. Similarly, we want to restrict access to only serious investors or helpful enthusiasts."
                    breakpoint3={breakpoint3}
                    breakpoint2={breakpoint2}
                    width={912}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Section 6 */}
          <div
            style={{
              ...style.screen,
              ...{
                display: "flex",
                flexDirection: "column",
                alignItems: breakpoint3 ? "flex-start" : "center",
                width: breakpoint2 ? "calc(100% - 48px)" : "100vw",
                marginTop: "120px",
                marginLeft: breakpoint2 ? "24px" : "auto",
                marginRight: breakpoint2 ? "24px" : "auto",
                paddingBottom: "90px"
              }
            }}
          >
            <div style={{ textAlign: breakpoint3 ? "start" : "center" }}>
              <SrRefTextRubSb25 content={text11} />

              <div style={style.marginTop18}>
                <SrRefTextIntRe15 content={text12} />
              </div>
            </div>

            {/* CTA button */}
            <div
              style={{
                ...style.flexRow,
                ...style.marginTop24
              }}
            >
              <CTABtn
                text={CTATxt3}
                // onClick={() => setShowReferral(true)}
                onClick={() => navigateToLogin()}
                color={true}
              />
            </div>

            {/* In partnership with Deshpande Startups stamp */}
            {/* <div
              style={{
                display: "flex",
                flexDirection: breakpoint3 ? "column" : "row",
                justifyContent: "center",
                alignItems: breakpoint3 ? "flex-start" : "center",
                marginTop: "72px"
              }}
            >
              <SrRefTextIntLi12 content={"In partnership with"} style={style.colorGrey} />

              <div
                style={{
                  height: "45px",
                  marginTop: breakpoint3 ? "24px" : 0,
                  marginLeft: breakpoint3 ? 0 : "9px"
                }}
              >
                <img
                  src={require("../../../media/deshpande_logo_grey.png")}
                  height={45}
                  alt={"Logo"}
                />
              </div>
            </div> */}
          </div>
        </div>

        {/* Footer */}
        <div
          style={{
            ...style.screen,
            ...{
              display: "flex",
              flexDirection: "column",
              alignItems: breakpoint2 ? "flex-start" : "center",
              borderTop: `1px solid ${SrRefColour.Neutral2B}`
            }
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: breakpoint2 ? "column" : "row",
              justifyContent: "space-between",
              alignItems: breakpoint2 ? "flex-start" : "center",
              width: "100%",
              maxWidth: "960px",
              padding: "48px 18px 60px"
            }}
          >
            {breakpoint3 ? (
              <div style={style.marginHor6}>
                <SrRefTextIntRe12 content={footerTxt1} style={style.colorGrey} />

                <div style={style.marginTop18}>
                  <SrRefTextIntRe12 content={footerTxt2 + footerTxt3} style={style.colorGrey} />
                </div>
              </div>
            ) : (
              <div style={style.marginHor6}>
                <SrRefTextIntRe12 content={footerTxt1} style={style.colorGrey} />

                <div style={style.marginTop18}>
                  <SrRefTextIntRe12 content={footerTxt2} style={style.colorGrey} />
                </div>

                <div style={style.marginTop6}>
                  <SrRefTextIntRe12 content={footerTxt3} style={style.colorGrey} />
                </div>
              </div>
            )}

            <div style={{ ...style.marginHor6, ...style.marginBot12 }}>
              <div style={{ ...style.marginHor6, ...{ marginTop: breakpoint2 ? "36px" : 0 } }}>
                <img src={require("../../../media/logo_grey.png")} height={50} alt={"Logo"} />
              </div>
              <div style={style.marginTop18}>
                <SocialMedia />
              </div>
            </div>
          </div>
        </div>

        {/* Waitlist popup */}
        {showWaitlist && (
          <Popup
            onClick={() => [setShowWaitlist(false), setShowWaitlistHover(false), allowScroll()]}
            scrollPosition={scrollPosition}
            noPadding={true}
            modal={breakpoint3 ? true : false}
          >
            {/* Edit10 popup content */}
            <WaitlistPopup
              setEdit={setShowWaitlist}
              setEditHover={setShowWaitlistHover}
              allowScroll={allowScroll}
            />
          </Popup>
        )}

        {/* Referral Code Popup */}
        {showReferral && (
          <Popup
            onClick={() => [setShowReferral(false), setShowReferralHover(false), allowScroll()]}
            scrollPosition={scrollPosition}
            noPadding={true}
            modal={breakpoint3 ? true : false}
          >
            {/* Referral popup popup content */}
            <ReferralPopup
              setEdit={setShowReferral}
              setEditHover={setShowReferralHover}
              allowScroll={allowScroll}
              setShowWaitlist={setShowWaitlist}
            />
          </Popup>
        )}
      </div>
    </HelmetProvider>
  );
};

export default Landing;
