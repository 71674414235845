import { useContext } from "react";
import MainContext from "../../../components/helpers/context/mainContext";

import { RectUpload } from "../../../components/lvl0Components/avatar";

import { SrRefTextIntSb15 } from "../../../ref/text";
import { SrRefTextIntRe12 } from "../../../ref/text";

import style from "./style";

// EditComponent which renders on startupEdit pag
const EditComponent = (startup, w, imageInputRef, setShowCropper, setImage) => {
  return (
    // Infographic upload avatar
    <div style={style.justifyContentCenter}>
      <RectUpload
        width={w}
        height={w * 1.5}
        pic={startup.pitch.image}
        imageInputRef={imageInputRef}
        onChange={(e) => {
          let files;
          if (e.dataTransfer) {
            files = e.dataTransfer.files;
          } else if (e.target) {
            files = e.target.files;
          }
          const reader = new FileReader();
          reader.onload = () => {
            setImage(reader.result);
          };
          reader.readAsDataURL(files[0]);
          setShowCropper(true);
        }}
        infographic={true}
      />
    </div>
  );
};

// Component which renders on startup page
const Component = (w, startup) => {
  // Dynamic stlying for this component
  const style2 = {
    outerDiv: {
      ...style.bottomCenter,
      width: `${w}px`,
      height: `${w * 1.5}px`
    }
  };

  return (
    <div style={style2.outerDiv}>
      {startup.pitch.image ? (
        <img
          src={startup.pitch.image}
          width={w}
          height={w * 1.5}
          alt={"Startup infographic"}
          style={style.borderRadius12}
        />
      ) : (
        <div style={style.centeredTextWrap}>
          <img
            src={require("../../../media/image_default.png")}
            width={w}
            height={w * 1.5}
            alt={"Startup default infographic"}
            style={style.borderRadius12}
          />
          <div style={style.centeredText}>
            <SrRefTextIntSb15 content={"Your infographic"} />
            <div style={style.marginTop6}>
              <SrRefTextIntRe12
                content={
                  "Use this image to emphasize on your unique features, your product line, etc."
                }
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const Infographic = ({ w, startup, imageInputRef, setShowCropper, setImage }) => {
  // ifEdit state to check if user is on startup page or startupEdit page
  const { ifEdit } = useContext(MainContext);
  return ifEdit
    ? EditComponent(startup, w, imageInputRef, setShowCropper, setImage)
    : Component(w, startup);
};

export default Infographic;
