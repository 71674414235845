import { useContext } from "react";
import MainContext from "../../helpers/context/mainContext";

import SrRefColour from "../../../ref/colours";
import { SrRefTextIntSb15 } from "../../../ref/text";
import { gradientBgTextBox, gradientBorderTextBox, gradientText } from "../../helpers/themeColors";
import { newLineText } from "../../helpers/themeColors";

import { EditBtn0 } from "../../lvl0Components/buttons/iconBtns";

import style from "./style";

// MainComponent which renders on startup page and also on startEdit page
// with some extra styling
const MainComponent = () => {
  const { ifEdit, startup, primaryColor, secondaryColor, invertBackground } =
    useContext(MainContext);

  // Dynamic styling for this component
  const style2 = {
    outerDiv: {
      ...style.textBox,
      ...gradientBgTextBox(secondaryColor, primaryColor, 0.6, invertBackground),
      ...gradientBorderTextBox(secondaryColor, primaryColor, invertBackground),
      ...style.width100,
      marginTop: ifEdit ? "0px" : "24px"
    }
  };

  return (
    <div style={style2.outerDiv}>
      <SrRefTextIntSb15
        content={"Target market"}
        style={gradientText(secondaryColor, primaryColor, invertBackground)}
      />

      {/* Target market text */}
      <div style={style.marginTop6}>
        {startup.pitch.market
          ? newLineText(startup.pitch.market, secondaryColor, primaryColor, invertBackground)
          : newLineText(
              "Who is your user? Can you describe them more accurately? Are there more than one customer segment you are targeting?",
              secondaryColor,
              primaryColor,
              invertBackground
            )}
      </div>
    </div>
  );
};

const Market = () => {
  // ifEdit state to check if user is on startup page or startupEdit page
  const { ifEdit, setEdit8, setAllHoverFalse, setEditHover8, editHover8 } = useContext(MainContext);

  // Dynamic styling for this component
  const style2 = {
    outerDiv: {
      ...style.outerDiv,
      backgroundColor: editHover8 && SrRefColour.Neutral1C
    },
    innerDiv: {
      ...style.innerDiv,
      border: `1px dotted ${SrRefColour.Neutral2B}`
    },
    editButton: {
      position: "absolute",
      top: "11px",
      right: "11px"
    }
  };

  return ifEdit ? (
    <div
      onClick={() => setEdit8(true)}
      onMouseEnter={() => [setAllHoverFalse(), setEditHover8(true)]}
      onMouseLeave={() => setEditHover8(false)}
      style={style2.outerDiv}
    >
      <div style={style2.innerDiv}>
        <MainComponent />

        <div style={style2.editButton}>
          <EditBtn0 selected={editHover8} />
        </div>
      </div>
    </div>
  ) : (
    <MainComponent />
  );
};

export default Market;
