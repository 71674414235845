import React, { useState } from "react";
import SrRefColour from "../../../../ref/colours";
import { SrRefTextIntLi12, SrRefTextIntLi10 } from "../../../../ref/text";
import style from "./style";

// Back FAB button
const BackFAB = (props) => {
  const [btnHover, setBtnHover] = useState(false);

  // Dynamic styling for this component
  const style2 = {
    divStyle: {
      ...style.divStyle,
      borderWidth: `1px solid ${SrRefColour.Neutral1D}`,
      backgroundColor: btnHover ? SrRefColour.Neutral1C : SrRefColour.Neutral1D
    }
  };

  return (
    <div
      onMouseEnter={() => setBtnHover(true)}
      onMouseLeave={() => setBtnHover(false)}
      onClick={props.onClick}
      style={style.clickable}
    >
      <div style={style2.divStyle}>
        <SrRefTextIntLi10 content={"Back"} state={"enabled"} style={style.colorBlack} />
      </div>
    </div>
  );
};

// Next FAB button
const NextFAB = (props) => {
  const [btnHover, setBtnHover] = useState(false);

  // Dynamic styling for this component
  const style2 = {
    divStyle: {
      ...style.nextDivStyle,
      border: `1px solid ${btnHover ? SrRefColour.Neutral2A : SrRefColour.Neutral2B}`,
      backgroundColor: btnHover ? SrRefColour.Neutral1C : SrRefColour.Neutral
    }
  };

  return (
    <div
      onMouseEnter={() => setBtnHover(true)}
      onMouseLeave={() => setBtnHover(false)}
      onClick={props.onClick}
      style={style.clickable}
    >
      <div style={style2.divStyle}>
        <SrRefTextIntLi12
          content={props.content ? props.content : "Next"}
          state={"enabled"}
          style={style.colorBlack}
        />
      </div>
    </div>
  );
};

export { BackFAB, NextFAB };
