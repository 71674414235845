import SrRefColour from "../../../ref/colours";
import { fsControl } from "../../../components/helpers/sizeController";

export const style = {
  textInput: {
    width: "100%",
    padding: "12px 9px",
    borderRadius: "6px",
    backgroundColor: SrRefColour.Neutral1C,
    resize: "none",
    outline: "none"
  },
  textInputSmall: {
    width: "100%",
    padding: "0 3px",
    borderRadius: "6px",
    backgroundColor: SrRefColour.Neutral1C,
    resize: "none",
    outline: "none"
  },
  intRe12: {
    fontFamily: "Inter",
    fontWeight: "400",
    fontSize: fsControl(1.2)
  },
  textInputTop: {
    position: "absolute",
    top: -5,
    left: 6,
    padding: "0 3px",
    borderRadius: "3px",
    backgroundColor: SrRefColour.Neutral0
  },
  positionRelative: {
    position: "relative"
  },
  transition: {
    transition: "0.2s ease-in"
  },

  // Custom style for this component
  inputStyle: {
    width: "100%",
    padding: "12px 9px",
    borderRadius: "6px",
    backgroundColor: SrRefColour.Neutral1C,
    resize: "none",
    outline: "none",
    fontFamily: "Inter",
    fontWeight: "400",
    fontSize: fsControl(1.2),
    transition: "0.2s ease-in"
  }
};
