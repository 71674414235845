import React, { useContext } from "react";
import MainContext from "../../../components/helpers/context/mainContext";
import { ThumbUpBtn, CommentBtn, ConnectBtn, RateBtn } from "../../lvl1Components/buttons/bigBtns";
import style from "./style";

const ReviewPanel = (props) => {
  // Context
  const { screenWidth, breakpoint2, breakpoint3 } = useContext(MainContext);

  return (
    <div
      style={{
        ...style.userPanelWrap,
        ...{
          bottom: props.changePosition
            ? breakpoint3
              ? props.scrollPosition +
                props.windowHeight -
                (props.bodyScrollHeight - (60 + 36 + 75 + 24))
              : props.scrollPosition +
                props.windowHeight -
                (props.bodyScrollHeight - (60 + 48 + 75 + 30))
            : 0
          // right: props.changePosition ? (breakpoint2 ? "24px" : "48px") : 0,
          // width: props.changePosition ? "auto" : "100%"
          // transition: "right 1s ease-in-out"
        }
      }}
    >
      <div
        style={{
          ...style.positionRelative,
          ...style.surface,
          ...style.surfaceBlur,
          ...style.surfaceBorder,
          ...{
            margin: "0 auto",
            boxShadow: "0px 2px 4px rgba(0,0,0,0.15)",
            maxWidth: breakpoint2 && `${screenWidth - 24}px`
          }
        }}
      >
        {/* User panel surface */}
        <div
          style={{
            ...style.flexColumn,
            ...{
              borderRadius: "18px"
            }
          }}
        >
          <div
            style={{
              ...style.flexRow,
              ...style.alignItemsCenter,
              ...style.overflowAuto,
              ...style.paddingHor6,
              ...style.paddingBot6
            }}
          >
            {props.specialGuest ? (
              // Rating
              <RateBtn
                onChange={(e) => props.onClickValueProp(e.target.value)}
                pressed={props.valueProp}
                breakpoint3={breakpoint3}
              />
            ) : (
              // Upvote
              <ThumbUpBtn
                onClick={props.onClickUpVote}
                upVoteCount={props.upVoteCount}
                pressed={props.upVote}
                breakpoint3={breakpoint3}
              />
            )}

            {/* Comment */}
            <ConnectBtn
              onClick={props.onClickInterest}
              pressed={props.interest}
              breakpoint3={breakpoint3}
            />

            {/* Comment */}
            <CommentBtn
              onClick={props.onClickComment}
              pressed={props.comment}
              breakpoint3={breakpoint3}
            />

            {/* thought clarity  */}
            {/* {!props.thoughtClarity ? ( */}
            {/*   <div style={{ marginLeft: "20px" }}> */}
            {/*     <p>Thought Clarity </p> */}
            {/**/}
            {/*     <select name="clarity" onChange={(e) => props.onClickClarity(e.target.value)}> */}
            {/*       <option value="1">1</option> */}
            {/*       <option value="2">2</option> */}
            {/*       <option value="3">3</option> */}
            {/*       <option value="4">4</option> */}
            {/*       <option value="5">5</option> */}
            {/*     </select> */}
            {/*   </div> */}
            {/* ) : ( */}
            {/*   <p>Already Answered !!</p> */}
            {/* )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export { ReviewPanel };
