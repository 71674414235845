import { useContext } from "react";
import { SrRefTextRubSb30 } from "../../../ref/text";
import MainContext from "../../helpers/context/mainContext";

const OrgName = () => {
  const { startup } = useContext(MainContext);

  return <SrRefTextRubSb30 content={startup.name ? startup.name : "Startup's name"} />;
};

export default OrgName;
