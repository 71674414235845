import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import MainContext from "../../../components/helpers/context/mainContext";
import { Industries, StageArrShort, StageArr } from "../../../components/helpers/localData";
import { apiendpoint } from "../../../components/helpers/apiendpoint";
import { returnRGBA } from "../../../components/helpers/themeColors";

import PhoneScreen from "../../../components/startupComponents/ViewPorts/PhoneScreen";
import SmallTablet from "../../../components/startupComponents/ViewPorts/SmallTablet";
import Laptop from "../../../components/startupComponents/ViewPorts/Laptop";

import Loading from "../../../components/lvl0Components/Loading";
import { Popup } from "../../../components/lvl0Components/popup";
import { ShramStamp } from "../../../components/lvl0Components/shramStamp";
import { CropperPopup2 } from "../../../components/lvl1Components/setupPopups/cropperPopup";
import EditPopup1 from "../../../components/lvl1Components/setupPopups/editPopup1";
import EditPopup2 from "../../../components/lvl1Components/setupPopups/editPopup2";
import EditPopup3 from "../../../components/lvl1Components/setupPopups/editPopup3";
import EditPopup4 from "../../../components/lvl1Components/setupPopups/editPopup4";
import EditPopup5 from "../../../components/lvl1Components/setupPopups/editPopup5";
import EditPopup6 from "../../../components/lvl1Components/setupPopups/editPopup6";
import AccountPopup from "../../../components/lvl1Components/setupPopups/AccountPopup";
import { UserPanel } from "../../../components/lvl2Components/userPanel";
import { ColorPanel } from "../../../components/lvl2Components/colorPanel";

import { style } from "./style";
import BigTablet from "../../../components/startupComponents/ViewPorts/BigTablet";

const StartupEdit = () => {
  // Context
  const {
    screenWidth,
    scrollBoxWidth,
    breakpoint1,
    breakpoint2,
    breakpoint3,
    defaultColor,
    colorSplit,
    scrollToTop,
    // API related
    user,
    userStartup,
    defaultStartup,
    startup,
    setStartup,
    setupNew,

    //edit state to check if user is on startupEdit page
    ifEdit,
    setIfEdit,

    // States related to popup
    primaryColor,
    secondaryColor,
    accentColor,
    tertiaryColor,
    invertBackground,
    setPrimaryColor,
    setSecondaryColor,
    setAccentColor,
    setInvertBackground,
    setTertiaryColor,
    primaryColorEdit,
    secondaryColorEdit,
    accentColorEdit,
    tertiaryColorEdit,
    setPrimaryColorEdit,
    setSecondaryColorEdit,
    setAccentColorEdit,
    setTertiaryColorEdit,

    setEdit1,
    editHover1,
    setEditHover1,
    setEdit2,
    editHover2,
    setEditHover2,
    editHover3,
    setEdit3,
    setEditHover3,
    setEdit4,
    editHover4,
    setEditHover4,
    setEdit5,
    editHover5,
    setEditHover5,
    setEdit6,
    editHover6,
    setEditHover6,
    setEdit7,
    editHover7,
    setEditHover7,
    setEdit8,
    editHover8,
    setEditHover8,
    editHover9,
    setEditHover9,
    setEdit10,
    editHover10,
    setEditHover10,
    setEdit11,
    editHover11,
    setEditHover11,
    setEdit12,
    setEdit13,
    editHover13,
    setEditHover13,
    setEdit14,
    editHover14,
    setEditHover14,
    setAllHoverFalse,
    edit1,
    edit2,
    edit3,
    edit4,
    edit5,
    edit6,
    edit7,
    edit8,
    edit9,
    edit10,
    edit11,
    edit12,
    edit13,
    edit14,

    editHover12,
    setEditHover12
  } = useContext(MainContext);
  const navigate = useNavigate();

  // If user doesn't exist, navigate to home
  window.onload = function () {
    const editingUser = localStorage.getItem("editing") === "true" || false;
    if (!editingUser && !user) navigateToLogin();
    localStorage.removeItem("loading");
    localStorage.removeItem("editing");
  };

  window.onbeforeunload = function () {
    /* setLoading(true); */
    localStorage.setItem("loading", "true");
  };

  // Get id of the startup currently being viewed
  const { pathname } = useLocation();
  const id = Number(window.location.pathname.split("/")[3]);

  // Get the startup body by filtering through the right id
  const [orgId, setOrgId] = useState(id);
  const [loading, setLoading] = useState(localStorage.getItem("loading") === "true" || false);
  const [showLogin, setShowLogin] = useState(false);
  const [accountEdit, setAccountEdit] = useState(false);

  const allowEdit = () => {
    if (user) {
      if (user.orgId === startup.id && user.orgLead === "Y") {
        return true;
      }
      return false;
    } else {
      return false;
    }
  };

  const [isEdit, setIsEdit] = useState(allowEdit());

  // Navigate to main startup listing
  const navigateHome = () => {
    navigate("/home");
    scrollToTop();
  };

  // Navigate too community page
  const navigateToCommunity = () => {
    let communityId = localStorage.getItem("@communityId");
    communityId = communityId ? communityId : "20";
    navigate(`/community/${communityId}`);
  };

  // Navigate to main startup listing
  const navigateToLogin = () => {
    navigate("/login");
  };

  // Make an array for unlisted cofounders and return a default avatar for each
  let uninvitedCofounders = [];
  const returnUnivitedCofounders = () => {
    let temp = [];
    for (
      let i = 1;
      i < startup.cofounderCount - startup.team.filter((m) => m.orgLead === "Y").length;
      i++
    ) {
      temp.push(i);
    }
    return (uninvitedCofounders = temp);
  };

  // Find how much progress has been made toward the listing
  const [progress, setProgress] = useState();
  const computeProgress = () => {
    const totalScore = 24 - 2; // Subtracted 2 because maybe the video should be optional for now
    const obtainedScore =
      // Startup details
      Boolean(startup.name && startup.name !== defaultStartup.name) +
      Boolean(startup.description && startup.description !== defaultStartup.description) +
      Boolean(startup.logo && startup.logo !== defaultStartup.logo) +
      Boolean(startup.industry && startup.industry !== defaultStartup.industry) +
      Boolean(startup.sectors && startup.sectors[0] !== defaultStartup.sectors[0]) +
      Boolean(startup.sectors && startup.sectors[1] !== defaultStartup.sectors[1]) +
      // Startup colours
      Boolean(startup.theme && startup.theme !== defaultStartup.theme) +
      // Startup teamData
      Boolean(startup.team && startup.team !== defaultStartup.team) +
      Boolean(startup.cofounderCount && startup.cofounderCount !== defaultStartup.cofounderCount) +
      Boolean(startup.teamSize && startup.teamSize !== defaultStartup.teamSize) +
      // Startup Founding data
      Boolean(startup.other.year && startup.other.year !== defaultStartup.other.year) +
      Boolean(startup.other.location && startup.other.location !== defaultStartup.other.location) +
      Boolean(startup.other.stage !== defaultStartup.other.stage) +
      Boolean(
        startup.other.socialMedia && startup.other.socialMedia !== defaultStartup.other.socialMedia
      ) +
      Boolean(startup.other.status && startup.other.status !== defaultStartup.other.status) +
      // Startup pitch data
      Boolean(startup.pitch.problem && startup.pitch.problem !== defaultStartup.pitch.problem) +
      Boolean(startup.pitch.solution && startup.pitch.solution !== defaultStartup.pitch.solution) +
      Boolean(startup.pitch.image && startup.pitch.image !== defaultStartup.pitch.image) +
      // Boolean(
      //   startup.pitch.video.link && startup.pitch.video.link !== defaultStartup.pitch.video.link
      // ) +
      // Boolean(
      //   startup.pitch.video.thumbnail &&
      //     startup.pitch.video.thumbnail !== defaultStartup.pitch.video.thumbnail
      // ) +
      Boolean(startup.pitch.features && startup.pitch.features !== defaultStartup.pitch.features) +
      Boolean(startup.pitch.traction && startup.pitch.traction !== defaultStartup.pitch.traction) +
      Boolean(startup.pitch.market && startup.pitch.market !== defaultStartup.pitch.market) +
      Boolean(startup.pitch.website && startup.pitch.website !== defaultStartup.pitch.website);
    setProgress((100 * obtainedScore) / totalScore);
  };

  useEffect(computeProgress, [startup]);

  // Navigate to a particular startup listing
  const navigateToOwnStartup = (e) => {
    navigate(`/startup/${e}`, { state: { id: e } });
    setOrgId(e);
    scrollToTop();
  };

  // Constants
  const screenPadding = breakpoint2 ? 24 : 48;
  const videoWidth = 240;

  const [shouldApprove, setShouldApprove] = useState(false);

  const [returnPrimary, setReturnPrimary] = useState(
    returnRGBA(primaryColor.r, primaryColor.g, primaryColor.b, 1)
  );

  const [returnPrimaryInverse, setReturnPrimaryInverse] = useState(
    returnRGBA(primaryColor.r, primaryColor.g, primaryColor.b, 0.1 / primaryColor.a)
  );

  // Picker for primary colour
  const PrimaryColourSet = (color) => {
    setPrimaryColor(color.rgb);
    setReturnPrimary(returnRGBA(color.rgb.r, color.rgb.g, color.rgb.b, 1));
    setReturnPrimaryInverse(returnRGBA(color.rgb.r, color.rgb.g, color.rgb.b, 0.1 / color.rgb.a));
    setStartup({
      ...startup,
      theme: {
        ...startup.theme,
        primary: { color: color.rgb }
      }
    });
  };

  // Picker for secondary colour
  const SecondaryColourSet = (color) => {
    setSecondaryColor(color.rgb);
    setStartup({
      ...startup,
      theme: {
        ...startup.theme,
        secondary: { color: color.rgb }
      }
    });
  };

  // Picker for tertiary colour
  const TertiaryColourSet = (color) => {
    setTertiaryColor(color.rgb);
    setStartup({
      ...startup,
      theme: {
        ...startup.theme,
        tertiary: { color: color.rgb }
      }
    });
  };

  // Picker for accent colour
  const AccentColourSet = (color) => {
    setAccentColor(color.rgb);
    setStartup({
      ...startup,
      theme: {
        ...startup.theme,
        accent: { color: color.rgb }
      }
    });
  };

  const fetchData = async (jwt) => {
    fetch(`${apiendpoint}/platform/org/${orgId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw new Error("Unauthorized");
      })
      .then((data) => {
        const temp = {
          id: data.org.id,
          name: data.org.name,
          brief: data.org.brief,
          description: data.org?.description,
          logo: data.org?.logo,
          inviteCode: data.org?.invite_code,
          listingStatus: data.org?.listing_status,
          theme: {
            primary: {
              color: data.org?.primary_colour ? colorSplit(data.org.primary_colour) : defaultColor
            },
            secondary: {
              color: data.org.secondary_colour ? colorSplit(data.org.secondary_colour) : ""
            },
            tertiary: {
              color: data.org.tertiary_colour ? colorSplit(data.org.tertiary_colour) : ""
            },
            accent: {
              color: data.org.accent_colour ? colorSplit(data.org.accent_colour) : ""
            },
            invertBackground: data.org.invert_background
          },
          industry: data.org.industry,
          sectors: [data.org.sector1, data.org.sector2],
          team: data.orgMembers.map((lead) => {
            return {
              id: lead.user.id,
              fname: lead.user.fname,
              lname: lead.user.lname,
              designation: lead.user.designation,
              pic: lead.user.picture,
              linkedIn: lead.user.linkedin,
              orgId: lead.user.org_id,
              orgLead: lead.user.is_org_lead,
              representer: lead.user.representer
            };
          }),
          cofounderCount: data.org.cofounder_count,
          teamSize: data.org.team_size,
          other: {
            year: data.org.establishment_year,
            location: data.org.location,
            zaubacorp: data.org.zaubacorp,
            stage: StageArrShort.indexOf(data.org.stage),
            socialMedia: {
              discord: data.org.discord,
              facebook: data.org.facebook,
              linkedIn: data.org.linkedin,
              instagram: data.org.instagram,
              medium: data.org.medium,
              pinterest: data.org.pinterest,
              reddit: data.org.reddit,
              substack: data.org.substack,
              twitter: data.org.twitter,
              whatsapp: data.org.whatsapp,
              youtube: data.org.youtube
            },
            status: data.org.org_status
          },
          pitch: {
            problem: data.platform?.problem,
            solution: data.platform?.solution,
            image: data.platform?.image,
            video: {
              link: data.platform?.video,
              thumbnail: data.platform?.thumbnail
            },
            website: data.org.website,
            features: data.platform?.features,
            traction: data.platform?.traction,
            market: data.platform?.market,
            challenge: data.platform?.challenge,
            pitchDeck: data.platform?.pitch_deck,
            challenge1: data.platform?.challenge1,
            challenge2: data.platform?.challenge2,
            challenge3: data.platform?.challenge3,
            mentorSector1: data.platform?.mentor_sector1,
            mentorSector2: data.platform?.mentor_sector2,
            mentorSector3: data.platform?.mentor_sector3,
            mentorSector4: data.platform?.mentor_sector4,
            mentorSector5: data.platform?.mentor_sector5
          }
        };

        // temp theme colours
        setPrimaryColor(temp.theme.primary.color);
        setSecondaryColor(temp.theme.secondary.color);
        setTertiaryColor(temp.theme.tertiary.color);
        setAccentColor(temp.theme.accent.color);
        setInvertBackground(temp.theme.invertBackground);
        setShouldApprove(data.shouldApprove);

        // Navigate to startup page
        !data.shouldApprove && !allowEdit() && navigate(`/startup/${id}`);

        setReturnPrimary(
          returnRGBA(
            temp.theme.primary.color.r,
            temp.theme.primary.color.g,
            temp.theme.primary.color.b,
            1
          )
        );
        setReturnPrimaryInverse(
          returnRGBA(
            temp.theme.primary.color.r,
            temp.theme.primary.color.g,
            temp.theme.primary.color.b,
            0.1 / temp.theme.primary.color.a
          )
        );

        setIsEdit(allowEdit());
        setStartup(temp);
        setLoading(false);
      });
  };

  useEffect(() => {
    try {
      let jwt = localStorage.getItem("@jwtauth");
      if (!jwt) jwt = "";
      fetchData(jwt);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }, [pathname]);

  const setup2 = async (body) => {
    let jwt = localStorage.getItem("@jwtauth");
    if (!jwt) jwt = "";

    var link = `${apiendpoint}/platform/phase2/${id}`;

    fetch(link, {
      // mode: "no-cors",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`
      },
      body: JSON.stringify(body)
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw new Error("Unauthorized");
      })
      .then((data) => {})
      .catch((err) => {
        console.log(err);
        console.log("setup2 failed");
      });
  };

  const setup3 = async (body) => {
    let jwt = localStorage.getItem("@jwtauth");
    if (!jwt) jwt = "";

    var link = `${apiendpoint}/platform/phase3/${id}`;
    fetch(link, {
      // mode: "no-cors",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`
      },
      body: JSON.stringify(body)
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw new Error("Unauthorized");
      })
      .then((data) => {})
      .catch((err) => {
        console.log(err);
        console.log("setup3 failed");
      });
  };

  const updateTeam = (teamData) => {
    var temp = teamData;
    var team = [];
    temp.forEach((emp) => {
      team.push({
        id: emp.id,
        fname: emp.fname,
        lname: emp.lname,
        designation: emp.designation,
        picture: emp.pic,
        linkedIn: emp.linkedin,
        org_id: emp.orgId,
        is_org_lead: emp.orgLead,
        representer: emp.representer
      });
    });
    var body = { changeEmpDetails: team };
    let jwt = localStorage.getItem("@jwtauth");
    if (!jwt) jwt = "";
    fetch(`${apiendpoint}/platform/orgUser`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`
      },
      body: JSON.stringify(body)
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw new Error("Unauthorised");
      })
      .then((json) => {})
      .catch((err) => {
        console.log("updateTeam failed");
      });
  };

  // Image
  const imageInputRef = useRef();
  const [image, setImage] = useState(null);
  const [showCropper, setShowCropper] = useState(false);
  const [infographic, setInfographic] = useState(startup.pitch.image);

  // Upload image
  const uploadFile = (image) => {
    var jwt = localStorage.getItem("@jwtauth");
    if (!jwt) jwt = "";

    var orgId = localStorage.getItem("@orgid");
    if (!orgId) return;

    // console.log("image:", image.size, image.size / 1024 / 1024, image.size / 1024 / 1024 < 1);

    if (image.size / 1024 / 1024 <= 10) {
      const fd = new FormData();
      fd.append("file", image);

      fetch(`${apiendpoint}/platform/graphics?org_id=${orgId}`, {
        method: "POST",
        body: fd,
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      })
        .then((response) => {
          if (response.ok) return response.json();
          else {
            throw new Error("Unauthorized");
          }
        })
        .then((json) => {
          setInfographic(json.url);
          setStartup({
            ...startup,
            pitch: {
              ...startup.pitch,
              ...{
                image: json.url
              }
            }
          });
          setShowCropper(false);
          snackbar("Image uploaded successfully");
          setLoading(false);
        })
        .catch((error) => {
          setShowCropper(false);
          snackbar("An error occurred, please try again.");
          setLoading(false);
        });
    } else {
      setShowCropper(false);
      snackbar("Image size should be less than 10mb");
    }
  };

  // scrollPosition
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset; // Find the top offset of the window
    setScrollPosition(position); // Set scrollPosition = top offset of the window
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true }); // listen to scroll event

    // Set if user is on edit page(To invoke component state)
    setIfEdit(true);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      setIfEdit(false);
    };
  }, []);

  // changePosition
  const [changePosition, setChangePosition] = useState(
    breakpoint3
      ? scrollPosition + window.innerHeight >= document.body.scrollHeight - (60 + 36)
      : scrollPosition + window.innerHeight >= document.body.scrollHeight - (60 + 48)
  );

  useEffect(() => {
    return () => {
      setChangePosition(
        breakpoint3
          ? scrollPosition + window.innerHeight >= document.body.scrollHeight - (60 + 36)
          : scrollPosition + window.innerHeight >= document.body.scrollHeight - (60 + 48)
      );
    };
  }, [scrollPosition]);

  // Popup effect
  const body = document.body;

  // Disallow scroll when login popup is visible
  useEffect(() => {
    body.style.overflowY =
      edit1 ||
      edit2 ||
      edit3 ||
      edit4 ||
      edit5 ||
      edit6 ||
      edit7 ||
      edit8 ||
      edit9 ||
      edit10 ||
      edit11
        ? "hidden"
        : "auto";
  }, [edit1]); // Whenever scroll position chamges

  // Allow scrolling on body element in DOM
  const allowScroll = () => {
    body.style.overflowY = "auto";
  };

  // Snackbar
  const snackbar = (message) => {
    toast(message, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
  };

  // For phones with width less than 480px
  const phone = () => {
    return (
      <PhoneScreen
        imageInputRef={imageInputRef}
        setImage={setImage}
        setShowCropper={setShowCropper}
      />
    );
  };

  // For tablets with width more than 480px and less than 840px
  const smallTablet = () => {
    return (
      <SmallTablet
        imageInputRef={imageInputRef}
        setImage={setImage}
        setShowCropper={setShowCropper}
      />
    );
  };

  // For tablets with width more than 840px and less than 1280px
  const bigTablet = () => {
    return (
      <BigTablet
        imageInputRef={imageInputRef}
        setImage={setImage}
        setShowCropper={setShowCropper}
      />
    );
  };

  // For screens with width more than 1280px
  const laptop = () => {
    return (
      <Laptop imageInputRef={imageInputRef} setImage={setImage} setShowCropper={setShowCropper} />
    );
  };

  return loading ? (
    <Loading />
  ) : (
    <div
      style={{
        ...style.outerMost,
        ...{ overflowY: edit1 || edit2 || edit3 || edit4 || edit5 || edit6 ? "hidden" : "auto" }
      }}
    >
      <div style={{ ...style.screen, ...{ marginBottom: `${breakpoint3 ? 24 : 36}px` } }}>
        {/* User panel at the top */}
        <UserPanel
          isEdit={isEdit}
          user={user}
          userStartup={userStartup}
          screenPadding={screenPadding}
          screenWidth={screenWidth}
          onClickHome={() => navigateToCommunity()}
          onClickAccount={() => setAccountEdit(true)}
          onClickSave={() =>
            user
              ? [
                  setup2({
                    primary_colour: startup.theme?.primary.color
                      ? `rgba(${startup.theme?.primary.color.r},${startup.theme.primary.color.g},${startup.theme.primary.color.b},${startup.theme.primary.color.a})`
                      : null,
                    secondary_colour: startup.theme?.secondary.color
                      ? `rgba(${startup.theme?.secondary.color.r},${startup.theme.secondary.color.g},${startup.theme.secondary.color.b},${startup.theme.secondary.color.a})`
                      : null,
                    tertiary_colour: startup.theme?.tertiary.color
                      ? `rgba(${startup.theme?.tertiary.color.r},${startup.theme.tertiary.color.g},${startup.theme.tertiary.color.b},${startup.theme.tertiary.color.a})`
                      : "",
                    accent_colour: startup.theme?.accent.color
                      ? `rgba(${startup.theme?.accent.color.r},${startup.theme.accent.color.g},${startup.theme.accent.color.b},${startup.theme.accent.color.a})`
                      : "",
                    invert_background: startup.theme?.invertBackground,
                    listing_status:
                      startup.listingStatus === "co" ? "co" : progress === 100 ? "re" : "do"
                  }),
                  setup3({
                    image: startup.pitch.image
                  }),
                  navigateToOwnStartup(id)
                ]
              : setShowLogin(true)
          }
          sameStartup={user ? user.orgId === startup.id : null}
          fromStartupEdit={true}
          progress={progress}
          listingStatus={startup.listingStatus}
        />

        {breakpoint3
          ? // For phones with width less than 480px
            phone()
          : breakpoint2
          ? // For tablets with width more than 480px and less than 840px
            smallTablet()
          : breakpoint1
          ? // For tablets with width more than 840px and less than 1280px
            bigTablet()
          : // For screens with width more than 1280px
            laptop()}

        {/* Account edit popup */}
        {accountEdit && (
          <Popup
            onClick={() => {
              setAccountEdit(false);
              setEditHover1(false);
              allowScroll();
            }}
            scrollPosition={scrollPosition}
            noPadding={true}
            modal={breakpoint3 ? true : false}
          >
            {/* Edit1 popup content */}
            <AccountPopup
              setEdit={setAccountEdit}
              setEditHover={setEditHover1}
              allowScroll={allowScroll}
            />
          </Popup>
        )}
      </div>

      {/* Color panel */}
      <ColorPanel
        // Primary color
        primaryColor={primaryColor}
        setPrimaryColor={setPrimaryColor}
        primaryColorEdit={primaryColorEdit}
        setPrimaryColorEdit={setPrimaryColorEdit}
        onPrimaryChange={PrimaryColourSet}
        // Secondary color
        secondaryColor={secondaryColor}
        setSecondaryColor={setSecondaryColor}
        secondaryColorEdit={secondaryColorEdit}
        setSecondaryColorEdit={setSecondaryColorEdit}
        onSecondaryChange={SecondaryColourSet}
        // Tertiary color
        tertiaryColor={tertiaryColor}
        setTertiaryColor={setTertiaryColor}
        tertiaryColorEdit={tertiaryColorEdit}
        setTertiaryColorEdit={setTertiaryColorEdit}
        onTertiaryChange={TertiaryColourSet}
        // Accent color
        accentColor={accentColor}
        setAccentColor={setAccentColor}
        accentColorEdit={accentColorEdit}
        setAccentColorEdit={setAccentColorEdit}
        onAccentChange={AccentColourSet}
        // Invert background
        invertBackground={invertBackground}
        setInvertBackground={setInvertBackground}
        // Other
        setStartup={setStartup}
        startup={startup}
        changePosition={changePosition}
        scrollPosition={scrollPosition}
        windowHeight={window.innerHeight}
        bodyScrollHeight={document.body.scrollHeight}
      />

      {/* Space for the colorPanel to rest at */}
      <div style={{ width: "100%", height: "112px" }}></div>

      {/* Shram stamp */}
      <div
        style={{
          marginTop: `${breakpoint3 ? 24 : 36}px`
        }}
      >
        <ShramStamp />
      </div>

      {/* Edit1 popup */}
      {edit1 && (
        <Popup
          onClick={() => {
            setEdit1(0);
            setEditHover1(false);
            allowScroll();
          }}
          scrollPosition={scrollPosition}
          noPadding={true}
          modal={breakpoint3 ? true : false}
        >
          {/* Edit1 popup content */}
          <EditPopup1
            setEdit={setEdit1}
            setEditHover={setEditHover1}
            allowScroll={allowScroll}
            currentScreen={edit1}
            orgId={id}
            startupName={startup.name}
            startupLogo={startup.logo}
            primaryColor={primaryColor}
            shouldApprove={shouldApprove}
          />
        </Popup>
      )}

      {/* Edit2 popup */}
      {edit2 && (
        <Popup
          onClick={() => {
            setEdit2(false);
            setEditHover2(false);
            allowScroll();
          }}
          scrollPosition={scrollPosition}
          noPadding={true}
          modal={breakpoint3 ? true : false}
        >
          {/* Edit2 popup content */}
          <EditPopup2
            setEdit={setEdit2}
            setEditHover={setEditHover2}
            allowScroll={allowScroll}
            orgId={id}
            startupName={startup.name}
            startupLogo={startup.logo}
            primaryColor={primaryColor}
            shouldApprove={shouldApprove}
          />
        </Popup>
      )}

      {/* Edit3 popup */}
      {edit3 && (
        <Popup
          onClick={() => {
            setEdit3(0);
            setEditHover3(false);
            allowScroll();
          }}
          scrollPosition={scrollPosition}
          noPadding={true}
          modal={breakpoint3 ? true : false}
        >
          {/* Edit3 popup content */}
          <EditPopup3
            setEdit={setEdit3}
            setEditHover={setEditHover3}
            allowScroll={allowScroll}
            currentScreen={edit3}
            orgId={id}
            startupName={startup.name}
            startupLogo={startup.logo}
            primaryColor={primaryColor}
            shouldApprove={shouldApprove}
          />
        </Popup>
      )}

      {/* Edit4 popup */}
      {edit4 && (
        <Popup
          onClick={() => {
            setEdit4(false);
            setEditHover4(false);
            allowScroll();
          }}
          scrollPosition={scrollPosition}
          noPadding={true}
          modal={breakpoint3 ? true : false}
        >
          {/* Edit4 popup content */}
          <EditPopup4
            setEdit={setEdit4}
            setEditHover={setEditHover4}
            allowScroll={allowScroll}
            problem={true}
            orgId={id}
            startupName={startup.name}
            startupLogo={startup.logo}
            primaryColor={primaryColor}
            shouldApprove={shouldApprove}
          />
        </Popup>
      )}

      {/* Edit5 popup */}
      {edit5 && (
        <Popup
          onClick={() => {
            setEdit5(false);
            setEditHover5(false);
            allowScroll();
          }}
          scrollPosition={scrollPosition}
          noPadding={true}
          modal={breakpoint3 ? true : false}
        >
          {/* Edit4 popup content */}
          <EditPopup4
            setEdit={setEdit5}
            setEditHover={setEditHover5}
            allowScroll={allowScroll}
            solution={true}
            orgId={id}
            startupName={startup.name}
            startupLogo={startup.logo}
            primaryColor={primaryColor}
            shouldApprove={shouldApprove}
          />
        </Popup>
      )}

      {/* Edit6 popup */}
      {edit6 && (
        <Popup
          onClick={() => {
            setEdit6(false);
            setEditHover6(false);
            allowScroll();
          }}
          scrollPosition={scrollPosition}
          noPadding={true}
          modal={breakpoint3 ? true : false}
        >
          {/* Edit4 popup content */}
          <EditPopup4
            setEdit={setEdit6}
            setEditHover={setEditHover6}
            allowScroll={allowScroll}
            features={true}
            orgId={id}
            startupName={startup.name}
            startupLogo={startup.logo}
            primaryColor={primaryColor}
            shouldApprove={shouldApprove}
          />
        </Popup>
      )}

      {/* Edit7 popup */}
      {edit7 && (
        <Popup
          onClick={() => {
            setEdit7(false);
            setEditHover7(false);
            allowScroll();
          }}
          scrollPosition={scrollPosition}
          noPadding={true}
          modal={breakpoint3 ? true : false}
        >
          {/* Edit4 popup content */}
          <EditPopup4
            setEdit={setEdit7}
            setEditHover={setEditHover7}
            allowScroll={allowScroll}
            traction={true}
            orgId={id}
            startupName={startup.name}
            startupLogo={startup.logo}
            primaryColor={primaryColor}
            shouldApprove={shouldApprove}
          />
        </Popup>
      )}

      {/* Edit8 popup */}
      {edit8 && (
        <Popup
          onClick={() => {
            setEdit8(false);
            setEditHover8(false);
            allowScroll();
          }}
          scrollPosition={scrollPosition}
          noPadding={true}
          modal={breakpoint3 ? true : false}
        >
          {/* Edit4 popup content */}
          <EditPopup4
            setEdit={setEdit8}
            setEditHover={setEditHover8}
            allowScroll={allowScroll}
            market={true}
            orgId={id}
            startupName={startup.name}
            startupLogo={startup.logo}
            primaryColor={primaryColor}
            shouldApprove={shouldApprove}
          />
        </Popup>
      )}

      {/* Edit10 popup */}
      {edit10 && (
        <Popup
          onClick={() => {
            setEdit10(false);
            setEditHover10(false);
            allowScroll();
          }}
          scrollPosition={scrollPosition}
          noPadding={true}
          modal={breakpoint3 ? true : false}
        >
          {/* Edit4 popup content */}
          <EditPopup4
            setEdit={setEdit10}
            setEditHover={setEditHover10}
            allowScroll={allowScroll}
            video={true}
            orgId={id}
            startupName={startup.name}
            startupLogo={startup.logo}
            primaryColor={primaryColor}
            shouldApprove={shouldApprove}
          />
        </Popup>
      )}

      {/* Edit11 popup */}
      {edit11 && (
        <Popup
          onClick={() => {
            setEdit11(false);
            setEditHover11(false);
            allowScroll();
          }}
          scrollPosition={scrollPosition}
          noPadding={true}
          modal={breakpoint3 ? true : false}
        >
          {/* Edit4 popup content */}
          <EditPopup4
            setEdit={setEdit11}
            setEditHover={setEditHover11}
            allowScroll={allowScroll}
            website={true}
            orgId={id}
            startupName={startup.name}
            startupLogo={startup.logo}
            primaryColor={primaryColor}
            shouldApprove={shouldApprove}
          />
        </Popup>
      )}

      {/* Edit12 popup */}
      {edit12 && (
        <Popup
          onClick={() => {
            setEdit12(false);
            setEditHover12(false);
            allowScroll();
          }}
          scrollPosition={scrollPosition}
          noPadding={true}
          modal={breakpoint3 ? true : false}
        >
          {/* Edit4 popup content */}
          <EditPopup5
            setEdit={setEdit12}
            setEditHover={setEditHover12}
            allowScroll={allowScroll}
            challenge={true}
            orgId={id}
            startupName={startup.name}
            startupLogo={startup.logo}
            primaryColor={primaryColor}
            shouldApprove={shouldApprove}
          />
        </Popup>
      )}

      {/* Edit13 popup */}
      {edit13 && (
        <Popup
          onClick={() => {
            setEdit13(false);
            setEditHover13(false);
            allowScroll();
          }}
          scrollPosition={scrollPosition}
          noPadding={true}
          modal={breakpoint3 ? true : false}
        >
          {/* Edit4 popup content */}
          <EditPopup6
            setEdit={setEdit13}
            setEditHover={setEditHover13}
            allowScroll={allowScroll}
            mentorSectors={true}
            orgId={id}
            startupName={startup.name}
            startupLogo={startup.logo}
            primaryColor={primaryColor}
            shouldApprove={shouldApprove}
          />
        </Popup>
      )}

      {/* Edit14 popup */}
      {edit14 && (
        <Popup
          onClick={() => {
            setEdit14(false);
            setEditHover14(false);
            allowScroll();
          }}
          scrollPosition={scrollPosition}
          noPadding={true}
          modal={breakpoint3 ? true : false}
        >
          {/* Edit4 popup content */}
          <EditPopup4
            setEdit={setEdit14}
            setEditHover={setEditHover14}
            allowScroll={allowScroll}
            pitchDeck={true}
            orgId={id}
            startupName={startup.name}
            startupLogo={startup.logo}
            primaryColor={primaryColor}
            shouldApprove={shouldApprove}
          />
        </Popup>
      )}

      {/* Image cropper */}
      {showCropper && (
        <CropperPopup2
          image={image}
          setImage={setImage}
          onClick={uploadFile}
          onClick2={() => {
            setShowCropper(false);
            allowScroll();
          }}
          aspectRatio={2 / 3}
          scrollPosition={scrollPosition}
          noPadding={true}
        />
      )}

      {/* Snackbar */}
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
};

export default StartupEdit;
