import React, { useEffect, useState } from "react";
import { apiendpoint } from "../../../../components/helpers/apiendpoint";
import { useNavigate, useParams } from "react-router-dom";
import { Popup } from "../../../../components/lvl0Components/popup";
import { CropperPopup } from "../../../../components/lvl1Components/setupPopups/cropperPopup";

const StartupCreate = ({ i, handleNumDelete, setFetchMore }) => {
  const [org_member, setOrg_member] = useState({
    name: "",
    email: "",
    number: ""
  });
  const [org_name, setOrg_name] = useState("");
  let { id } = useParams();
  let jwt = localStorage.getItem("@jwtauth");
  if (!jwt) jwt = "";

  const handleSubmit = (e) => {
    e.preventDefault();
    let jwt = localStorage.getItem("@jwtauth");
    if (!jwt) jwt = "";

    fetch(`${apiendpoint}/event/referral-code?event_id=${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`
      },
      body: JSON.stringify({ org: [{ org_name, org_member }] })
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw new Error("Unauthorized");
      })
      .then((data) => {
        alert("Sent !!");
        setFetchMore(true);
        setOrg_name("");
        setOrg_member({
          name: "",
          email: "",
          number: ""
        });
      });
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", margin: "15px" }}>
      <div
        style={{
          height: "250px",
          width: "250px",
          border: "1px solid black",
          padding: "5px 5px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <h2>{i + 1} : Startup Details </h2>
        <form
          onSubmit={(e) => handleSubmit(e)}
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            justifyContent: "center",
            alignContent: "center",
            marginTop: "10px"
          }}
        >
          <input
            type="text"
            value={org_name}
            placeholder="Organization Name"
            onChange={(e) => setOrg_name(e.target.value)}
            required
            style={{ marginBottom: "5px", padding: "5px" }}
          />

          <input
            type="text"
            value={org_member.name}
            placeholder="Organization Member Name"
            onChange={(e) => setOrg_member({ ...org_member, name: e.target.value })}
            required
            style={{ marginBottom: "5px", padding: "5px" }}
          />

          <input
            type="number"
            value={org_member.number}
            placeholder="Organization Member Number"
            onChange={(e) => setOrg_member({ ...org_member, number: e.target.value })}
            required
            style={{ marginBottom: "5px", padding: "5px" }}
          />

          <input
            type="email"
            value={org_member.email}
            placeholder="Organization Member Email"
            onChange={(e) => setOrg_member({ ...org_member, email: e.target.value })}
            required
            style={{ marginBottom: "5px", padding: "5px" }}
          />

          <button type="submit" style={{ padding: "5px", cursor: "pointer" }}>
            Submit
          </button>
        </form>
      </div>
      <button style={{ height: "20px", width: "100%" }} onClick={handleNumDelete}>
        Delete
      </button>
    </div>
  );
};

const Invites = () => {
  const [eventData, setEventData] = useState("");
  const [startups, setStartups] = useState([]);
  const [details, setDetails] = useState({
    name: "",
    email: "",
    number: ""
  });
  const [guestEnter, setGuestEnter] = useState(false);
  const [guest, setGuest] = useState([]);
  // const [jurEnter, setJurEnter] = useState(false);
  const [fetchMore, setFetchMore] = useState(false);
  const [sponserAdd, setSponserAdd] = useState(false);
  const [eventInfoEdit, setEventInfoEdit] = useState(false);
  const [sponsors, setSponsors] = useState([]);
  const [image, setImage] = useState(null);
  const [showCropper, setShowCropper] = useState(false);

  let { id } = useParams();
  let jwt = localStorage.getItem("@jwtauth");
  if (!jwt) jwt = "";
  const navigate = useNavigate();

  // add sponsor
  const uploadFile = (videoImage) => {
    var jwt = localStorage.getItem("@jwtauth");
    if (!jwt) jwt = "";

    let sponsorUrl = "";
    if (videoImage.size / 1024 / 1024 <= 10) {
      const fd = new FormData();
      fd.append("file", videoImage);

      fetch(`${apiendpoint}/platform/eventgraphics`, {
        method: "POST",
        body: fd,
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      })
        .then((response) => {
          if (response.ok) return response.json();
          else {
            throw new Error("unauthorised");
          }
        })
        .then((json) => {
          sponsorUrl = json.url;
          setSponserAdd(false);
          setShowCropper(false);
        })
        .catch((error) => {
          setShowCropper(false);
        });
    } else {
      setShowCropper(false);
    }
  };

  //data of event
  const fetchData = () => {
    fetch(`${apiendpoint}/event?event_id=${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw new Error("Unauthorized");
      })
      .then((data) => {
        setGuest(data.eventGuest.guest);
        setSponsors(data.event.sponsors);
        setStartups(data.startups);
        setEventData(data.event.eventData);
      });
  };

  const handleSubmit = (e, type) => {
    e.preventDefault();
    let jwt = localStorage.getItem("@jwtauth");
    if (!jwt) jwt = "";

    fetch(`${apiendpoint}/event/referral-code?event_id=${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`
      },
      body: JSON.stringify({ guest: [details] })
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw new Error("Unauthorized");
      })
      .then((data) => {
        alert("Sent !!");
        setGuestEnter(false);
        setFetchMore(true);
        setDetails({
          name: "",
          email: "",
          number: ""
        });
      });
  };

  //fetch data after every change
  useEffect(() => {
    fetchData();
    setFetchMore(false);
  }, [fetchMore]);

  const handleNumDelete = (e) => {
    e.preventDefault();
  };

  const handleSpecialGuest = (memberId, type) => {
    fetch(`${apiendpoint}/event/members/${memberId}`, {
      method: `${type === "spl" ? "POST" : "DELETE"}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw new Error("Unauthorized");
      })
      .then((data) => {
        setFetchMore(true);
      });
  };

  // func to edit event details
  const handleEvent = () => {
    fetch(`${apiendpoint}/event/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`
      },
      body: JSON.stringify({ event_details: eventData })
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw new Error("Unauthorized");
      })
      .then((data) => {
        setFetchMore(true);
        setEventInfoEdit(false);
      });
  };

  return (
    <div
      style={{
        height: "100%",
        width: "100vw",
        backgroundColor: "white"
      }}
    >
      {showCropper && <CropperPopup image={image} onClick={uploadFile} setImage={setImage} />}

      {/* popup to enter guest  */}
      {guestEnter && (
        <Popup
          onClick={() => {
            setGuestEnter(false);
            // setJurEnter(false);
          }}
          noPadding={true}
        >
          <div
            style={{
              height: "250px",
              width: "400px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <h1>Guest Details : </h1>
            <form
              onSubmit={(e) => handleSubmit(e, "guest")}
              style={{
                display: "flex",
                flexDirection: "column",
                width: "50%",
                justifyContent: "center",
                alignContent: "center",
                marginTop: "10px"
              }}
            >
              <input
                type="text"
                value={details.name}
                placeholder={"Guest's Name"}
                onChange={(e) => setDetails({ ...details, name: e.target.value })}
                required
                style={{ marginBottom: "5px", padding: "5px" }}
              />

              <input
                type="number"
                value={details.number}
                placeholder={"Guest's Number"}
                onChange={(e) => setDetails({ ...details, number: e.target.value })}
                required
                style={{ marginBottom: "5px", padding: "5px" }}
              />

              <input
                type="email"
                value={details.email}
                placeholder={"Guest's Email Address"}
                onChange={(e) => setDetails({ ...details, email: e.target.value })}
                required
                style={{ marginBottom: "5px", padding: "5px" }}
              />

              <button type="submit" style={{ padding: "5px", cursor: "pointer" }}>
                Add
              </button>
            </form>
          </div>
        </Popup>
      )}

      {/* Event Details  */}
      <div style={{ padding: "5px" }}>
        <img
          src={eventData?.logo}
          style={{ borderRadius: "100%" }}
          height={150}
          width={150}
          alt=""
        />
        {!eventInfoEdit ? (
          <>
            <p style={{ fontWeight: "bold" }}>{eventData?.title}</p>
            <p style={{ fontWeight: "bold" }}>{eventData?.brief}</p>
            <p style={{ fontWeight: "bold" }}>{eventData?.location}</p>
            <p style={{ fontWeight: "bold" }}>{eventData?.starting_date}</p>
            <p style={{ fontWeight: "bold" }}>{eventData?.ending_date}</p>
            <button onClick={() => setEventInfoEdit(true)}>Edit Event Details</button>
          </>
        ) : (
          <div style={{ display: "flex", flexDirection: "column", width: "30%" }}>
            <input
              type="text"
              value={eventData?.title}
              onChange={(e) => setEventData({ ...eventData, title: e.target.value })}
            />
            <input
              type="text"
              value={eventData?.brief}
              onChange={(e) => setEventData({ ...eventData, brief: e.target.value })}
            />
            <input
              type="text"
              value={eventData?.location}
              onChange={(e) => setEventData({ ...eventData, location: e.target.value })}
            />
            <span>Starting Date</span>
            <input
              type="date"
              value={eventData?.starting_date}
              onChange={(e) => setEventData({ ...eventData, starting_date: e.target.value })}
            />
            <span>Ending Date</span>
            <input
              type="date"
              value={eventData?.ending_date}
              onChange={(e) => setEventData({ ...eventData, ending_date: e.target.value })}
            />
            <button onClick={() => handleEvent()}>submit</button>
          </div>
        )}

        <h1>Sponsors : </h1>
        <div>
          {sponsors?.map((e) => (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "150px",
                position: "relative"
              }}
            >
              <button style={{ position: "absolute", bottom: "0", right: "40%" }}>delete</button>
              <img src={e.photo} style={{ borderRadius: "100%" }} height={150} width={150} alt="" />
            </div>
          ))}
        </div>
        {sponserAdd ? (
          <div>
            <input
              type="file"
              onChange={(e) => {
                let files;
                if (e.dataTransfer) {
                  files = e.dataTransfer.files;
                } else if (e.target) {
                  files = e.target.files;
                }
                const reader = new FileReader();
                reader.onload = () => {
                  setImage(reader.result);
                };
                reader.readAsDataURL(files[0]);
                setShowCropper(true);
              }}
              style={{ marginBottom: "5px", padding: "5px" }}
            />
            <button onClick={() => setSponserAdd(false)}>Cancel</button>
          </div>
        ) : (
          <button onClick={() => setSponserAdd(true)}>Add Sponsors</button>
        )}
      </div>

      <div style={{ display: "flex", flexDirection: "column" }}>
        <h2>Guest Details : </h2>
        {guest.map((e, i) => (
          <div style={{ display: "flex", flexDirection: "column", margin: "5px", width: "25%" }}>
            <div
              style={{
                height: "100px",
                width: "auto",
                backgroundColor: "cyan",
                border: "1px solid black",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between"
              }}
            >
              <h2>{i + 1}</h2>
              <p>{e?.email_id}</p>
              <p>{e?.phone_no}</p>
              <p>Referral: {e?.referral_code}</p>
            </div>
            {e?.special_guest === "N" ? (
              <button style={{ height: "20px" }} onClick={() => handleSpecialGuest(e?.id, "spl")}>
                Add as Special Guest
              </button>
            ) : (
              <button style={{ height: "20px" }} onClick={() => handleSpecialGuest(e?.id, "spl")}>
                Remove as Special Guest
              </button>
            )}
            <button style={{ height: "20px" }} onClick={() => handleSpecialGuest(e?.id, "del")}>
              Delete
            </button>
          </div>
        ))}
        <button
          onClick={() => {
            setGuestEnter(true);
            window.scrollTo(0, 0);
          }}
          style={{ width: "100px" }}
        >
          Add More
        </button>
      </div>

      <h1>Startups :</h1>
      <button>Add more Startups</button>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {startups.map((e, i) => (
          <div style={{ display: "flex", flexDirection: "column", margin: "15px" }}>
            <div
              style={{
                height: "250px",
                width: "250px",
                padding: "5px 5px",
                backgroundColor: "cyan",
                border: "1px solid black",
                display: "flex",
                justifyContent: "space-between"
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <h2>{i + 1} : Startup Name </h2>
                <h2>{e.name}</h2>
              </div>
            </div>
            <button style={{ height: "20px" }} onClick={handleNumDelete}>
              Delete
            </button>
          </div>
        ))}
        {[...Array(eventData?.number_of_startups)].map((e, i) => (
          <>
            {i >= startups.length && (
              <StartupCreate i={i} setFetchMore={setFetchMore} handleNumDelete={handleNumDelete} />
            )}
          </>
        ))}
      </div>
    </div>
  );
};

export default Invites;
