import React, { useState } from "react";
import SrRefColour from "../../../ref/colours";
import { SrRefTextIntRe12, SrRefTextIntRe10, SrRefTextIntLi075 } from "../../../ref/text";
import { SrCompIconExpandMore } from "../../../components/lvl0Components/icons";
import style from "./style";

// List input for font Int12
const ListInputInt12 = (props) => {
  const [inputHover, setInputHover] = useState(false);
  const [inputClick, setInputClick] = useState(false);

  // Dynamic styling for this component
  const style2 = {
    outerDiv: {
      ...style.inputOuterDiv,
      border:
        inputHover || inputClick
          ? `1px solid ${SrRefColour.Neutral2A}`
          : `1px solid ${SrRefColour.Neutral2B}`
    }
  };
  return (
    <div>
      {/* Placeholder text at the top */}
      {/* {!props.topTextAlwaysOn && props.topText && !props.content ? (
        <div></div>
      ) : (
        <div style={style.textInputTop}>
          <SrRefTextIntLi075
            content={props.topText}
            state={
              !props.content && props.condition
                ? "enabled"
                : !props.content
                ? "disabled"
                : props.condition
                ? "pressed"
                : "enabled"
            }
          />
        </div>
      )} */}

      {/* List item */}
      <div
        onMouseEnter={() => setInputHover(true)}
        onMouseLeave={() => setInputHover(false)}
        onClick={props.onPress}
        style={style2.outerDiv}
      >
        {/* List item text */}
        <SrRefTextIntRe12
          content={!props.content ? props.placeholder : props.content}
          style={{
            color:
              !props.content && props.condition
                ? SrRefColour.Neutral2A
                : !props.content
                ? SrRefColour.Neutral2A
                : props.condition
                ? SrRefColour.Neutral1A
                : SrRefColour.Neutral2A
          }}
        />

        {/* Dropdown icon */}
        <SrCompIconExpandMore
          color={
            !props.content && props.condition
              ? SrRefColour.Neutral2A
              : !props.content
              ? SrRefColour.Neutral2A
              : props.condition
              ? SrRefColour.Neutral1A
              : SrRefColour.Neutral2A
          }
        />
      </div>
    </div>
  );
};

// List popup for font Int12
const ListPopupInt12 = (props) => {
  // List item for font Int12
  const ListItemInt12 = (params) => {
    const [inputHover, setInputHover] = useState(false);

    // Dynamic styling for this component
    const style2 = {
      innerDiv: {
        ...style.list,
        ...style.marginHor12,
        paddingBottom: params.id !== params.array.length - 1 ? 0 : "6px",
        borderWidth: params.id !== params.array.length - 1 ? "0 0 0.5px" : 0
      },
      inputStyle: {
        ...style.marginBot12,
        color:
          params.array[params.value] === params.ind || inputHover
            ? SrRefColour.Neutral1A
            : SrRefColour.Neutral2A
      }
    };

    return (
      <div
        onMouseEnter={() => setInputHover(true)}
        onMouseLeave={() => setInputHover(false)}
        onClick={() => {
          // +1 because array starts from 0 and industry are stored from 1 in db
          let value = params.setupIndustry ? params.id + 1 : params.id;
          params.setValue(value);
          params.setValueSelect(false);
          // If setting up startup industry through the setup guide
          params.setupIndustry &&
            params.setStartup({
              ...params.startup,
              industry: params.id + 1 // +1 becuase it is the index of array and we need to store the id from industry which starts from 0
            });
          // If setting up startup stage through the setup guide
          params.setupStage &&
            params.setStartup({
              ...params.startup,
              other: {
                ...params.startup.other,
                location: params.location,
                year: params.orgYear,
                stage: params.id,
                socialMedia: params.socialMedia,
                status: params.orgStatus
              }
            });

          params.setupStatus &&
            params.setStartup({
              ...params.startup,
              other: {
                ...params.startup.other,
                status: params.id
              }
            });
        }}
        style={style.outerDiv}
        key={params.id}
      >
        <div style={style2.innerDiv}>
          <SrRefTextIntRe10 content={params.ind} state={"enabled"} style={style2.inputStyle} />
        </div>
      </div>
    );
  };

  return (
    <div style={style.scrollWrapV}>
      {props.array.map((ind, id) => (
        <ListItemInt12
          ind={ind}
          id={id}
          array={props.array}
          value={props.value}
          setValue={props.setValue}
          setValueSelect={props.setValueSelect}
          // Useful for editing in the setup guide
          setStartup={props.setStartup}
          startup={props.startup}
          // If setting up startup industry through the setup guide
          setupIndustry={props.setupIndustry}
          // If setting up startup stage through the setup guide
          setupStage={props.setupStage}
          setupStatus={props.setupStatus}
          location={props.location}
          orgYear={props.orgYear}
          socialMedia={props.socialMedia}
          orgStatus={props.orgStatus}
        />
      ))}
    </div>
  );
};

export { ListInputInt12, ListPopupInt12 };
