import { useContext } from "react";
import MainContext from "../../../helpers/context/mainContext";
import OrgDetails from "../../OrgDetails";
import TeamDetails from "../../TeamDetails";
import OtherDetails from "../../OtherDetails";
import Problem from "../../Problem";
import Solution from "../../Solution";
import Infographic from "../../Infographic";
import Video from "../../Video";
import Features from "../../Features";
import Market from "../../Market";
import Traction from "../../Traction";
import Website from "../../Website";
import Challenges from "../../Challenges";
import MentorshipAreas from "../../MentorshipAreas";
import PitchDeck from "../../PitchDeck";
import style from "./style";

const EditComponent = ({ imageInputRef, setImage, setShowCropper }) => {
  const { startup, returnPrimary, halfWidth } = useContext(MainContext);

  // Dynamic style for this component
  const style2 = {
    outerWrap: {
      ...style.flexColumn,
      ...style.screen
    },
    TopSectionWrap: {
      ...style.flexColumn,
      ...style.marginHor36,
      ...{ marginTop: `${74 + 36 + 36 - 12}px` }
    },
    teamWrap: {
      ...style.flexRow,
      ...style.width100,
      ...style.minHeight151,
      ...style.marginTop12,
      ...style.marginHor12
    },

    bottomSectionWrap: {
      ...style.flexRow,
      ...style.spaceBetween,
      ...style.editBottom,
      ...style.marginTop24,
      ...style.marginHor48,
      borderColor: returnPrimary
    },
    bottomRightWrap: {
      ...style.flexColumn,
      ...style.spaceBetween,
      ...style.alignItemsFlexEnd,
      ...{ width: `${halfWidth}px` }
    },
    websiteWrap: {
      ...style.flexRowReverse,
      ...style.spaceBetween,
      ...style.alignItemsFlexEnd,
      width: `${halfWidth + 24}px`
    },
    bitsWrap: {
      ...style.flexRow,
      ...style.spaceBetween,
      ...style.bottom,
      ...{
        borderColor: returnPrimary
      },
      ...style.marginTop24,
      ...style.marginHor48
    },
    BITSLeftOuterDiv: {
      ...style.flexColumn,
      ...{ width: `${halfWidth + 24}px` }
    },
    BITSRightOuterDiv: {
      ...style.flexColumn,
      ...style.spaceBetween,
      ...style.alignItemsFlexEnd,
      ...{ width: `${halfWidth + 24}px` }
    },
    pitchWrap: {
      ...style.flexRowReverse,
      ...style.spaceBetween,
      ...style.alignItemsFlexEnd,
      ...{ width: `${halfWidth + 24}px` }
    }
  };

  return (
    <div style={style2.outerWrap}>
      {/* Startup listing */}
      <div style={style2.TopSectionWrap}>
        {/* Org details */}
        <OrgDetails />

        <div style={style2.teamWrap}>
          {/* Team */}
          <TeamDetails />

          {/* Other */}
          <OtherDetails />
        </div>
      </div>

      <div style={style2.bottomSectionWrap}>
        {/* Bottom left section */}
        <div
          style={{
            ...style.flexColumn,
            ...{ width: `${halfWidth + 24}px` }
          }}
        >
          {/* Problem */}
          <Problem />

          {/* Solution */}
          <Solution />

          {/* Features */}
          <Features />

          {/* Unique features */}
          <Traction />

          {/* Target market */}
          <Market />
        </div>

        {/* Bottom right section */}
        <div style={style2.bottomRightWrap}>
          {/* Infographic */}
          <Infographic
            w={halfWidth}
            startup={startup}
            imageInputRef={imageInputRef}
            setShowCropper={setShowCropper}
            setImage={setImage}
          />

          <div style={style2.websiteWrap}>
            {/* Website */}
            <Website />

            {/* Video */}
            <Video />
          </div>
        </div>
      </div>

      {/* BITS section */}
      <div style={style2.bitsWrap}>
        {/* BITS left section */}
        <div style={style2.BITSLeftOuterDiv}>
          {/* Challenges */}
          <Challenges />
        </div>

        {/* BITS right section */}
        <div style={style2.BITSRightOuterDiv}>
          {/* Mentorship areas */}
          <MentorshipAreas />

          <div style={style2.pitchWrap}>
            {/* Pitch deck */}
            <PitchDeck />
          </div>
        </div>
      </div>
    </div>
  );
};

const Component = () => {
  const { startup, returnPrimary, halfWidth } = useContext(MainContext);

  //Dynamic and combined styling for this component
  const style2 = {
    outerWrap: {
      ...style.flexColumn,
      ...style.screen
    },
    innerWrap: {
      ...style.flexColumn,
      ...style.marginHor48,
      ...{ marginTop: `${74 + 36 + 36}px` }
    },
    teamOuterWrap: {
      ...style.flexRow,
      ...style.spaceBetween,
      ...style.width100,
      ...style.minHeight127,
      ...style.marginTop36
    },

    mainSectionOuterWrap: {
      ...style.flexRow,
      ...style.spaceBetween,
      ...style.bottom,
      ...style.marginTop36,
      ...style.marginHor48,
      borderColor: returnPrimary
    },

    bottomLeftOuterWrap: {
      ...style.flexColumn,
      width: `${halfWidth}px`
    },

    bottomRightOuterWrap: {
      ...style.flexColumn,
      ...style.spaceBetween,
      ...style.alignItemsFlexEnd,
      width: `${halfWidth}px`
    },

    websiteOuterWrap: {
      ...style.flexRowReverse,
      ...style.spaceBetween,
      ...style.alignItemsFlexEnd,
      width: `${halfWidth}px`
    },

    BITSLeftOuterDiv: {
      ...style.flexColumn,
      ...style.spaceBetween,
      ...{ width: `${halfWidth}px` }
    },

    BITSRightOuterDiv: {
      ...style.flexColumn,
      ...style.spaceBetween,
      ...style.alignItemsFlexEnd,
      ...{ width: `${halfWidth}px` }
    }
  };

  return (
    <div style={style2.outerWrap}>
      {/* Startup listing */}
      <div style={style2.innerWrap}>
        {/* Org details */}
        <OrgDetails />

        <div style={style2.teamOuterWrap}>
          {/* Team */}
          <TeamDetails />

          {/* Other */}
          <OtherDetails />
        </div>
      </div>

      <div style={style2.mainSectionOuterWrap}>
        {/* Bottom left section */}
        <div style={style2.bottomLeftOuterWrap}>
          {/* Problem */}
          <Problem />

          {/* Solution */}
          <Solution />

          <div style={style.marginTop24}>
            {/* Features */}
            <Features />

            {/* Traction */}
            <Traction />

            {/* Target market */}
            <Market />
          </div>
        </div>

        {/* Bottom right section */}
        <div style={style2.bottomRightOuterWrap}>
          {/* Infographic */}
          <Infographic w={halfWidth} startup={startup} />

          <div style={style2.websiteOuterWrap}>
            {/* Website */}
            <Website />

            {/* Video */}
            <Video />
          </div>
        </div>
      </div>

      {/* BITS section */}
      <div style={style2.mainSectionOuterWrap}>
        {/* BITS left section */}
        <div style={style2.BITSLeftOuterDiv}>
          {/* Challenges */}
          <Challenges />
        </div>

        {/* BITS right section */}
        <div style={style2.BITSRightOuterDiv}>
          {/* Features */}
          <MentorshipAreas />

          {/* Website */}
          <PitchDeck />
        </div>
      </div>
    </div>
  );
};

const BigTablet = ({ imageInputRef, setImage, setShowCropper }) => {
  const { ifEdit } = useContext(MainContext);
  return ifEdit ? (
    <EditComponent
      imageInputRef={imageInputRef}
      setImage={setImage}
      setShowCropper={setShowCropper}
    />
  ) : (
    <Component />
  );
};

export default BigTablet;
