import { useContext } from "react";
import MainContext from "../../helpers/context/mainContext";
import WebsiteBtn from "../WebsiteBtn";
import style from "./style";

// EditComponent which is visible on the startupEdit page
const EditComponent = ({
  startup,
  primaryColor,
  invertBackground,
  editHover11,
  setEdit11,
  setAllHoverFalse,
  setEditHover11
}) => {
  return startup.pitch.website ? (
    <WebsiteBtn
      content={"Website"}
      startup={startup}
      primaryColor={primaryColor}
      invertBackground={invertBackground}
      setEdit11={setEdit11}
      setAllHoverFalse={setAllHoverFalse}
      editHover11={editHover11}
      setEditHover11={setEditHover11}
    />
  ) : (
    <WebsiteBtn
      content={"Your website"}
      startup={startup}
      primaryColor={primaryColor}
      invertBackground={invertBackground}
      setEdit11={setEdit11}
      setAllHoverFalse={setAllHoverFalse}
      editHover11={editHover11}
      setEditHover11={setEditHover11}
    />
  );
};

// Component which is visible on the startup page
const Component = ({ startup, primaryColor, invertBackground, setCardHover4, cardHover4 }) => {
  return startup.pitch.website ? (
    <a
      onMouseEnter={() => setCardHover4(true)}
      onMouseLeave={() => setCardHover4(false)}
      href={`${startup.pitch.website}`}
      target="_blank"
      rel="noopener noreferrer"
      style={style.linkStyle}
    >
      <WebsiteBtn
        startup={startup}
        primaryColor={primaryColor}
        invertBackground={invertBackground}
        cardHover4={cardHover4}
        content={"Website"}
      />
    </a>
  ) : (
    <div style={style.outerDiv}>
      <WebsiteBtn
        startup={startup}
        primaryColor={primaryColor}
        invertBackground={invertBackground}
        cardHover4={cardHover4}
        content={"Your website"}
      />
    </div>
  );
};

const Website = () => {
  // ifEdit state to check if user is on startup page or startupEdit page
  const {
    ifEdit,
    startup,
    primaryColor,
    invertBackground,
    setCardHover4,
    cardHover4,
    setEdit11,
    setAllHoverFalse,
    editHover11,
    setEditHover11
  } = useContext(MainContext);
  return ifEdit ? (
    <EditComponent
      startup={startup}
      primaryColor={primaryColor}
      invertBackground={invertBackground}
      setEdit11={setEdit11}
      setAllHoverFalse={setAllHoverFalse}
      editHover11={editHover11}
      setEditHover11={setEditHover11}
    />
  ) : (
    <Component
      startup={startup}
      primaryColor={primaryColor}
      invertBackground={invertBackground}
      setCardHover4={setCardHover4}
      cardHover4={cardHover4}
    />
  );
};

export default Website;
