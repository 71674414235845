import SrRefColour from "../../../../ref/colours";

const style = {
  scrollBox: {
    position: "relative",
    minHeight: "510px"
  },
  surfaceBorder: {
    borderRadius: "12px",
    backgroundColor: SrRefColour.Neutral0
  },
  cropperWrap: {
    height: "510px",
    borderRadius: "12px",
    backgroundColor: SrRefColour.Neutral0
  },
  cropper: {
    height: "510px",
    borderRadius: "12px",
    backgroundColor: SrRefColour.Neutral0
  },
  alignCenter: {
    display: "flex",
    alignItems: "center"
  },
  absolutePosition: { position: "absolute", top: 0, zIndex: 25 }
};

export default style;
