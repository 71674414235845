import { useContext } from "react";
import MainContext from "../../helpers/context/mainContext";

import { IndustryChip } from "../../lvl0Components/chips";
import { SectorChip } from "../../lvl0Components/chips";
import { Industries } from "../../helpers/localData";

import style from "./style";

const IndustryAndSectors = () => {
  // ifEdit state to check if user is on startup page or startupEdit page
  const { ifEdit, setEdit1, startup, primaryColor, accentColor, invertBackground } =
    useContext(MainContext);

  // Click function handler which runs if Edit state and opens Edit Popup
  const handleClick = (index) => {
    if (!ifEdit) return;
    setEdit1(index);
  };

  // Dynamic style for this component
  const style2 = {
    outerDiv: {
      ...style.outerDiv,
      marginLeft: ifEdit ? 0 : "21px"
    }
  };

  return (
    <div style={style2.outerDiv}>
      {/* Chip for industry */}
      <div onClick={() => handleClick(3)}>
        <IndustryChip
          industry={Industries[startup.industry] ? Industries[startup.industry - 1] : "Industry"}
          primaryColor={primaryColor}
          accentColor={accentColor}
          invertBackground={invertBackground}
        />
      </div>

      {/* Chip for sectors */}
      {startup.sectors[0] && startup.sectors[1]
        ? startup.sectors?.map((sector) => (
            <div onClick={() => handleClick(4)}>
              <SectorChip
                sector={sector}
                primaryColor={primaryColor}
                accentColor={accentColor}
                invertBackground={invertBackground}
              />
            </div>
          ))
        : ["Sector 1", "Sector 2"].map((sector) => (
            <SectorChip
              sector={sector}
              primaryColor={primaryColor}
              accentColor={accentColor}
              invertBackground={invertBackground}
            />
          ))}
    </div>
  );
};

export default IndustryAndSectors;
