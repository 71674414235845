import { useContext } from "react";
import MainContext from "../../../helpers/context/mainContext";

import OrgDetails from "../../OrgDetails";
import TeamDetails from "../../TeamDetails";
import OtherDetails from "../../OtherDetails";
import Problem from "../../Problem";
import Solution from "../../Solution";
import Infographic from "../../Infographic";
import Video from "../../Video";
import Features from "../../Features";
import Market from "../../Market";
import Traction from "../../Traction";
import Website from "../../Website";
import Challenges from "../../Challenges";
import MentorshipAreas from "../../MentorshipAreas";
import PitchDeck from "../../PitchDeck";

import style from "./style";

const EditComponent = ({ imageInputRef, setImage, setShowCropper }) => {
  const { startup, returnPrimary, oneThirdWidth, halfWidth } = useContext(MainContext);

  // Dynamic styling for this component
  const style2 = {
    outerDiv: {
      ...style.flexColumn,
      ...style.screen
    },
    innerDiv: {
      ...style.flexRow,
      ...style.marginHor36,
      ...{ marginTop: `${74 + 36 + 36 - 12}px` }
    },
    orgWrap: {
      ...style.flexRow,
      ...style.width50
    },
    teamWrap: {
      ...style.flexRow,
      ...style.spaceBetween,
      ...style.width50
    },
    bottomOuterDiiv: {
      ...style.flexRow,
      ...style.spaceBetween,
      ...style.bottom,
      ...{
        borderColor: returnPrimary
      },
      ...style.marginTop24,
      ...style.marginHor48
    },
    bottomInnerDiv: {
      ...style.flexColumn,
      ...style.spaceBetween,
      ...{ width: `${oneThirdWidth + 24}px` }
    },
    bottonRightWrap: {
      ...style.flexColumn,
      ...style.spaceBetween,
      ...style.alignItemsFlexEnd,
      ...{ width: `${oneThirdWidth + 24}px` }
    },
    bitsWrap: {
      ...style.flexRow,
      ...style.spaceBetween,
      ...style.bottom,
      ...{
        borderColor: returnPrimary
      },
      ...style.marginTop24,
      ...style.marginHor48
    },
    bitsRightWrap: {
      ...style.flexColumn,
      ...style.spaceBetween,
      ...style.alignItemsFlexEnd,
      ...{ width: `${halfWidth + 24}px` }
    },
    bitsLeftWrap: {
      ...style.flexColumn,
      ...style.spaceBetween,
      ...{ width: `${halfWidth + 24}px` }
    }
  };

  return (
    <div style={style2.outerDiv}>
      {/* Startup listing */}
      <div style={style2.innerDiv}>
        <div style={style2.orgWrap}>
          {/* Org details */}
          <OrgDetails />
        </div>

        <div style={style2.teamWrap}>
          {/* Team */}
          <TeamDetails />

          {/* Other */}
          <OtherDetails />
        </div>
      </div>

      <div style={style2.bottomOuterDiiv}>
        {/* Bottom left section */}
        <div style={style2.bottomInnerDiv}>
          <div>
            {/* Problem */}
            <Problem />

            {/* Solution */}
            <Solution />
          </div>

          <div style={style.marginTop24}>
            {/* Video */}
            <Video />
          </div>
        </div>

        {/* Bottom center section */}
        {/* Infographic */}
        <Infographic
          w={oneThirdWidth}
          startup={startup}
          imageInputRef={imageInputRef}
          setShowCropper={setShowCropper}
          setImage={setImage}
        />

        {/* Bottom right section */}
        <div style={style2.bottonRightWrap}>
          <div style={style.width100}>
            {/* Features */}
            <Features />

            {/* Unique features */}
            <Traction />

            {/* Target market */}
            <Market />
          </div>

          {/* Website */}
          <Website />
        </div>
      </div>

      {/* BITS section */}
      <div style={style2.bitsWrap}>
        {/* BITS left section */}
        <div style={style2.bitsLeftWrap}>
          {/* Challenges */}
          <Challenges />
        </div>

        {/* BITS right section */}
        <div style={style2.bitsRightWrap}>
          {/* Mentorship areas */}
          <MentorshipAreas />

          {/* Pitch deck */}
          <PitchDeck />
        </div>
      </div>
    </div>
  );
};

const Component = () => {
  // Dynamic styling for this component

  const {
    startup,
    primaryColor,
    secondaryColor,
    tertiaryColor,
    returnPrimary,
    cardHover4,
    setCardHover4,
    halfWidth,
    oneThirdWidth,
    invertBackground
  } = useContext(MainContext);

  const style2 = {
    outerWrap: {
      ...style.flexColumn,
      ...style.screen
    },
    innerWrap: {
      ...style.flexRow,
      ...style.marginHor48,
      marginTop: `${74 + 36 + 36}px`
    },

    teamOuterWrap: {
      ...style.flexRow,
      ...style.spaceBetween,
      ...style.width50
    },

    mainSectionOuterWrap: {
      ...style.flexRow,
      ...style.spaceBetween,
      ...style.bottom,
      ...style.marginTop36,
      ...style.marginBot36,
      ...style.marginHor48,
      borderColor: returnPrimary
    },

    bottomLeftOuterDiv: {
      ...style.flexColumn,
      ...style.spaceBetween,
      ...{ width: `${oneThirdWidth}px` }
    },

    bottomRightOuterDiv: {
      ...style.flexColumn,
      ...style.spaceBetween,
      ...style.alignItemsFlexEnd,
      ...{ width: `${oneThirdWidth}px` }
    },

    BITSLeftOuterDiv: {
      ...style.flexColumn,
      ...style.spaceBetween,
      ...{ width: `${halfWidth}px` }
    },

    BITSRightOuterDiv: {
      ...style.flexColumn,
      ...style.spaceBetween,
      ...style.alignItemsFlexEnd,
      ...{ width: `${halfWidth}px` }
    }
  };

  return (
    <div style={style2.outerWrap}>
      {/* Startup listing */}
      <div style={style2.innerWrap}>
        <div style={{ ...style.flexRow, ...style.width50 }}>
          {/* Org details */}
          <OrgDetails />
        </div>

        <div style={style2.teamOuterWrap}>
          {/* Team */}
          <TeamDetails />

          {/* Other */}
          <OtherDetails />
        </div>
      </div>

      <div style={style2.mainSectionOuterWrap}>
        {/* Bottom left section */}
        <div style={style2.bottomLeftOuterDiv}>
          <div style={{ ...style.flexColumn, ...style.width100 }}>
            {/* Problem */}
            <Problem />

            {/* Solution */}
            <Solution />
          </div>

          {/* Video */}
          <Video />
        </div>

        {/* Bottom center section */}
        {/* Infographic */}
        <Infographic w={oneThirdWidth} startup={startup} />

        {/* Bottom right section */}
        <div style={style2.bottomRightOuterDiv}>
          <div style={{ ...style.flexColumn, ...style.width100 }}>
            {/* Features */}
            <Features />

            {/* traction */}
            <Traction />

            {/* Target market */}
            <Market />
          </div>

          {/* Website */}
          <Website cardHover4={cardHover4} setCardHover4={setCardHover4} />
        </div>
      </div>

      {/* BITS section */}
      <div style={style2.mainSectionOuterWrap}>
        {/* BITS left section */}
        <div style={style2.BITSLeftOuterDiv}>
          {/* Challenges */}
          <Challenges
            startup={startup}
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
            invertBackground={invertBackground}
          />
        </div>

        {/* BITS right section */}
        <div style={style2.BITSRightOuterDiv}>
          {/* Features */}
          <MentorshipAreas
            startup={startup}
            primaryColor={primaryColor}
            tertiaryColor={tertiaryColor}
            invertBackground={invertBackground}
          />

          {/* Website */}
          <PitchDeck
            startup={startup}
            primaryColor={primaryColor}
            invertBackground={invertBackground}
            cardHover4={cardHover4}
            setCardHover4={setCardHover4}
          />
        </div>
      </div>
    </div>
  );
};

const Laptop = ({ imageInputRef, setImage, setShowCropper }) => {
  const { ifEdit } = useContext(MainContext);

  return ifEdit ? (
    <EditComponent
      imageInputRef={imageInputRef}
      setImage={setImage}
      setShowCropper={setShowCropper}
    />
  ) : (
    <Component />
  );
};

export default Laptop;
