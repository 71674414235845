import React, { useState, useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import SrRefColour from "../../../../ref/colours";
import { SrRefTextIntRe15, SrRefTextIntRe12, SrRefTextIntLi12 } from "../../../../ref/text";

import MainContext from "../../../helpers/context/mainContext";

import { BackFAB, NextFAB } from "../../../lvl0Components/buttons/FABs";
import { TextInputIntRe12 } from "../../../lvl0Components/textInputs";

import { style } from "./style";

const InvitePopup = (props) => {
  // Main context
  const {
    breakpoint3,
    scrollBoxWidth
    // API related,
  } = useContext(MainContext);
  const navigate = useNavigate();

  // Navigate to main startup listing
  const navigateToLogin = () => {
    navigate("/login");
  };

  // Navigate to main startup listing
  const navigateToLoginAsEM = () => {
    navigate("/login?em");
  };

  // Scroll view
  const [screen, setScreen] = useState(0);
  const scrollRef = useRef();

  useEffect(() => {
    scrollRef.current.scrollLeft = screen * scrollBoxWidth;
  }, [screen]);

  // Snackbar
  const snackbar = (message) => {
    toast(message, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
  };

  // useStates
  const [referralCode, setReferralCode] = useState("");
  const [referralCodeEdit, setReferralCodeEdit] = useState(false);

  // Six digit referral codes
  // const referralCodes = [
  //   "7MR0TM",
  //   "UVFF10",
  //   "2SMBK2",
  //   "TC31X4",
  //   "HV7GV0",
  //   "O7S1I8",
  //   "DQDFKM",
  //   "T9ZORE",
  //   "KUF9WQ",
  //   "09AP9L",
  //   "DM7OVG",
  //   "W19HBS",
  //   "161Q6V",
  //   "87GNR1",
  //   "XNRXYW",
  //   "S6YZGJ",
  //   "3E8W8N",
  //   "F00KQD",
  //   "7VGZCH",
  //   "MONFID",
  //   "8TW2CO",
  //   "LI2VIW",
  //   "XRYYVU",
  //   "GGVYI9",
  //   "5UZYXR",
  //   "8I8U1R",
  //   "PVU16S",
  //   "NKH7VV",
  //   "83V82V",
  //   "33VTSO",
  //   "DITSED",
  //   "0UXZE5",
  //   "H7THHI",
  //   "VSFOS5",
  //   "KNWQDA",
  //   "OD4A37",
  //   "2BI7CR",
  //   "EA51AG",
  //   "01169W",
  //   "G4T12F",
  //   "ADS8HA",
  //   "CN7MV2",
  //   "BBLTS1",
  //   "4Q6F08",
  //   "6XRHAB",
  //   "IEF51H",
  //   "9KBWLT",
  //   "Z16BK2",
  //   "VXOLIO",
  //   "VGHGEO"
  // ];

  // Does referral code validation
  const validateCode = () => {
    // Four digit referral codes
    const referralCodes = [
      "7M0T",
      "UVF1",
      "2SBK",
      "TC1X",
      "HVGV",
      "O71I",
      "DQFK",
      "T9OR",
      "KU9W",
      "09P9",
      "DMOV",
      "W1HB",
      "16Q6",
      "87NR",
      "XNXY",
      "S6ZG",
      "3EW8",
      "F0KQ",
      "7GZC",
      "MNFI",
      "8W2C",
      "L2VI",
      "XYYV",
      "GVYI",
      "5ZYX",
      "88U1",
      "PU16",
      "NH7V",
      "8V82",
      "3VTS",
      "DTSE",
      "0XZE",
      "HTHH",
      "VFOS",
      "KWQD",
      "O4A3",
      "BI7C",
      "A51A",
      "1169",
      "4T12",
      "DS8H",
      "N7MV",
      "BLTS",
      "Q6F0",
      "XRHA",
      "EF51",
      "KBWL",
      "16BK",
      "XOLI",
      "VHEO"
    ];
    if (referralCodes.includes(referralCode)) return true;
    return false;
  };

  // handleSubmit
  const handleSubmit = async () => {
    if (screen === 0 && referralCode === "")
      snackbar("Please enter your referral code to continue");
    // else if (validateCode()) navigate("/login");
    else if (validateCode()) {
      setScreen(screen + 1);
      setTimeout(() => {
        navigateToLoginAsEM();
      }, 2000);
    } else snackbar("Please enter a valid referral code to continue");
  };

  const Link = (props) => {
    const [linkHover, setLinkHover] = useState(false);

    // Dynamic style for this component
    const style2 = {
      textStyle: {
        ...style.textStyle,
        color: linkHover ? SrRefColour.Neutral2A : SrRefColour.Neutral1A,
        textDecoration: `underline ${linkHover ? SrRefColour.Neutral2A : SrRefColour.Neutral1A}`
      }
    };

    return (
      <div
        onMouseEnter={() => setLinkHover(true)}
        onMouseLeave={() => setLinkHover(false)}
        onClick={props.onClick}
        style={style.outerDiv}
      >
        <div style={style.flexRow}>
          <SrRefTextIntLi12 content={props.content1} />
          <SrRefTextIntLi12 content={props.content2} style={style2.textStyle} />
        </div>
      </div>
    );
  };

  //for dynamic styling
  const style2 = {
    backgroundWrap: {
      ...style.alignItemsCenter,
      justifyContent: breakpoint3 ? "none" : "center"
    },
    cardWrap: {
      ...style.cardWrap,
      width: `${scrollBoxWidth}px`,
      border: breakpoint3 ? "none" : `0.5px solid ${SrRefColour.Neutral2B}`,
      marginTop: breakpoint3 ? "24px" : 0
    },
    scrollViewContainer: {
      ...style.scrollViewContainer,
      width: `${scrollBoxWidth}px`
    },
    nameContainer: {
      ...style.paddingHor24,
      width: `${scrollBoxWidth}px`
    }
  };

  return (
    <div>
      {/* Background */}
      <div style={style2.backgroundWrap}>
        {/* Bordered card */}
        <div style={style2.cardWrap}>
          {/* Shram logo at the top */}
          <div style={style.logoOuterWrap}>
            <img src={require("../../../../media/logo.png")} height={50} alt={"Shram logo"} />
          </div>

          {/* Scroll view */}
          <div style={style2.scrollViewContainer} ref={scrollRef}>
            {/* Enter referral code screen */}
            <div style={style.scroll}>
              <div style={style2.nameContainer}>
                {/* Text */}
                <SrRefTextIntRe15
                  content={"Please enter your referral code"}
                  state={"enabled"}
                  style={style.colorBlack}
                />

                {/* Input for referral code */}
                <div style={style.inputWrap}>
                  <SrRefTextIntRe12
                    content={"platform-"}
                    state={"enabled"}
                    style={{ color: referralCode ? SrRefColour.Neutral1A : SrRefColour.Neutral2A }}
                  />

                  <div style={style.inputStyle}>
                    <TextInputIntRe12
                      type="text"
                      name="referralCode"
                      placeholder={"Referral Code"}
                      value={referralCode}
                      onChange={(e) => setReferralCode(e.target.value)}
                      onClick={() => {
                        setReferralCodeEdit(true);
                      }}
                      noLimit={true}
                    />
                  </div>
                </div>

                {/* <Link
                  onClick={() => {
                    props.setEdit(false);
                    props.setShowWaitlist(true);
                  }}
                  content1={"Don't have a code? "}
                  content2={"Request access"}
                /> */}

                <Link
                  onClick={navigateToLogin}
                  content1={"Don't have a code? "}
                  content2={"Login as a visitor"}
                />
              </div>
            </div>

            {/* AHA moment screen */}
            <div style={style.scroll}>
              <div style={style2.nameContainer}>
                <div style={style.imgWrap}>
                  {/* Cover image */}
                  <img
                    src={require("../../../../media/setup_guide_aha_2.png")}
                    width={180}
                    alt={"Waitlisted!"}
                  />

                  {/* Aha moment text */}
                  <SrRefTextIntRe15
                    content={"Welcome!"}
                    state={"enabled"}
                    style={{ ...style.marginTop24, ...style.textCenter, ...style.colorBlack }}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Back button */}
          {screen !== 1 && (
            <BackFAB
              onClick={() =>
                screen === 0
                  ? [props.setEdit(false), props.setEditHover(false), props.allowScroll()]
                  : setScreen((p) => p - 1)
              }
            />
          )}

          {/* Next button */}
          {screen !== 1 && <NextFAB content={"Submit"} onClick={() => handleSubmit()} />}
        </div>
      </div>

      {/* Snackbar */}
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
};

export default InvitePopup;
