import { useContext } from "react";
import MainContext from "../../../components/helpers/context/mainContext";

import { EditBtn0 } from "../../../components/lvl0Components/buttons/iconBtns";

import SrRefColour from "../../../ref/colours";
import { SrRefTextIntSb15 } from "../../../ref/text";
import { gradientBgTextBox, gradientBorderTextBox, gradientText } from "../../helpers/themeColors";
import { newLineText } from "../../helpers/themeColors";

import style from "./style";

// MainComponent which is rendered on startup page
// And Also renders on startupEdit page with some extra styling
const MainComponent = () => {
  // ifEdit state to check if user is on startup page or startupEdit page
  const { ifEdit, startup, tertiaryColor, primaryColor, invertBackground } =
    useContext(MainContext);

  // Dynamic styling for this component
  const style2 = {
    outerDiv: {
      ...style.outerDiv,
      ...gradientBgTextBox(tertiaryColor, primaryColor, 0.6, invertBackground),
      ...gradientBorderTextBox(tertiaryColor, primaryColor, invertBackground),
      marginTop: ifEdit ? "0px" : "24px"
    }
  };

  return (
    <div style={style2.outerDiv}>
      <SrRefTextIntSb15
        content={"Solution"}
        style={gradientText(tertiaryColor, primaryColor, invertBackground)}
      />

      {/* Solution text */}
      <div style={style.marginTop6}>
        {startup.pitch.solution
          ? newLineText(startup.pitch.solution, tertiaryColor, primaryColor, invertBackground)
          : newLineText(
              "Describe your value proposition briefly. Is it a product or a service? How does it deliver value to your customer?",
              tertiaryColor,
              primaryColor,
              invertBackground
            )}
      </div>
    </div>
  );
};

const Solution = () => {
  // ifEdit state to check if user is on startupEdit page pf startup Page
  const {
    ifEdit,
    startup,
    tertiaryColor,
    primaryColor,
    invertBackground,
    setEdit5,
    editHover5,
    setEditHover5,
    setAllHoverFalse
  } = useContext(MainContext);

  // Dynamic style for this component
  const style2 = {
    outerDiv: {
      ...style.editOuterDiv,
      backgroundColor: editHover5 && SrRefColour.Neutral1C
    },
    innerDiv: {
      ...style.editInnerDiv,
      border: `1px dotted ${SrRefColour.Neutral2B}`
    },
    editButton: {
      position: "absolute",
      top: "11px",
      right: "11px"
    }
  };

  return ifEdit ? (
    <div
      onClick={() => setEdit5(true)}
      onMouseEnter={() => [setAllHoverFalse(), setEditHover5(true)]}
      onMouseLeave={() => setEditHover5(false)}
      style={style2.outerDiv}
    >
      <div style={style2.innerDiv}>
        <MainComponent />

        <div style={style2.editButton}>
          <EditBtn0 selected={editHover5} />
        </div>
      </div>
    </div>
  ) : (
    <MainComponent />
  );
};

export default Solution;
