import { useContext } from "react";
import MainContext from "../../helpers/context/mainContext";

import { SrRefTextIntSb15 } from "../../../ref/text";
import { SrRefTextIntRe12 } from "../../../ref/text";
import SrRefColour from "../../../ref/colours";

import { EditBtn0 } from "../../lvl0Components/buttons/iconBtns";

import style from "./style";

// EditComponent which render on startupEdit page
const EditComponent = () => {
  const { startup, videoWidth, setEdit10, setAllHoverFalse, setEditHover10, editHover10 } =
    useContext(MainContext);

  return (
    <div
      onClick={() => setEdit10(true)}
      onMouseEnter={() => [setAllHoverFalse(), setEditHover10(true)]}
      onMouseLeave={() => setEditHover10(false)}
      style={{
        ...style.editContainer,
        backgroundColor: editHover10 && SrRefColour.Neutral1C
      }}
    >
      <div
        style={{
          ...style.editOuterWrap,
          border: `1px dotted ${SrRefColour.Neutral2B}`
        }}
      >
        <div
          style={{
            ...style.video,
            ...{ width: `${videoWidth}px`, height: `${(videoWidth * 2) / 3}px` },
            ...style.transition
          }}
        >
          {startup.pitch.video.thumbnail ? (
            <div style={style.positionRelative}>
              {/* Video overlay */}
              <div style={style.positionAbsolute}>
                <img
                  src={require("../../../media/video_overlay.png")}
                  width={videoWidth}
                  height={(videoWidth * 2) / 3}
                  alt={"Startup video"}
                  style={style.borderRadius12}
                />
              </div>
              {/* Video thumbnail image */}
              <img
                src={startup.pitch.video.thumbnail}
                width={videoWidth}
                height={(videoWidth * 2) / 3}
                alt={"Startup video"}
                style={style.borderRadius12}
              />
            </div>
          ) : (
            <div style={style.centeredTextWrap}>
              {/* Default video image with text */}
              <img
                src={require("../../../media/video_default.png")}
                width={videoWidth}
                height={(videoWidth * 2) / 3}
                alt={"Startup default video"}
                style={style.borderRadius12}
              />
              {/* Default video text */}
              <div style={style.centeredText}>
                <SrRefTextIntSb15 content={"Your video"} />
                <div style={style.marginTop6}>
                  <SrRefTextIntRe12 content={"A demo perhaps?"} />
                </div>
              </div>
            </div>
          )}
        </div>

        <div style={{ position: "absolute", top: "11px", right: "11px" }}>
          <EditBtn0 selected={editHover10} />
        </div>
      </div>
    </div>
  );
};

// Component which renders on startup screen
const Component = () => {
  const { startup, cardHover3, setCardHover3, videoWidth } = useContext(MainContext);

  // Dynamic styling for this component
  const style2 = {
    outerDiv: {
      ...style.outerDiv,
      opacity: cardHover3 ? 1 : 0.85
    }
  };

  return (
    startup.pitch.video.thumbnail &&
    startup.pitch.video.link && (
      <div style={style2.outerDiv}>
        {startup.pitch.video.thumbnail ? (
          <a
            onMouseEnter={() => setCardHover3(true)}
            onMouseLeave={() => setCardHover3(false)}
            href={`${startup.pitch.video.link}`}
            target="_blank"
            rel="noopener noreferrer"
            style={style.linkStyle}
          >
            <div style={style.positionAbsolute}>
              <img
                src={require("../../../media/video_overlay.png")}
                width={videoWidth}
                height={(videoWidth * 2) / 3}
                alt={"Startup default video"}
                style={style.borderRadius12}
              />
            </div>
            <img
              src={startup.pitch.video.thumbnail}
              width={videoWidth}
              height={(videoWidth * 2) / 3}
              alt={"Startup video"}
              style={style.borderRadius12}
            />
          </a>
        ) : (
          <div style={style.centeredTextWrap}>
            <img
              src={require("../../../media/video_default.png")}
              width={videoWidth}
              height={(videoWidth * 2) / 3}
              alt={"Startup default video"}
              style={style.borderRadius12}
            />
            <div style={style.centeredText}>
              <SrRefTextIntSb15 content={"Your video"} />
              <div style={style.marginTop6}>
                <SrRefTextIntRe12 content={"A demo perhaps?"} />
              </div>
            </div>
          </div>
        )}
      </div>
    )
  );
};

const Video = () => {
  // State to check if user is on startup page or startupEdit pae
  const { ifEdit } = useContext(MainContext);

  return ifEdit ? <EditComponent /> : <Component />;
};

export default Video;
