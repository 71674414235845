import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import MainState from "./components/helpers/context/mainState";

import Routes from "./routes";
import "./App.css";

function App() {
  return (
    <div className="App">
      <MainState>
        <Router>
          <Routes />
        </Router>
      </MainState>
    </div>
  );
}

export default App;
