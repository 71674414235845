import React from "react";
import {
  SrRefTextIntSb10,
  SrRefTextIntRe10,
  SrRefTextIntRe09,
  SrRefTextIntRe12
} from "../../../ref/text";
import {
  white,
  concatColorMixer,
  gradientText,
  gradientBgTextBox
} from "../../helpers/themeColors";
import style from "./style";
import { StatusArr } from "../../helpers/localData";

// Chip for industry
const IndustryChip = (props) => {
  // Dynamic style for this component
  const style2 = {
    outerDiv: {
      ...style.industryOuterDiv,
      backgroundColor: props.accentColor
        ? concatColorMixer(
            [props.accentColor.r, props.accentColor.g, props.accentColor.b],
            white,
            props.accentColor.a
          )
        : concatColorMixer(
            [props.primaryColor.r, props.primaryColor.g, props.primaryColor.b],
            white,
            props.primaryColor.a
          )
    }
  };

  return (
    <div style={style2.outerDiv}>
      <SrRefTextIntRe09
        content={props.industry}
        style={gradientText(props.accentColor, props.primaryColor)}
      />
    </div>
  );
};

// Chip for sector
const SectorChip = (props) => {
  // Dyanmic style for this component
  const style2 = {
    outerDiv: {
      ...style.sectorOuterDiv,
      backgroundColor: props.accentColor
        ? concatColorMixer(
            [props.accentColor.r, props.accentColor.g, props.accentColor.b],
            white,
            props.accentColor.a * 0.5
          )
        : concatColorMixer(
            [props.primaryColor.r, props.primaryColor.g, props.primaryColor.b],
            white,
            props.primaryColor.a * 0.5
          )
    }
  };

  return (
    <div style={style2.outerDiv}>
      <SrRefTextIntRe09
        content={props.sector}
        style={gradientText(props.accentColor, props.primaryColor)}
      />
    </div>
  );
};

// Chip for BITS
const BITSChip = (props) => {
  // Dyanmic style for this component
  const style2 = {
    outerDiv: {
      ...style.BITSOuterDiv,
      backgroundColor: props.color2
        ? concatColorMixer(
            [props.color2.r, props.color2.g, props.color2.b],
            white,
            props.color2.a * 0.5
          )
        : concatColorMixer(
            [props.color1.r, props.color1.g, props.color1.b],
            white,
            props.color1.a * 0.5
          )
    }
  };

  return (
    <div style={style2.outerDiv}>
      <SrRefTextIntRe12 content={props.content} style={gradientText(props.color2, props.color1)} />
    </div>
  );
};

// Chip for status
const StatusChip = (props) => {
  // Dynamic styles for this component
  const style2 = {
    outerDiv: {
      ...style.statusOuterDiv,
      ...gradientBgTextBox(props.accentColor, props.primaryColor, 0.75)
    },
    textStyle: {
      ...style.marginLeft6,
      ...gradientText(props.accentColor, props.primaryColor)
    }
  };

  return (
    <div style={style2.outerDiv}>
      <SrRefTextIntSb10
        content={
          props.status && props.status !== -1 ? `${StatusArr[props.status]}` : "Funding status"
        }
        style={gradientText(props.accentColor, props.primaryColor)}
      />

      <SrRefTextIntRe10 content={"at present"} style={style2.textStyle} />
    </div>
  );
};

export { IndustryChip, SectorChip, BITSChip, StatusChip };
