import React from "react";
import { SrRefTextIntRe08 } from "../../../ref/text";
import style from "./style";

// To check the number of characters entry
const SizeLimit = (props) => {
  return (
    props.isShown === true && (
      <div>
        <SrRefTextIntRe08
          content={`${props.lengthOfDesc}/${props.lengthLimit}`}
          style={style.componentColor}
        />
      </div>
    )
  );
};

export { SizeLimit };
