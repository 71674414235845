import React from "react";

import SrRefColour from "../../../ref/colours";
import {
  SrRefTextRubSb25,
  SrRefTextIntSb20,
  SrRefTextIntSb15,
  SrRefTextIntRe15,
  SrRefTextIntLi12,
  SrRefTextIntMe10,
  SrRefTextIntLi10
} from "../../../ref/text";

import { style } from "./style";

const Prototype = () => {
  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;
  const maxLargeCardWidth = 960;
  const gap = 18;
  const maxViewportWidth = 1368;
  const maxViewportHeight = screenWidth < screenHeight ? (maxLargeCardWidth * 2) / 3 : screenHeight;

  const minSmallCardWidth = (maxViewportWidth - maxLargeCardWidth) / 2 - gap * 2; // 136px

  const largeCardWidth =
    screenWidth > maxLargeCardWidth + minSmallCardWidth * 2 ? maxLargeCardWidth : screenWidth;

  const smallCardWidth =
    screenWidth > maxLargeCardWidth + minSmallCardWidth * 2
      ? (screenWidth - gap * 4 - largeCardWidth) / 2
      : 0;

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        width: "100vw",
        height: "100vh",
        padding: `${gap}px`,
        backgroundColor: "white"
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: `${gap}px`,
          width: "100%",
          height: "100%"
          // maxHeight: `${maxViewportHeight}px`
          // backgroundColor: "aliceblue"
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            gap: `${screenWidth > maxLargeCardWidth + minSmallCardWidth * 2 ? gap : 0}px`,
            width: "100%",
            height: "calc(100% * 7 / 12)"
          }}
        >
          <div
            style={{
              width: `${smallCardWidth}px`,
              height: `${(smallCardWidth * 4) / 5}px`,
              borderRadius: "12px",
              backgroundColor: "lightblue"
            }}
          ></div>

          <div
            style={{
              width: `${largeCardWidth}px`,
              height: "100%",
              borderRadius: "12px",
              backgroundColor: "skyblue"
            }}
          ></div>

          <div
            style={{
              width: `${smallCardWidth}px`,
              height: `${(smallCardWidth * 4) / 5}px`,
              borderRadius: "12px",
              backgroundColor: "lightblue"
            }}
          ></div>
        </div>

        <div
          style={{
            width: "100%",
            maxWidth: `${largeCardWidth}px`,
            height: "calc(100% * 5 / 12)",
            borderRadius: "12px",
            backgroundColor: "blue"
          }}
        ></div>
      </div>
    </div>
  );
};

export default Prototype;
