const style = {
  marginHor3: { margin: "0 3px" },
  marginTop6: {
    marginTop: "6px"
  },

  chips: {
    display: "flex",
    flexWrap: "wrap",
    gap: "12px",
    marginTop: "6px"
  },

  // Custom styling for this component
  textBox: {
    display: "flex",
    flexDirection: "column",
    padding: "12px 9px",
    borderRadius: "12px"
  },
  width100: {
    width: "100%"
  },

  // Custom styling for this component on edit state
  editOuterDiv: {
    width: "100%",
    borderRadius: "18px",
    padding: "3px",
    cursor: "pointer",
    textDecoration: "none",
    transition: "0.2s ease-in"
  },
  editInnerDiv: {
    borderRadius: "16px",
    position: "relative",
    padding: "8px",
    cursor: "pointer",
    textDecoration: "none",
    transition: "0.2s ease-in"
  }
};

export default style;
