import React, { useState } from "react";

import SrRefColour from "../../../../ref/colours";
import {
  SrRefTextIntSb12,
  SrRefTextIntRe12,
  SrRefTextIntMe10,
  SrRefTextIntRe10
} from "../../../../ref/text";

import {
  white,
  rgbToHex,
  colorMixer,
  getTextColor,
  gradientText,
  gradientBgTextBox,
  gradientBorderTextBox
} from "../../../helpers/themeColors";

import {
  SrCompIconAdd,
  SrCompIconArrowBack,
  SrCompIconContentCopy,
  SrCompIconCalendarViewMonth,
  SrCompIconVisibility
} from "../../../lvl0Components/icons";

import style from "./style";

// Login button
const LoginBtn = (props) => {
  const [btnHover, setBtnHover] = useState(false);

  const style2 = {
    loginTextContainer: {
      ...style.loginTextContainer,
      borderColor: btnHover ? SrRefColour.Neutral1A : SrRefColour.Neutral2A
    }
  };

  return (
    <div
      onMouseEnter={() => setBtnHover(true)}
      onMouseLeave={() => setBtnHover(false)}
      onClick={props.onClick}
      style={style.clickable}
    >
      <div style={style2.loginTextContainer}>
        {/* Login text */}
        <SrRefTextIntMe10
          content={`Continue to ${props.text}`}
          state={"enabled"}
          style={style.colorBlack}
        />

        {/* Arrow icon */}
        <div style={style.arrowContainer}>
          <div style={style.invertIcon}>
            <SrCompIconArrowBack size={24} />
          </div>
        </div>
      </div>
    </div>
  );
};

const ShareInviteBtn = (props) => {
  const [btnHover, setBtnHover] = useState(false);

  // Dynamic styling for this component
  const style2 = {
    outerDiv: {
      ...style.shareOuterDiv,
      ...gradientBgTextBox(props.color, "", btnHover ? 1 : 0.8, "N"),
      ...gradientBorderTextBox(props.color, "", "N")
    },
    inputStyles: {
      ...style.clickable,
      ...gradientText(props.color, "", props.startup.theme.invertBackground)
    }
  };

  return (
    <div
      onMouseEnter={() => setBtnHover(true)}
      onMouseLeave={() => setBtnHover(false)}
      onClick={() => {
        // Copy text to clipboard
        navigator.clipboard.writeText(
          `Join ${props.startup.name} on Shram's Growth Platform by using this invite link https://platform.shram.io/login?inviteCode=${props.startup.inviteCode}`
        );
        props.snackbar("Invite code copied to clipboard");
      }}
      style={style2.outerDiv}
    >
      {/* Invite code text */}
      <SrRefTextIntRe12
        content={props.content ? props.content : `shram-${props.startup.inviteCode}`}
        style={style2.inputStyles}
      />

      {/* Copy icon */}
      <div style={style.marginLeft12}>
        <SrCompIconContentCopy
          size={24}
          color={getTextColor(
            rgbToHex(
              colorMixer([props.color.r, props.color.g, props.color.b], white, props.color.a)
            ),
            props.startup.theme.invertBackground
          )}
        />
      </div>
    </div>
  );
};

// Call to action button
const CTABtn = (props) => {
  const [btnHover, setBtnHover] = useState(false);

  // Dynamic styling
  const style2 = {
    outerDiv: {
      ...style.ctaOuterDiv,
      padding: "2px",
      border: btnHover ? "1px solid rgba(0,0,0,1)" : "1px solid rgba(0,0,0,0)",
      borderRadius: "14px"
    }
  };

  return (
    <div
      onMouseEnter={() => setBtnHover(true)}
      onMouseLeave={() => setBtnHover(false)}
      onClick={props.onClick}
      style={style.clickable}
    >
      <div style={style2.outerDiv}>
        <div style={style.CTAInnerDiv}>
          {/* CTA text */}
          <SrRefTextIntRe12 content={`${props.text}`} state={"enabled"} style={style.colorWhite} />

          {/* Arrow icon */}
          <div style={style.arrowContainer}>
            <div style={style.invertIcon}>
              <SrCompIconArrowBack size={24} color={"#ffffff"} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Add new button
const AddNewBtn = (props) => {
  const [btnHover, setBtnHover] = useState(false);

  return (
    <div
      onMouseEnter={() => setBtnHover(true)}
      onMouseLeave={() => setBtnHover(false)}
      onClick={props.onClick}
      style={style.clickable}
    >
      <div
        style={{
          ...style.flexRow,
          ...style.alignItemsCenter,
          ...style.button2,
          ...{
            borderColor: btnHover ? "rgba(74, 161, 59, 0.6)" : "rgba(74, 161, 59, 0.3)"
          },
          ...style.transition
        }}
      >
        {/* Login text */}
        <SrRefTextIntSb12
          content={`Add a new ${props.text}`}
          state={"enabled"}
          style={style.colorBlack}
        />

        {/* Arrow icon */}
        <div style={{ ...style.icon, ...style.marginLeft6 }}>
          <div style={style.invertIcon}>
            <SrCompIconAdd size={24} />
          </div>
        </div>
      </div>
    </div>
  );
};

// Event choice button
const EventChoiceBtn = (props) => {
  const [btnHover, setBtnHover] = useState(false);

  return (
    <div
      onMouseEnter={() => setBtnHover(true)}
      onMouseLeave={() => setBtnHover(false)}
      onClick={props.onClick}
      style={style.clickable}
    >
      <div
        style={{
          ...style.flexRow,
          ...style.alignItemsCenter,
          ...style.button3,
          ...style.transition,
          ...{
            borderColor: btnHover || props.pressed ? SrRefColour.Neutral1A : SrRefColour.Neutral2A,
            backgroundColor:
              btnHover || props.pressed ? SrRefColour.Neutral1B : SrRefColour.Neutral1C
          }
        }}
      >
        {/* Login text */}
        <SrRefTextIntRe10 content={`${props.text}`} state={"enabled"} style={style.colorBlack} />

        {/* Arrow icon */}
        <div style={{ ...style.icon20, ...style.marginLeft6 }}>
          {props.type === "invite" ? (
            <SrCompIconCalendarViewMonth size={20} />
          ) : (
            <SrCompIconVisibility size={20} />
          )}
        </div>
      </div>
    </div>
  );
};

export { LoginBtn, ShareInviteBtn, CTABtn, AddNewBtn, EventChoiceBtn };
