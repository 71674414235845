import React, { useState, useContext, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";

import SrRefColour from "../../../../ref/colours";
import { SrRefTextRubSb25, SrRefTextIntRe15 } from "../../../../ref/text";

import { apiendpoint } from "../../../helpers/apiendpoint";
import MainContext from "../../../helpers/context/mainContext";
import { returnRGBA } from "../../../helpers/themeColors";

import { Avatar } from "../../../lvl0Components/avatar";
import { BackFAB, NextFAB } from "../../../lvl0Components/buttons/FABs";
import { TextAreaIntRe12 } from "../../../lvl0Components/textAreas";

import { style } from "./style";

const EditPopup5 = (props) => {
  // Main context
  const {
    breakpoint3,
    scrollBoxWidth,
    // API related,
    userStartup,
    startup,
    setStartup
  } = useContext(MainContext);

  // Startup theme colours
  const primaryColor = props?.primaryColor;
  const returnPrimary = returnRGBA(primaryColor?.r, primaryColor?.g, primaryColor?.b, 1);

  // useStates
  const [challenge1, setChallenge1] = useState(startup.pitch.challenge1);
  const [challenge1Edit, setChallenge1Edit] = useState(false);
  const [challenge2, setChallenge2] = useState(startup.pitch.challenge2);
  const [challenge2Edit, setChallenge2Edit] = useState(false);
  const [challenge3, setChallenge3] = useState(startup.pitch.challenge3);
  const [challenge3Edit, setChallenge3Edit] = useState(false);

  const saveData = () => {
    const body = {
      challenge1,
      challenge2,
      challenge3
    };
    var jwt = localStorage.getItem("@jwtauth");
    if (!jwt) jwt = "";

    const id = Number(window.location.pathname.split("/")[3]);

    var link = `${apiendpoint}/platform/phase3/${id}`;

    fetch(link, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`
      },
      body: JSON.stringify(body)
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw new Error("Unauthorized");
      })
      .then((json) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    return saveData;
  }, [challenge1, challenge2, challenge3]);

  //for dynamic styling
  const style2 = {
    backgroundWrap: {
      ...style.alignItemsCenter,
      justifyContent: breakpoint3 ? "none" : "center"
    },
    cardWrap: {
      ...style.cardWrap,
      width: `${scrollBoxWidth}px`,
      border: breakpoint3 ? "none" : `0.5px solid ${SrRefColour.Neutral2B}`,
      marginTop: breakpoint3 ? "24px" : 0
    },

    scrollViewContainer: {
      ...style.scrollViewContainer,
      width: `${scrollBoxWidth}px`
    },
    nameContainer: {
      ...style.paddingHor24,
      width: `${scrollBoxWidth}px`
    }
  };

  return (
    <div>
      {/* Background */}
      <div style={style2.backgroundWrap}>
        {/* Bordered card */}
        <div style={style2.cardWrap}>
          {/* Shram logo at the top */}
          <div style={style.logoOuterWrap}>
            <div style={style.avatarWrap}>
              {/* User org logo */}
              <Avatar pic={props.startupLogo} width={50} borderColor={returnPrimary} />

              {/* User org name */}
              <SrRefTextRubSb25 content={props.startupName} style={style.marginLeft12} />
            </div>
          </div>

          {/* Scroll view */}
          <div style={style2.scrollViewContainer}>
            {/* Enter key challenges screen */}
            <div style={style.scroll}>
              <div style={style2.nameContainer}>
                {/* Text */}
                <SrRefTextIntRe15
                  content={"Your key challenges"}
                  state={"enabled"}
                  style={style.colorBlack}
                />

                <div style={style.vpScrollable}>
                  {/* Input for challenge1 link */}
                  <div style={style.inputWrap1}>
                    <div style={style.inputStyle}>
                      <TextAreaIntRe12
                        isShown={true}
                        limit="100"
                        name="challenge1"
                        placeholder={"Describe your first challenge briefly."}
                        value={challenge1}
                        onChange={(e) => {
                          if (e.target.value.length < 101) {
                            setChallenge1(e.target.value);

                            setStartup({
                              ...startup,
                              pitch: {
                                ...startup.pitch,
                                ...{
                                  challenge1: e.target.value.trim()
                                }
                              }
                            });
                          }
                        }}
                        onClick={() => setChallenge1Edit(true)}
                        rows={3}
                      />
                    </div>
                  </div>

                  {/* Input for challenge2 link */}
                  <div style={style.inputWrap1}>
                    <div style={style.inputStyle}>
                      <TextAreaIntRe12
                        isShown={true}
                        limit="100"
                        name="challenge2"
                        placeholder={"Describe your second challenge briefly."}
                        value={challenge2}
                        onChange={(e) => {
                          if (e.target.value.length < 101) {
                            setChallenge2(e.target.value);

                            setStartup({
                              ...startup,
                              pitch: {
                                ...startup.pitch,
                                ...{
                                  challenge2: e.target.value.trim()
                                }
                              }
                            });
                          }
                        }}
                        onClick={() => setChallenge2Edit(true)}
                        rows={3}
                      />
                    </div>
                  </div>

                  {/* Input for challenge3 link */}
                  <div style={style.inputWrap1}>
                    <div style={style.inputStyle}>
                      <TextAreaIntRe12
                        isShown={true}
                        limit="100"
                        name="challenge3"
                        placeholder={"Describe your third challenge briefly."}
                        value={challenge3}
                        onChange={(e) => {
                          if (e.target.value.length < 101) {
                            setChallenge3(e.target.value);

                            setStartup({
                              ...startup,
                              pitch: {
                                ...startup.pitch,
                                ...{
                                  challenge3: e.target.value.trim()
                                }
                              }
                            });
                          }
                        }}
                        onClick={() => setChallenge3Edit(true)}
                        rows={3}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Back button */}
          <BackFAB
            onClick={() => {
              props.setEdit(false), props.setEditHover(false), props.allowScroll();
            }}
          />

          {/* Next button */}
          <NextFAB
            content={"Done"}
            onClick={() => {
              props.setEdit(false), props.setEditHover(false), props.allowScroll();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default EditPopup5;
