import SrRefColour from "../colours.js";
import { fsControl } from "../../components/helpers/sizeController";

// Text styles for various states
const textStates = {
  enabled: {
    color: SrRefColour.Neutral2A
  },
  disabled: {
    color: SrRefColour.Neutral2B
  },
  focussed: {
    color: SrRefColour.Neutral1A
  },
  pressed: {
    color: SrRefColour.Neutral1A
  }
};

// Rubik font weights
const RubikFontWeights = {
  Sb: {
    fontFamily: "Rubik",
    fontWeight: "600"
  },
  Re: {
    fontFamily: "Rubik",
    fontWeight: "400"
  }
};

// Rubik font sizes
const RubikFontSizes = {
  fs48: {
    fontSize: fsControl(4.8)
  },
  fs42: {
    fontSize: fsControl(4.2)
  },
  fs36: {
    fontSize: fsControl(3.6)
  },
  fs30: {
    fontSize: fsControl(3)
  },
  fs25: {
    fontSize: fsControl(2.5)
  },
  fs18: {
    fontSize: fsControl(1.8)
  },
  fs15: {
    fontSize: fsControl(1.5)
  },
  fs12: {
    fontSize: fsControl(1.2)
  },
  fs10: {
    fontSize: fsControl(1)
  }
};

// Inter font weights
const InterFontWeights = {
  Sb: {
    fontFamily: "Inter",
    fontWeight: "600"
  },
  Me: {
    fontFamily: "Inter",
    fontWeight: "500"
  },
  Re: {
    fontFamily: "Inter",
    fontWeight: "400"
  },
  Li: {
    fontFamily: "Inter",
    fontWeight: "300"
  }
};

// Inter font sizes
const InterFontSizes = {
  fs30: {
    fontSize: fsControl(3)
  },
  fs27: {
    fontSize: fsControl(2.7)
  },
  fs25: {
    fontSize: fsControl(2.5)
  },
  fs20: {
    fontSize: fsControl(2.0)
  },
  fs15: {
    fontSize: fsControl(1.5)
  },
  fs12: {
    fontSize: fsControl(1.2)
  },
  fs10: {
    fontSize: fsControl(1)
  },
  fs09: {
    fontSize: fsControl(0.9)
  },
  fs08: {
    fontSize: fsControl(0.8)
  },
  fs075: {
    fontSize: fsControl(0.75)
  },
  fs07: {
    fontSize: fsControl(0.7)
  },
  fs065: {
    fontSize: fsControl(0.65)
  },
  fs06: {
    fontSize: fsControl(0.6)
  }
};

export { textStates, RubikFontWeights, RubikFontSizes, InterFontWeights, InterFontSizes };
