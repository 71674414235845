import React, { useState } from "react";
import SrRefColour from "../../../ref/colours";
import { SrRefTextIntSb15, SrRefTextIntRe12 } from "../../../ref/text";
import { SrCompIconEdit } from "../../../components/lvl0Components/icons";
import style from "./style";

// Avatar for big image
const Avatar = (props) => {
  const style2 = {
    outerDiv: {
      ...style.pic,
      ...props.style,
      width: `${props.width + 5}px`,
      height: `${props.width + 5}px`,
      borderRadius: `${props.width + 5}px`,
      borderColor: props.borderColor
    },
    imageStyle: {
      width: `${props.width}px`,
      height: `${props.width}px`,
      borderRadius: `${props.width}px`
    }
  };

  return (
    <div style={style2.outerDiv} key={props.key} onClick={props.onClick}>
      <img src={props.pic} width={props.width} style={style2.imageStyle} alt={"Picture"} />
    </div>
  );
};

// Avatar for big image
const AvatarUpload = (props) => {
  const [btnHover, setBtnHover] = useState(false);

  // For dynamic styling
  const style2 = {
    outerDiv: {
      ...style.positionAbsolute,
      bottom: `${props.innerWidth}px`,
      right: `${props.innerWidth}px`
    },

    innerDiv: {
      ...style.avatarUpInnerDiv,
      borderColor: btnHover ? SrRefColour.Neutral2A : SrRefColour.Neutral2B,
      backgroundColor: btnHover ? SrRefColour.Neutral1C : SrRefColour.Neutral0
    }
  };

  return (
    <div
      onMouseEnter={() => setBtnHover(true)}
      onMouseLeave={() => setBtnHover(false)}
      onClick={() => {
        props.imageInputRef.current.click();
      }}
      style={style.clickable}
    >
      <div style={style.positionRelative}>
        {/* Avatar */}
        <Avatar width={props.width} pic={props.pic} borderColor={SrRefColour.Neutral2B} />

        <div style={style2.outerDiv}>
          {/* Edit button */}
          <div style={style2.innerDiv}>
            {/* Input */}
            <input
              type="file"
              ref={props.imageInputRef}
              onChange={props.onChange}
              style={{ display: "none" }}
            />

            {/* Edit icon */}
            <SrCompIconEdit size={20} color={SrRefColour.Neutral2A} />
          </div>
        </div>
      </div>
    </div>
  );
};

// Avatar for big image
const RectUpload = (props) => {
  const [btnHover, setBtnHover] = useState(false);

  // Dynamic styling for this component
  const style2 = {
    outerDiv: {
      ...style.reactUpOuterDiv,
      backgroundColor: btnHover && SrRefColour.Neutral1C
    },
    innerDiv: {
      ...style.reactUpInnerDiv,
      border: `1px dotted ${SrRefColour.Neutral2B}`
    },
    containerDiv: {
      ...style.reactUpContainerDiv,
      width: `${props.width}px`,
      height: `${props.height}px`
    },
    editButton: {
      ...style.reactUpEditBtn,
      borderColor: btnHover ? SrRefColour.Neutral2A : SrRefColour.Neutral2B,
      backgroundColor: btnHover ? SrRefColour.Neutral1C : SrRefColour.Neutral0
    }
  };

  return (
    <div
      onMouseEnter={() => setBtnHover(true)}
      onMouseLeave={() => setBtnHover(false)}
      onClick={() => {
        props.imageInputRef.current.click();
      }}
      style={style.clickable}
    >
      {/* Avatar */}
      <div style={style2.outerDiv}>
        <div style={style2.innerDiv}>
          <div style={style2.containerDiv}>
            {props.infographic && !props.pic ? (
              // If it is an infographic without an uploaded image
              <div style={style.centeredTextWrap}>
                <img
                  src={
                    props.pic
                      ? props.pic
                      : props.infographic
                      ? require("../../../media/image_default.png")
                      : require("../../../media/video_default.png")
                  }
                  width={props.width}
                  height={props.height}
                  alt={"Picture"}
                  style={style.borderRadius12}
                />

                {/* Overlay text */}
                <div style={style.centeredText}>
                  <SrRefTextIntSb15 content={"Your infographic"} />
                  <div style={style.marginTop6}>
                    <SrRefTextIntRe12
                      content={
                        "Use this image to emphasize on your unique features, your product line, etc. Image should be less than 10mb"
                      }
                    />
                  </div>
                </div>
              </div>
            ) : !props.pic ? (
              // If it is not an infographic there is no thumnail
              <div style={style.centeredTextWrap}>
                <img
                  src={
                    props.pic
                      ? props.pic
                      : props.infographic
                      ? require("../../../media/image_default.png")
                      : require("../../../media/video_default.png")
                  }
                  width={props.width}
                  height={props.height}
                  alt={"Picture"}
                  style={style.borderRadius12}
                />

                {/* Overlay text */}
                <div style={style.centeredText2}>
                  <div style={style.marginTop6}>
                    <SrRefTextIntRe12 content={"Image should be less than 10mb "} />
                  </div>
                </div>
              </div>
            ) : (
              // If it is not an infographic and there is a thumnail
              <img
                src={
                  props.pic
                    ? props.pic
                    : props.infographic
                    ? require("../../../media/image_default.png")
                    : require("../../../media/video_default.png")
                }
                width={props.width}
                height={props.height}
                alt={"Picture"}
                style={style.borderRadius12}
              />
            )}
          </div>

          <div style={style.editWrap}>
            {/* Edit button */}
            <div style={style2.editButton}>
              {/* Input */}
              <input
                type="file"
                ref={props.imageInputRef}
                onChange={props.onChange}
                style={{ display: "none" }}
              />

              {/* Edit icon */}
              <SrCompIconEdit size={20} color={SrRefColour.Neutral2A} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Avatar, AvatarUpload, RectUpload };
