import React, { useState, useContext, useRef, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import SrRefColour from "../../../../ref/colours";
import { SrRefTextRubSb25, SrRefTextIntRe15, SrRefTextIntRe10 } from "../../../../ref/text";

import { apiendpoint } from "../../../helpers/apiendpoint";
import MainContext from "../../../helpers/context/mainContext";
import { returnRGBA } from "../../../helpers/themeColors";

import { AvatarUpload } from "../../../lvl0Components/avatar";
import { BackFAB, NextFAB } from "../../../lvl0Components/buttons/FABs";
import { Dot } from "../../../lvl0Components/dot";
import { TextInputIntRe12 } from "../../../lvl0Components/textInputs";
import { CropperPopup } from "../cropperPopup";

import { style } from "./style";

const SpeakerPopup = (props) => {
  // Main context
  const {
    breakpoint3,
    scrollBoxWidth,
    // API related,
    user,
    userStartup
  } = useContext(MainContext);

  // Scroll view
  const [screen, setScreen] = useState(0);
  const scrollRef = useRef();

  useEffect(() => {
    scrollRef.current.scrollLeft = screen * scrollBoxWidth;
  }, [screen]);

  // Snackbar
  const snackbar = (message) => {
    toast(message, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
  };

  // Startup theme colours
  // const primaryColor =
  //   user.userType === "emp" ? userStartup.theme?.primary.color : "rgba(200,200,200,1)";
  const primaryColor = "rgba(200,200,200,1)";
  const returnPrimary = returnRGBA(primaryColor.r, primaryColor.g, primaryColor.b, 1);

  // useStates
  // console.log(props.data, "data");
  const [pp, setPP] = useState(props.data?.image);
  const [loading, setLoading] = useState(false);

  // Image
  const imageInputRef = useRef();
  const [image, setImage] = useState(null);
  const [showCropper, setShowCropper] = useState(false);

  const [eventData, setEventData] = useState({
    name: "",
    phone_no: "",
    email: ""
  });

  const uploadFile = (image) => {
    var jwt = localStorage.getItem("@jwtauth");
    if (!jwt) jwt = "";

    let imgUrl = "";
    if (image.size / 1024 / 1024 <= 10) {
      const fd = new FormData();
      fd.append("file", image);
      fetch(`${apiendpoint}/platform/eventgraphics`, {
        method: "POST",
        body: fd,
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      })
        .then((response) => {
          if (response.ok) return response.json();
          else {
            throw new Error("unauthorised");
          }
        })
        .then((json) => {
          setPP(json.url);

          imgUrl = json.url;
          if (!props.speakerAdd)
            fetch(`${apiendpoint}/event/speaker/${props.data.id}`, {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${jwt}`
              },
              body: JSON.stringify({
                image: imgUrl
              })
            })
              .then((res) => {
                if (res.ok) return res.json();
                else throw new Error("Unauthorized");
              })
              .then((data) => {
                props.setFetchMore(true);
              });
          setShowCropper(false);
          snackbar("Image uploaded successfully");
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setShowCropper(false);
          snackbar("An error occurred, please try again.");
          setLoading(false);
        });
    } else {
      setShowCropper(false);
      snackbar("Image size should be less than 10mb");
    }
  };

  // func to edit user
  const editUser = () => {
    var jwt = localStorage.getItem("@jwtauth");
    if (!jwt) jwt = "";

    fetch(`${apiendpoint}/event/speaker/${props.data.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`
      },
      body: JSON.stringify(eventData)
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw new Error("Unauthorized");
      })
      .then((data) => {
        snackbar("Admins updated");
        props.setFetchMore(true);
        props.setSpeakerEdit(-1);
      });
  };

  const handleAddUser = () => {
    var jwt = localStorage.getItem("@jwtauth");
    if (!jwt) jwt = "";

    fetch(`${apiendpoint}/community/addAdmin/${props.eventId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`
      },
      body: JSON.stringify({
        user: {
          name: eventData.name,
          email: eventData.email,
          phone_no: eventData.phone_no
        }
      })
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw new Error("Unauthorized");
      })
      .then((data) => {
        snackbar("Admin added");
        props.setFetchMore(true);
        props.setSpeakerEdit(-1);
        props.setSpeakerAdd(false);
      });
  };

  // handleSubmit
  const handleSubmit = async () => {
    if (screen === 0 && !eventData.name) snackbar("Please write the speaker's name");
    else if (screen === 1 && (!eventData.phone_no || !eventData.email))
      snackbar("Please enter admin's user name and email address!");
    else if (screen === 1) {
      setScreen(screen + 1);
      setTimeout(() => {
        if (props.speakerAdd) handleAddUser();
        else editUser();
        setScreen(0);
        props.setSpeakerEdit(false);
      }, 1000);
    } else setScreen(screen + 1);
  };

  return (
    <div>
      {/* Background */}
      <div
        style={{
          ...style.alignItemsCenter,
          ...{ justifyContent: breakpoint3 ? "none" : "center" }
        }}
      >
        {/* Bordered card */}
        <div
          style={{
            ...style.surfaceBorder,
            ...style.scrollBox,
            ...{
              width: `${scrollBoxWidth}px`,
              border: breakpoint3 ? "none" : `0.5px solid ${SrRefColour.Neutral2B}`,
              marginTop: breakpoint3 ? "24px" : 0
            }
          }}
        >
          {/* Event Title at the top */}
          <div
            style={{
              ...style.height50,
              ...style.marginTop48,
              ...style.marginBot72,
              ...style.marginHor24
            }}
          >
            {user.userType === "emp" ? (
              <div
                style={{
                  ...style.flexRow,
                  ...style.alignItemsCenter
                }}
              >
                {/* Event Title */}
                <SrRefTextRubSb25 content={"The Rise Submmit"} style={style.marginLeft12} />
              </div>
            ) : (
              // Shram logo at the top
              <div
                style={{
                  ...style.height50,
                  ...style.marginTop48,
                  ...style.marginBot72,
                  ...style.marginHor24
                }}
              >
                <img src={require("../../../../media/logo.png")} height={50} alt={"Shram logo"} />
              </div>
            )}
          </div>

          {/* Scroll view */}
          <div
            style={{
              ...style.scrollWrap,
              ...{
                width: `${scrollBoxWidth}px`,
                height: "316px"
              },
              ...style.marginTop48
            }}
            ref={scrollRef}
          >
            {/* Enter name screen */}
            <div style={style.scroll}>
              <div style={{ ...{ width: `${scrollBoxWidth}px` }, ...style.paddingHor24 }}>
                {/* Text */}
                <SrRefTextIntRe15
                  content={"Admin's full name"}
                  state={"enabled"}
                  style={style.colorBlack}
                />

                {/* Input for speaker's name */}
                <div style={style.marginTop18}>
                  <TextInputIntRe12
                    type="text"
                    name="name"
                    isShown={true}
                    limit="30"
                    placeholder={"Admin's full name"}
                    topText={"Name"}
                    value={eventData?.name}
                    onChange={(e) => {
                      if (e.target.value.length < 31) {
                        setEventData({ ...eventData, name: e.target.value });
                      }
                    }}
                  />
                </div>
              </div>
            </div>

            {/* Speaker's image */}
            {/* <div style={style.scroll}>
              <div style={{ ...{ width: `${scrollBoxWidth}px` }, ...style.paddingHor24 }}>
              
                <SrRefTextIntRe15
                  content={"Speaker's profile picture"}
                  state={"enabled"}
                  style={style.colorBlack}
                />

               
                <div
                  style={{
                    ...style.justifyContentCenter,
                    ...style.marginTop24
                  }}
                >
                  <AvatarUpload
                    width={122}
                    pic={props.data?.image ? props.data?.image : pp}
                    imageInputRef={imageInputRef}
                    onChange={(e) => {
                      let files;
                      if (e.dataTransfer) {
                        files = e.dataTransfer.files;
                      } else if (e.target) {
                        files = e.target.files;
                      }
                      const reader = new FileReader();
                      reader.onload = () => {
                        console.log(reader.result);
                        setImage(reader.result);
                      };
                      reader.readAsDataURL(files[0]);
                      setShowCropper(true);
                    }}
                    innerWidth={6}
                    type={"pic"}
                  />
                </div>
              </div>
            </div> */}

            {/* Designation */}
            <div style={style.scroll}>
              <div style={{ ...{ width: `${scrollBoxWidth}px` }, ...style.paddingHor24 }}>
                {/* Admin's phone number */}
                <SrRefTextIntRe15
                  content={"Admin's phone number"}
                  state={"enabled"}
                  style={style.colorBlack}
                />

                <div style={style.marginTop18}>
                  <TextInputIntRe12
                    type="number"
                    name="phone_no"
                    isShown={true}
                    limit="10"
                    placeholder={"Phone number"}
                    topText={"Designation"}
                    value={eventData?.phone_no}
                    onChange={(e) => {
                      if (e.target.value.length < 11) {
                        setEventData({ ...eventData, phone_no: e.target.value });
                      }
                    }}
                  />
                </div>

                {/* Admin's email address */}
                <SrRefTextIntRe15
                  content={"Admins Email Address"}
                  state={"enabled"}
                  style={style.colorBlack}
                />

                <div style={style.marginTop18}>
                  <TextInputIntRe12
                    type="text"
                    name="designation"
                    isShown={true}
                    limit="50"
                    placeholder={"Email Address"}
                    topText={"Designation"}
                    value={eventData?.email}
                    onChange={(e) => {
                      if (e.target.value.length < 51) {
                        setEventData({ ...eventData, email: e.target.value });
                      }
                    }}
                  />
                </div>
              </div>
              {/* 

                
                <div style={style.marginTop18}>
                  <TextInputIntRe12
                    type="text"
                    name="url"
                    isShown={true}
                    limit="100"
                    placeholder={"Speaker's LinkedIn URL"}
                    topText={"LinkedIn"}
                    value={eventData?.url}
                    onChange={(e) => {
                      if (e.target.value.length < 101) {
                        setEventData({ ...eventData, url: e.target.value });
                      }
                    }}
                  />
                </div>
              </div> */}
            </div>
          </div>

          {/* Back button */}
          <BackFAB
            onClick={() =>
              screen === 0
                ? [
                    props.setSpeakerAdd(false),
                    props.setSpeakerEdit(-1),
                    props.setEditHover(false),
                    props.allowScroll()
                  ]
                : setScreen((p) => p - 1)
            }
          />

          {/* Dot carousal */}
          <div style={style.dotArray}>
            <Dot screen={screen} actualScreen={0} />
            <Dot screen={screen} actualScreen={1} />
          </div>

          {/* Next button */}
          <NextFAB content={screen === 1 ? "Done" : "Next"} onClick={() => handleSubmit()} />

          {/* Image cropper */}
          {showCropper && <CropperPopup image={image} onClick={uploadFile} setImage={setImage} />}
        </div>
      </div>

      {/* Snackbar */}
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
};

export default SpeakerPopup;
