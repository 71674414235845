import { useContext } from "react";
import MainContext from "../../helpers/context/mainContext";

import { EditBtn0 } from "../../lvl0Components/buttons/iconBtns";
import { BITSChip } from "../../lvl0Components/chips";

import SrRefColour from "../../../ref/colours";
import { SrRefTextIntRe12, SrRefTextIntSb15 } from "../../../ref/text";
import { gradientBorderTextBox } from "../../helpers/themeColors";

import style from "./style";

// "Outline the three main obstacles and limitations hindering the development of your product or service."

const newLineText2 = (props, color1, color2, invertBackground) => {
  const newText = props
    ? props
        .split("\n")
        .map((str) =>
          str ? <SrRefTextIntRe12 content={str} style={{ color: "black" }} /> : <br />
        )
    : [];

  return newText;
};

const MainComponent = () => {
  const { startup, secondaryColor, primaryColor, invertBackground } = useContext(MainContext);

  // Dynamic styling for this component
  const style2 = {
    container: {
      ...style.textBox,
      ...gradientBorderTextBox(secondaryColor, primaryColor, invertBackground),
      ...style.width100,
      backgroundColor: SrRefColour.Neutral0
    }
  };

  return (
    <div style={style2.container}>
      <div style={style.marginHor3}>
        <SrRefTextIntSb15 content={"Key challenges"} style={{ color: "black" }} />
      </div>

      {/* Challenge default text */}
      <div style={style.marginTop6}>
        {!(startup.pitch.challenge1 || startup.pitch.challenge2 || startup.pitch.challenge3) &&
          newLineText2(
            "Outline the three main obstacles and limitations hindering the development of your product or service.",
            secondaryColor,
            primaryColor,
            true
          )}
      </div>

      {/* Challenge text */}
      <div style={style.chips}>
        {/* Chip for challenges */}
        {startup.pitch.challenge1 && (
          <BITSChip
            content={startup.pitch.challenge1 || "Key challenge 1"}
            color1={primaryColor}
            color2={secondaryColor}
            invertBackground={invertBackground}
          />
        )}

        {startup.pitch.challenge2 && (
          <BITSChip
            content={startup.pitch.challenge2 || "Key challenge 2"}
            color1={primaryColor}
            color2={secondaryColor}
            invertBackground={invertBackground}
          />
        )}

        {startup.pitch.challenge3 && (
          <BITSChip
            content={startup.pitch.challenge3 || "Key challenge 3"}
            color1={primaryColor}
            color2={secondaryColor}
            invertBackground={invertBackground}
          />
        )}
      </div>
    </div>
  );
};

const Challenges = () => {
  // Edit state to check if user is on startupEdit page
  const { ifEdit, setEdit12, editHover12, setEditHover12, setAllHoverFalse } =
    useContext(MainContext);

  // Dynamic style for this component
  const style2 = {
    continer: {
      ...style.editOuterDiv,
      backgroundColor: editHover12 && SrRefColour.Neutral1C
    },
    innerContainer: {
      ...style.editInnerDiv,
      border: `1px dotted ${SrRefColour.Neutral2B}`
    },
    editButton: {
      position: "absolute",
      top: "11px",
      right: "11px"
    }
  };

  return ifEdit ? (
    <div
      onClick={() => setEdit12(true)}
      onMouseEnter={() => [setAllHoverFalse(), setEditHover12(true)]}
      onMouseLeave={() => setEditHover12(false)}
      style={style2.continer}
    >
      <div style={style2.innerContainer}>
        <MainComponent />

        <div style={style2.editButton}>
          <EditBtn0 selected={editHover12} />
        </div>
      </div>
    </div>
  ) : (
    <MainComponent />
  );
};

export default Challenges;
