import React, { useState, useContext, useRef, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import SrRefColour from "../../../../ref/colours";
import { SrRefTextRubSb25, SrRefTextIntRe15 } from "../../../../ref/text";

import { apiendpoint } from "../../../helpers/apiendpoint";
import MainContext from "../../../helpers/context/mainContext";
import { returnRGBA } from "../../../helpers/themeColors";

import { AvatarUpload, RectUpload } from "../../../lvl0Components/avatar";
import { BackFAB, NextFAB } from "../../../lvl0Components/buttons/FABs";
import { Dot } from "../../../lvl0Components/dot";
import { TextAreaIntRe12 } from "../../../lvl0Components/textAreas";
import { TextInputIntRe12 } from "../../../lvl0Components/textInputs";
import { CropperPopup } from "../cropperPopup";

import { style } from "./style";

const EventPopup = (props) => {
  // Main context
  const {
    breakpoint3,
    scrollBoxWidth,
    // API related,
    user
  } = useContext(MainContext);

  // Scroll view
  const [screen, setScreen] = useState(0);
  const scrollRef = useRef();

  useEffect(() => {
    scrollRef.current.scrollLeft = screen * scrollBoxWidth;
  }, [screen]);

  // Snackbar
  const snackbar = (message) => {
    toast(message, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
  };
  // Startup theme colours
  // const primaryColor =
  //   user.userType === "emp" ? userStartup.theme?.primary.color : "rgba(200,200,200,1)";
  const primaryColor = "rgba(200,200,200,1)";
  const returnPrimary = returnRGBA(primaryColor.r, primaryColor.g, primaryColor.b, 1);

  // useStates
  const [pp, setPP] = useState(props.eventData.logo);
  const [coverPP, setCoverPP] = useState(props.eventData.cover);
  const [loading, setLoading] = useState(false);

  // Image
  const imageInputRef = useRef();
  const coverImageRef = useRef();
  const [image, setImage] = useState(null);
  const [coverImage, setCoverImage] = useState(null);
  const [showCropper, setShowCropper] = useState(false);
  const [showCoverImageCropper, setCoverCropper] = useState(false);

  const uploadFile = (image) => {
    var jwt = localStorage.getItem("@jwtauth");
    if (!jwt) jwt = "";

    let imgUrl = "";
    if (image.size / 1024 / 1024 <= 10) {
      const fd = new FormData();
      fd.append("file", image);
      fetch(`${apiendpoint}/platform/eventgraphics`, {
        method: "POST",
        body: fd,
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      })
        .then((response) => {
          if (response.ok) return response.json();
          else {
            throw new Error("unauthorised");
          }
        })
        .then((json) => {
          setCoverPP(json.url);

          imgUrl = json.url;
          fetch(`${apiendpoint}/community/${props.eventId}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwt}`
            },
            body: JSON.stringify({
              event_details: { logo: imgUrl }
            })
          })
            .then((res) => {
              if (res.ok) return res.json();
              else throw new Error("Unauthorized");
            })
            .then((data) => {
              props.setFetchMore(true);
            });
          setShowCropper(false);
          snackbar("Image uploaded successfully");
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setShowCropper(false);
          snackbar("An error occurred, please try again.");
          setLoading(false);
        });
    } else {
      setShowCropper(false);
      snackbar("Image size should be less than 10mb");
    }
  };

  const uploadCoverImage = (image) => {
    var jwt = localStorage.getItem("@jwtauth");
    if (!jwt) jwt = "";

    let imgUrl = "";
    if (image.size / 1024 / 1024 <= 10) {
      const fd = new FormData();
      fd.append("file", image);
      fetch(`${apiendpoint}/platform/eventgraphics`, {
        method: "POST",
        body: fd,
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      })
        .then((response) => {
          if (response.ok) return response.json();
          else {
            throw new Error("unauthorised");
          }
        })
        .then((json) => {
          setCoverPP(json.url);

          imgUrl = json.url;
          fetch(`${apiendpoint}/community/${props.eventId}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwt}`
            },
            body: JSON.stringify({
              event_details: { cover: imgUrl }
            })
          })
            .then((res) => {
              if (res.ok) return res.json();
              else throw new Error("Unauthorized");
            })
            .then((data) => {
              props.setFetchMore(true);
            });
          setCoverCropper(false);
          snackbar("Image uploaded successfully");
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setCoverCropper(false);
          snackbar("An error occurred, please try again.");
          setLoading(false);
        });
    } else {
      setCoverCropper(false);
      snackbar("Image size should be less than 10mb");
    }
  };

  const editUser = () => {
    var jwt = localStorage.getItem("@jwtauth");
    if (!jwt) jwt = "";

    fetch(`${apiendpoint}/community/${props.eventId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`
      },
      body: JSON.stringify({ event_details: props.eventData })
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw new Error("Unauthorized");
      })
      .then((data) => {
        props.setFetchMore(true);
        props.setEventInfoEdit(false);
      });
  };

  //   useEffect(() => {
  //     return editUser;
  //   }, [fName, lName, pp, emailId, phoneNoWA, designation]);

  // handleSubmit
  const handleSubmit = async () => {
    if (screen === 0 && props.eventData.title === "")
      snackbar("Please write your title of the event");
    else if (screen === 1 && !props.eventData.logo) snackbar("Please add your logo");
    // else if (screen === 2 && !props.eventData.event_start_date)
    //   snackbar("Please add event start date");
    // else if (screen === 2 && !props.eventData.event_end_date) snackbar("Please add event end date");
    // else if (screen === 2 && !props.eventData.location) snackbar("Please add location");
    else if (screen === 3 && props.eventData.brief === "") snackbar("Please brief about the event");
    else if (screen === 3) {
      setScreen(screen + 1);
      setTimeout(() => {
        editUser();
        setScreen(0);
        props.setEdit(false);
      }, 1000);
    } else setScreen(screen + 1);
  };

  return (
    <div>
      {/* Background */}
      <div
        style={{
          ...style.alignItemsCenter,
          ...{ justifyContent: breakpoint3 ? "none" : "center" }
        }}
      >
        {/* Bordered card */}
        <div
          style={{
            ...style.surfaceBorder,
            ...style.scrollBox,
            ...{
              width: `${scrollBoxWidth}px`,
              border: breakpoint3 ? "none" : `0.5px solid ${SrRefColour.Neutral2B}`,
              marginTop: breakpoint3 ? "24px" : 0
            }
          }}
        >
          {/* Event Title at the top */}
          <div
            style={{
              ...style.height50,
              ...style.marginTop48,
              ...style.marginBot72,
              ...style.marginHor24
            }}
          >
            {!pp ? (
              <div
                style={{
                  ...style.flexRow,
                  ...style.alignItemsCenter
                }}
              >
                {/* Event Title */}
                <SrRefTextRubSb25 content={props.eventData?.name} />
              </div>
            ) : (
              // Shram logo at the top
              <div
                style={{
                  ...style.height50,
                  ...style.marginTop48,
                  ...style.marginBot72
                  // ...style.marginHor24
                }}
              >
                <img src={pp} height={50} alt={"Community logo"} />
              </div>
            )}
          </div>

          {/* Scroll view */}
          <div
            style={{
              ...style.scrollWrap,
              ...{
                width: `${scrollBoxWidth}px`,
                height: "316px"
              },
              ...style.marginTop48
            }}
            ref={scrollRef}
          >
            {/* Enter title screen */}
            <div style={style.scroll}>
              <div style={{ ...{ width: `${scrollBoxWidth}px` }, ...style.paddingHor24 }}>
                {/* Text */}
                <SrRefTextIntRe15
                  content={"Enter the name of your community?"}
                  state={"enabled"}
                  style={style.colorBlack}
                />

                {/* Input for title of event*/}
                <div style={style.marginTop18}>
                  <TextInputIntRe12
                    type="text"
                    name="title"
                    isShown={true}
                    limit="30"
                    placeholder={"Community name"}
                    topText={"Community name"}
                    value={props.eventData?.name}
                    onChange={(e) => {
                      if (e.target.value.length < 31) {
                        props.setEventData({ ...props.eventData, name: e.target.value });
                      }
                    }}
                  />
                </div>
              </div>
            </div>

            {/* Upload Event Logo screen */}
            <div style={style.scroll}>
              <div style={{ ...{ width: `${scrollBoxWidth}px` }, ...style.paddingHor24 }}>
                {/* Text */}
                <SrRefTextIntRe15
                  content={"Upload your profile picture"}
                  state={"enabled"}
                  style={style.colorBlack}
                />

                {/* Image upload avatar */}
                <div
                  style={{
                    ...style.justifyContentCenter,
                    ...style.marginTop24
                  }}
                >
                  <AvatarUpload
                    width={122}
                    pic={props.eventData.logo ? props.eventData.logo : pp}
                    imageInputRef={imageInputRef}
                    onChange={(e) => {
                      let files;
                      if (e.dataTransfer) {
                        files = e.dataTransfer.files;
                      } else if (e.target) {
                        files = e.target.files;
                      }
                      const reader = new FileReader();
                      reader.onload = () => {
                        setImage(reader.result);
                      };
                      reader.readAsDataURL(files[0]);
                      setShowCropper(true);
                    }}
                    innerWidth={6}
                  />
                </div>
              </div>
            </div>

            {/* Community brief  */}
            <div style={style.scroll}>
              <div style={{ ...{ width: `${scrollBoxWidth}px` }, ...style.paddingHor24 }}>
                {/* Text */}
                <SrRefTextIntRe15
                  content={"Describe your community briefly"}
                  state={"enabled"}
                  style={style.colorBlack}
                />

                <div style={style.marginTop18}>
                  <TextAreaIntRe12
                    isShown={true}
                    limit="500"
                    name="brief"
                    placeholder={"Community brief"}
                    value={props.eventData?.description}
                    onChange={(e) => {
                      if (e.target.value.length < 501) {
                        props.setEventData({ ...props.eventData, description: e.target.value });
                      }
                    }}
                    rows={4}
                  />
                </div>
              </div>
            </div>

            {/* Upload Community cover */}
            <div style={style.scroll}>
              <div style={{ ...{ width: `${scrollBoxWidth}px` }, ...style.paddingHor24 }}>
                {/* Text */}
                <SrRefTextIntRe15
                  content={"Upload your cover picture"}
                  state={"enabled"}
                  style={style.colorBlack}
                />

                {/* Cover upload avatar */}
                <div
                  style={{
                    ...style.justifyContentCenter,
                    ...style.marginTop24
                  }}
                >
                  <RectUpload
                    width={360}
                    height={60}
                    pic={props.eventData.cover ? props.eventData.cover : coverPP}
                    imageInputRef={coverImageRef}
                    onChange={(e) => {
                      let files;
                      if (e.dataTransfer) {
                        files = e.dataTransfer.files;
                      } else if (e.target) {
                        files = e.target.files;
                      }
                      const reader = new FileReader();
                      reader.onload = () => {
                        setCoverImage(reader.result);
                      };
                      reader.readAsDataURL(files[0]);
                      setCoverCropper(true);
                    }}
                    darkMode={true}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Back button */}
          <BackFAB
            onClick={() =>
              screen === 0
                ? [props.setEdit(false), props.setEditHover(false), props.allowScroll()]
                : setScreen((p) => p - 1)
            }
          />

          {/* Dot carousal */}
          <div style={style.dotArray}>
            <Dot screen={screen} actualScreen={0} />
            <Dot screen={screen} actualScreen={1} />
            <Dot screen={screen} actualScreen={2} />
            <Dot screen={screen} actualScreen={3} />
          </div>

          {/* Next button */}
          <NextFAB content={screen === 3 ? "Done" : "Next"} onClick={() => handleSubmit()} />

          {/* Image cropper */}
          {showCropper && (
            <CropperPopup
              image={image}
              onClick={uploadFile}
              setImage={setShowCropper}
              aspectRatio={1}
            />
          )}

          {/* Cover cropper */}
          {showCoverImageCropper && (
            <CropperPopup
              image={coverImage}
              onClick={uploadCoverImage}
              setImage={setCoverCropper}
              aspectRatio={6}
            />
          )}
        </div>
      </div>

      {/* Snackbar */}
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
};

export default EventPopup;
