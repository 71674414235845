import { useContext } from "react";

import MainContext from "../../helpers/context/mainContext";

import SrRefColour from "../../../ref/colours";

import { EditBtn0 } from "../../../components/lvl0Components/buttons/iconBtns";
import TeamLead from "../TeamLead";
import OtherTeamLeads from "../OtherTeamLeads";

import style from "./style";

//EditComponent which renders on startupEdit page
const EditComponent = () => {
  const {
    breakpoint1,
    breakpoint2,
    breakpoint3,
    startup,
    returnPrimary,
    setEdit2,
    setAllHoverFalse,
    editHover2,
    setEditHover2,
    returnUnivitedCofounders
  } = useContext(MainContext);

  // Dynamic styling for this component
  const style2 = {
    editOuterDiv: {
      ...style.editOuterDiv,
      backgroundColor: editHover2 && SrRefColour.Neutral1C
    },
    editInnerDiv: {
      ...style.editInnerDiv,
      border: `1px dotted ${SrRefColour.Neutral2B}`
    },

    //Dynamic styling for this component on large screen
    largeEditOuterDiv: {
      ...style.screenEditOuterDiv,
      width: breakpoint2
        ? "calc(50% - 12px)"
        : breakpoint1
        ? "calc(50% - 12px)"
        : "calc(50% - 6px)",
      backgroundColor: editHover2 && SrRefColour.Neutral1C
    },
    largeEditInnerDiv: {
      ...style.screenEditInnerDiv,
      border: `1px dotted ${SrRefColour.Neutral2B}`
    },
    editButton: {
      position: "absolute",
      top: "8px",
      right: "8px"
    }
  };

  return breakpoint3 ? (
    <div
      onClick={() => setEdit2(true)}
      onMouseEnter={() => [setAllHoverFalse(), setEditHover2(true)]}
      onMouseLeave={() => setEditHover2(false)}
      style={style2.editOuterDiv}
    >
      <div style={style2.editInnerDiv}>
        {/* Team lead */}
        <TeamLead startup={startup} returnPrimary={returnPrimary} />

        {/* Other team leads */}
        <div style={style.marginTop12}>
          <OtherTeamLeads
            startup={startup}
            returnPrimary={returnPrimary}
            returnUnivitedCofounders={returnUnivitedCofounders}
          />
        </div>

        <div style={style2.editButton}>
          <EditBtn0 selected={editHover2} />
        </div>
      </div>
    </div>
  ) : (
    <div
      onClick={() => setEdit2(true)}
      onMouseEnter={() => [setAllHoverFalse(), setEditHover2(true)]}
      onMouseLeave={() => setEditHover2(false)}
      style={style2.largeEditOuterDiv}
    >
      <div style={style2.largeEditInnerDiv}>
        {/* Team lead */}
        <TeamLead startup={startup} returnPrimary={returnPrimary} edit={true} />

        {/* Other team leads */}
        <OtherTeamLeads />

        <div style={style2.editButton}>
          <EditBtn0 selected={editHover2} />
        </div>
      </div>
    </div>
  );
};

//Component which renders on startup page
const Component = () => {
  const { breakpoint3, startup, returnPrimary } = useContext(MainContext);

  return breakpoint3 ? (
    <div style={style.outerDiv}>
      {/* Team lead */}
      <div style={style.marginHor12}>
        <TeamLead startup={startup} returnPrimary={returnPrimary} />
      </div>

      {/* Other team leads */}
      <div style={style.marginTop12}>
        <OtherTeamLeads startup={startup} returnPrimary={returnPrimary} />
      </div>
    </div>
  ) : (
    <div style={style.outerDiv2}>
      {/* Team lead */}
      <TeamLead startup={startup} returnPrimary={returnPrimary} />

      {/* Other team leads */}
      <OtherTeamLeads startup={startup} returnPrimary={returnPrimary} />
    </div>
  );
};

const TeamDetails = () => {
  // ifEdit state to check if user is on startup page or startupEdit page
  const { ifEdit } = useContext(MainContext);

  return ifEdit ? <EditComponent /> : <Component />;
};

export default TeamDetails;
