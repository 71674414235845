import React, { useEffect, useState, useRef } from "react";
import MainContext from "./mainContext";
import { apiendpoint } from "../apiendpoint";
import { Industries, StageArrShort } from "../localData";
import { returnRGBA } from "../themeColors";

const MainState = (props) => {
  // Set user and his/her startup
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("@user")) || null);
  const [userStartup, setUserStartup] = useState(null);
  const [eventData, setEventData] = useState("");
  // const [loadUser, setLoadUser] = useState(false);

  // Set if user is on startupEdit page
  // This is being set in startupEdit page's useEffect hook
  const [ifEdit, setIfEdit] = useState(false);

  // Screen width and breakpoints
  const screenWidth = window.innerWidth;
  const breakpoint1 = screenWidth <= 1280; // (screenWidth - 48 * 2) / 4 <= 296
  const breakpoint2 = screenWidth <= 840;
  const breakpoint3 = screenWidth <= 600;
  const scrollBoxWidth = breakpoint3 ? screenWidth : 420;
  const popupWidth = breakpoint3 ? screenWidth - 48 : 376;
  const defaultColor = { r: 200, g: 200, b: 200, a: 1 };

  //load more
  const [loadMore, setLoadMore] = useState(false);

  // Convert colour string to r, g, b, a values
  const colorSplit = (color) => {
    const colorString = String(color);
    const colorSplit1 = String(colorString.split("rgba")[1]);
    const colorSplit2 = String(colorSplit1).split("(")[1];
    const colorSplit3 = String(colorSplit2).split(")")[0];
    const colorSplit4 = String(colorSplit3).split(",");
    const colorRGBA = {
      r: Number(colorSplit4[0]),
      g: Number(colorSplit4[1]),
      b: Number(colorSplit4[2]),
      a: Number(colorSplit4[3])
    };

    return color ? colorRGBA : defaultColor;
  };

  // Refresh page
  const refreshPage = () => {
    window.location.reload(false);
  };

  // Scroll to the top of the page
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  // Default startup
  const defaultStartup = {
    name: "Startup's name",
    brief: "Startup's brief",
    description: "Startup description",
    logo: require("../../../media/no_org.png"),
    theme: {
      primary: { color: defaultColor },
      secondary: { color: "" },
      tertiary: { color: "" },
      accent: { color: "" },
      invertBackground: false
    },
    industry: "Industry",
    sectors: ["Sector 1", "Sector 2"],
    team: [
      {
        fname: "Name",
        lname: "",
        designation: "Founder",
        pic: require("../../../media/no_user.png"),
        representer: true
      }
    ],
    cofounderCount: 1,
    teamSize: 3,
    other: {
      year: "year",
      location: "location",
      stage: -1,
      socialMedia: {
        discord: "",
        facebook: "",
        instagram: "",
        linkedIn: "",
        medium: "",
        pinterest: "",
        reddit: "",
        substack: "",
        twitter: "",
        whatsapp: "",
        youtube: ""
      },
      status: "Status"
    },
    pitch: {
      problem:
        "What is the specific pain point your target customer faces? What are the shortcomings to current solutions?",
      solution:
        "Describe your value proposition briefly. Is it a product or a service? How does it deliver value to your customer?",
      image: require("../../../media/image_default.png"),
      video: {
        link: "",
        thumbnail: require("../../../media/video_default_2.png")
      },
      website: "",
      features:
        "Articulate the features of your product/service. These need not be unique but should paint a picture of what a user can do with it.",
      unique:
        "Talk about how your product solves the pain point you’ve just described in a unique way. How does this set you apart from your competitors?",
      market:
        "Who is your user? Can you describe them more accurately? Are there more than one customer segment you are targeting?"
    }
  };

  // Set user's startup while editing
  const [startup, setStartup] = useState(defaultStartup);

  // Colors related
  const [primaryColor, setPrimaryColor] = useState(startup.theme.primary.color);
  const [secondaryColor, setSecondaryColor] = useState(startup.theme.secondary.color);
  const [tertiaryColor, setTertiaryColor] = useState(startup.theme.tertiary.color);
  const [accentColor, setAccentColor] = useState(startup.theme.accent.color);
  const [invertBackground, setInvertBackground] = useState(startup.theme.invertBackground);

  // Colors related to startup page
  const [primaryColorEdit, setPrimaryColorEdit] = useState(false);
  const [secondaryColorEdit, setSecondaryColorEdit] = useState(false);
  const [tertiaryColorEdit, setTertiaryColorEdit] = useState(false);
  const [accentColorEdit, setAccentColorEdit] = useState(false);

  // Social Media links of teh startup
  const [discord, setDiscord] = useState(startup.other.socialMedia.discord);
  const [facebook, setFacebook] = useState(startup.other.socialMedia.facebook);
  const [instagram, setInstagram] = useState(startup.other.socialMedia.instagram);
  const [linkedIn, setLinkedIn] = useState(startup.other.socialMedia.linkedIn);
  const [medium, setMedium] = useState(startup.other.socialMedia.medium);
  const [pinterest, setPinterest] = useState(startup.other.socialMedia.pinterest);
  const [reddit, setReddit] = useState(startup.other.socialMedia.reddit);
  const [substack, setSubstack] = useState(startup.other.socialMedia.substack);
  const [twitter, setTwitter] = useState(startup.other.socialMedia.twitter);
  const [whatsapp, setWhatsapp] = useState(startup.other.socialMedia.whatsapp);
  const [youtube, setYoutube] = useState(startup.other.socialMedia.youtube);

  // States used for hover effect on startupEdit page
  const [editHover1, setEditHover1] = useState(false);
  const [edit1, setEdit1] = useState(0);
  const [editHover2, setEditHover2] = useState(false);
  const [edit2, setEdit2] = useState(false);
  const [editHover3, setEditHover3] = useState(false);
  const [edit3, setEdit3] = useState(0);
  const [editHover4, setEditHover4] = useState(false);
  const [edit4, setEdit4] = useState(false);
  const [editHover5, setEditHover5] = useState(false);
  const [edit5, setEdit5] = useState(false);
  const [editHover6, setEditHover6] = useState(false);
  const [edit6, setEdit6] = useState(false);
  const [editHover7, setEditHover7] = useState(false);
  const [edit7, setEdit7] = useState(false);
  const [editHover8, setEditHover8] = useState(false);
  const [edit8, setEdit8] = useState(false);
  const [editHover9, setEditHover9] = useState(false);
  const [edit9, setEdit9] = useState(false);
  const [editHover10, setEditHover10] = useState(false);
  const [edit10, setEdit10] = useState(false);
  const [editHover11, setEditHover11] = useState(false);
  const [edit11, setEdit11] = useState(false);
  const [editHover12, setEditHover12] = useState(false);
  const [edit12, setEdit12] = useState(false);
  const [editHover13, setEditHover13] = useState(false);
  const [edit13, setEdit13] = useState(false);
  const [editHover14, setEditHover14] = useState(false);
  const [edit14, setEdit14] = useState(false);

  const [returnPrimary, setReturnPrimary] = useState(
    returnRGBA(primaryColor.r, primaryColor.g, primaryColor.b, 1)
  );
  const [returnPrimaryInverse, setReturnPrimaryInverse] = useState(
    returnRGBA(primaryColor.r, primaryColor.g, primaryColor.b, 0.1 / primaryColor.a)
  );

  const setAllHoverFalse = () => {
    return (
      setEditHover1(false),
      setEditHover2(false),
      setEditHover3(false),
      setEditHover4(false),
      setEditHover5(false),
      setEditHover6(false),
      setEditHover7(false),
      setEditHover8(false),
      setEditHover9(false),
      setEditHover10(false),
      setEditHover11(false),
      setEditHover12(false),
      setEditHover13(false),
      setEditHover14(false)
    );
  };

  const screenPadding = breakpoint2 ? 24 : 48;
  const videoWidth = 240;
  const stageArr = ["Ideation", "Validation", "Early traction", "Scaling"];
  const stageArrShort = ["id", "va", "et", "sc"];

  const fullWidthPlus = screenWidth - screenPadding * 2; //screen padding + inner margins
  const fullWidth = screenWidth - (screenPadding * 2 + 1 * 2 + 24 * 3 + videoWidth); //screen padding + border width + inner margins + video width
  const halfWidth = (screenWidth - (screenPadding * 2 + 1 * 2 + 24 * 3)) / 2; //screen padding + border width + inner margins
  const oneThirdWidth =
    screenWidth <= 1600
      ? (screenWidth - (screenPadding * 2 + 1 * 2 + 24 * 4)) / 3 //screen padding + border width + inner margins
      : (1600 - (screenPadding * 2 + 1 * 2 + 24 * 4)) / 3;

  // Card hover useStates
  const [cardHover3, setCardHover3] = useState(false);
  const [cardHover4, setCardHover4] = useState(false);

  // API related
  const [peopleData, setPeopleData] = useState(null);
  const [orgGoal, setGoal] = useState("");
  const [teamGoals, setTeamGoals] = useState([]);
  const [targets, setTargets] = useState([]);
  const [userId, setUserId] = useState(-1);
  const [expoPushToken, setExpoPushToken] = useState("");
  const notificationListener = useRef();
  const responseListener = useRef();

  let date = new Date();

  // Set user's startup
  const fetchUserStartupData = async (jwt, orgId) => {
    if (!jwt || !orgId) return;
    fetch(`${apiendpoint}/platform/org/${orgId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw new Error("Unauthorized");
      })
      .then((data) => {
        if (data.org) {
          setLoadMore(false);
          const startup = {
            id: data.org.id,
            name: data.org.name,
            brief: data.org.brief,
            description: data.org.description,
            logo: data.org.logo,
            theme: {
              primary: { color: data.org.primary_colour ? data.org.primary_colour : defaultColor },
              secondary: {
                color: data.org.secondary_colour
              },
              tertiary: { color: data.org.tertiary_colour },
              accent: { color: data.org.accent_colour },
              invertBackground: data.org.invert_background
            },
            industry: Industries[data.org.industry],
            sectors: [data.org.sector1, data.org.sector2],
            team: data.orgMembers.map((lead) => {
              return {
                id: lead.id,
                fname: lead.fname,
                lname: lead.lname,
                designation: lead.designation,
                pic: lead.picture,
                linkedIn: lead.linkedin,
                orgId: lead.org_id,
                orgLead: lead.is_org_lead,
                representer: lead.representer
              };
            }),
            cofounderCount: data.org.cofounder_count,
            teamsize: data.org.team_size,
            other: {
              year: data.org.establishment_year,
              location: data.org.location,
              zaubacorp: data.org.zaubacorp,
              stage: StageArrShort.indexOf(data.org.stage),
              socialMedia: {
                discord: data.org.discord,
                facebook: data.org.facebook,
                instagram: data.org.instagram,
                linkedIn: data.org.linkedin,
                medium: data.org.medium,
                pinterest: data.org.pinterest,
                reddit: data.org.reddit,
                substack: data.org.substack,
                twitter: data.org.twitter,
                whatsapp: data.org.whatsapp,
                youtube: data.org.youtube
              },
              status: data.org.org_status
            },
            pitch: {
              problem: data.platform.problem,
              solution: data.platform.solution,
              image: data.platform.image,
              video: {
                link: data.platform.video,
                thumbnail: data.platform.thumbnail
              },
              website: data.org.website,
              features: data.platform.features,
              unique: data.platform.unique_features,
              market: data.platform.market,
              challenge1: data?.platform?.challenge1,
              challenge2: data?.platform?.challenge2,
              challenge3: data?.platform?.challenge3,
              mentorSector1: data?.platform?.mentor_sector1,
              mentorSector2: data?.platform?.mentor_sector2,
              mentorSector3: data?.platform?.mentor_sector3,
              mentorSector4: data?.platform?.mentor_sector4,
              mentorSector5: data?.platform?.mentor_sector5,
              pitchDeck: data.platform.pitch_deck
            }
          };
          setUserStartup(startup);
        }
      });
  };

  const loadUser = () => {
    let jwt = localStorage.getItem("@jwtauth");
    if (!jwt) jwt = "";
    if (!user)
      fetch(`${apiendpoint}/platform/orgUser`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`
        }
      })
        .then((res) => {
          if (res.ok) return res.json();
          else throw new Error("Unauthorized");
        })
        .then(({ employee: data, org: orgData }) => {
          const currentUser = {
            id: data.id,
            fname: data.fname,
            lname: data.lname,
            designation: data.designation,
            pic: data.picture,
            linkedIn: data.linkedin,
            orgId: data.org_id ? data.org_id : orgData.id,
            userType: data.user_type,
            orgLead: data.is_org_lead,
            representer: data.representer,
            whatsappNo: data.whatsapp_no,
            emailId: data.email_id
          };
          setUser(currentUser);
          localStorage.setItem("@user", JSON.stringify(currentUser));
          localStorage.setItem("@orgid", JSON.stringify(orgData.id));
          if (data.org_id) fetchUserStartupData(jwt, data.org_id);
        })
        .catch((err) => console.log(err));
    else if (user.orgId) fetchUserStartupData(jwt, user.orgId);
  };

  // Make an array for unlisted cofounders and return a default avatar for each
  let uninvitedCofounders = [];
  const returnUnivitedCofounders = () => {
    let temp = [];
    for (
      let i = 1;
      i < startup.cofounderCount - startup.team.filter((m) => m.orgLead === "Y").length;
      i++
    ) {
      temp.push(i);
    }
    return (uninvitedCofounders = temp);
  };

  // Set user
  useEffect(() => {
    try {
      let jwt = localStorage.getItem("@jwtauth");
      if (!jwt) jwt = "";
      if (user.orgId) fetchUserStartupData(jwt, user.orgId);
    } catch (err) {
      console.log(err);
    }
  }, [user, loadMore]);

  const [setupExist, setExist] = useState({
    addEmp: {
      designation: "",
      dob: `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${(
        "0" + date.getDate()
      ).slice(-2)}`,
      emailID: "",
      fname: "",
      gender: "",
      linkedIn: "",
      lname: "",
      pp: null
    },
    empCorfunctions: [],
    orgCode: ""
  });

  const [setupNew, setNew] = useState({
    addEmp: {
      designation: "",
      dob: `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${(
        "0" + date.getDate()
      ).slice(-2)}`,
      emailID: "",
      fname: "",
      gender: "",
      linkedIn: "",
      lname: "",
      pp: null
    },
    addOrg: {
      brief: "",
      days: "",
      fromEvent: "",
      industry: {
        id: 1
      },
      isSolopreneur: "",
      location: "",
      logo: null,
      orgName: "",
      purpose: "",
      sector: {
        id: 1
      },
      stage: "",
      cofounderCount: 0,
      teamSize: 0
    },
    empCorfunctions: [],
    orgCorfunctions: [],
    orgGoal: "",
    orgServices: []
  });

  return (
    <MainContext.Provider
      value={{
        user,
        setUser,
        userStartup,
        setUserStartup,
        loadUser,
        screenWidth,
        breakpoint1,
        breakpoint2,
        breakpoint3,
        scrollBoxWidth,
        popupWidth,
        defaultColor,
        colorSplit,
        refreshPage,
        scrollToTop,
        // API related
        peopleData,
        setPeopleData,
        orgGoal,
        setGoal,
        teamGoals,
        setTeamGoals,
        targets,
        setTargets,
        userId,
        setUserId,
        expoPushToken,
        setExpoPushToken,
        setupExist,
        setExist,
        setupNew,
        setNew,
        defaultStartup,
        startup,
        setStartup,
        loadMore,
        setLoadMore,
        // To check if user is on startupEdit page or startupPage
        ifEdit,
        setIfEdit,

        // Community related data
        eventData,
        setEventData,

        //Colors related
        primaryColor,
        secondaryColor,
        accentColor,
        tertiaryColor,
        invertBackground,
        setPrimaryColor,
        setSecondaryColor,
        setAccentColor,
        setInvertBackground,
        setTertiaryColor,
        returnPrimary,
        returnPrimaryInverse,
        setReturnPrimary,
        setReturnPrimaryInverse,

        //Colors related to startupEdit page
        primaryColorEdit,
        secondaryColorEdit,
        accentColorEdit,
        tertiaryColorEdit,
        setPrimaryColorEdit,
        setSecondaryColorEdit,
        setAccentColorEdit,
        setTertiaryColorEdit,

        // State used for hover in startupEdit page
        setEdit1,
        editHover1,
        setEditHover1,
        setEdit2,
        editHover2,
        setEditHover2,
        editHover3,
        setEdit3,
        setEditHover3,
        setEdit4,
        editHover4,
        setEditHover4,
        setEdit5,
        editHover5,
        setEditHover5,
        setEdit6,
        editHover6,
        setEditHover6,
        setEdit7,
        editHover7,
        setEditHover7,
        setEdit8,
        editHover8,
        setEditHover8,
        setEdit10,
        editHover9,
        setEditHover9,
        editHover10,
        setEditHover10,
        setEdit11,
        editHover11,
        setEditHover11,
        setEdit12,
        editHover12,
        setEditHover12,
        setEdit13,
        editHover13,
        setEditHover13,
        setEdit14,
        editHover14,
        setEditHover14,
        setAllHoverFalse,
        edit1,
        edit2,
        edit3,
        edit4,
        edit5,
        edit6,
        edit7,
        edit8,
        edit9,
        edit10,
        edit11,
        edit12,
        edit13,
        edit14,

        returnUnivitedCofounders,

        // Related tp startupEdit page
        videoWidth,
        fullWidth,
        fullWidthPlus,
        halfWidth,
        oneThirdWidth,
        cardHover3,
        setCardHover3,
        cardHover4,
        setCardHover4,

        //Social media link
        discord,
        reddit,
        facebook,
        instagram,
        medium,
        linkedIn,
        substack,
        whatsapp,
        twitter,
        pinterest,
        youtube,
        setDiscord,
        setReddit,
        setFacebook,
        setInstagram,
        setMedium,
        setLinkedIn,
        setSubstack,
        setWhatsapp,
        setTwitter,
        setPinterest,
        setYoutube
      }}
    >
      {props.children}
    </MainContext.Provider>
  );
};

export default MainState;
