import { useState, useContext } from "react";
import MainContext from "../../helpers/context/mainContext";

import {
  SrCompIconDiscord,
  SrCompIconInstagram,
  SrCompIconMedium,
  SrCompIconLinkedin,
  SrCompIconPinterest,
  SrCompIconReddit,
  SrCompIconSubstack,
  SrCompIconTwitter,
  SrCompIconWhatsapp,
  SrCompIconYoutube,
  SrCompIconFacebook
} from "../../lvl0Components/icons";

import style from "./style";

// EditComponent which render on startupEdit page
const EditComponent = ({ link }) => {
  const { startup } = useContext(MainContext);

  // Dynamic style for this component
  const style2 = {
    outerDiv: {
      ...style.flexColumn,
      ...style.marginHor6,
      ...style.icon,
      ...style.transition,
      ...style.borderRadius6
    }
  };

  return (
    link && (
      <div style={style2.outerDiv}>
        {link === startup.other.socialMedia.discord ? (
          <SrCompIconDiscord size={24} />
        ) : link === startup.other.socialMedia.facebook ? (
          <SrCompIconFacebook size={24} />
        ) : link === startup.other.socialMedia.instagram ? (
          <SrCompIconInstagram size={24} />
        ) : link === startup.other.socialMedia.linkedIn ? (
          <SrCompIconLinkedin size={24} />
        ) : link === startup.other.socialMedia.medium ? (
          <SrCompIconMedium size={24} />
        ) : link === startup.other.socialMedia.pinterest ? (
          <SrCompIconPinterest size={24} />
        ) : link === startup.other.socialMedia.reddit ? (
          <SrCompIconReddit size={24} />
        ) : link === startup.other.socialMedia.substack ? (
          <SrCompIconSubstack size={24} />
        ) : link === startup.other.socialMedia.twitter ? (
          <SrCompIconTwitter size={24} />
        ) : link === startup.other.socialMedia.whatsapp ? (
          <SrCompIconWhatsapp size={24} />
        ) : (
          <SrCompIconYoutube size={24} />
        )}
      </div>
    )
  );
};

//Component which renders on startup page
const Component = ({ link, returnPrimaryInverse }) => {
  const [linkHover, setLinkHover] = useState(false);

  const {
    discord,
    facebook,
    instagram,
    linkedIn,
    medium,
    pinterest,
    substack,
    reddit,
    twitter,
    whatsapp
  } = useContext(MainContext);

  // Dynamic styling for this component
  const style2 = {
    outerDiv: {
      ...style.flexColumn,
      ...style.icon,
      ...style.transition,
      ...style.borderRadius6,
      backgroundColor: linkHover && returnPrimaryInverse
    }
  };

  return (
    link && (
      <div style={style.marginHor6}>
        <a
          onMouseEnter={() => setLinkHover(true)}
          onMouseLeave={() => setLinkHover(false)}
          href={`${link}`}
          target="_blank"
          style={style2.outerDiv}
          rel="noopener noreferrer"
        >
          {link === discord ? (
            <SrCompIconDiscord size={24} />
          ) : link === facebook ? (
            <SrCompIconFacebook size={24} />
          ) : link === instagram ? (
            <SrCompIconInstagram size={24} />
          ) : link === linkedIn ? (
            <SrCompIconLinkedin size={24} />
          ) : link === medium ? (
            <SrCompIconMedium size={24} />
          ) : link === pinterest ? (
            <SrCompIconPinterest size={24} />
          ) : link === reddit ? (
            <SrCompIconReddit size={24} />
          ) : link === substack ? (
            <SrCompIconSubstack size={24} />
          ) : link === twitter ? (
            <SrCompIconTwitter size={24} />
          ) : link === whatsapp ? (
            <SrCompIconWhatsapp size={24} />
          ) : (
            <SrCompIconYoutube size={24} />
          )}
        </a>
      </div>
    )
  );
};

const Link = ({ link }) => {
  // State to check if user is on startup page or startup Edit page
  const { ifEdit, returnPrimaryInverse, startup } = useContext(MainContext);

  return ifEdit ? (
    <EditComponent startup={startup} link={link} />
  ) : (
    <Component link={link} returnPrimaryInverse={returnPrimaryInverse} />
  );
};

export default Link;
