import { useContext } from "react";
import MainContext from "../../helpers/context/mainContext";

import { EditBtn0 } from "../../../components/lvl0Components/buttons/iconBtns";

import SrRefColour from "../../../ref/colours";
import { SrRefTextIntSb15 } from "../../../ref/text";
import { gradientBgTextBox, gradientBorderTextBox, gradientText } from "../../helpers/themeColors";
import { newLineText } from "../../helpers/themeColors";

import style from "./style";

const MainComponent = () => {
  const { startup, primaryColor, tertiaryColor, invertBackground } = useContext(MainContext);
  return (
    <div
      style={{
        ...style.textBox,
        ...gradientBgTextBox(tertiaryColor, primaryColor, 0.6, invertBackground),
        ...gradientBorderTextBox(tertiaryColor, primaryColor, invertBackground),
        ...style.width100
      }}
    >
      <SrRefTextIntSb15
        content={"Problem statement"}
        style={gradientText(tertiaryColor, primaryColor, invertBackground)}
      />

      {/* Problem text */}
      <div style={style.marginTop6}>
        {startup.pitch.problem
          ? newLineText(startup.pitch.problem, tertiaryColor, primaryColor, invertBackground)
          : newLineText(
              "What is the specific pain point your target customer faces? What are the shortcomings to current solutions?",
              tertiaryColor,
              primaryColor,
              invertBackground
            )}
      </div>
    </div>
  );
};

const Problem = () => {
  // Edit state to check if user is on startupEdit page
  const { ifEdit, setEdit4, editHover4, setEditHover4, setAllHoverFalse } = useContext(MainContext);

  // Dynamic styling for this component
  const style2 = {
    outerDiv: {
      ...style.editOuterDiv,
      backgroundColor: editHover4 && SrRefColour.Neutral1C
    },
    innerDiv: {
      ...style.editInnerDiv,
      border: `1px dotted ${SrRefColour.Neutral2B}`
    },
    editButton: {
      position: "absolute",
      top: "11px",
      right: "11px"
    }
  };

  return ifEdit ? (
    <div
      onClick={() => setEdit4(true)}
      onMouseEnter={() => [setAllHoverFalse(), setEditHover4(true)]}
      onMouseLeave={() => setEditHover4(false)}
      style={style2.outerDiv}
    >
      <div style={style2.innerDiv}>
        <MainComponent />

        <div style={style2.editButton}>
          <EditBtn0 selected={editHover4} />
        </div>
      </div>
    </div>
  ) : (
    <MainComponent />
  );
};

export default Problem;
