import React, { useState, useContext } from "react";

import SrRefColour from "../../../ref/colours";
import { SrRefTextIntRe12, SrRefTextIntLi12, SrRefTextIntLi10 } from "../../../ref/text";
import MainContext from "../../../components/helpers/context/mainContext";

import { SrCompIconCheckBox, SrCompIconCheckBoxOutlineBlank } from "../../lvl0Components/icons";
import { ColorPickerBtn } from "../../lvl0Components/buttons/colorBtns";
import { ColorPicker } from "../../lvl0Components/colorPickerComp";
import { SrCompIconFormatPaint } from "../../lvl0Components/icons";

import style from "./style";

const ColorPanel = (props) => {
  // Context
  const {
    screenWidth,
    breakpoint2,
    breakpoint3,
    // API related
    startup,
    setStartup
  } = useContext(MainContext);

  const [showAdvanced, setShowAdvanced] = useState(false);
  const [invertBackground, setInvertBackground] = useState(startup.theme.invertBackground);

  // Check box
  const CheckBox = (props) => {
    const [btnHover, setBtnHover] = useState(false);
    const [checkBoxFill, setCheckBoxFill] = useState(props.status);

    return (
      <div
        onMouseEnter={() => setBtnHover(true)}
        onMouseLeave={() => setBtnHover(false)}
        onClick={() => {
          props.setInvertBackground(props.invertBackground === "Y" ? "N" : "Y");
          props.setStartup({
            ...props.startup,
            theme: {
              ...props.startup.theme,
              invertBackground: props.invertBackground === "Y" ? "N" : "Y"
            }
          });
        }}
        style={style.innerDiv}
      >
        {/* Checkbox for orglead */}
        {!checkBoxFill ? (
          <SrCompIconCheckBox
            size={24}
            color={btnHover ? SrRefColour.Neutral1A : SrRefColour.Neutral2A}
          />
        ) : (
          <SrCompIconCheckBoxOutlineBlank
            size={24}
            color={btnHover ? SrRefColour.Neutral1A : SrRefColour.Neutral2A}
          />
        )}

        <div style={style.marginLeft3}>
          <SrRefTextIntRe12
            content={"Fill"}
            style={{ color: btnHover ? SrRefColour.Neutral1A : SrRefColour.Neutral2A }}
          />
        </div>
      </div>
    );
  };

  // Show more/less options link
  const Link = (props) => {
    const [linkHover, setLinkHover] = useState(false);

    //for dynamic styling
    const style2 = {
      textStyle: {
        ...style.linkTextStyle,
        width: "max-content",
        color: linkHover ? SrRefColour.Neutral1A : SrRefColour.Neutral2A,
        textDecoration: `underline ${linkHover ? SrRefColour.Neutral1A : SrRefColour.Neutral2A}`
      }
    };

    return (
      <div
        onMouseEnter={() => setLinkHover(true)}
        onMouseLeave={() => setLinkHover(false)}
        onClick={props.onClick}
        style={style.outerDiv}
      >
        <SrRefTextIntLi12 content={props.content} style={style2.textStyle} />
      </div>
    );
  };

  //for dynamic styling
  const style2 = {
    outerDiv: {
      ...style.userPanelWrap,
      bottom: props.changePosition
        ? breakpoint3
          ? props.scrollPosition + props.windowHeight - (props.bodyScrollHeight - (60 + 36))
          : props.scrollPosition + props.windowHeight - (props.bodyScrollHeight - (60 + 48))
        : 0
    },
    innerDiv: {
      ...style.positionRelative, //this style is not present in style
      ...style.colorPanelInnerDiv,
      margin: "0 auto",
      boxShadow: "0px 2px 4px rgba(0,0,0,0.15)",
      maxWidth: breakpoint2 && `${screenWidth - 24}px`
    }
  };

  return (
    <div style={style2.outerDiv}>
      <div style={style2.innerDiv}>
        {/* User panel surface */}
        <div style={style.userPanelContainer}>
          <div style={style.userPanelInnerDiv}>
            {/* Colour buttons */}
            {/* Primary colour button */}
            <ColorPickerBtn
              color={props.primaryColor}
              content={"Primary color"}
              onClick={() => (
                props.setPrimaryColorEdit(!props.primaryColorEdit),
                props.setSecondaryColorEdit(false),
                props.setTertiaryColorEdit(false),
                props.setAccentColorEdit(false)
              )}
            />

            {/* Secondary colour button */}
            <ColorPickerBtn
              color={props.secondaryColor}
              content={"Secondary color"}
              onClick={() => (
                props.setPrimaryColorEdit(false),
                props.setSecondaryColorEdit(!props.secondaryColorEdit),
                props.setTertiaryColorEdit(false),
                props.setAccentColorEdit(false)
              )}
            />

            {/* Show more colour pickers when advanced options is clicked */}
            {showAdvanced && (
              <>
                {/* Tertiary colour button */}
                <ColorPickerBtn
                  color={props.tertiaryColor}
                  content={"Tertiary color"}
                  onClick={() => (
                    props.setPrimaryColorEdit(false),
                    props.setSecondaryColorEdit(false),
                    props.setTertiaryColorEdit(!props.tertiaryColorEdit),
                    props.setAccentColorEdit(false)
                  )}
                />

                {/* Accent colour button */}
                <ColorPickerBtn
                  color={props.accentColor}
                  content={"Accent color"}
                  onClick={() => (
                    props.setPrimaryColorEdit(false),
                    props.setSecondaryColorEdit(false),
                    props.setTertiaryColorEdit(false),
                    props.setAccentColorEdit(!props.accentColorEdit)
                  )}
                />

                {/* Invert colour checkbox */}
                <CheckBox
                  status={props.invertBackground === "Y"}
                  invertBackground={props.invertBackground}
                  setInvertBackground={props.setInvertBackground}
                  setStartup={props.setStartup}
                  startup={props.startup}
                />
              </>
            )}

            {/* Vertical line to separate the left from right section */}
            <div style={style.verticalLine}></div>

            {/* Advanced option */}
            <Link
              onClick={() => {
                setShowAdvanced(!showAdvanced);
                props.setTertiaryColorEdit(false);
                props.setAccentColorEdit(false);
              }}
              content={showAdvanced ? "Less" : "More"}
              style={{ width: "max-content" }}
            />
          </div>

          <div style={style.iconTextContainer}>
            {/* <SrCompIconFormatPaint size={20} color={SrRefColour.Neutral2A} /> */}
            <div style={style.marginLeft6}>
              <SrRefTextIntLi10 content={"Personalize your listing here"} style={style.colorGrey} />
            </div>
          </div>

          {/* Colour pickers */}
          {/* Primary colour picker */}
          {props.primaryColorEdit && (
            <ColorPicker
              color={props.primaryColor}
              setColor={props.setPrimaryColor}
              onChange={props.onPrimaryChange}
              setColorEdit={props.setPrimaryColorEdit}
              setStartup={props.setStartup}
              startup={props.startup}
              primary={true}
            />
          )}

          {/* Secondary colour picker */}
          {props.secondaryColorEdit && (
            <ColorPicker
              color={props.secondaryColor}
              setColor={props.setSecondaryColor}
              onChange={props.onSecondaryChange}
              setColorEdit={props.setSecondaryColorEdit}
              setStartup={props.setStartup}
              startup={props.startup}
              secondary={true}
            />
          )}

          {/* Tertiary colour picker */}
          {props.tertiaryColorEdit && (
            <ColorPicker
              color={props.tertiaryColor}
              setColor={props.setTertiaryColor}
              onChange={props.onTertiaryChange}
              setColorEdit={props.setTertiaryColorEdit}
              setStartup={props.setStartup}
              startup={props.startup}
              tertiary={true}
            />
          )}

          {/* Accent colour picker */}
          {props.accentColorEdit && (
            <ColorPicker
              color={props.accentColor}
              setColor={props.setAccentColor}
              onChange={props.onAccentChange}
              setColorEdit={props.setAccentColorEdit}
              setStartup={props.setStartup}
              startup={props.startup}
              accent={true}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export { ColorPanel };
