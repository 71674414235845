import SrRefColour from "../../../ref/colours";

const style = {
  componentColor: {
    marginTop: "6px",
    color: SrRefColour.Neutral2A,
    textAlign: "end"
    // height: "15px",
    // width: "15px"
  }
};

export default style;
