import React, { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import SrRefColour from "../../ref/colours";
import {
  SrRefTextIntSb12,
  SrRefTextIntRe12,
  SrRefTextIntLi12,
  SrRefTextIntRe09
} from "../../ref/text";

import { apiendpoint } from "../helpers/apiendpoint";
import MainContext from "../helpers/context/mainContext";

import {
  SrCompIconDone,
  SrCompIconClose,
  SrCompIconEdit,
  SrCompIconDelete,
  SrCompIconExpandMore,
  SrCompIconExpandLess
} from "../lvl0Components/icons";
import { EditBtn0, DeleteBtn0 } from "..//lvl0Components/buttons/iconBtns";
import { TextInputIntRe12Small } from "..//lvl0Components/textInputs";

import style from "./style";

// Returns the date string in a spoken format and shows the year if the second param is true
const DateSuffix = (dateString, showYear) => {
  const date = new Date(dateString);
  const day = date.getDate();
  let suffix = "";
  if (day >= 11 && day <= 13) {
    suffix = "th";
  } else if (day % 10 === 1) {
    suffix = "st";
  } else if (day % 10 === 2) {
    suffix = "nd";
  } else if (day % 10 === 3) {
    suffix = "rd";
  } else {
    suffix = "th";
  }
  const monthAbbreviation = date.toLocaleString("default", { month: "short" });
  const yearAbbreviation = date.getFullYear().toString().substr(-2);
  return day + suffix + " " + monthAbbreviation + (showYear ? "'" + yearAbbreviation : "");
};

// Returns the event duration and location
const DateAndLocation = (startDate, endDate) => {
  return startDate === endDate
    ? DateSuffix(startDate, false) + ", " + location
    : DateSuffix(startDate, false) + " to " + DateSuffix(endDate, true);
};

const speakerImgWidth = 120;
const speakerNameLength = 10;
const speakerDesignationLength = 26;

// Returns speaker objects
const SpeakersList = (props) => {
  const snackbar = (message) => {
    toast(message, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
  };

  // func to delete speakers
  const handleSDel = (sid) => {
    var jwt = localStorage.getItem("@jwtauth");
    if (!jwt) jwt = "";
    fetch(`${apiendpoint}/community/admin/${sid}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw new Error("Unauthorized");
      })
      .then((data) => {
        snackbar("Admin removed");
        props.setFetchMore(true);
      });
  };

  // For each speaker, return an image with apt picture, href, name and designation
  return props.speakers?.map((e, i) => (
    <div
      style={{
        position: "relative",
        ...style.flexColumn,
        ...style.gap6,
        ...style.fitContent
      }}
      key={i}
    >
      {/* Speaker's picture */}
      <img
        onClick={() => {
          if (!props.eventEdit) {
            window.open(e?.url, "_blank", "noreferrer");
          }
        }}
        src={e?.picture}
        width={speakerImgWidth}
        height={speakerImgWidth}
        alt="Speaker's picture"
        style={{
          border: `0.5px solid ${SrRefColour.Neutral2A}`,
          ...style.borderRadius6,
          cursor: !props.eventEdit && "pointer"
        }}
      />

      <div
        style={{
          ...style.flexColumn,
          ...style.gap3
        }}
      >
        {/* Speaker's name */}
        <SrRefTextIntRe12
          content={
            (e.fname + " " + e.lname)?.length > speakerNameLength + 2
              ? (e.fname + " " + e.lname).slice(0, speakerNameLength) + "..."
              : (e.fname ? e.fname : "User") + (e.lname ? e.lname : "")
          }
          style={{ maxWidth: `${speakerImgWidth}px`, ...style.colorBlack }}
        />
      </div>

      {/* Edit button position */}
      {props.eventEdit && (
        <>
          {/* <Edit button /> */}
          <div onClick={() => props.setSpeakerEdit(i)} style={style.editBtn}>
            <EditBtn0 />
          </div>

          <div onClick={() => handleSDel(e.id)} style={style.deleteBtn}>
            <DeleteBtn0 />
          </div>
        </>
      )}
    </div>
  ));
};

// Constants for Sponsors
const sponsorImgWidth = 240;
const sponsorImgHeight = 160;

// Returns sponsor objects
const SponsorsList = (props) => {
  //snackbar
  const snackbar = (message) => {
    toast(message, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
  };

  // func to delete sponsors
  const handleSDel = (sid) => {
    var jwt = localStorage.getItem("@jwtauth");
    if (!jwt) jwt = "";
    fetch(`${apiendpoint}/event/sponsor/${sid}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw new Error("Unauthorized");
      })
      .then((data) => {
        snackbar("Sponsor removed");
        props.setFetchMore(true);
      });
  };

  // For each sponsor, return an image with apt picture, href, name and designation
  return props.sponsors?.map((e, i) => (
    <div
      style={{
        position: "relative",
        ...style.flexColumn,
        ...style.gap6,
        ...style.fitContent
      }}
      key={i}
    >
      {/* Sponsor's picture */}
      <img
        onClick={() => {
          if (!props.eventEdit) {
            window.open(e?.url, "_blank");
          }
        }}
        src={e.photo}
        width={sponsorImgWidth}
        height={sponsorImgHeight}
        alt="Sponsor's picture"
        style={{
          border: `0.5px solid ${SrRefColour.Neutral2A}`,
          ...style.borderRadius6,
          cursor: !props.eventEdit && "pointer"
        }}
      />

      {props.eventEdit && (
        <>
          {/* <Edit button /> */}
          <div onClick={() => props.setSponsorEdit(i)} style={style.editBtn}>
            <EditBtn0 />
          </div>

          {/* <Delete button /> */}
          <div onClick={() => handleSDel(e.id)} style={style.deleteBtn}>
            <DeleteBtn0 />
          </div>
        </>
      )}
    </div>
  ));
};

// Attendee card
const AttendeeCard = (props) => {
  const [expanded, setExpanded] = useState(props.adding);
  const [guestEdit, setGuestEdit] = useState(props.adding);

  const [orgName, setOrgName] = useState(props.org);
  const [orgNameEdit, setOrgNameEdit] = useState(false);
  const [guestName, setGuestName] = useState(props.name);
  const [guestNameEdit, setGuestNameEdit] = useState(false);
  const [guestMobile, setGuestMobile] = useState(props.mobile);
  const [guestMobileEdit, setGuestMobileEdit] = useState(false);
  const [guestEmail, setGuestEmail] = useState(props.email);
  const [guestEmailEdit, setGuestEmailEdit] = useState(false);

  const [status, setStatus] = useState(
    props.userType === "gst"
      ? props.listingStatus === "Y"
        ? 0
        : -1
      : props.listingStatus === "co"
      ? 3
      : props.listingStatus === "re"
      ? 2
      : props.listingStatus === "do"
      ? 1
      : props.representer && props.representer.is_setup === "Y"
      ? 0
      : -1
  );

  // Context
  const {
    screenWidth,
    breakpoint1,
    breakpoint2,
    breakpoint3,
    defaultColor,
    colorSplit,
    // API related
    user,
    userStartup,
    scrollToTop
  } = useContext(MainContext);

  const snackbar = (message) => {
    toast(message, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
  };

  let jwt = localStorage.getItem("@jwtauth");
  if (!jwt) jwt = "";

  //To update states back after cancel
  const onCancel = () => {
    setOrgName(props.org);
    setGuestEmail(props.email);
    setGuestMobile(props.mobile);
    setGuestName(props.name);
  };

  // This useEffect is used to change the states after deletion
  useEffect(() => {
    onCancel();
  }, [props.name]);

  //func to add startup
  const handleStartupAdd = () => {
    let jwt = localStorage.getItem("@jwtauth");
    if (!jwt) jwt = "";

    fetch(`${apiendpoint}/community/inviteMember/${props.eventId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`
      },
      body: JSON.stringify(
        props.type === "participant"
          ? {
              org: {
                org_name: orgName,
                org_member: {
                  fname: guestName,
                  email: guestEmail,
                  number: guestMobile
                }
              }
            }
          : props.type === "guest"
          ? {
              guest: [
                {
                  fname: guestName,
                  email: guestEmail,
                  number: guestMobile
                }
              ]
            }
          : {
              special_guest: [
                {
                  fname: guestName,
                  email: guestEmail,
                  number: guestMobile
                }
              ]
            }
      )
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw new Error("Unauthorized");
      })
      .then((data) => {
        if (!data.success) {
          snackbar("Attendee's number already exists!");
        } else {
          snackbar("Attendee Added");
        }
        props.setFetchMore(true);
        props.setStartupAdd(false);
        props.setGuestAdd(false);
        setGuestEdit(false);
        props.setSpecialGuestAdd(false);
      });
  };

  // func for editing startup
  const handleStartupEdit = (mid, oid, type) => {
    if (type === "startup")
      fetch(`${apiendpoint}/event/startups/${oid}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwt}`
        },
        body: JSON.stringify({ name: orgName })
      })
        .then((res) => {
          if (res.ok) return res.json();
          else throw new Error("Unauthorized");
        })
        .then((data) => {
          props.setFetchMore(true);
          // setEventInfoEdit(false);
        });

    // console.log(mid);
    fetch(`${apiendpoint}/event/members/${mid}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`
      },
      body: JSON.stringify({
        fname: guestName,
        phone_no: guestMobile,
        email_id: guestEmail
      })
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw new Error("Unauthorized");
      })
      .then((data) => {
        snackbar("Attendee updated");
        props.setFetchMore(true);
        // setEventInfoEdit(false);
      });
    setGuestEdit(false);
  };

  // func to delete
  const handleStartupDel = (sid, type) => {
    fetch(`${apiendpoint}/community/${type}/${sid}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw new Error("Unauthorized");
      })
      .then((data) => {
        snackbar("Attendee removed");
        props.setFetchMore(true);
        onCancel();
      });
  };

  const Dot = (props) => {
    return (
      <div style={style.status}>
        {status >= props.status ? (
          <div style={style.greenDot}></div>
        ) : (
          <div style={style.redDot}></div>
        )}
      </div>
    );
  };

  // Dynamic styling for this component
  const style2 = {
    container: {
      ...style.flexRow,
      ...style.alignItemsCenter,
      ...style.spaceBetween,
      // margin: `${breakpoint2 ? 24 : 0}px 12px 0`
      width: "calc(100% - 24px)",
      ...style.marginHor12,
      ...style.padding12,
      ...style.surfaceBlurNote,
      ...style.borderRadius12,
      ...style.overflowScroll
    },
    outerDiv: {
      ...style.flexColumn,
      ...style.gap6
    },
    innerDiv: {
      ...style.flexRow,
      ...style.gap6,
      width: expanded && !guestEdit ? "300px" : "228px"
    }
  };

  return (
    <div style={style2.container}>
      <div style={style2.outerDiv}>
        <div style={style2.innerDiv}>
          {/* Card title */}
          {guestEdit ? (
            props.type === "participant" ? (
              // If editing is true and card type is participant
              // Text input for startup's name
              <TextInputIntRe12Small
                type="text"
                name="orgName"
                placeholder={"Startup's name"}
                value={orgName}
                onChange={(e) => {
                  setOrgName(e.target.value);
                }}
                onClick={() => setOrgNameEdit(true)}
              />
            ) : (
              // If editing is true and card type is NOT participant
              // Text input for name
              <TextInputIntRe12Small
                type="text"
                name="guestName"
                placeholder={"Full name"}
                value={guestName}
                onChange={(e) => {
                  setGuestName(e.target.value);
                }}
                onClick={() => setGuestNameEdit(true)}
              />
            )
          ) : props.type === "participant" ? (
            // If editing is false and card type is participant
            // Text for startup's name
            <div style={style.paddingVert1}>
              <SrRefTextIntSb12 content={orgName} />
            </div>
          ) : (
            // If editing is false and card type is NOT participant
            // Text for name
            <div style={style.paddingVert1}>
              <SrRefTextIntSb12 content={guestName} />
            </div>
          )}

          {!guestEdit && (
            <>
              {/* Row of icons */}
              <div style={{ ...style.flexRow, ...style.alignItemsCenter, ...style.gap6 }}>
                {/* Show these icons only if the card is expanded */}
                {expanded && (
                  <>
                    {/* Edit button */}
                    <div onClick={() => setGuestEdit(true)} style={style.icon20}>
                      <SrCompIconEdit />
                    </div>

                    {/* Delete button */}
                    <div
                      style={style.icon20}
                      onClick={() =>
                        props.type === "participant"
                          ? handleStartupDel(props.orgId, "startups")
                          : handleStartupDel(props.guestId, "members")
                      }
                    >
                      <SrCompIconDelete />
                    </div>
                  </>
                )}

                <>
                  {/* Dropdown */}
                  <div
                    onClick={() => {
                      setExpanded(!expanded);
                      setGuestEdit(false);
                    }}
                    style={style.icon20}
                  >
                    {/* Expand or minimize icon based on state */}
                    {!expanded ? <SrCompIconExpandMore /> : <SrCompIconExpandLess />}
                  </div>
                </>
              </div>
            </>
          )}
        </div>

        {/* Show more info if the card is expanded */}
        {expanded && (
          <div
            style={{
              ...style.flexColumn,
              ...style.gap24,
              width: "222px" // 300px - 72px for 3 icons and -6px for gap
            }}
          >
            <div
              style={{
                ...style.flexColumn,
                ...style.gap6
              }}
            >
              {guestEdit ? (
                <>
                  {props.type === "participant" && (
                    // If editing is true and card type is participant
                    // Text input for startup founder's full name
                    <TextInputIntRe12Small
                      type="text"
                      name="guestName"
                      placeholder={"Startup founder's name"}
                      value={guestName}
                      onChange={(e) => {
                        setGuestName(e.target.value);
                      }}
                      onClick={() => setGuestNameEdit(true)}
                    />
                  )}

                  {/* Text input for mobile number */}
                  <TextInputIntRe12Small
                    type="text"
                    name="guestMobile"
                    placeholder={"Mobile number"}
                    value={guestMobile}
                    onChange={(e) => {
                      setGuestMobile(e.target.value);
                    }}
                    onClick={() => setGuestMobileEdit(true)}
                  />

                  {/* Text input for email id */}
                  <TextInputIntRe12Small
                    type="text"
                    name="guestEmail"
                    placeholder={"Email id"}
                    value={guestEmail}
                    onChange={(e) => {
                      setGuestEmail(e.target.value);
                    }}
                    onClick={() => setGuestEmailEdit(true)}
                  />
                </>
              ) : (
                <>
                  {/* If editing is true and card type is participant */}
                  {/* Text for startup founder's full name */}
                  {props.type === "participant" && (
                    <div style={style.paddingVert1}>
                      <SrRefTextIntLi12 content={guestName} />
                    </div>
                  )}

                  {/* Text for mobile number */}
                  <div style={style.paddingVert1}>
                    <SrRefTextIntLi12 content={guestMobile} />
                  </div>

                  {/* Text for email id */}
                  <div style={style.paddingVert1}>
                    <SrRefTextIntLi12 content={guestEmail} />
                  </div>
                </>
              )}
            </div>

            {guestEdit ? (
              <>
                {/* Buttons for reminder */}
                <div
                  style={{
                    ...style.flexRow,
                    ...style.gap12
                  }}
                >
                  {guestEdit && (
                    <>
                      <div
                        style={{
                          ...style.flexRow,
                          ...style.alignItemsCenter,
                          ...style.gap3,
                          padding: "3px 6px 3px 9px",
                          ...style.surfaceBlurNote,
                          ...style.borderRadius6,
                          ...style.clickable
                        }}
                        onClick={() => {
                          if (props.adding) {
                            handleStartupAdd();
                          } else {
                            props.type === "participant"
                              ? handleStartupEdit(props.guestId, props.orgId, "startup")
                              : handleStartupEdit(props.guestId);
                          }
                        }}
                      >
                        <SrRefTextIntRe09 content={"SAVE"} />
                        <SrCompIconDone />
                      </div>

                      <div
                        onClick={() => {
                          // props.setFetchMore(true);
                          if (props.adding) {
                            props.setStartupAdd(false);
                            props.setGuestAdd(false);
                            props.setSpecialGuestAdd(false);
                          } else setGuestEdit(false);
                          onCancel();
                        }}
                        style={{
                          ...style.flexRow,
                          ...style.alignItemsCenter,
                          ...style.gap3,
                          padding: "3px 6px 3px 9px",
                          ...style.surfaceBlurNote,
                          ...style.borderRadius6,
                          ...style.clickable
                        }}
                      >
                        <SrRefTextIntRe09 content={"CANCEL"} />
                        <SrCompIconClose />
                      </div>
                    </>
                  )}
                </div>
              </>
            ) : (
              <>
                {/* {!guestEdit && (
                <div
                  style={{
                    ...style.flexRow,
                    ...style.alignItemsCenter,
                    ...style.gap3,
                    padding: "3px 6px 3px 9px",
                    ...style.surfaceBlurNote,
                    ...style.borderRadius6,
                    ...style.clickable
                  }}
                >
                  <SrRefTextIntRe09 content={"SEND INVITE"} />
                  <SrCompIconDone />
                </div>
              )} */}
              </>
            )}
          </div>
        )}
      </div>

      {props.type === "participant" ? (
        <div style={style.flexRow}>
          <div style={style.status}>
            <div style={style.greenDot}></div>
          </div>
          <Dot status={0} />
          <Dot status={1} />
          <Dot status={2} />
          <Dot status={3} />
        </div>
      ) : (
        <div style={style.flexRow}>
          <div style={style.status}>
            <div style={style.greenDot}></div>
          </div>
          <Dot status={0} />
        </div>
      )}
    </div>
  );
};

export { DateAndLocation, SpeakersList, SponsorsList, AttendeeCard };
