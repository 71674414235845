import { useContext } from "react";
import MainContext from "../../../helpers/context/mainContext";

import OrgDetails from "../../OrgDetails";
import TeamDetails from "../../TeamDetails";
import OtherDetails from "../../OtherDetails";
import Problem from "../../Problem";
import Solution from "../../Solution";
import Infographic from "../../Infographic";
import Video from "../../Video";
import Features from "../../Features";
import Market from "../../Market";
import Traction from "../../Traction";
import Website from "../../Website";
import PitchDeck from "../../PitchDeck";
import MentorshipAreas from "../../MentorshipAreas";
import Challenges from "../../Challenges";

import style from "./style";

const Component = () => {
  const { startup, returnPrimary, fullWidth } = useContext(MainContext);
  //Dynamic and combined styling for this component
  const style2 = {
    outerWrap: {
      ...style.flexColumn,
      ...style.screen
    },
    innerWrap: {
      ...style.flexColumn,
      ...style.marginHor24,
      marginTop: `${74 + 12 + 36}px`
    },
    teamOuterWrap: {
      ...style.flexRow,
      ...style.spaceBetween,
      ...style.width100,
      ...style.minHeight127,
      ...style.marginTop36
    },

    mainSectionOuterWrap: {
      ...style.flexColumn,
      ...style.spaceBetween,
      ...style.bottom,
      ...style.marginTop36,
      ...style.marginHor24,
      borderColor: returnPrimary
    },
    bottomTopWrap: {
      ...style.flexColumn,
      ...style.spaceBetween,
      ...style.width100
    },
    bottomCenterWrap: {
      ...style.flexRowReverse,
      ...style.spaceBetween,
      ...style.width100
    },
    bottomBottomWrap: {
      ...style.flexColumn,
      ...style.spaceBetween,
      ...style.alignItemsFlexEnd,
      ...style.width100,
      ...style.marginTop24
    }
  };

  return (
    <div style={style2.outerWrap}>
      {/* Startup listing */}
      <div style={style2.innerWrap}>
        {/* Org details */}
        <OrgDetails />

        <div style={style2.teamOuterWrap}>
          {/* Team */}
          <TeamDetails startup={startup} returnPrimary={returnPrimary} />

          {/* Other */}
          <OtherDetails />
        </div>
      </div>

      <div style={style2.mainSectionOuterWrap}>
        {/* Bottom top section */}
        <div style={style2.bottomTopWrap}>
          {/* Problem */}
          <Problem />

          {/* Solution */}
          <Solution />
        </div>

        {/* Bottom center section */}
        <div style={style2.bottomCenterWrap}>
          {/* Infographic */}
          <div style={style.marginTop24}>{<Infographic w={fullWidth} startup={startup} />}</div>

          {/* Video */}
          <Video />
        </div>

        {/* Bottom bottom section */}
        <div style={style2.bottomBottomWrap}>
          {/* Features */}
          <Features />

          {/* Traction */}
          <Traction />

          {/* Target market */}
          <Market />

          {/* Website */}
          <Website />
        </div>
      </div>

      {/* BITS section */}
      <div style={style2.mainSectionOuterWrap}>
        {/* BITS top section */}
        <div style={style2.bottomTopWrap}>
          {/* Challenges */}
          <Challenges />
        </div>

        {/* BITS bottom section */}
        <div style={style2.bottomBottomWrap}>
          {/* Mentorship areas */}
          <MentorshipAreas />

          {/* Pitch deck */}
          <PitchDeck />
        </div>
      </div>
    </div>
  );
};

const EditComponent = ({ imageInputRef, setImage, setShowCropper }) => {
  // Get states from MainContext
  const { startup, returnPrimary, fullWidth } = useContext(MainContext);

  // Dynamic style for this component
  const style2 = {
    outerWrap: { ...style.flexColumn, ...style.screen },
    TopSectionWrap: {},
    teamWrap: {
      ...style.flexRow,
      ...style.width100,
      ...style.minHeight151,
      ...style.marginTop12,
      ...style.marginHor12
    },
    orgWrap: {
      ...style.flexColumn,
      ...style.marginHor12,
      ...{ marginTop: `${74 + 12 + 36 - 12}px` }
    },
    bottomSectionWrap: {
      ...style.flexColumn,
      ...style.spaceBetween,
      ...style.bottom,
      ...{
        borderColor: returnPrimary
      },
      ...style.marginTop24,
      ...style.marginHor24
    },
    bottomRightWrap: {
      ...style.flexColumn,
      ...style.spaceBetween,
      ...style.width100
    },
    bottomCenterWrap: { ...style.flexRowReverse, ...style.spaceBetween, ...style.width100 },
    bottomBottomWrap: {
      ...style.flexColumn,
      ...style.spaceBetween,
      ...style.alignItemsFlexEnd,
      ...style.width100
    },
    bitsWrap: {
      ...style.flexColumn,
      ...style.spaceBetween,
      ...style.bottom,
      ...{
        borderColor: returnPrimary
      },
      ...style.marginTop24,
      ...style.marginHor24
    },
    BITSLeftOuterDiv: {
      ...style.flexColumn,
      ...style.spaceBetween,
      ...style.width100
    },
    BITSRightOuterDiv: {
      ...style.flexColumn,
      ...style.spaceBetween,
      ...style.alignItemsFlexEnd,
      ...style.width100
    }
  };

  return (
    <div style={style2.outerWrap}>
      {/* Startup listing */}
      <div style={style2.orgWrap}>
        {/* Org details */}
        <OrgDetails />

        <div style={style2.teamWrap}>
          {/* Team */}
          <TeamDetails />

          {/* Other */}
          <OtherDetails />
        </div>
      </div>

      <div style={style2.bottomSectionWrap}>
        {/* Bottom top section */}
        <div style={style2.bottomRightWrap}>
          {/* Problem */}
          <Problem />

          {/* Solution */}
          <Solution />
        </div>

        {/* Bottom center section */}
        <div style={style2.bottomCenterWrap}>
          {/* Infographic */}
          <div>
            <Infographic
              w={fullWidth}
              startup={startup}
              imageInputRef={imageInputRef}
              setShowCropper={setShowCropper}
              setImage={setImage}
            />
          </div>

          {/* Video */}
          <Video />
        </div>

        {/* Bottom bottom section */}
        <div style={style2.bottomBottomWrap}>
          <div style={style.width100}>
            {/* Features */}
            <Features />

            {/* Unique features */}
            <Traction />

            {/* Target market */}
            <Market />
          </div>

          {/* Website */}
          <Website />
        </div>
      </div>

      {/* BITS section */}
      <div style={style2.bitsWrap}>
        {/* BITS top section */}
        <div style={style2.BITSLeftOuterDiv}>
          {/* Challenges */}
          <Challenges />
        </div>

        {/* BITS bottom section */}
        <div style={style2.BITSRightOuterDiv}>
          {/* Mentorship areas */}
          <MentorshipAreas />

          {/* Pitch deck */}
          <PitchDeck />
        </div>
      </div>
    </div>
  );
};

const SmallTablet = ({ imageInputRef, setImage, setShowCropper }) => {
  const { ifEdit } = useContext(MainContext);

  return ifEdit ? (
    <EditComponent
      imageInputRef={imageInputRef}
      setImage={setImage}
      setShowCropper={setShowCropper}
    />
  ) : (
    <Component />
  );
};

export default SmallTablet;
