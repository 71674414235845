import { useContext } from "react";
import MainContext from "../../helpers/context/mainContext";

import { SrRefTextIntSb12 } from "../../../ref/text";
import { SrRefTextIntRe12 } from "../../../ref/text";

import Link from "../Link";
import style from "./style";

// Edit Component which renders on Edit state
const EditComponent = () => {
  const { startup } = useContext(MainContext);

  return (
    <div style={style.editOuterDiv}>
      {startup.other.socialMedia.discord && <Link link={startup.other.socialMedia.discord} />}

      {startup.other.socialMedia.facebook && <Link link={startup.other.socialMedia.facebook} />}

      {startup.other.socialMedia.instagram && <Link link={startup.other.socialMedia.instagram} />}

      {startup.other.socialMedia.linkedIn && <Link link={startup.other.socialMedia.linkedIn} />}

      {startup.other.socialMedia.medium && <Link link={startup.other.socialMedia.medium} />}

      {startup.other.socialMedia.pinterest && <Link link={startup.other.socialMedia.pinterest} />}

      {startup.other.socialMedia.reddit && <Link link={startup.other.socialMedia.reddit} />}

      {startup.other.socialMedia.substack && <Link link={startup.other.socialMedia.substack} />}

      {startup.other.socialMedia.twitter && <Link link={startup.other.socialMedia.twitter} />}

      {startup.other.socialMedia.whatsapp && <Link link={startup.other.socialMedia.whatsapp} />}

      {startup.other.socialMedia.youtube && <Link link={startup.other.socialMedia.youtube} />}

      {!startup.other.socialMedia.discord &&
        !startup.other.socialMedia.facebook &&
        !startup.other.socialMedia.instagram &&
        !startup.other.socialMedia.linkedIn &&
        !startup.other.socialMedia.medium &&
        !startup.other.socialMedia.pinterest &&
        !startup.other.socialMedia.reddit &&
        !startup.other.socialMedia.substack &&
        !startup.other.socialMedia.twitter &&
        !startup.other.socialMedia.whatsapp &&
        !startup.other.socialMedia.youtube && (
          <div style={style.outerDiv}>
            <SrRefTextIntSb12 content={"Social media"} />
            <SrRefTextIntRe12 content={"handles"} style={style.marginLeft6} />
          </div>
        )}
    </div>
  );
};

// Component which renders on state
const Component = () => {
  const {
    twitter,
    discord,
    facebook,
    instagram,
    reddit,
    linkedIn,
    youtube,
    pinterest,
    medium,
    substack,
    whatsapp
  } = useContext(MainContext);

  return (
    <div style={style.outerDiv}>
      {discord && <Link link={discord} />}

      {facebook && <Link link={facebook} />}

      {instagram && <Link link={instagram} />}

      {linkedIn && <Link link={linkedIn} />}

      {medium && <Link link={medium} />}

      {pinterest && <Link link={pinterest} />}

      {reddit && <Link link={reddit} />}

      {substack && <Link link={substack} />}

      {twitter && <Link link={twitter} />}

      {whatsapp && <Link link={whatsapp} />}

      {youtube && <Link link={youtube} />}

      {!discord &&
        !facebook &&
        !instagram &&
        !linkedIn &&
        !medium &&
        !pinterest &&
        !reddit &&
        !substack &&
        !twitter &&
        !whatsapp &&
        !youtube && (
          <div style={style.outerDiv}>
            <SrRefTextIntSb12 content={"Social media "} />
            <SrRefTextIntRe12 content={" handles"} style={style.marginLeft6} />
          </div>
        )}
    </div>
  );
};

const SocialMedia = () => {
  // ifEdit state to check if user is on startup page or startupEdit page
  const { ifEdit } = useContext(MainContext);

  return ifEdit ? <EditComponent /> : <Component />;
};

export default SocialMedia;
