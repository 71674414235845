import React, { useState, useContext, useRef, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import SrRefColour from "../../../../ref/colours";
import { SrRefTextRubSb25, SrRefTextIntRe15, SrRefTextIntLi10 } from "../../../../ref/text";

import { apiendpoint } from "../../../helpers/apiendpoint";
import MainContext from "../../../helpers/context/mainContext";
import { Industries } from "../../../helpers/localData";
import { returnRGBA } from "../../../helpers/themeColors";

import { Avatar, AvatarUpload } from "../../../lvl0Components/avatar";
import { BackFAB, NextFAB } from "../../../lvl0Components/buttons/FABs";
import { Dot } from "../../../lvl0Components/dot";
import { ListInputInt12, ListPopupInt12 } from "../../../lvl0Components/listInputs";
import { TextInputIntRe12 } from "../../../lvl0Components/textInputs";
import { CropperPopup } from "../../../lvl1Components/setupPopups/cropperPopup";

import { style } from "./style";

const EditPopup1 = (props) => {
  // Main context
  const {
    breakpoint3,
    scrollBoxWidth,
    // API related,
    setupNew,
    startup,
    setStartup,
    setLoadMore
  } = useContext(MainContext);

  // Scroll view
  const [screen, setScreen] = useState(props.currentScreen === -1 ? 0 : props.currentScreen);
  const scrollRef = useRef();

  useEffect(() => {
    scrollRef.current.scrollLeft = screen * scrollBoxWidth;
  }, [screen]);

  // Snackbar
  const snackbar = (message) => {
    toast(message, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
  };

  // Startup theme colours
  const primaryColor = props.primaryColor;
  const returnPrimary = returnRGBA(primaryColor?.r, primaryColor?.g, primaryColor?.b, 1);

  // useStates
  const [orgName, setOrgName] = useState(startup.name);
  const [orgNameEdit, setOrgNameEdit] = useState(false);
  const [logo, setLogo] = useState(startup.logo);
  const [orgBrief, setOrgBrief] = useState(startup.brief);
  const [orgBriefEdit, setOrgBriefEdit] = useState(false);
  const [orgIndustry, setOrgIndustry] = useState(startup.industry);
  const [sector1, setSector1] = useState(startup.sectors[0]);
  const [sector1Edit, setSector1Edit] = useState(false);
  const [sector2, setSector2] = useState(startup.sectors[1]);
  const [sector2Edit, setSector2Edit] = useState(false);
  const [loading, setLoading] = useState(false);

  const [isOrgIndSelecting, setOrgIndSelecting] = useState(false);

  const id = Number(window.location.pathname.split("/")[3]);

  // Image
  const imageInputRef = useRef();
  const [image, setImage] = useState(null);
  const [showCropper, setShowCropper] = useState(false);
  const [lenOfDesc1, setLenOfDesc1] = useState(0);
  const [lenOfDesc2, setLenOfDesc2] = useState(0);
  const [lenOfDesc3, setLenOfDesc3] = useState(0);

  // Upload image
  const uploadFile = (image) => {
    var jwt = localStorage.getItem("@jwtauth");
    if (!jwt) jwt = "";

    if (image.size / 1024 / 1024 <= 10) {
      const fd = new FormData();
      fd.append("file", image);

      fetch(`${apiendpoint}/empCreateOrg/orgLogo`, {
        method: "POST",
        body: fd,
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      })
        .then((response) => {
          if (response.ok) return response.json();
          else {
            throw new Error("unauthorised");
          }
        })
        .then((json) => {
          setLogo(json.url);
          setStartup({
            ...startup,
            logo: json.url
          });
          setShowCropper(false);
          snackbar("Image uploaded successfully");
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setShowCropper(false);
          snackbar("An error occurred, please try again.");
          setLoading(false);
        });
    } else {
      setShowCropper(false);
      snackbar("Image size should be less than 10mb");
    }
  };

  const saveData = () => {
    const body = {
      name: orgName,
      logo,
      brief: orgBrief,
      industry: orgIndustry,
      sector1,
      sector2
    };
    var jwt = localStorage.getItem("@jwtauth");
    if (!jwt) jwt = "";

    fetch(`${apiendpoint}/platform/phase2/${id}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`
      },
      body: JSON.stringify(body)
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw new Error("Unauthorized");
      })
      .then((json) => {
        setLoadMore(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    return saveData;
  }, [orgName, logo, orgBrief, orgIndustry, sector1, sector2]);

  //dynamic styling for this component
  const style2 = {
    backgroundWrap: {
      ...style.alignItemsCenter,
      justifyContent: breakpoint3 ? "none" : "center"
    },
    cardContainer: {
      ...style.cardContainer,
      width: `${scrollBoxWidth}px`,
      border: breakpoint3 ? "none" : `0.5px solid ${SrRefColour.Neutral2B}`,
      marginTop: breakpoint3 ? "24px" : 0
    },
    scrollViewContainer: {
      ...style.scrollWrap,
      ...style.marginTop48,
      width: `${scrollBoxWidth}px`
    },
    nameContainer: {
      ...style.paddingHor24,
      width: `${scrollBoxWidth}px`
    }
  };

  return (
    <div>
      {/* Background */}
      <div style={style2.backgroundWrap}>
        {/* Bordered card */}
        <div style={style2.cardContainer}>
          {/* Shram logo at the top */}
          <div style={style.logoOuterWrap}>
            <div style={style.logoInnerWrap}>
              {/* User org logo */}
              <Avatar pic={props.startupLogo} width={50} borderColor={returnPrimary} />

              {/* User org name */}
              <SrRefTextRubSb25 content={props.startupName} style={style.marginLeft12} />
            </div>
          </div>

          {/* Scroll view */}
          <div style={style2.scrollViewContainer} ref={scrollRef}>
            {/* Upload org logo screen */}
            <div style={style.scroll}>
              <div style={{ ...{ width: `${scrollBoxWidth}px` }, ...style.paddingHor24 }}>
                {/* Text */}
                <SrRefTextIntRe15
                  content={"Upload your organization's logo"}
                  state={"enabled"}
                  style={style.colorBlack}
                />

                {/* Image upload avatar */}
                <div style={style.uploadWrap}>
                  <AvatarUpload
                    width={122}
                    pic={setupNew.addOrg.logo ? setupNew.addOrg.logo : logo}
                    imageInputRef={imageInputRef}
                    onChange={(e) => {
                      let files;
                      if (e.dataTransfer) {
                        files = e.dataTransfer.files;
                      } else if (e.target) {
                        files = e.target.files;
                      }
                      const reader = new FileReader();
                      reader.onload = () => {
                        setImage(reader.result);
                      };
                      reader.readAsDataURL(files[0]);
                      setShowCropper(true);
                    }}
                    innerWidth={6}
                  />
                </div>

                {/* Note to users */}
                {/* <div style={style.marginTop24}>
                  <SrRefTextIntLi10
                    content={
                      "Sadly, this image picker only accepts images less than size 1mb and resolution 1000x1000px. Sorry :)"
                    }
                  />
                </div> */}
              </div>
            </div>

            {/* Enter org name screen */}
            <div style={style.scroll}>
              <div style={style2.nameContainer}>
                {/* Text */}
                <SrRefTextIntRe15
                  content={"What is your organization called?"}
                  state={"enabled"}
                  style={style.colorBlack}
                />

                {/* Input for org name */}
                <div style={style.marginTop18}>
                  <TextInputIntRe12
                    type="text"
                    name="orgName"
                    isShown={true}
                    limit="25"
                    placeholder={"Organization's short name"}
                    value={orgName}
                    onChange={(e) => {
                      if (e.target.value.length < 26) {
                        setOrgName(e.target.value),
                          setStartup({
                            ...startup,
                            name: e.target.value.trim()
                          });
                      }
                    }}
                    onClick={() => setOrgNameEdit(true)}
                  />
                </div>
              </div>
            </div>

            {/* Enter org brief screen */}
            <div style={style.scroll}>
              <div style={style2.nameContainer}>
                {/* Text */}
                <SrRefTextIntRe15
                  content={"Describe what you do in one line"}
                  state={"enabled"}
                  style={style.colorBlack}
                />

                {/* Input for org brief */}
                <div style={style.marginTop18}>
                  <TextInputIntRe12
                    type="text"
                    name="orgBrief"
                    isShown={true}
                    limit="50"
                    placeholder={`"Food libraries in your neighbourhood"`}
                    value={orgBrief}
                    onChange={(e) => {
                      if (e.target.value.length < 51) {
                        setOrgBrief(e.target.value),
                          setStartup({
                            ...startup,
                            brief: e.target.value.trim()
                          });
                      }
                    }}
                    onClick={() => setOrgBriefEdit(true)}
                  />
                </div>
              </div>
            </div>

            {/* Enter org industry screen */}
            <div style={style.scroll}>
              <div style={style2.nameContainer}>
                {/* Text */}
                <SrRefTextIntRe15
                  content={"What Industry do you belong to?"}
                  state={"enabled"}
                  style={style.colorBlack}
                />

                {/* List input for industry */}
                <div style={style.marginTop18}>
                  <ListInputInt12
                    onPress={() =>
                      isOrgIndSelecting ? setOrgIndSelecting(false) : setOrgIndSelecting(true)
                    }
                    placeholder={"Industry"}
                    condition={orgIndustry !== -1}
                    // -1 because industry are stored in array in
                    // frontend which starts from 0 and industry in db from 1
                    content={Industries[orgIndustry - 1]}
                  />
                </div>

                {/* List popup for industry */}
                {isOrgIndSelecting && (
                  <ListPopupInt12
                    array={Industries}
                    value={orgIndustry}
                    setValue={setOrgIndustry}
                    setValueSelect={setOrgIndSelecting}
                    // If setting up industry through the setup guide
                    setupIndustry={true}
                    setStartup={setStartup}
                    startup={startup}
                  />
                )}
              </div>
            </div>

            {/* Enter sectors screen */}
            <div style={style.scroll}>
              <div style={style2.nameContainer}>
                {/* Text */}
                <SrRefTextIntRe15
                  content={"Add two sectors you are related to"}
                  state={"enabled"}
                  style={style.colorBlack}
                />

                {/* Input for sector 1 */}
                <div style={style.marginTop18}>
                  <TextInputIntRe12
                    type="text"
                    name="sector1"
                    isShown={true}
                    limit="20"
                    placeholder={"For ex: Food delivery"}
                    topText={"Sector 1"}
                    value={sector1}
                    onChange={(e) => {
                      if (e.target.value.length < 21) {
                        setSector1(e.target.value),
                          setStartup({
                            ...startup,
                            sectors: [...[e.target.value.trim(), sector2]]
                          });
                      }
                    }}
                    onClick={() => setSector1Edit(true)}
                  />
                </div>

                {/* Input for sector 2 */}
                <div style={style.marginTop18}>
                  <TextInputIntRe12
                    type="text"
                    name="sector2"
                    isShown={true}
                    limit="20"
                    placeholder={"For ex: Logistics"}
                    topText={"Sector 2"}
                    value={sector2}
                    onChange={(e) => {
                      if (e.target.value.length < 21) {
                        setSector2(e.target.value),
                          setStartup({
                            ...startup,
                            sectors: [...[sector1, e.target.value.trim()]]
                          });
                      }
                    }}
                    onClick={() => setSector2Edit(true)}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Back button */}
          <BackFAB
            onClick={() =>
              screen === 0
                ? [props.setEdit(false), props.setEditHover(false), props.allowScroll()]
                : setScreen((p) => p - 1)
            }
          />

          {/* Dot carousal */}
          <div style={style.dotArray}>
            <Dot screen={screen} actualScreen={0} />
            <Dot screen={screen} actualScreen={1} />
            <Dot screen={screen} actualScreen={2} />
            <Dot screen={screen} actualScreen={3} />
            <Dot screen={screen} actualScreen={4} />
          </div>

          {/* Next button */}
          <NextFAB
            content={screen === 4 ? "Done" : "Next"}
            onClick={() =>
              screen === 4
                ? [props.setEdit(false), props.setEditHover(false), props.allowScroll()]
                : setScreen((p) => p + 1)
            }
          />

          {/* Image cropper */}
          {showCropper && <CropperPopup image={image} onClick={uploadFile} setImage={setImage} />}
        </div>
      </div>

      {/* Snackbar */}
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
};

export default EditPopup1;
