import SrRefColour from "../../../../ref/colours";

const style = {
  flexRow: {
    display: "flex",
    flexDirection: "row"
  },
  alignItemsCenter: {
    display: "flex",
    alignItems: "center"
  },
  justifyContentCenter: {
    display: "flex",
    justifyContent: "center"
  },
  shell: {
    padding: "12px",
    border: `1px solid ${SrRefColour.Neutral2A}`,
    borderRadius: "12px",
    backgroundColor: "rgb(255,255,255,0.8)"
  },
  shell2: {
    maxHeight: "50px",
    padding: "14px 17px",
    border: `1px solid ${SrRefColour.Neutral2A}`,
    borderRadius: "12px",
    backgroundColor: "rgb(255,255,255,0.8)"
  },
  maxWidth: {
    width: "max-content"
  },
  icon20: { width: "20px", height: "20px" },
  progressBar: {
    position: "absolute",
    bottom: 1,
    height: "calc(100% - 2px)",
    borderRadius: "12px",
    zIndex: 0
  },
  icon18: { width: "20px", height: "20px" },
  positionRelative: {
    position: "relative"
  },
  icon24: { width: "24px", height: "24px" },
  userPanelPic: {
    width: "fit-content",
    padding: "4.5px",
    border: `1px solid ${SrRefColour.Neutral2B}`,
    borderRadius: "50px",
    backgroundColor: "rgb(255,255,255,0.8)"
  },
  borderRadius39: {
    borderRadius: "39px"
  },
  clickable: {
    cursor: "pointer",
    border: "none",
    textDecoration: "none"
  },
  transition: {
    transition: "0.2s ease-in"
  },

  // Widths, heights and margins
  maxHeight39: { maxHeight: "39px" },
  marginHor6: {
    marginLeft: "6px",
    marginRight: "6px"
  },
  marginLeft6: {
    marginLeft: "6px"
  },
  marginLeft9: {
    marginLeft: "9px"
  },
  marginLeft12: {
    marginLeft: "12px"
  },

  // Custom styling
  outerDiv: {
    position: "relative",
    cursor: "pointer",
    border: "none",
    textDecoration: "none"
  },

  iconStyle: {
    width: "20px",
    height: "20px",
    marginLeft: "6px"
  },
  logo: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  inputStyle: {
    marginLeft: "6px",
    transition: "0.2s ease-in"
  },

  // Custom style for HomeBtn
  homeInnerDiv: {
    padding: "12px",
    border: `1px solid ${SrRefColour.Neutral2A}`,
    borderRadius: "12px",
    backgroundColor: "rgb(255,255,255,0.8)",
    transition: "0.2s ease-in"
  },

  // Custom style for ThumbUpBtn
  thumbInnerDiv: {
    padding: "12px",
    border: `1px solid ${SrRefColour.Neutral2A}`,
    borderRadius: "12px",
    backgroundColor: "rgb(255,255,255,0.8)",
    transition: "0.2s ease-in"
  },
  thumbInputContainer: {
    position: "absolute",
    bottom: "-5px",
    right: "-5px",
    width: "20px",
    height: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "10px"
  },

  // Custom style for UploadBtn component
  uploadInputContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    maxHeight: "50px",
    padding: "14px 17px",
    border: `1px solid ${SrRefColour.Neutral2A}`,
    borderRadius: "12px",
    backgroundColor: "rgb(255,255,255,0.8)",
    transition: "0.2s ease-in"
  },

  // Custom style for LogoutBtn component
  logoutPhoneContainer: {
    padding: "12px",
    border: `1px solid ${SrRefColour.Neutral2A}`,
    borderRadius: "12px",
    backgroundColor: "rgb(255,255,255,0.8)",
    transition: "0.2s ease-in"
  },

  logoutScreenContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    maxHeight: "50px",
    padding: "14px 17px",
    border: `1px solid ${SrRefColour.Neutral2A}`,
    borderRadius: "12px",
    backgroundColor: "rgb(255,255,255,0.8)",
    transition: "0.2s ease-in"
  },

  // Custom style for AccountBtn
  accountPhoneContainer: {
    padding: "12px",
    border: `1px solid ${SrRefColour.Neutral2A}`,
    borderRadius: "12px",
    backgroundColor: "rgb(255,255,255,0.8)",
    transition: "0.2s ease-in"
  },

  accountScreenContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    maxHeight: "50px",
    padding: "14px 17px",
    border: `1px solid ${SrRefColour.Neutral2A}`,
    borderRadius: "12px",
    backgroundColor: "rgb(255,255,255,0.8)",
    transition: "0.2s ease-in"
  },

  // Custom style for EditBtn component
  editPhoneContainer: {
    padding: "12px",
    border: `1px solid ${SrRefColour.Neutral2A}`,
    borderRadius: "12px",
    backgroundColor: "rgb(255,255,255,0.8)",
    transition: "0.2s ease-in"
  },

  editScreenContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    maxHeight: "50px",
    padding: "14px 17px",
    border: `1px solid ${SrRefColour.Neutral2A}`,
    borderRadius: "12px",
    backgroundColor: "rgb(255,255,255,0.8)",
    transition: "0.2s ease-in"
  },

  // Custom styling for SaveBtn component
  savePhoneContainer: {
    padding: "12px",
    border: `1px solid ${SrRefColour.Neutral2A}`,
    borderRadius: "12px",
    backgroundColor: "rgb(255,255,255,0.8)",
    transition: "0.2s ease-in"
  },
  saveScreenContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    maxHeight: "50px",
    padding: "14px 17px",
    border: `1px solid ${SrRefColour.Neutral2A}`,
    borderRadius: "12px",
    backgroundColor: "rgb(255,255,255,0.8)",
    transition: "0.2s ease-in"
  },

  // Custom style for PreviewBtn component
  previewPhoneContainer: {
    padding: "12px",
    border: `1px solid ${SrRefColour.Neutral2A}`,
    borderRadius: "12px",
    backgroundColor: "rgb(255,255,255,0.8)",
    transition: "0.2s ease-in"
  },

  previewScreenContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    maxHeight: "50px",
    padding: "14px 17px",
    border: `1px solid ${SrRefColour.Neutral2A}`,
    borderRadius: "12px",
    backgroundColor: "rgb(255,255,255,0.8)",
    transition: "0.2s ease-in"
  },

  // Custom style for UserPanelButton
  userOuterDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    transition: "0.2s ease-in",
    width: "fit-content",
    padding: "4.5px",
    border: `1px solid ${SrRefColour.Neutral2B}`,
    borderRadius: "50px",
    backgroundColor: "rgb(255,255,255,0.8)"
  }
};

export default style;
