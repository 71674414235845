import SrRefColour from "../../../ref/colours";

const style = {
  flexRow: {
    display: "flex",
    flexDirection: "row"
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column"
  },
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between"
  },
  justifyContentCenter: {
    display: "flex",
    justifyContent: "center"
  },
  alignItemsCenter: {
    display: "flex",
    alignItems: "center"
  },
  overflowAuto: {
    overflow: "auto"
  },
  userPanelWrap: {
    position: "fixed",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    margin: "0 auto",
    paddingBottom: "12px"
  },
  surface: {
    // height: "100px",
    padding: "12px 0 6px",
    borderRadius: "18px"
  },
  surfaceDim: {
    // height: "100px",
    maxWidth: "1600px"
  },
  surfaceBorder: {
    border: `0.5px solid ${SrRefColour.Neutral2A}`,
    borderRadius: "18px",
    zIndex: 10
  },
  surfaceBlur: {
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    backgroundImage:
      "linear-gradient(to bottom right, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0))",
    backdropFilter: "blur(9px)",
    "-webkit-backdrop-filter": "blur(9px)"
  },
  positionRelative: {
    position: "relative"
  },
  positionAbsolute: {
    position: "absolute",
    zIndex: 8
  },
  clickable: {
    cursor: "pointer"
  },
  transition: {
    transition: "0.2s ease-in"
  },
  verticalLine: {
    minWidth: "0.5px",
    width: "0.5px",
    height: "36px",
    marginLeft: "12px",
    marginRight: "12px",
    backgroundColor: SrRefColour.Neutral2A
  },
  colorGrey: {
    color: SrRefColour.Neutral2A
  },
  paddingHor6: { paddingLeft: "6px", paddingRight: "6px" },
  paddingBot6: { paddingBottom: "6px" },

  // Widths, heights and margins
  marginTop6: {
    marginTop: "6px"
  },
  marginHor6: {
    marginLeft: "6px",
    marginRight: "6px"
  },
  marginLeft3: {
    marginLeft: "3px"
  },
  marginLeft6: {
    marginLeft: "6px"
  }
};

export default style;
