import SrRefColour from "../../../../ref/colours";

const style = {
  flexRow: {
    display: "flex",
    flexDirection: "row"
  },
  alignItemsCenter: {
    display: "flex",
    alignItems: "center"
  },
  card: {
    position: "relative",
    padding: "6px 12px",
    border: `1px solid ${SrRefColour.Neutral2A}`,
    borderRadius: "12px",
    transition: "0.2s ease-in",
    zIndex: 1
  },
  surfaceBlur: {
    // backgroundColor: "rgba(255, 255, 255, 0.8)",
    backgroundImage:
      "linear-gradient(to bottom right, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0))"
    // backdropFilter: "blur(9px)",,
    // "-webkit-backdrop-filter": "blur(9px)"
  },
  positionRelative: {
    position: "relative"
  },
  avatarShell: {
    padding: "1.5px",
    borderWidth: "1px",
    borderStyle: "solid",
    backgroundColor: SrRefColour.Neutral0
  },
  logo: {
    width: "36px",
    height: "36px",
    borderRadius: "36px"
  },
  maxContent: {
    width: "max-content"
  },
  clickable: {
    cursor: "pointer",
    border: "none",
    textDecoration: "none"
  },
  transition: {
    transition: "0.2s ease-in"
  },

  // Widths, heights and margins
  marginHor6: {
    marginLeft: "6px",
    marginRight: "6px"
  },
  marginLeft6: {
    marginLeft: "6px"
  },

  // Custom styling for component
  textStyle: {
    marginLeft: "6px",
    width: "max-content"
  },

  cardContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    position: "relative",
    padding: "6px 12px",
    border: `1px solid ${SrRefColour.Neutral2A}`,
    borderRadius: "12px",
    transition: "0.2s ease-in",
    zIndex: 1,
    // backgroundColor: "rgba(255, 255, 255, 0.8)",
    backgroundImage:
      "linear-gradient(to bottom right, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0))"
    // backdropFilter: "blur(9px)",,
    // "-webkit-backdrop-filter": "blur(9px)",
  },

  blurEffect: {
    position: "absolute",
    top: "-12px",
    left: "0px",
    zIndex: 0
  }
};

export default style;
