import React, { useState, useContext, useRef, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import SrRefColour from "../../../../ref/colours";
import { SrRefTextRubSb25, SrRefTextIntRe15, SrRefTextIntLi10 } from "../../../../ref/text";

import { apiendpoint } from "../../../helpers/apiendpoint";
import { Industries, StageArrShort } from "../../../helpers/localData";
import MainContext from "../../../helpers/context/mainContext";
import { returnRGBA } from "../../../helpers/themeColors";

import { Avatar, AvatarUpload } from "../../../lvl0Components/avatar";
import { BackFAB, NextFAB } from "../../../lvl0Components/buttons/FABs";
import { Dot } from "../../../lvl0Components/dot";
import { TextInputIntRe12 } from "../../../lvl0Components/textInputs";
import { CropperPopup } from "../cropperPopup";

import { style } from "./style";

const AccountPopup = (props) => {
  // Main context
  const {
    breakpoint3,
    scrollBoxWidth,
    defaultColor,
    // API related,
    user,
    userStartup,
    setUserStartup,
    loadUser,
    setUser,
    setupNew,
    setNew
  } = useContext(MainContext);

  // Scroll view
  const [screen, setScreen] = useState(0);
  const scrollRef = useRef();

  useEffect(() => {
    scrollRef.current.scrollLeft = screen * scrollBoxWidth;
  }, [screen]);

  // Snackbar
  const snackbar = (message) => {
    toast(message, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
  };

  // Startup theme colours
  const primaryColor = userStartup ? userStartup.theme?.primary.color : "rgba(200,200,200,1)";
  const returnPrimary = returnRGBA(primaryColor.r, primaryColor.g, primaryColor.b, 1);

  // useStates
  const [fName, setFName] = useState(user.fname);
  const [fNameEdit, setFNameEdit] = useState(false);
  const [lName, setLName] = useState(user.lname);
  const [lNameEdit, setLNameEdit] = useState(false);
  const [pp, setPP] = useState(user.pic);
  const [emailId, setEmailId] = useState(user.emailId);
  const [emailIdEdit, setEmailIdEdit] = useState("");
  const [phoneNoWA, setPhoneNoWA] = useState(user.whatsappNo);
  const [phoneNoWAEdit, setPhoneNoWAEdit] = useState("");
  const [linkHover, setLinkHover] = useState(false);
  const [designation, setDesignation] = useState(user.designation);
  const [designationEdit, setDesignationEdit] = useState("");
  const [loading, setLoading] = useState(false);

  // Remove first character if it is 0
  const remove0 = (num) => {
    if (num.charAt(0) === "0") {
      num = num.substring(1);
    }
    return num;
  };

  // checks if digits in the mobile number is equal to 10
  const validatePhoneNumber = (num) => {
    const regEx = /^\d{10}$/;
    return regEx.test(num);
  };

  // Does email validation
  const validateEmail = (email) => {
    const regEx =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;
    return regEx.test(email);
  };

  // Image
  const imageInputRef = useRef();
  const [image, setImage] = useState(null);
  const [showCropper, setShowCropper] = useState(false);

  // Upload image
  const uploadFile = (image) => {
    var jwt = localStorage.getItem("@jwtauth");
    if (!jwt) jwt = "";

    if (image.size / 1024 / 1024 <= 10) {
      const fd = new FormData();
      fd.append("file", image);

      fetch(`${apiendpoint}/empCreateOrg/empPP`, {
        method: "POST",
        body: fd,
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      })
        .then((response) => {
          if (response.ok) return response.json();
          else {
            throw new Error("unauthorised");
          }
        })
        .then((json) => {
          setPP(json.url);
          setNew({
            ...setupNew,
            addEmp: {
              ...setupNew.addEmp,
              pp: json.url
            }
          });
          setShowCropper(false);
          snackbar("Image uploaded successfully");
          setLoading(false);
        })
        .catch((error) => {
          setShowCropper(false);
          snackbar("An error occurred, please try again.");
          setLoading(false);
        });
    } else {
      setShowCropper(false);
      snackbar("Image size should be less than 10mb");
    }
  };

  const editUser = () => {
    var jwt = localStorage.getItem("@jwtauth");
    if (!jwt) jwt = "";

    const body = {
      changeEmpDetails: [
        {
          id: user.id,
          fname: fName,
          lname: lName,
          picture: pp,
          email_id: emailId,
          whatsapp_no: phoneNoWA,
          designation: designation
        }
      ]
    };

    fetch(`${apiendpoint}/platform/orgUser`, {
      method: "PUT",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${jwt}`
      },
      body: JSON.stringify(body)
    })
      .then((res) => {
        loadUser();
        if (res.ok) return res.json();
        else throw new Error("Unauthorized");
      })
      .then((json) => {
        var temp = user;
        temp = {
          ...temp,
          fname: fName,
          lname: lName,
          pic: pp,
          emailId,
          whatsappNo: phoneNoWA,
          designation
        };
        setUser(temp);
        localStorage.setItem("@user", JSON.stringify(temp));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    return editUser;
  }, [fName, lName, pp, emailId, phoneNoWA, designation]);

  // Set user's startup
  const fetchUserStartupData = async (jwt, orgId) => {
    if (!jwt || !orgId) return;
    fetch(`${apiendpoint}/platform/org/${orgId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`
      }
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw new Error("Unauthorized");
      })
      .then((data) => {
        const startup = {
          id: data.org.id,
          name: data.org.name,
          description: data.org.description,
          logo: data.org.logo,
          theme: {
            primary: { color: data.org.primary_colour ? data.org.primary_colour : defaultColor },
            secondary: {
              color: data.org.secondary_colour
            },
            tertiary: { color: data.org.tertiary_colour },
            accent: { color: data.org.accent_colour },
            invertBackground: data.org.invert_background
          },
          industry: Industries[data.org.industry],
          sectors: [data.org.sector1, data.org.sector2],

          team: data.orgMembers.map((lead) => {
            return {
              id: lead.id,
              fname: lead.fname,
              lname: lead.lname,
              designation: lead.designation,
              pic: lead.picture,
              linkedIn: lead.linkedin,
              orgId: lead.org_id,
              orgLead: lead.is_org_lead,
              representer: lead.representer
            };
          }),
          cofounderCount: data.org.cofounder_count,
          teamsize: data.org.team_size,
          other: {
            year: data.org.establishment_year,
            location: data.org.location,
            zaubacorp: data.org.zaubacorp,
            stage: StageArrShort.indexOf(data.org.stage),
            socialMedia: {
              discord: data.org.discord,
              facebook: data.org.facebook,
              instagram: data.org.instagram,
              linkedIn: data.org.linkedin,
              medium: data.org.medium,
              pinterest: data.org.pinterest,
              reddit: data.org.reddit,
              substack: data.org.substack,
              twitter: data.org.twitter,
              whatsapp: data.org.whatsapp,
              youtube: data.org.youtube
            },
            status: data.org.org_status
          },
          pitch: {
            problem: data.platform.problem,
            solution: data.platform.solution,
            image: data.platform.image,
            video: {
              link: data.platform.video,
              thumbnail: data.platform.thumbnail
            },
            website: data.org.website,
            features: data.platform.features,
            unique: data.platform.unique_features,
            market: data.platform.market
          }
        };

        setUserStartup(startup);
      });
  };

  // Set user
  useEffect(() => {
    try {
      let jwt = localStorage.getItem("@jwtauth");
      if (!jwt) jwt = "";
      if (!user)
        fetch(`${apiendpoint}/platform/orgUser`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwt}`
          }
        })
          .then((res) => {
            if (res.ok) return res.json();
            else throw new Error("Unauthorized");
          })
          .then(({ employee: data }) => {
            const currentUser = {
              id: data.id,
              fname: data.fname,
              lname: data.lname,
              designation: data.designation,
              pic: data.picture,
              linkedIn: data.linkedin,
              orgId: data.org_id,
              userType: data.user_type,
              orgLead: data.is_org_lead,
              representer: data.representer,
              whatsappNo: data.whatsapp_no,
              emailId: data.email_id
            };
            setUser(currentUser);
            localStorage.setItem("@user", JSON.stringify(currentUser));
            if (data.org_id) fetchUserStartupData(jwt, data.org_id);
          })
          .catch((err) => console.log(err));
      else if (user.orgId) fetchUserStartupData(jwt, user.orgId);
    } catch (err) {
      console.log(err);
    }
  }, []);

  // handleSubmit
  const handleSubmit = async () => {
    if (screen === 0 && (fName === "" || lName === ""))
      snackbar("Please write your full name to continue");
    else if (screen === 2 && !validateEmail(emailId) && !validatePhoneNumber(phoneNoWA))
      snackbar("Please add your contact details to continue");
    else if (screen === 2 && !validateEmail(emailId) && validatePhoneNumber(phoneNoWA))
      snackbar("Please enter a valid email address");
    else if (screen === 2 && validateEmail(emailId) && !validatePhoneNumber(phoneNoWA))
      snackbar("Please enter a valid mobile number");
    else if (screen === 3 && designation === "")
      snackbar("Please write the designation you hold in your organization");
    else if (screen === 3 && designation !== "") {
      setScreen(screen + 1);
      setTimeout(() => {
        editUser();
        setScreen(0);
        props.setEdit(false);
      }, 2000);
    } else setScreen(screen + 1);
  };

  //for dynamic styling
  const style2 = {
    background: {
      ...style.alignItemsCenter,
      justifyContent: breakpoint3 ? "none" : "center"
    },
    cardContainer: {
      ...style.cardContainer,
      width: `${scrollBoxWidth}px`,
      border: breakpoint3 ? "none" : `0.5px solid ${SrRefColour.Neutral2B}`,
      marginTop: breakpoint3 ? "24px" : 0
    },
    scrollViewContainer: {
      ...style.scrollViewContainer,
      width: `${scrollBoxWidth}px`
    },
    textWrap: {
      width: `${scrollBoxWidth}px`,
      ...style.paddingHor24
    }
  };

  return (
    <div>
      {/* Background */}
      <div style={style2.background}>
        {/* Bordered card */}
        <div style={style2.cardContainer}>
          {/* Shram logo at the top */}
          <div style={style.logo}>
            {user.userType === "emp" ? (
              <div style={style.logoInnerDiv}>
                {/* User org logo */}
                <Avatar pic={userStartup?.logo} width={50} borderColor={returnPrimary} />

                {/* User org name */}
                <SrRefTextRubSb25 content={userStartup?.name} style={style.marginLeft12} />
              </div>
            ) : (
              // Shram logo at the top
              <div style={style.logo}>
                <img src={require("../../../../media/logo.png")} height={50} alt={"Shram logo"} />
              </div>
            )}
          </div>

          {/* Scroll view */}
          <div style={style2.scrollViewContainer} ref={scrollRef}>
            {/* Enter name screen */}
            <div style={style.scroll}>
              <div style={style2.textWrap}>
                {/* Text */}
                <SrRefTextIntRe15
                  content={"What is your name?"}
                  state={"enabled"}
                  style={style.colorBlack}
                />

                {/* Input for first name */}
                <div style={style.marginTop18}>
                  <TextInputIntRe12
                    type="text"
                    name="fName"
                    isShown={true}
                    limit="20"
                    placeholder={"First name"}
                    topText={"First name"}
                    value={fName}
                    onChange={(e) => {
                      if (e.target.value.length < 21) {
                        setFName(e.target.value);
                      }
                    }}
                    onClick={() => setFNameEdit(true)}
                  />
                </div>

                {/* Input for last name */}
                <div style={style.marginTop18}>
                  <TextInputIntRe12
                    type="text"
                    name="lName"
                    isShown={true}
                    limit="20"
                    placeholder={"Last name"}
                    topText={"Last name"}
                    value={lName}
                    onChange={(e) => {
                      if (e.target.value.length < 21) {
                        setLName(e.target.value);
                      }
                    }}
                    onClick={() => setLNameEdit(true)}
                  />
                </div>
              </div>
            </div>

            {/* Upload profile pic screen */}
            <div style={style.scroll}>
              <div style={{ ...{ width: `${scrollBoxWidth}px` }, ...style.paddingHor24 }}>
                {/* Text */}
                <SrRefTextIntRe15
                  content={"Upload your profile picture"}
                  state={"enabled"}
                  style={style.colorBlack}
                />

                {/* Image upload avatar */}
                <div style={style.avatarWrap}>
                  <AvatarUpload
                    width={122}
                    pic={setupNew.addEmp.pp ? setupNew.addEmp.pp : pp}
                    imageInputRef={imageInputRef}
                    onChange={(e) => {
                      let files;
                      if (e.dataTransfer) {
                        files = e.dataTransfer.files;
                      } else if (e.target) {
                        files = e.target.files;
                      }
                      const reader = new FileReader();
                      reader.onload = () => {
                        setImage(reader.result);
                      };
                      reader.readAsDataURL(files[0]);
                      setShowCropper(true);
                    }}
                    innerWidth={6}
                  />
                </div>

                {/* Note to users */}
                {/* <div style={style.marginTop24}>
                  <SrRefTextIntLi10
                    content={
                      "Sadly, this image picker only accepts images less than size 1mb and resolution 1000x1000px. Sorry :)"
                    }
                  />
                </div> */}
              </div>
            </div>

            {/* Enter contact details screen */}
            <div style={style.scroll}>
              <div style={style2.textWrap}>
                {/* Text */}
                <SrRefTextIntRe15
                  content={"What are your other contact details?"}
                  state={"enabled"}
                  style={style.colorBlack}
                />

                {/* User email id text input */}
                <div style={style.marginTop18}>
                  <TextInputIntRe12
                    type="text"
                    name="emailId"
                    placeholder={"Email ID"}
                    topText={"Email ID"}
                    value={emailId}
                    onChange={(e) => setEmailId(e.target.value)}
                    onClick={() => setEmailIdEdit(true)}
                    noLimit={true}
                  />
                </div>

                {/* User phone no text input */}
                <div style={style.marginTop18}>
                  <TextInputIntRe12
                    type="number"
                    name="phoneNoWA"
                    placeholder={"Phone Number"}
                    topText={"Phone Number"}
                    value={phoneNoWA}
                    onChange={(e) => setPhoneNoWA(e.target.value)}
                    onClick={() => setPhoneNoWAEdit(true)}
                    noLimit={true}
                  />
                </div>
              </div>
            </div>

            {/* Enter designation screen */}
            <div style={style.scroll}>
              <div style={style2.textWrap}>
                {/* Text */}
                <SrRefTextIntRe15
                  content={"What is your designation?"}
                  state={"enabled"}
                  style={style.colorBlack}
                />

                {/* Input for designation */}
                <div style={style.marginTop18}>
                  <TextInputIntRe12
                    type="text"
                    name="designation"
                    isShown={true}
                    limit="20"
                    placeholder={`For ex: "Software Developer"`}
                    value={designation}
                    onChange={(e) => {
                      if (e.target.value.length < 21) {
                        setDesignation(e.target.value);
                      }
                    }}
                    onClick={() => setDesignationEdit(true)}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Back button */}
          <BackFAB
            onClick={() =>
              screen === 0
                ? [props.setEdit(false), props.setEditHover(false), props.allowScroll()]
                : setScreen((p) => p - 1)
            }
          />

          {/* Dot carousal */}
          <div style={style.dotArray}>
            <Dot screen={screen} actualScreen={0} />
            <Dot screen={screen} actualScreen={1} />
            <Dot screen={screen} actualScreen={2} />
            <Dot screen={screen} actualScreen={3} />
          </div>

          {/* Next button */}
          <NextFAB content={screen === 3 ? "Done" : "Next"} onClick={() => handleSubmit()} />

          {/* Image cropper */}
          {showCropper && <CropperPopup image={image} onClick={uploadFile} setImage={setImage} />}
        </div>
      </div>

      {/* Snackbar */}
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
};

export default AccountPopup;
