import { useContext } from "react";
import MainContext from "../../helpers/context/mainContext";

import { SrRefTextIntSb15 } from "../../../ref/text";
import { gradientBgTextBox, gradientBorderTextBox, gradientText } from "../../helpers/themeColors";
import { newLineText } from "../../helpers/themeColors";
import SrRefColour from "../../../ref/colours";

import { EditBtn0 } from "../../lvl0Components/buttons/iconBtns";

import style from "./style";

// MainComponent which renders on startup page and startupEdit page with some extra styling
const MainComponent = () => {
  const { startup, secondaryColor, primaryColor, invertBackground } = useContext(MainContext);

  //Dynamic styling for this component
  const style2 = {
    outerDiv: {
      ...style.outerDiv,
      ...gradientBgTextBox(secondaryColor, primaryColor, 0.6, invertBackground),
      ...gradientBorderTextBox(secondaryColor, primaryColor, invertBackground)
    }
  };

  return (
    <div style={style2.outerDiv}>
      <SrRefTextIntSb15
        content={"Unique features"}
        style={gradientText(secondaryColor, primaryColor, invertBackground)}
      />

      {/* Features text */}
      <div style={style.marginTop6}>
        {startup.pitch.features
          ? newLineText(startup.pitch.features, secondaryColor, primaryColor, invertBackground)
          : newLineText(
              "Articulate the features of your product/service. These need not be unique but should paint a picture of what a user can do with it.",
              secondaryColor,
              primaryColor,
              invertBackground
            )}
      </div>
    </div>
  );
};

const Features = () => {
  // ifEdit state to check if user is on startup page or startupEdit page
  const {
    ifEdit,
    startup,
    secondaryColor,
    primaryColor,
    invertBackground,
    setEdit6,
    setAllHoverFalse,
    setEditHover6,
    editHover6
  } = useContext(MainContext);

  // Dynamic styling for this component
  const style2 = {
    editOuterDiv: {
      ...style.editOuterDiv,
      backgroundColor: editHover6 && SrRefColour.Neutral1C
    },
    editInnerDiv: {
      ...style.editInnerDiv,
      border: `1px dotted ${SrRefColour.Neutral2B}`
    },

    featureSectionWrap: {
      ...style.textBox,
      ...gradientBgTextBox(secondaryColor, primaryColor, 0.6, invertBackground),
      ...gradientBorderTextBox(secondaryColor, primaryColor, invertBackground),
      ...style.width100
    },
    editButton: {
      position: "absolute",
      top: "11px",
      right: "11px"
    }
  };

  return ifEdit ? (
    <div
      onClick={() => setEdit6(true)}
      onMouseEnter={() => [setAllHoverFalse(), setEditHover6(true)]}
      onMouseLeave={() => setEditHover6(false)}
      style={style2.editOuterDiv}
    >
      <div style={style2.editInnerDiv}>
        <div style={style2.featureSectionWrap}>
          <SrRefTextIntSb15
            content={"Unique features"}
            style={gradientText(secondaryColor, primaryColor, invertBackground)}
          />

          {/* Features text */}
          <div style={style.marginTop6}>
            {startup.pitch.features
              ? newLineText(startup.pitch.features, secondaryColor, primaryColor, invertBackground)
              : newLineText(
                  "Articulate the features of your product/service. These need not be unique but should paint a picture of what a user can do with it.",
                  secondaryColor,
                  primaryColor,
                  invertBackground
                )}
          </div>

          <div style={style2.editButton}>
            <EditBtn0 selected={editHover6} />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <MainComponent />
  );
};

export default Features;
