import { useContext } from "react";
import MainContext from "../../helpers/context/mainContext";

import { SrRefTextIntSb15 } from "../../../ref/text";
import { gradientBgTextBox, gradientBorderTextBox, gradientText } from "../../helpers/themeColors";
import { newLineText } from "../../helpers/themeColors";
import SrRefColour from "../../../ref/colours";

import { EditBtn0 } from "../../lvl0Components/buttons/iconBtns";

import style from "./style";

// EditComponent which renders on startEdit page
const EditComponent = ({
  startup,
  secondaryColor,
  primaryColor,
  invertBackground,
  setEdit7,
  setAllHoverFalse,
  setEditHover7,
  editHover7
}) => {
  return (
    <div
      onClick={() => setEdit7(true)}
      onMouseEnter={() => [setAllHoverFalse(), setEditHover7(true)]}
      onMouseLeave={() => setEditHover7(false)}
      style={{
        ...style.editOuterDiv,
        backgroundColor: editHover7 && SrRefColour.Neutral1C
      }}
    >
      <div
        style={{
          ...style.editInnerDiv,
          border: `1px dotted ${SrRefColour.Neutral2B}`
        }}
      >
        <div
          style={{
            ...style.textWrap,
            ...gradientBgTextBox(secondaryColor, primaryColor, 0.6, invertBackground),
            ...gradientBorderTextBox(secondaryColor, primaryColor, invertBackground)
          }}
        >
          <SrRefTextIntSb15
            content={"Traction"}
            style={gradientText(secondaryColor, primaryColor, invertBackground)}
          />

          {/* Traction text */}
          <div style={style.marginTop6}>
            {startup.pitch.traction
              ? newLineText(startup.pitch.traction, secondaryColor, primaryColor, invertBackground)
              : newLineText(
                  "Please write about metrics focused on product usage, customer behaviour, engagement, other operational aspects and if possible, the unit economics of your business.",
                  secondaryColor,
                  primaryColor,
                  invertBackground
                )}
          </div>

          <div style={{ position: "absolute", top: "11px", right: "11px" }}>
            <EditBtn0 selected={editHover7} />
          </div>
        </div>
      </div>
    </div>
  );
};

// Component which renders on startup page
const Component = ({ startup, secondaryColor, primaryColor, invertBackground }) => {
  // Dynamic styles for this component
  const style2 = {
    outerDiv: {
      ...style.outerDiv,
      ...gradientBgTextBox(secondaryColor, primaryColor, 0.6, invertBackground),
      ...gradientBorderTextBox(secondaryColor, primaryColor, invertBackground)
    }
  };

  return (
    <div style={style2.outerDiv}>
      <SrRefTextIntSb15
        content={"Traction"}
        style={gradientText(secondaryColor, primaryColor, invertBackground)}
      />

      {/* Traction text */}
      <div style={style.marginTop6}>
        {startup.pitch.traction
          ? newLineText(startup.pitch.traction, secondaryColor, primaryColor, invertBackground)
          : newLineText(
              "Talk about how your product solves the pain point you’ve just described in a unique way. How does this set you apart from your competitors?",
              secondaryColor,
              primaryColor,
              invertBackground
            )}
      </div>
    </div>
  );
};

const Traction = () => {
  // State to check if user is on startup page or startupEdit pae
  const {
    ifEdit,
    startup,
    secondaryColor,
    primaryColor,
    invertBackground,
    setEdit7,
    setAllHoverFalse,
    setEditHover7,
    editHover7
  } = useContext(MainContext);

  return ifEdit ? (
    <EditComponent
      startup={startup}
      secondaryColor={secondaryColor}
      primaryColor={primaryColor}
      invertBackground={invertBackground}
      setEdit7={setEdit7}
      setAllHoverFalse={setAllHoverFalse}
      setEditHover7={setEditHover7}
      editHover7={editHover7}
    />
  ) : (
    <Component
      startup={startup}
      secondaryColor={secondaryColor}
      primaryColor={primaryColor}
      invertBackground={invertBackground}
    />
  );
};

export default Traction;
