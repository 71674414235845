import SrRefColour from "../../../ref/colours";

const style = {
  flexRow: {
    display: "flex",
    flexDirection: "row"
  },
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between"
  },
  alignItemsCenter: {
    display: "flex",
    alignItems: "center"
  },
  overflowAuto: {
    overflow: "auto"
  },
  surface: {
    padding: "12px 6px",
    border: `0.5px solid ${SrRefColour.Neutral2A}`,
    borderRadius: "18px",
    // backgroundColor: "rgba(0,0,0,0.06)",
    boxShadow: "inset 0px 2px 4px rgba(0,0,0,0.15)"
  },
  surfaceDim: {
    margin: "0 auto"
  },
  clickable: {
    cursor: "pointer",
    border: "none",
    textDecoration: "none"
  },
  transition: {
    transition: "0.2s ease-in"
  },

  // custom styling for this component
  outerDiv: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  innerDiv: {
    display: "flex",
    flexDirection: "row",
    overflow: "auto",
    padding: "12px 6px",
    border: `0.5px solid ${SrRefColour.Neutral2A}`,
    borderRadius: "18px",
    // backgroundColor: "rgba(0,0,0,0.06)",
    boxShadow: "inset 0px 2px 4px rgba(0,0,0,0.15)"
  }
};

export default style;
