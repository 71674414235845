import { useContext } from "react";
import MainContext from "../../../helpers/context/mainContext";

import OrgDetails from "../../OrgDetails";
import TeamDetails from "../../TeamDetails";
import OtherDetails from "../../OtherDetails";
import Problem from "../../Problem";
import Solution from "../../Solution";
import Infographic from "../../Infographic";
import Video from "../../Video";
import Features from "../../Features";
import Market from "../../Market";
import Traction from "../../Traction";
import Website from "../../Website";
import Challenges from "../../Challenges";
import MentorshipAreas from "../../MentorshipAreas";
import PitchDeck from "../../PitchDeck";

import style from "./style";

const Component = () => {
  const { startup, fullWidthPlus, returnPrimary } = useContext(MainContext);

  // Dynamic styling for this component
  const style2 = {
    outerDiv: {
      ...style.flexColumn,
      ...style.screen
    },
    teamWrap: {
      ...style.flexColumn,
      ...style.width100,
      ...style.marginTop36
    },
    sectionOuterWrap: {
      ...style.flexColumn,
      ...style.spaceBetween,
      ...style.marginHor24,
      ...style.marginTop36,
      borderColor: returnPrimary
    },
    sectionInnerWrap: {
      ...style.flexColumn,
      ...style.spaceBetween,
      ...style.width100
    },

    bottomCenterWrap: {
      ...style.flexColumn,
      ...style.spaceBetween,
      ...style.width100
    },

    bottomBottomWrap: {
      ...style.flexColumn,
      ...style.spaceBetween,
      ...style.alignItemsFlexEnd,
      ...style.width100,
      ...style.marginTop24
    }
  };

  return (
    <div style={style2.outerDiv}>
      {/* Startup listing */}
      <div style={{ ...style.flexColumn, ...{ marginTop: `${74 + 12 + 36}px` } }}>
        {/* Org name, description and industry+sectors chips */}
        <OrgDetails />

        <div style={style2.teamWrap}>
          {/* Team */}
          <TeamDetails />

          {/* Other */}
          <div style={style.marginHor12}>
            <OtherDetails />
          </div>
        </div>
      </div>

      <div style={style2.sectionOuterWrap}>
        {/* Bottom top section */}
        <div style={style2.sectionInnerWrap}>
          {/* Problem */}
          <Problem />

          {/* Solution */}
          <Solution />
        </div>

        {/* Bottom center section */}
        <div style={style2.bottomCenterWrap}>
          {/* Infographic */}
          <div style={style.marginTop24}>
            <Infographic w={fullWidthPlus} startup={startup} />
          </div>

          {/* Video */}
          <Video />
        </div>

        {/* Bottom bottom section */}
        <div style={style2.bottomBottomWrap}>
          {/* Features */}
          <Features />

          {/* Traction */}
          <Traction />

          {/* Target market */}
          <Market />

          {/* Website */}
          <Website />
        </div>
      </div>

      {/* BITS section */}
      <div style={style2.sectionOuterWrap}>
        {/* BITS top section */}
        <div style={style2.sectionInnerWrap}>
          {/* Challenges */}
          <Challenges />
        </div>

        {/* BITS bottom section */}
        <div style={style2.bottomBottomWrap}>
          {/* Mentorship areas */}
          <MentorshipAreas />

          {/* Pitch deck */}
          <PitchDeck />
        </div>
      </div>
    </div>
  );
};

const EditComponent = ({ imageInputRef, setImage, setShowCropper }) => {
  const { startup, returnPrimary, fullWidthPlus } = useContext(MainContext);

  // Dynamic styling for this component
  const style2 = {
    outerDiv: {
      ...style.flexColumn,
      ...style.screen
    },
    innerDiv: { ...style.flexColumn, ...{ marginTop: `${74 + 12 + 36 - 24}px` } },
    orgWrap: {},
    teamWrap: {
      ...style.flexColumn,
      ...{ width: "calc(100%-24px)" },
      ...style.marginTop12,
      ...style.marginHor12
    },
    bottomOuterDiiv: {
      ...style.flexColumn,
      ...style.spaceBetween,
      ...style.marginHor12,
      ...{
        borderColor: returnPrimary
      },
      ...style.marginTop12
    },
    bottomInnerDiv: {
      ...style.flexColumn,
      ...style.spaceBetween,
      ...style.width100
    },
    bottonBottomWrap: {},
    bitsWrap: {
      ...style.flexColumn,
      ...style.spaceBetween,
      ...style.marginHor12,
      ...{
        borderColor: returnPrimary
      },
      ...style.marginTop12
    },
    bitsRightWrap: {
      ...style.flexColumn,
      ...style.spaceBetween,
      ...style.alignItemsFlexEnd,
      ...style.width100
    },
    bitsLeftWrap: {
      ...style.flexColumn,
      ...style.spaceBetween,
      ...style.alignItemsFlexEnd,
      ...style.width100
    }
  };

  return (
    <div style={style2.outerDiv}>
      {/* Startup listing */}
      <div style={style2.innerDiv}>
        {/* Org name, description and industry+sectors chips */}
        <OrgDetails />

        <div style={style2.teamWrap}>
          {/* Team */}
          <TeamDetails />

          {/* Other */}
          <OtherDetails />
        </div>
      </div>

      <div style={style2.bottomOuterDiiv}>
        {/* Bottom top section */}
        <div style={style2.bottomInnerDiv}>
          {/* Problem */}
          <Problem />

          {/* Solution */}
          <Solution />
        </div>

        {/* Bottom center section */}
        <div
          style={{
            ...style.flexColumn,
            ...style.spaceBetween,
            ...style.width100
          }}
        >
          {/* Infographic */}
          <div>
            <Infographic
              w={fullWidthPlus}
              startup={startup}
              imageInputRef={imageInputRef}
              setShowCropper={setShowCropper}
              setImage={setImage}
            />
          </div>

          {/* Video */}
          <Video />
        </div>

        {/* Bottom bottom section */}
        <div style={style2.bottonBottomWrap}>
          <div style={style.width100}>
            {/* Features */}
            <Features />

            {/* Traction */}
            <Traction />

            {/* Target market */}
            <Market />
          </div>

          {/* Website */}
          <Website />
        </div>
      </div>

      {/* BITS section */}
      <div style={style2.bitsWrap}>
        {/* BITS top section */}
        {/* Challenges */}
        <Challenges />

        {/* BITS bottom section */}
        <div style={style2.bitsLeftWrap}>
          {/* Mentorship areas */}
          <MentorshipAreas />

          {/* Website */}
          <PitchDeck />
        </div>
      </div>
    </div>
  );
};

const PhoneScreen = ({ setShowCropper, setImage, imageInputRef }) => {
  const { ifEdit } = useContext(MainContext);

  return ifEdit ? (
    <EditComponent
      imageInputRef={imageInputRef}
      setImage={setImage}
      setShowCropper={setShowCropper}
    />
  ) : (
    <Component />
  );
};

export default PhoneScreen;
