import React from "react";
import { SrRefTextIntLi12 } from "../../../ref/text";
import style from "./style";

const ShramStamp = (props) => {
  return (
    <div style={style.shramStamp}>
      <div style={style.innerDiv}>
        {/* "Powered by" text */}
        {!props.noText && <SrRefTextIntLi12 content={"Powered by"} style={style.colorGrey} />}

        {/* Shram logo */}
        <a
          href="https://www.shram.io/"
          style={style.clickable}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div style={style.imgWrap}>
            <img src={require("../../../media/logo.png")} height={30} alt={"Shram logo"} />
          </div>
        </a>
      </div>
    </div>
  );
};

export { ShramStamp };
