import { useContext } from "react";

import MainContext from "../../helpers/context/mainContext";

import { SrRefTextIntRe12 } from "../../../ref/text";
import { SrCompIconOpenInNew } from "../../lvl0Components/icons";
import { EditBtn0 } from "../../lvl0Components/buttons/iconBtns";

import SrRefColour from "../../../ref/colours";

import {
  white,
  colorMixer,
  concatColorMixer,
  rgbToHex,
  getTextColor,
  gradientText,
  gradientBorderTextBox
} from "../../helpers/themeColors";

import style from "./style";

// EditComponent which renders on startupEdit page
const EditComponent = ({ content }) => {
  const {
    startup,
    primaryColor,
    invertBackground,
    setEdit14,
    setAllHoverFalse,
    editHover14,
    setEditHover14
  } = useContext(MainContext);

  // Dynamic styling for this component
  const style2 = {
    editOuterWrap: {
      ...style.editOuterWrap,
      backgroundColor: editHover14 && SrRefColour.Neutral1C
    },

    editInnerDiv: {
      ...style.editInnerWrap,
      border: `1px dotted ${SrRefColour.Neutral2B}`
    },
    editTextWrap: {
      ...style.editTextWrap,
      ...gradientBorderTextBox(primaryColor, "", invertBackground),
      backgroundColor:
        invertBackground === "Y"
          ? SrRefColour.Neutral0
          : concatColorMixer(
              [primaryColor.r, primaryColor.g, primaryColor.b],
              white,
              primaryColor.a
            )
    },

    editButton: {
      position: "absolute",
      top: "11px",
      right: "11px"
    }
  };

  return (
    <div
      onClick={() => setEdit14(true)}
      onMouseEnter={() => [setAllHoverFalse(), setEditHover14(true)]}
      onMouseLeave={() => setEditHover14(false)}
      style={style2.editOuterWrap}
    >
      <div style={style2.editInnerDiv}>
        <div style={style2.editTextWrap}>
          {/* Website text */}
          <SrRefTextIntRe12
            content={content}
            style={{
              ...style.marginRight6,
              ...gradientText(startup.theme.primary.color, "", startup.theme.invertBackground)
            }}
          />

          {/* Website icon */}
          <SrCompIconOpenInNew
            size={24}
            color={getTextColor(
              rgbToHex(
                colorMixer([primaryColor.r, primaryColor.g, primaryColor.b], white, primaryColor.a)
              ),
              invertBackground
            )}
          />
        </div>

        <div style={style2.editButton}>
          <EditBtn0 selected={editHover14} />
        </div>
      </div>
    </div>
  );
};

// Component which render on startup page
const Component = ({ content }) => {
  const { startup, primaryColor, invertBackground, cardHover4 } = useContext(MainContext);

  //Dynamic style for this component
  const style2 = {
    outerDiv: {
      ...style.outerDiv,
      ...gradientBorderTextBox(primaryColor, "", invertBackground),
      backgroundColor: cardHover4
        ? invertBackground === "Y"
          ? concatColorMixer(
              [primaryColor.r, primaryColor.g, primaryColor.b],
              white,
              primaryColor.a * 0.06
            )
          : concatColorMixer(
              [primaryColor.r, primaryColor.g, primaryColor.b],
              white,
              primaryColor.a * 1.2
            )
        : invertBackground === "Y"
        ? SrRefColour.Neutral0
        : concatColorMixer([primaryColor.r, primaryColor.g, primaryColor.b], white, primaryColor.a)
    },
    textStyle: {
      ...style.marginRight6,
      ...gradientText(startup.theme.primary.color, "", startup.theme.invertBackground)
    }
  };

  return (
    <div style={style2.outerDiv}>
      {/* Website text */}
      <SrRefTextIntRe12 content={content} style={style2.textStyle} />

      {/* Website icon */}
      <SrCompIconOpenInNew
        size={24}
        color={getTextColor(
          rgbToHex(
            colorMixer([primaryColor.r, primaryColor.g, primaryColor.b], white, primaryColor.a)
          ),
          invertBackground
        )}
      />
    </div>
  );
};

const PitchDeckBtn = ({
  content,
  startup,
  primaryColor,
  invertBackground,
  cardHover4,
  setEditHover14,
  editHover14,
  setAllHoverFalse,
  setEdit14
}) => {
  // ifEdit state to check if user is on startup page or startupEdit page
  const { ifEdit } = useContext(MainContext);

  return ifEdit ? <EditComponent content={content} /> : <Component content={content} />;
};

export default PitchDeckBtn;
