import { useContext } from "react";
import MainContext from "../../helpers/context/mainContext";
import PitchDeckBtn from "../PitchDeckBtn";
// import WebsiteBtn from "../WebsiteBtn";
import style from "./style";

// EditComponent which is visible on the startupEdit page
const EditComponent = () => {
  const {
    startup,
    primaryColor,
    invertBackground,
    editHover14,
    setEdit14,
    setAllHoverFalse,
    setEditHover14
  } = useContext(MainContext);

  return startup?.pitch.PitchDeck ? (
    <WebsiteBtn
      content={"Pitch deck"}
      startup={startup}
      primaryColor={primaryColor}
      invertBackground={invertBackground}
      setEdit14={setEdit14}
      setAllHoverFalse={setAllHoverFalse}
      editHover14={editHover14}
      setEditHover14={setEditHover14}
    />
  ) : (
    <PitchDeckBtn
      content={"Your pitch deck url"}
      startup={startup}
      primaryColor={primaryColor}
      invertBackground={invertBackground}
      setEdit14={setEdit14}
      setAllHoverFalse={setAllHoverFalse}
      editHover14={editHover14}
      setEditHover14={setEditHover14}
    />
  );
};

// Component which is visible on the startup page
const Component = () => {
  const { startup, primaryColor, invertBackground, setCardHover4, cardHover4 } =
    useContext(MainContext);

  return startup.pitch.pitchDeck ? (
    <a
      onMouseEnter={() => setCardHover4(true)}
      onMouseLeave={() => setCardHover4(false)}
      href={`${startup?.pitch.pitchDeck}`}
      target="_blank"
      rel="noopener noreferrer"
      style={style.linkStyle}
    >
      <PitchDeckBtn
        startup={startup}
        primaryColor={primaryColor}
        invertBackground={invertBackground}
        cardHover4={cardHover4}
        content={"Pitch deck"}
      />
    </a>
  ) : (
    <div style={style.outerDiv}>
      <PitchDeckBtn
        startup={startup}
        primaryColor={primaryColor}
        invertBackground={invertBackground}
        cardHover4={cardHover4}
        content={"Your pitch deck url"}
      />
    </div>
  );
};

const PitchDeck = () => {
  // ifEdit state to check if user is on startup page or startupEdit page
  const { ifEdit } = useContext(MainContext);
  return ifEdit ? <EditComponent /> : <Component />;
};

export default PitchDeck;
